import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DadosPagtoInfo } from 'src/app/core/models/dadosPagtoInformado';
import { Fornecimento } from 'src/app/core/models/fornecimento';

@Component({
  selector: 'app-confirma-pagto',
  templateUrl: './confirma-pagto.component.html',
  styleUrls: ['./confirma-pagto.component.scss'],
})
export class ConfirmaPagtoComponent implements OnInit {
  @Input() fornecimento: Fornecimento | null = new Fornecimento();
  @Input() dados: DadosPagtoInfo = new DadosPagtoInfo();
  @Input() protocolo: string = '';
  @Output() comunicaOutro: EventEmitter<any> = new EventEmitter();
  @Output() comunica: EventEmitter<any> = new EventEmitter();

  status: string = '';
  perg1: string = '';
  etapa: number = 1;

  ngOnInit() {
    this.status = this.fornecimento?.status.toUpperCase() ?? '';
    if (this.status === 'ATIVO' || this.status === 'SUPRIMIDO - A PEDIDO') {
      this.comunicar();
      this.etapa = 3;
    }
  }

  voltar() {
    this.etapa -= 1;
  }

  continuar() {
    if (this.etapa === 2) {
      this.comunicar();
      return;
    }
    this.etapa += 1;
  }

  habilitaContinuar(): boolean {
    if(this.etapa === 1) return this.perg1 !== '';
    return true;
  }

  comunicar() {
    if (this.perg1 !== '1') {
      this.dados.subtipoProcesso = '3'
    }
    this.comunica.emit(this.dados);
  }

  comunicarOutro() {
    this.comunicaOutro.emit(true);
  }
}

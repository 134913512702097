import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  // Observador responsável por notificar ouvintes de mudança
  private themeSource = new BehaviorSubject<string>('default-theme');
  // Variável publica para acessar o valor e receber as alterações
  public currentTheme = this.themeSource.asObservable();

  // Populando a variável com o valor gravado em memória
  constructor() {
    this.changeTheme(`${localStorage.getItem('activeTheme') || 'default-theme'}`);
  }

  // Função para alterar o valor no observador
  changeTheme(theme: string) {
    this.themeSource.next(theme);
    this.saveTheme(theme);
  }

  // Função responsável por saltar a alteração de valores em sessão
  saveTheme(themeSaved: string) {
    localStorage.setItem('activeTheme', themeSaved);
  }
}

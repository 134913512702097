import { Component, OnInit, Input } from '@angular/core';
import { OutrosDetalhes } from 'src/app/core/models/pagamentoFaturaItens';
import { PagamentoFaturaService } from 'src/app/shared/components/pagamento-fatura/pagamento-fatura.service';

@Component({
  selector: 'app-pagto-app',
  templateUrl: './pagto-app.component.html',
  styleUrls: ['./pagto-app.component.scss'],
})
export class PagtoAppComponent implements OnInit {
  outros: OutrosDetalhes = {} as OutrosDetalhes;
  @Input() banco: number = 0;
  @Input() valor: number = 0;

  constructor(
    private readonly pagamentoFaturaService: PagamentoFaturaService
  ) {}

  ngOnInit(): void {
    this.outros =
      this.pagamentoFaturaService.getInfoPagamentoFaturaItens().outrosDetalhes;
  }

  getUrlBanco(nomeBanco: string): string {
    const bancos : any = {
      caixaEconomica: 'https://internetbanking.caixa.gov.br/',
      bancoBrasil: 'https://www.bb.com.br/site/',
      bradesco: 'https://banco.bradesco/html/classic/index.shtm',
      itau: 'https://www.itau.com.br/',
      santander: 'https://www.santander.com.br/'
    };

    return bancos[nomeBanco] || '';
  }

  redirecionaBanco(nomeBanco: string) {
    const userAgent = navigator.userAgent.toLowerCase();

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    ) {
      setTimeout(() => {
        window.location.href = this.getUrlBanco(nomeBanco);
      }, 1000);
    } else {
      window.open(this.getUrlBanco(nomeBanco), '_blank');
    }
  }
}

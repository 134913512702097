import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'agv-registered-password',
  templateUrl: './registered-password.component.html',
  styleUrls: ['./registered-password.component.scss']
})
export class RegisteredPasswordComponent implements OnInit {

  @Output() setPassword: EventEmitter<any> = new EventEmitter<string>();
  @Output() setKeep: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() setVoltar: EventEmitter<any> = new EventEmitter<boolean>();
  @Input() name: string = "";
  @Input() labelBtn: string = 'Entrar';
  @Input() showVoltar: boolean = false;
  @Input() isLastComponent: boolean = false;
  showPass: boolean = false;
  password: string = '';
  manterConectado: boolean = false;
  senhaIncorreta: boolean = false;
  mensagemErro: string = "";
  isMobile: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
    window.onresize = () => (this.isMobile = window.innerWidth <= 768);
  }

  entrar() {
    if(this.password == "senhaerrada"){
      this.senhaIncorreta = true;
      this.mensagemErro = "A senha não corresponde ao cadastro. Por favor, digite outra vez. <a href='/recuperar-senha'>Esqueceu sua senha</a>?"
    }
    else
      this.setPassword.emit(this.password)
  }

  keep() {
    this.setKeep.emit(this.manterConectado)
  }

  recuperar(){
    this.router.navigate(['/recuperar-senha']);
  }

  verificaSeErro(){
    if(this.senhaIncorreta == true){
      this.senhaIncorreta = false;
    }
  }

  voltar(){
    this.setVoltar.emit()
  }

}

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { EventEmitterService } from 'src/app/event-emits.service';
import { Regex } from 'src/app/shared/utils/regex';

@Component({
  selector: 'app-fornecimento',
  templateUrl: './fornecimento.component.html',
  styleUrls: ['./fornecimento.component.scss'],
})
export class FornecimentoComponent implements OnInit {
  @Input() buttonLabel: string = 'Ver conta';
  @Input() findSupplyLabel: string = 'Localize o fornecimento em sua conta';
  @Input() nomeTitular: string = '';
  @Input() enderecoTitular: string = '';
  @Input() mensagemErro: string = '';
  
  @Output() limpaForn: EventEmitter<boolean> = new EventEmitter();
  @Output() validaFornecimento: EventEmitter<any> = new EventEmitter();

  @ViewChild('popupFornecimento') popupFornecimentoElement: ElementRef;
  @ViewChild('linkPopupImgFatura') linkPopupImgFaturaElement: ElementRef;

  codFornecimento: string = '';

  mostrarFaturaSabesp: boolean = false;
  mostrarInfoFaturas: boolean = true;
  salvaForn: boolean = false;
  
  limpouFornecimento: boolean = true;
  buscaFornecimento: boolean = false;

  constructor(
    private tokenService: TokenStorageService
  ) {}

  ngOnInit(): void {
    if (
      document.cookie
        .split('; ')
        .find((row) => row.startsWith('Fornecimento='))
        ?.split('=')[1]
        .split(',')[0] !== undefined
    ) {
      this.codFornecimento =
        `${document.cookie
          .split('; ')
          .find((row) => row.startsWith('Fornecimento='))
          ?.split('=')[1]
          .split(',')[0]}`;

      this.salvaForn = true;
    }
    const checkFornecimento = localStorage.getItem('saveFornecimento');
    if (checkFornecimento) {
      this.salvaForn = checkFornecimento === 'true';
    } else {
      this.salvaForn = false;
    }
  }

  pesquisarFornecimento() {
    this.mensagemErro = '';
    this.buscaFornecimento = true;
    this.validaFornecimento.emit(this.codFornecimento);
    EventEmitterService.get('limpaForn').subscribe((valor) => {
      this.mostrarInfoFaturas = valor;
      this.limpouFornecimento = valor;
      this.buscaFornecimento = valor;
    });
  }

  limpaFornecimento() {
    this.limpaForn.emit(true);
    this.mostrarInfoFaturas = true;
    this.limpouFornecimento = true;
    this.buscaFornecimento = false;
    this.codFornecimento = '';
    this.nomeTitular = '';
    this.enderecoTitular = '';
    this.tokenService.removeDadosTitular();
  }

  validaForn(elemento: HTMLInputElement) {
    elemento.value = Regex.inputNumero(elemento.value);
  }

  carragaImagemFaturaSabesp() {
    this.mostrarFaturaSabesp = !this.mostrarFaturaSabesp;
    if (this.mostrarFaturaSabesp) {
      setTimeout(() => {
        //Carrega o focus na mensagem de erro
        this.popupFornecimentoElement?.nativeElement?.focus();
      }, 0);
    } else {
      setTimeout(() => {
        //Carrega o focus na mensagem de erro
        this.linkPopupImgFaturaElement?.nativeElement?.focus();
      }, 0);
    }
  }

  salvaFornecimento(event: any) {
    this.salvaForn = !this.salvaForn;
    if (this.salvaForn) {
      localStorage.setItem('codFornecimento', this.codFornecimento);
      document.cookie = `Fornecimento=${this.codFornecimento},path=/`;
    } else {
      localStorage.removeItem('codFornecimento');
      document.cookie = `Fornecimento=`;
    }
    localStorage.setItem('saveFornecimento', this.salvaForn.toString());
  }
}

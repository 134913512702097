import { text } from "stream/consumers";

export class Regex {
    static formataNascimentoToUS(data: string) {
        return data.split('/').reverse().join('-');
    }

    static formataNascimentoToBRL(data: string) {
        return data.split('-').reverse().join('/');
    }

    static removeEspacos(informacao: string) {
        return informacao.replace(/\s/g, '')
    }

    static formataTipoCivico(tipoCivico: string): string {
        switch (tipoCivico) {
            case 'GLEBA':
                return 'glb';
            case 'QUILÔMETRO':
                return 'km';
            case 'LOTE':
                return 'lot';
            case 'NÚMERO':
                return 'nº';
            case 'QUADRA':
                return 'qd';
            case 'SEM NÚMERO':
                return 's.n';
            default:
                return '';
        }
    }

    static formataDescVencimento(codVencimento: string) {
        switch (codVencimento) {
            case 'F_D10':
                return 'Vencimento dia 10'
            case 'F_D20':
                return 'Vencimento dia 20'
            case 'F_D25':
                return 'Vencimento dia 25'
            case 'F_9DU_DDE':
                return 'Vencimento 7º dia útil'
            case 'F_D05':
                return 'Vencimento dia 5'
            case 'F_D01':
                return 'Vencimento dia 1'
            case 'F_D15':
                return 'Vencimento dia 15'
            case 'F_D25_MS':
                return 'Vencimento dia 25 do mês subsequente'
            case 'F_1DU_2MS':
                return 'Vencimento dia 1 do segundo mês subsequente'
            case 'F_D20_MS':
                return 'Vencimento dia 20 do mês subsequente'
            case 'F_D01_MS':
                return 'Vencimento dia 1 do mês subsequente'
            case 'F_D23_MS':
                return 'Vencimento dia 23 do mês subsequente'
            case 'F_D05_MS':
                return 'Vencimento dia 5 do mês subsequente'
            case 'F_D10_MS':
                return 'Vencimento dia 10 do mês subsequente'
            case 'F_D15_MS':
                return 'Vencimento dia 15 do mês subsequente'
            case 'AD_PROXDIA25':
                return 'Vencimento próximo dia 25'
            case 'F_D01_S9U':
                return 'Vencimento dia 1 imediatamente subsequente'
            default:
                return ''
        }
    }


    static maskCpf(cpf: string): string {
        cpf = cpf.replace(/\D/g, "")
        if (cpf.length > 11) {
            cpf = cpf.substring(0, 11);
        }

        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

        return cpf
    }

    static removeMaskCpf(cpf: string): string {
        return cpf.replace(/\./g, "").replace(/\-/g, "")
    }

    static maskCnpj(cnpj: string): string {
        const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
        const maskedCnpj = cnpj.replace(cnpjRegex, '$1.$2.$3/$4-$5');
        return maskedCnpj;
    }

    static removeMaskCnpj(cnpj: string): string {
        const maskremoved = cnpj.replace(/[^\d]+/g, "");
        return maskremoved;
    }

    static removeMaskTelefone(telefone: string): string {
        return telefone.replace(" ", "").replace(/\-/g, "").replace(/\(/g, "").replace(/\)/g, "")
    }

    static maskTelefone(telefone: string): string {
        telefone = telefone.replace(/\D/g, "")
        if (telefone.length > 11) {
            telefone = telefone.substr(-11)
        }
        telefone = telefone.replace(/^(\d)/, "($1")
        telefone = telefone.replace(/(.{3})(\d)/, "$1) $2")
        if (telefone.length == 9) {
            telefone = telefone.replace(/(.{1})$/, "-$1")
        } else if (telefone.length == 10) {
            telefone = telefone.replace(/(.{2})$/, "-$1")
        } else if (telefone.length == 11) {
            telefone = telefone.replace(/(.{3})$/, "-$1")
        } else if (telefone.length == 12) {
            telefone = telefone.replace(/(.{4})$/, "-$1")
        } else if (telefone.length > 12) {
            telefone = telefone.replace(/(.{4})$/, "-$1")
        }
        return telefone;
    }

    static inputNumero(valor: string): string {
        const rgx = /[0-9]+/g;
        let result = '' + valor.match(rgx);
        if (result == 'null')
            return '';
        else
            return result.replace(" ", "").replace(/\-/g, "").replace(/\(/g, "").replace(/\)/g, "").replace(".", "").replace(",", "");
    }

    static maskCEP(cep: string): string {
        cep = cep.replace(/\D/g, "")
        if (cep.length > 8) {
            cep = cep.substring(0, 8);
        }

        cep = cep.replace(/(\d{5})(\d{1,3})$/, "$1-$2")

        return cep;
    }

    static isValidEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    static removerAcentos(text: string): string {
        text = text?.toLowerCase();
        text = text?.replace(new RegExp(/[\xE0-\xE6]/g), 'a');
        text = text?.replace(new RegExp(/[\xE8-\xEB]/g), 'e');
        text = text?.replace(new RegExp(/[\xEC-\xEF]/g), 'i');
        text = text?.replace(new RegExp(/[\xF2-\xF6]/g), 'o');
        text = text?.replace(new RegExp(/[\xF9-\xFC]/g), 'u');
        text = text?.replace(new RegExp(/\xE7/g), 'c');
        text = text?.replace(new RegExp(/\xF1/g), 'n');
        return text;
    }

    static capitalize(text: string): string {
        let list = text.split(' ');
        text = '';
        for (let index = 0; index < list.length; index++) {
            let word = list[index];
            text = `${text} ${word.toLowerCase().charAt(0).toLocaleUpperCase() + word.substr(1, word.length - 1).toLowerCase()}`;
        }
        return text;

    }

    static bandeiraCartao(numeroCartao: string): string {
        const visa = /^(?!504175|506699|5067|509|6500|6501|4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|627780|63(6297|6368|6369)|65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8])))4[0-9]{12}(?:[0-9]{3})?$/;
        const mastercard = /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;
        const elo = /(4011|431274|438935|451416|457393|4576|457631|457632|504175|627780|636297|636368|636369|(6503[1-3])|(6500(3[5-9]|4[0-9]|5[0-1]))|(6504(0[5-9]|1[0-9]|2[0-9]|3[0-9]))|(650(48[5-9]|49[0-9]|50[0-9]|51[1-9]|52[0-9]|53[0-7]))|(6505(4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8]))|(6507(0[0-9]|1[0-8]))|(6507(2[0-7]))|(650(90[1-9]|91[0-9]|920))|(6516(5[2-9]|6[0-9]|7[0-9]))|(6550(0[0-9]|1[1-9]))|(6550(2[1-9]|3[0-9]|4[0-9]|5[0-8]))|(506(699|77[0-8]|7[1-6][0-9))|(509([0-9][0-9][0-9])))/;
        const diners = /^3(?:0[0-5]|[68]\d)\d{11}$/;
        const hipercard = /^(606282\d{10}(\d{3})?)|(3841\d{12})|(637\d{13})$/;

        if (visa.test(numeroCartao)) {
            return 'visa';
        } else if (mastercard.test(numeroCartao)) {
            return 'mastercard';
        } else if (elo.test(numeroCartao)) {
            return 'elo';
        } else if (diners.test(numeroCartao)) {
            return 'diners';
        } else if (hipercard.test(numeroCartao)) {
            return 'hipercard';
        } else {
            return '';
        }
    }
}

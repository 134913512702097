import { createAction, props } from "@ngrx/store";
import { DadosPagtoInfo, DadosPagtoInfoProtocolo } from "../../models/dadosPagtoInformado";

export const comunicaPagamento = createAction(
    `[Pagamento] comunica pagamento`,
    props<{ req: DadosPagtoInfo}>()
);

export const sucessoComunicaPagamento = createAction(
    `[Pagamento] sucesso ao comunicar pagamento`,
    props<{ response: DadosPagtoInfoProtocolo }>()
);

export const erroComunicaPagamento = createAction(
    `[Pagamento] falha ao comunicar pagamento`,
    props<{ error: any }>()
  );
<div class="cardFaturaMobileContainer" *ngIf="faturaRevisadaData?.documentoId">
  <div class="cardFaturaMobileContent">
    <mat-card class="cardFaturaMobile">
      <table  [ngClass]="{'border-dark': currentTheme === 'theme-dark'}" class="cardFaturaMobile__table" tabindex="0">
        <thead>
          <tr>
            <th role="columnheader">Data de emissão</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td role="cell" tabindex="0">
              {{ faturaRevisadaData?.dataEmissao | date : "dd/MM/yyyy" }}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th role="columnheader">Valor da fatura</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td role="cell" tabindex="0">
              {{ faturaRevisadaData?.valor | currency : "BRL" }}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th role="columnheader">Data de vencimento</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td role="cell" tabindex="0">
              {{ faturaRevisadaData?.dataVencimento | date : "dd/MM/yyyy" }}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th role="columnheader">Situação</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td role="cell" tabindex="0" style="color: #DC803E;">
              {{ faturaRevisadaData.estadoSaldoPagamento.charAt(0).toUpperCase() +
                faturaRevisadaData.estadoSaldoPagamento.slice(1).toLowerCase() }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="cardFaturaMobile__btns">
        <button class="btn-custom" disabled>
          <div tabindex="0">
            <agv-icon [ngClass]="{'dark-theme': currentTheme === 'theme-dark'}" class="trocaCor" [name]="'icn_copy'" title="Ícone de copiar" ></agv-icon>
            'Copiar'
          </div>
        </button>

        <button class="btn-custom" (click)="downloadFaturaCompleta(faturaRevisadaData, true)">
          <div tabindex="0">
            <agv-icon [ngClass]="{'dark-theme': currentTheme === 'theme-dark'}" class="trocaCor" [name]="'icn_lupa'" title="Ícone de visualizar"></agv-icon>
            Exibir
          </div>
        </button>

        <button class="btn-custom" [fxHide]='liberaLoadingDownload'
          (click)="downloadFaturaCompleta(faturaRevisadaData, false)">
          <div tabindex="0">
            <agv-icon [ngClass]="{'dark-theme': currentTheme === 'theme-dark'}" class="trocaCor" [name]="'icn-download'" title="Ícone de baixar"></agv-icon>
            Baixar
          </div>
        </button>
        <button [fxHide]='!liberaLoadingDownload' class="loadingDownload btn-custom">
          <div>
            <div class="loadingDownloadLayout">
              <agv-icon class="loadingDownload__img" [ngClass]="{'dark-theme': currentTheme === 'theme-dark'}" [fxHide]="downloadExec" title="Loading branco" [name]="'icn-load-branco16'"></agv-icon>
              <agv-icon [ngClass]="{'dark-theme': currentTheme === 'theme-dark'}" [fxHide]="!downloadExec" title="Loading branco" [name]="'button-check16'"></agv-icon>
              <p>Aguarde</p>
            </div>
          </div>
        </button>
      </div>
    </mat-card>
  </div>
</div>
<div *ngIf="!faturaRevisadaData?.documentoId && !cobrancaJuridica" class="warning-mobile">
  <agv-icon role="img" [ngClass]="{'dark-theme': currentTheme === 'theme-dark'}" [name]="'icon_atencao'" title="Ícone de alerta"></agv-icon>
  <p>Esta fatura não possui conta revisada.</p>
</div>
<div *ngIf="cobrancaJuridica" class="warning-mobile">
  <agv-icon role="img" [ngClass]="{'dark-theme': currentTheme === 'theme-dark'}" [name]="'icon_atencao'" title="Ícone de alerta"></agv-icon>
  <p>Esta fatura não possui conta revisada e está em cobrança jurídica, portanto não poderá ser exibida.</p>
</div>

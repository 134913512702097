import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { ThemeService } from '../../observables/theme.service';

@Component({
  selector: 'agv-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges, OnInit {
  // Controlador do tema
  currentTheme: string;

  @Input() currentPage: number;
  @Input() pageSize: number;
  @Input() data: any[];
  @Output() pageChanged = new EventEmitter<any[]>();
  
  constructor(
    private theme: ThemeService
  ) {}

  get totalPages() {
    return Math.ceil(this.data.length / this.pageSize ?? 1);
  }

  get pages() {
    const pages = [];
    for (let i = 1; i <= this.totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }

  ngOnInit(): void {
    this.theme.currentTheme.subscribe(theme => this.currentTheme = theme);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentPage = 1; // reset page when changes
    this.updateCurrentPageData();
  }

  updateCurrentPageData() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    const currentPageData = this.data.slice(startIndex, endIndex);
    this.pageChanged.emit(currentPageData);
  }

  onChangePage(page: number, event: any) {
    event.preventDefault();

    this.currentPage = page;
    this.updateCurrentPageData();
  }

  onPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateCurrentPageData();
    }
  }

  onNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updateCurrentPageData();
    }
  }

  onFirstPage() {
    this.currentPage = this.pages[0];
    this.updateCurrentPageData();
  }

  onLastPage() {
    this.currentPage = this.pages[this.pages.length - 1];
    this.updateCurrentPageData();
  }
}

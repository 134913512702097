import { LeituraFatura } from './leituraFatura';
export class DadosFatura {
  leituraAtual: LeituraFatura;
  leituraAnterior: LeituraFatura;
  dataProximaLeitura: Date;

  constructor() {
    this.leituraAtual = new LeituraFatura();
    this.leituraAnterior = new LeituraFatura();
    this.dataProximaLeitura = new Date();
  }
}

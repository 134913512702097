export class FaturaEmail{
    email: string;
    emailConfirma: string;
    codigoFornecimento: string;
    codigoRepresentante?: string;
    cnpj?: string;

    constructor(email: string, emailConfirma:string, codigoFornecimento:string, codigoRepresentante?:string, cnpj?: string) {
        this.email = email;
        this.emailConfirma = emailConfirma;
        this.codigoFornecimento = codigoFornecimento;
        this.codigoRepresentante = codigoRepresentante;
        this.cnpj = cnpj;
    }
}

class Endereco {
    municipio: string;
    logradouro: string;
    numeroImovel: string;
    complemento: string;
    pontoReferencia: string;
    bairro: string;

    constructor() {
        this.municipio = '';
        this.logradouro = '';
        this.numeroImovel = '';
        this.complemento = '';
        this.pontoReferencia = '';
        this.bairro = '';
    }
}

export class ResponseProcotolo {
    protocolo: string;
  
    constructor() {
      this.protocolo = '';
    }
}
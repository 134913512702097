import { catchError, map, mergeMap, of } from 'rxjs';
import {
  failedLoadingClientInformation,
  loadingAuth,
  loadingClientInformation,
  successAuth,
  failedAuth,
  validaCPF,
  sucessoValidacao,
  falhaValidacao,
  cadastraNovaSenha,
  sucessoCadastraNovaSenha,
  erroCadastraNovaSenha,
} from './user.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from '../../../../environments/environment';
import { Auth } from '../../models/auth';
import { ClienteResumido } from '../../models/clienteSimplificado';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';

@Injectable({
  providedIn: 'root',
})
export class UserEffectService {
  private readonly BASE_URL = `${environment.uri}`;

  constructor(
    private actions$: Actions, 
    private http: HttpClient,
    private tokenStorage:TokenStorageService) { }

  loadingAuthEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(loadingAuth),
      mergeMap((action) => {
        const headers = new HttpHeaders({
          'X-ReCaptcha-Token': action.recaptcha,
        });
        return this.http
          .post<Auth>(`${this.BASE_URL}v1/auth`, action.payload, { headers })
          .pipe(
            map((auth) => {
              localStorage.setItem('user', JSON.stringify(auth));
              return successAuth({ payload: auth });
            }),
            catchError(() => of(failedAuth()))
          );
      })
    )
  );

  validarCPF = createEffect(() =>
    this.actions$.pipe(
      ofType(validaCPF),
      mergeMap((action) =>
        this.http
          .get<ClienteResumido>(
            `${this.BASE_URL}v1/troca-titularidade/cliente/${action.cpf}/valida`
          )
          .pipe(
            map((cliente) => {
              return sucessoValidacao({ cliente });
            }),
            catchError(() => of(falhaValidacao()))
          )
      )
    )
  );

  alterarSenha = createEffect(() =>
    this.actions$.pipe(
      ofType(cadastraNovaSenha),
      mergeMap((action) => {
        const authToken = this.tokenStorage.getToken();
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
        return this.http
          .post<null>(`${this.BASE_URL}v1/completa/fatura/fornecimento/cliente/logado/senha`, action.ReqAlteraSenha, {headers, observe: 'response' })
          .pipe(
            map((response) => {
              return sucessoCadastraNovaSenha({ status: response.status });
            }),
            catchError(error => {
              return of(erroCadastraNovaSenha({ error: error }))
            })
          );
      })
    )
  );

}

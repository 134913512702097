<div>
  <div tabindex="0" mat-dialog-content>
    <h3>{{data.title}}</h3>
    <p [innerHTML]="data.content"></p>
  </div>
  <div mat-dialog-actions class="footer-modal">
    <button class="button" role="group" aria-label="Fechar modal" tabindex="0" (keypress)="fecharModal($event)" (click)="fecharModal($event)">
      <mat-icon id="botao-modal-fechar">highlight_off</mat-icon>
    </button>
  </div>
</div>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import { ListaDadosModel } from 'src/app/core/models/listaDados';

@Component({
  selector: 'app-lista-dados',
  templateUrl: './lista-dados.component.html',
  styleUrls: ['./lista-dados.component.scss']
})
export class ListaDadosComponent {
  constructor(
  ){ }

  @Input() titulo: string | undefined = '';
  @Input() dados: ListaDadosModel[] | null | undefined = [];
  @Input() editar: boolean | undefined = false;
  @Output() editEvent = new EventEmitter<boolean>();

  edit() {
    this.editEvent.emit(true)
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export var carregaOpcao: number = 0;

@Component({
  selector: 'app-sucesso-nova-senha',
  templateUrl: './sucesso-nova-senha.component.html',
  styleUrls: ['./sucesso-nova-senha.component.scss']
})
export class SucessoNovaSenhaComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  voltar(){
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['']);
    carregaOpcao = 1;
  }

}

<div id="cardSemFornecimento" class="cardContainer">
    <div id="cardSemFornecimento-conteudo" class="cardContainer_content">
        <h3>Você não possui fornecimentos em seu nome</h3>
        <div class="cardContainer_content_description">
            <p class="title">
                Para acessar os serviços da agência virtual, é necessário que um fornecimento esteja associado ao seu CPF. 
                <br/>
                Acesse um dos serviços abaixo para prosseguir:
            </p>
            <div class="cardContainer_boxImgDescription">
                <agv-icon [name]="'icn_transf_titularidade'"></agv-icon>
                <div class="trocaTitularidade">
                    <h4><span role="link" [routerLink]="'/troca-titularidade/identificacao'">Transferência de titularidade</span></h4>
                    <p>Faça a transferência de um fornecimento que já existe para o seu CPF.</p>
                </div>
            </div>
        </div>
    </div>
</div>
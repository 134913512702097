<ng-container *ngTemplateOutlet="buttonConfig?.text ? text : image">
</ng-container>

<!-- Text Button -->
<ng-template #text>
  <button
          [ngClass]="{'disabled': isDisabled}"
          mat-button type="button"
          [ngStyle]="buttonConfig?.styles"
          [disabled]="isDisabled"
          (click)="onClickButton($event)">
    {{ buttonConfig?.text }}
  </button>
</ng-template>

<!-- Image Button -->
<ng-template #image>
  <img alt="image button"
       [src]="buttonConfig?.src"
       [ngStyle]="buttonConfig?.styles"
       (click)="onClickButton($event)">
</ng-template>

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Validator } from '../../utils/validator';
import { Subject, takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { ISupplyState } from 'src/app/core/store/supply/supply.reducer';
import { Store } from '@ngrx/store';
import { Fornecimento } from 'src/app/core/models/fornecimento';

@Component({
  selector: 'agv-input-select-endereco',
  templateUrl: './inputSelectEndereco.component.html',
  styleUrls: ['./inputSelectEndereco.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class InputSelectEnderecoComponent implements OnInit{
  @Output() enviarFornecimentoSelected: EventEmitter<Fornecimento> = new EventEmitter();
  @Output() sendAction = new EventEmitter<any>();
  @Input() listaFornec: Fornecimento[];
  @Input() erro: boolean = false;
  @Input() mostraNovaOpcao: boolean = false;
  @Input() habilita: boolean = true;
  @Input() loading: boolean = false;
  @Input() text: string = '';


  showOptions: boolean
  fornecChoosed: string;
  msgErro: string;

  constructor(
    private updates$: Actions,
    private store: Store<{ supply: ISupplyState }>,) {
    this.showOptions = false;
  }

  ngOnInit(): void {}

  openSelect(e: Event) {
    Validator.validaEventKeyPressClick(e) ? this.showOptions = true : '';
  }

  closeSelect() {
    this.showOptions = false;
  }

  chooseFornec(e: Event, fornecimento: Fornecimento | string) {
    Validator.validaEventKeyPressClick(e) ? this.emiteFornecimento(fornecimento) : '';
    this.validaEntrada();
    this.closeSelect();
  }

  emiteFornecimento(fornecimento: Fornecimento | string) {
    typeof(fornecimento) !== 'string' ? this.fornecChoosed = `${fornecimento.endereco}, ${fornecimento.numero} - ${fornecimento.cidade} - ${fornecimento.uf}`: this.fornecChoosed = fornecimento;
    this.enviarFornecimentoSelected.emit(typeof(fornecimento) !== 'string'? fornecimento : undefined);
    this.closeSelect();
  }

  validaEntrada() {
    this.fornecChoosed ? this.msgErro = '' : this.msgErro = 'Selecione um fornecimento para continuar com sua solicitação.'
  }

  action(e: any) {
    if (!this.loading) {
      this.sendAction.emit();
    }
  }

}

import { PayloadEfetivaParcelamento, PayloadEfetivaReparcelamento } from '../../models/reqBody/parcelamento/payloadEfetivaParcelamento';
import { ResponseAtualizaParcelamento } from '../../models/reqBody/parcelamento/ResponseAtualizaParcelamento';
import { RequestSimularReparcelamento } from '../../models/reqBody/parcelamento/reqSimulaReparcelamento';
import { RequestSimularParcelamento } from '../../models/reqBody/parcelamento/reqSimulaParcelamento';
import {  IResponseSimularParcelamento } from '../../models/parcelamento';
import { createAction, props } from '@ngrx/store';

export const simulaParcelamento = createAction(
  `[Parcelamento] Simular parcelamento`,
  props<{ req: RequestSimularParcelamento }>()
);

export const simularReparcelamento = createAction(
  `[Reparcelamento] Simular reparcelamento`,
  props<{ req: RequestSimularReparcelamento }>()
);

export const sucessoSimulaParcelamento = createAction(
  `[Parcelamento] sucesso na simulação`,
  props<{ dados: IResponseSimularParcelamento }>()
);

export const erroSimulaParcelamento = createAction(
  `[Parcelamento] Falhou no processo de parcelamento`,
  props<{ erro: any }>()
);

export const saveFormParcelamento = createAction(
  `[Parcelamento] salvar form do parcelamento`,
  props<{ payload: PayloadEfetivaParcelamento | PayloadEfetivaReparcelamento }>()
);

export const loadingAtualizaParcelamento = createAction(
  `[Parcelamento] enviado request pra atualizar parcelamento`,
  props<{ req: PayloadEfetivaParcelamento }>()
);

export const sucessoAtualizaParcelamento = createAction(
  `[Parcelamento] sucesso na atualização`,
  props<{ payload: ResponseAtualizaParcelamento }>()
);

export const erroAtualizaParcelamento = createAction(
  `[Parcelamento] Falhou em atualizar parcelamento`
);

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClienteCompleto } from 'src/app/core/models/service/cadastroCliente';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';

@Injectable({
  providedIn: 'root',
})
export class ClienteService {
  // Observador responsável por notificar ouvintes de mudança
  private clienteSource = new BehaviorSubject<ClienteCompleto | undefined>(
    new ClienteCompleto()
  );

  // Observador responsável por notificar ouvintes de mudança do antigo cliente para troca de titularidade
  private antigoTitularSource = new BehaviorSubject<{
    nome: string;
    endereco: string;
  }>({ nome: '', endereco: '' });

  // Variável publica para acessar o valor e receber as alterações
  public currentCliente = this.clienteSource.asObservable();

  // Variável publica para acessar o valor e receber as alterações
  public antigoTitular = this.antigoTitularSource.asObservable();

  // Populando a variável com o valor gravado em memória
  constructor(private token: TokenStorageService) {
    this.getCliente()
  }

  // Função para alterar o valor no observador do antigo cliente
  changeAntigoTitular(cliente: string, endereco: string) {
    this.antigoTitularSource.next({
      nome: cliente,
      endereco: endereco,
    });
  }

  // Função para alterar o valor no observador
  changeCliente(cliente: ClienteCompleto | undefined) {
    sessionStorage.setItem(
      `${this.token.getToken()}-cliente`,
      JSON.stringify(cliente)
    );
    this.clienteSource.next(cliente);
  }

  getCliente(): Observable<ClienteCompleto | undefined> {
    this.currentCliente.subscribe((cliente) => {
      if (!cliente || (cliente.cpf === '' && this.token.checkToken())) {
        let clt = JSON.parse(
          `${sessionStorage.getItem(`${this.token.getToken()}-cliente`)}`
        );
        if (clt) {
          this.clienteSource.next(clt);
        }
      }
    });
    return this.currentCliente;
  }

  getAntigoTitular(): Observable<{ nome: string; endereco: string }> {
    return this.antigoTitular;
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FaturaServices,
  DetalheFatura,
} from 'src/app/services/faturaServices.service';
import { DialogErrorComponent } from '../dialog-error/dialog-error.component';
import { ThemeService } from '../../observables/theme.service';
export interface FaturaSelecionada {
  action: string;
  codPagamento: string;
}
@Component({
  selector: 'agv-cardFaturaDesktop',
  templateUrl: './card-fatura-desktop.component.html',
  styleUrls: ['./card-fatura-desktop.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardFaturaDesktop implements OnInit {
  // Controlador do tema
  currentTheme: string;

  @Output() emitItemDownload = new EventEmitter<FaturaSelecionada>();
  @Input() faturaRevisadaData: DetalheFatura;
  @Input() cobrancaJuridica: boolean = false;
  @Input() codigoFornec: string;
  isSelected: boolean;
  downloadExec: boolean;
  liberaLoadingDownload: boolean;
  counter: string;
  timeoutHandle: NodeJS.Timeout;
  authToken: string | null;

  constructor(
    private readonly faturaService: FaturaServices,
    private http: HttpClient,
    private readonly dialog: MatDialog,
    private theme: ThemeService
  ) {}

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
  }

  openDialog(): void {
    this.dialog.open(DialogErrorComponent, {
      data: {
        img: 'icon_atencao',
        title: 'FALHA NO DOWNLOAD',
        content:
          'Ocorreu um erro e não foi possível realizar o download da sua fatura, por favor, tente novamente.<p> Caso o erro persista, favor aguardar um tempo antes de voltar a tentar novamente.</p>',
      },
    });

    //controla o funcionamento do botão de loading
    this.liberaLoadingDownload = false;
  }

  emitCodPag(): void {
    if (this.isSelected) {
      this.emitItemDownload.emit({
        action: 'adicionar',
        codPagamento: this.faturaRevisadaData.codigoPagamento,
      });
    } else {
      this.emitItemDownload.emit({
        action: 'excluir',
        codPagamento: this.faturaRevisadaData.codigoPagamento,
      });
    }
  }

  openDialogLoading(): void {
    this.dialog.open(DialogErrorComponent, {
      data: {
        img: 'icon_atencao',
        title: 'AGUARDE',
        content: 'Estamos buscando a sua fatura.',
      },
    });
  }

  ngAfterViewInit(): void {
    this.authToken = window.sessionStorage.getItem('auth-token');
  }

  downloadFaturaCompleta(item: DetalheFatura, exibir: boolean) {
    this.downloadExec = false;
    this.openDialogLoading();
    exibir ? '' : (this.liberaLoadingDownload = true);
    if (this.authToken) {
      this.faturaService
        .getUrlFaturaCompleta(
          this.codigoFornec,
          item.codigoPagamento,
          this.authToken
        )
        .subscribe({
          next: async (data) => {
            if (data === null) {
              this.openDialog();
              console.error(
                'ERROR189: ===== Não foi possível efetuar o download da fatura! ====='
              );
            } else {
              let Buffer = require('buffer/').Buffer;
              const decodeURL = Buffer.from(data.url64, 'base64').toString(
                'binary'
              );
              if (decodeURL) {
                if (exibir) {
                  window.open(decodeURL, '_blank');
                } else {
                  let blob = await fetch(decodeURL).then((r) => r.blob());
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `Fatura_${item.codigoPagamento}.pdf`;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  a.remove();
                }
                this.downloadExec = true;
              }
              this.countdown(0, 1);
            }
          },
          error: (error) => {
            this.openDialog();
            console.error(error);
          },
        });
    }
  }

  countdown(minutes: number, seconds: number) {
    const tick = () => {
      this.counter =
        minutes.toString() + ':' + (seconds < 10 ? '0' : '') + String(seconds);
      seconds--;
      if (seconds >= 0) {
        this.timeoutHandle = setTimeout(tick, 2000);
      } else if (seconds === -1 && minutes === 0) {
        this.liberaLoadingDownload = false;
      }
    };
    tick();
  }
}

<div id="selecao-cancellation-msg-erro" role="group" tabindex="0" class="cancellation-warning">
  <agv-icon
  role="img"
  id="selecao-fornecimento__conteudo__aviso__icone"
  class="selecao-fornecimento__conteudo__aviso__icone"
  [name]="'icon_atencao'"
  title="Ícone exclamação de aviso"
></agv-icon>
  <div id="selecao-cancellation-msg-erro-titulo" role="group" class="cancellation-warning__title" aria-hidden="true">Cancelamento</div>
  <div id="selecao-cancellation-msg-erro-descricao" role="group" class="cancellation-warning__content" aria-hidden="true">
    <div [innerHTML]="dados.conteudo | safe: 'html'"></div>
  </div>
  <div id="selecao-cancellation-msg-erro-fechar" role="group" class="cancellation-warning__footer">
    <agv-button
    [id]="'selecao-processo__btns__btns__continuar'"
    [text]="'Cancelar'"
    [habilita]="true"
    [type]="'1'"
    [loading]="false"
    (sendAction)="cancel($event)"
  ></agv-button>
    <button (click)="closeModal()" id="selecao-cvv-msg-erro-botao-fechar" title="Botão Fechar pop-up" mat-button class="close-button"
      mat-dialog-close>
      <mat-icon class="close_icn" [title]="'Fechar modal'">close</mat-icon>
    </button>
  </div>
</div>

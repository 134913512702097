<section [id]="id + '__endereco-correio'" class="endereco-correio">
  <div [id]="id + '__endereco-correio__line'" class="endereco-correio__line">
    <div [id]="id + '__endereco-correio__line__divisao__1'" class="endereco-correio__line__divisao__1">
      <agv-input-cep [id]="id + '__endereco-correio__line__divisao__1'" [value]="endereco?.cepCodigo ?? ''" [placeholder]="'Digite o CEP'" [label]="'CEP *'" [blocked]="blocked ?? false" (sendValor)="getCep($event)"></agv-input-cep>
    </div>
    <div [id]="id + '__endereco-correio__line__divisao__2'" class="endereco-correio__line__divisao__2">
      <button class="endereco-cliente__content__btn-validar" (click)="buscaCep()" [disabled]="blocked ?? false">
        <mat-icon class="icon-search">search</mat-icon>
        <span class="span-validar">Validar</span>
      </button>
    </div>
  </div>

  <div [id]="id + '__endereco-correio__line'" class="endereco-correio__line">
    <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="__blanck">Não sei meu CEP</a>
  </div>
  <div [id]="id + '__endereco-correio__line'" class="endereco-correio__line">
    <div [id]="id + '__endereco-correio__line__divisao__3'" class="endereco-correio__line__divisao__3">
      <agv-input-text [id]="id + '__endereco-correio__line__divisao__3'" [blocked]="true" [value]="endereco?.estadoCodigo ?? ''" [placeholder]="'UF'" [label]="'UF: '" (sendValor)="getUf($event)"></agv-input-text>
    </div>
    <div [id]="id + '__endereco-correio__line__divisao__4'" class="endereco-correio__line__divisao__4">
      <agv-input-text [id]="id + '__endereco-correio__line__divisao__4'" [blocked]="true" [value]="endereco?.municipioDescr ?? ''" [placeholder]="'Município'" [label]="'Município: '" (sendValor)="getMunicipio($event)"></agv-input-text>
    </div>
  </div>
  <div [id]="id + '__endereco-correio__line'" class="endereco-correio__line">
    <div [id]="id + '__endereco-correio__line__divisao__5'" class="endereco-correio__line__divisao__5">
      <agv-input-text [id]="id + '__endereco-correio__line__divisao__5'" [blocked]="endereco.bairroDescr? true : false"  [value]="endereco?.bairroDescr ?? ''" [placeholder]="'Digite o bairro'" [label]="'Bairro: '" (sendValor)="getBairro($event)"></agv-input-text>
    </div>
  </div>
  <div [id]="id + '__endereco-correio__line'" class="endereco-correio__line">
    <div [id]="id + '__endereco-correio__line__divisao__6'" class="endereco-correio__line__divisao__6">
      <agv-input-select [id]="'__endereco-correio__line__divisao__6'" [value]="endereco?.toponimoDescr ?? ''" [structor]="tipoToponimoOptions" (sendValor)="getToponimo($event)"></agv-input-select>
    </div>
  </div>
  <div [id]="id + '__endereco-correio__line'" class="endereco-correio__line">
    <div [id]="id + '__endereco-correio__line__divisao__7'" class="endereco-correio__line__divisao__7">
      <agv-input-text [id]="id + '__endereco-correio__line__divisao__7'" [value]="endereco?.logradouroDescr ?? ''" [placeholder]="'Digite o endereço'" [label]="'Endereço: '" (sendValor)="getEndereco($event)"></agv-input-text>
    </div>
  </div>
  <div [id]="id + '__endereco-correio__line'" class="endereco-correio__line">
    <div [id]="id + '__endereco-correio__line__divisao__8'" class="endereco-correio__line__divisao__8">
      <agv-input-select [id]="id + '__endereco-correio__line__divisao__8'" [value]="endereco?.tipoCivicoDescr ?? ''" [structor]="tipoCivicoOptions" (sendValor)="getTipoCivico($event)"></agv-input-select>
    </div>
    <div [id]="id + '__endereco-correio__line__divisao__9'" class="endereco-correio__line__divisao__9">
      <agv-input-text [id]="id + '__endereco-correio__line__divisao__9'" [value]="endereco?.tipoCivicoId" [placeholder]="'Digite o nº'" [label]="'Número: '" (sendValor)="getNumero($event)"></agv-input-text>
    </div>
  </div>
  <div [id]="id + '__endereco-correio__line'" class="endereco-correio__line">
    <div [id]="id + '__endereco-correio__line__divisao__10'" class="endereco-correio__line__divisao__10">
      <agv-input-text [id]="id + '__endereco-correio__line__divisao__10'" [value]="endereco?.complemento ?? ''" [placeholder]="'Digite o complemento'" [label]="'Complemento: '" (sendValor)="getComplemento($event)"></agv-input-text>
    </div>
  </div>
</section>

<div class="chartHolder">
  <div class="diarioSection filtro-faturas">
    <div id="diarioInfoHolder" class="info-holder">
      <h2>Fatura venc. 5 de Abril - 2024</h2>
      <p class="diarioLabel">Diário</p>
    </div>
    <div class="info-holder">
      <p>Água e Esgoto</p>
    </div>
    <div class="comparativoHolderDesktop">
      <div class="comparativoHolder">
        <div *ngIf="exibeConsumo" class="consumo">
          <h2>{{consumoComparativoResponse.consumoMensal}} m³</h2>
          <p>Consumo</p>
        </div>
        <div *ngIf="exibeParcial" class="consumo">
          <h2>{{consumoComparativoResponse.consumoParcial}} m³</h2>
          <p>Consumo parcial</p>
        </div>
        <div *ngIf="exibeProjecao" class="projecaoConsumo consumo">
          <h2>{{consumoComparativoResponse.consumoPrevisao}} m³</h2>
          <p>Projeção de consumo</p>
        </div>
        <div class="comparativo">
          <h3>Comparativo</h3>
          <mat-radio-group class="checkboxHolder" [(ngModel)]="selectedOption" (change)="requestParentChange()">
            <mat-radio-button value="">Não comparar</mat-radio-button>
            <mat-radio-button value="mes">Mês anterior</mat-radio-button>
            <mat-radio-button value="ano">Ano anterior</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="exibeComparativo" class="mesAnterior">
          <div class="arrowHolder">
            <img src="../../../../../../assets/imgs/png/icn-arrow-down-3x.png" *ngIf="consumoComparativoResponse.variacaoTotal < 0" [name]="'icn_arrow_down'">
            <img src="../../../../../../assets/imgs/png/icn-arrow-up-3x.png" *ngIf="consumoComparativoResponse.variacaoTotal >= 0" [name]="'icn_arrow_up'">
            <h1 [ngClass]="consumoComparativoResponse.variacaoTotal < 0 ? 'green' : 'red'">{{consumoComparativoResponse.variacaoTotal}}%</h1>
          </div>
          <p>Vs mês anterior</p>
        </div>
      </div>
    </div>
    <div class="comparativoHolderMobile">
      <div class="comparativoHolder">
        <div class="consumo">
          <h2>4.6 m³</h2>
          <p>Consumo</p>
        </div>
        <div class="mesAnterior">
          <h1>0,9%</h1>
          <p>Vs mês anterior</p>
        </div>
      </div>
      <div class="comparativo">
        <h3>Comparativo</h3>
        <div class="checkboxHolder">
          <mat-radio-button value="none"></mat-radio-button>
          <p>Não comparar</p>
          <mat-radio-button value="month"></mat-radio-button>
          <p>Mês anterior</p>
          <mat-radio-button value="year"></mat-radio-button>
          <p>Ano anterior</p>
        </div>
      </div>
    </div>
    <div class="diarioChartHolder">
    </div>
  </div>
  <div style="position: relative;">
    <div id="outerContainer">
      <div id="labelsContainer" style="position: relative;"></div>
    </div>
    <div class="chart-container">
      <div class="container-body">
        <canvas id="chartIotDiario"></canvas>
      </div>
    </div>
  </div>
  <div class="legend-container">
    <div *ngIf="exibeConsumo || exibeParcial" class="legend-item">
      <span class="legend-color blue"></span>
      <p>24 de fev 2024 - 26 de mar 2024</p>
    </div>
    <div *ngIf="exibeParcial || exibeProjecao" class="legend-item">
      <span class="legend-color orange"></span>
      <p>24 de fev 2024 - 26 de mar 2024</p>
    </div>
    <div *ngIf="selectedOption === 'mes'" class="legend-item">
      <span class="legend-color purple"></span>
      <p>24 de fev 2023 - 26 de mar 2023</p>
    </div>
    <div *ngIf="selectedOption === 'ano'" class="legend-item">
      <span class="legend-color green"></span>
      <p>24 de fev 2023 - 26 de mar 2023</p>
    </div>
  </div>
</div>

export function encriptaCartao(key, cartao) {
    const card = PagSeguro.encryptCard({
        publicKey: key,
        holder: cartao.holder,
        number: cartao.number.replace(/\s/g, ''),
        expMonth: Number(cartao.exp_month),
        expYear: Number(cartao.exp_year),
        securityCode: cartao.securityCode
    });
    
    return card
}
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ClienteCompleto, DadosComponenteOCR } from 'src/app/core/models/service/cadastroCliente';
import { AtualizacaoCadastralService } from 'src/app/services/atualizacao-cadastral.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { maioridadeValidator } from '../../utils/maioridade.validator';
import { ThemeService } from '../../observables/theme.service';
import { Validator } from '../../utils/validator';
import { Regex } from '../../utils/regex';

export interface DocEmitido {
  eventUpload: any,
  file: any,
  lado: string,
  eventReader:any
}

@Component({
  selector: 'agv-cadastro-ocr',
  templateUrl: './cadastro-ocr.component.html',
  styleUrls: ['./cadastro-ocr.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CadastroOcrComponent implements OnInit, OnChanges {

  // Actions
  @Output() enviaDadosOcr: EventEmitter<DadosComponenteOCR> = new EventEmitter();
  @Output() emitImgSelecionada = new EventEmitter<DocEmitido>();
  
  // Inputs
  @Input() emailCliente: string;
  @Input() nomeCliente: string;
  @Input() dataCliente: string;
  @Input() telCliente: string;
  @Input() cpfCliente: string;
  @Input() dadosOcr: any;
  @Input() erroOcr: any;

  // Objects
  clienteAtualizado: ClienteCompleto;
  dadosUsuariAtualizacao: FormGroup;
  docEmitido: DocEmitido;

  // String
  nomeDocumentoFrente: string = 'Carregar arquivo';
  nomeDocumentoVerso: string = 'Carregar arquivo';
  currentTheme: string;
  docFrente: string;
  docVerso: string;
  counter: string;
  
  // Listas
  listaEmail: string[];

  // Boolean
  camposDesabilitados: boolean = true;
  documentoInfoFaltante: boolean;
  bloquearVerso: boolean = true;
  tipoArquivoInvalido: boolean;
  documentoDivergente: boolean;
  arquivoTamExcedido: boolean;
  documentoInvalido: boolean;
  loadingDocFrente: boolean;
  loadingDocVerso: boolean;
  erroDocFrente: boolean;
  erroDocVerso: boolean;

  // Outros
  timeoutHandle: NodeJS.Timeout;
  menorIdade: any;

  constructor(formBuilder: FormBuilder, private atualizaDadosService: AtualizacaoCadastralService,
    private theme: ThemeService) {
    this.dadosUsuariAtualizacao = formBuilder.group({
      nomeCompleto: ['', [Validators.required]],
      documento: [  { value: null, disabled: this.camposDesabilitados }, [Validators.required]],
      dataNascimento: ['', [Validators.required, maioridadeValidator()]],
      celular: ['', [Validators.required, Validators.minLength(14)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    if (this.cpfCliente) {
      this.dadosUsuariAtualizacao.controls['documento'].setValue(Regex.maskCpf(this.cpfCliente));
    }
    if (this.nomeCliente) {
      this.dadosUsuariAtualizacao.controls['nomeCompleto'].setValue(this.nomeCliente);
    }
    if (this.dataCliente) {
      this.dadosUsuariAtualizacao.controls['dataNascimento'].setValue(this.dataCliente);
    }
    if (this.telCliente) {
      this.dadosUsuariAtualizacao.controls['celular'].setValue(this.telCliente);
    }
    if (this.emailCliente) {
      this.dadosUsuariAtualizacao.controls['email'].setValue(this.emailCliente);
    }
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
    this.listaEmail = ['@gmail.com', '@hotmail.com', '@outlook.com'];
    this.camposDesabilitados = true;
    this.bloquearUploadVerso();
    this.disableInput();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.erroOcr){
      const frenteDoc = this.erroOcr.lado === 'frente';
      this.documentoInvalido = true;
      if(frenteDoc){
        this.loadingDocFrente = false;
        this.erroDocFrente = true;
      } else{
        this.loadingDocVerso = false;
        this.erroDocVerso = true;
      }
    }else{
      this.validaInfoOCR(this.docEmitido?.file, this.docEmitido?.lado, this.docEmitido?.eventReader);
    }
  }

  get form(): { [key: string]: AbstractControl } {
    return this.dadosUsuariAtualizacao.controls;
  }

  setMessageErrorDoc(): string {
    if (this.arquivoTamExcedido) {
      return 'Arquivo possui mais de 20MB. Escolha um arquivo menor, no formato .jpg, .jpeg, ou .png. e tente novamente.';
    }
    if (this.tipoArquivoInvalido) {
      return 'Formato não suportado. Escolha um arquivo de até 20MB, no formato .jpg, .jpeg, ou .png. e tente novamente.';
    }
    if (this.documentoDivergente) {
      return 'Documento selecionado é diferente o CPF inserido, por favor verifique o documento e tente novamente.';
    }
    if (this.documentoInvalido) {
      return 'O documento selecionado é inválido porque não possui CPF, selecione um documento válido e tente novamente.';
    }
    if (this.documentoInfoFaltante) {
      return 'O documento selecionado é inválido porque não possui CPF, selecione um documento válido e tente novamente.';
    } else return '';
  }

  bloquearUploadVerso(): boolean {
    if (this.dadosUsuariAtualizacao.controls['nomeCompleto'].valid && this.dadosUsuariAtualizacao.controls['documento'].valid && this.dadosUsuariAtualizacao.controls['dataNascimento'].valid) {
      this.bloquearVerso = true;
      return true
    } else {
      this.bloquearVerso = false;
      return false
    }
  }

  limparAquivo(tipo: string, evento: any) {
    if (Validator.validaEventKeyPressClick(evento)) {
      if (tipo === 'frente') {
        this.docFrente = '';
        this.nomeDocumentoFrente = 'Carregar arquivo';
      }
      this.docVerso = '';
      this.nomeDocumentoVerso = 'Carregar arquivo';
      this.clienteAtualizado.nome = '';
      this.clienteAtualizado.dataNascimento = '';
    }
  }

  validaDataNascimento(dataNasc: any): boolean {
    let data = dataNasc;
    let difIdade = Date.now() - new Date(data).getTime();
    if (difIdade < 568080000000) {
      return true;
    }
    return false;
  }

  selecionarArquivo(event: any, tipo: string): void {
    this.disableInput();
    this.camposDesabilitados = true;
    const arquivosSelecionados: FileList = event.target.files;
    const tipoArq: string = tipo;

    if (arquivosSelecionados) {
      this.limparAquivo(tipo, event);
      const file: File | null = arquivosSelecionados.item(0);
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.docEmitido = {
            eventUpload: event,
            file: file,
            lado: tipo,
            eventReader: e.target.result
          };
          if (tipoArq === 'frente') {
            if (this.validaImagem(file, 'frente')) {
              this.emitImgSelecionada.emit(this.docEmitido)
              this.loadingDocFrente = true;
            }
          } else {
            if (this.validaImagem(file, 'verso')) {
              this.emitImgSelecionada.emit(this.docEmitido)
              this.loadingDocVerso = true;
            }
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  validaImagem(file: File, tipo: string): boolean {
    this.tipoArquivoInvalido = false;
    this.arquivoTamExcedido = false;
    this.erroDocFrente = false;
    this.erroDocVerso = false;

    const fileSize = file.size / 1024 / 1024; // para mb

    if (fileSize > 20) {
      this.arquivoTamExcedido = true;
      tipo === 'frente' ? this.erroDocFrente = true : this.erroDocVerso = true;
      return false
    }
    if (file.type != 'image/jpg' && file.type != 'image/jpeg' && file.type != 'image/png') {
      this.tipoArquivoInvalido = true;
      tipo === 'frente' ? this.erroDocFrente = true : this.erroDocVerso = true;
      return false
    }
    return true
  }

  validaInfoOCR(file: any, tipo: string, image: any): void {
    if (tipo === 'frente') {
      if (this.dadosOcr?.tipo) {
        if (!this.dadosOcr?.cpf && this.dadosOcr?.tipo !== 'rg' && this.dadosOcr?.tipo !== 'carteira-de-identidade') {
          this.documentoInfoFaltante = true;
          this.loadingDocFrente = false;
          this.erroDocFrente = true;
          return
        }
        if (this.dadosOcr?.cpf) {
          if (this.dadosOcr?.cpf !== Regex.removeMaskCpf(this.dadosUsuariAtualizacao.controls['documento'].value)) {
            this.documentoDivergente = true;
            this.loadingDocFrente = false;
            this.erroDocFrente = true;
            return
          }
        }
        this.dadosUsuariAtualizacao.controls['nomeCompleto'].setValue(this.dadosOcr?.nome ? this.dadosOcr?.nome : this.dadosUsuariAtualizacao.controls['nomeCompleto'].value);
        this.dadosUsuariAtualizacao.controls['dataNascimento'].setValue(this.dadosOcr?.dataNascimento ? this.dadosOcr?.dataNascimento.split('-').reverse().join('/') : this.dadosUsuariAtualizacao.controls['dataNascimento'].value);
        this.menorIdade = this.validaDataNascimento(this.dadosUsuariAtualizacao.controls['dataNascimento'].value);
        this.emiteDadosOcr()
      } else {
        this.camposDesabilitados = false;
        this.loadingDocFrente = false;
        this.enableInput();
      }
      this.setDocFrenteVerso('frente', image, file.name);
      this.bloquearUploadVerso()
    } else {
      if (this.dadosOcr?.tipo) {
        if (!this.dadosOcr?.cpf && this.dadosOcr?.tipo !== 'cnh') {
          this.documentoInfoFaltante = true;
          this.loadingDocVerso = false;
          this.erroDocVerso = true;
          return
        }
        if (this.dadosOcr?.cpf) {
          if (this.dadosOcr?.cpf !== Regex.removeMaskCpf(this.dadosUsuariAtualizacao.controls['documento'].value)) {
            this.documentoDivergente = true;
            this.loadingDocVerso = false;
            this.erroDocVerso = true;
            return
          }
        }
        this.dadosUsuariAtualizacao.controls['nomeCompleto'].setValue(this.dadosOcr?.nome ? this.dadosOcr?.nome : this.dadosUsuariAtualizacao.controls['nomeCompleto'].value);
        this.dadosUsuariAtualizacao.controls['dataNascimento'].setValue(this.dadosOcr?.dataNascimento ? this.dadosOcr?.dataNascimento.split('-').reverse().join('/') : this.dadosUsuariAtualizacao.controls['dataNascimento'].value);
        this.menorIdade = this.validaDataNascimento(this.dadosUsuariAtualizacao.controls['dataNascimento'].value);
        this.emiteDadosOcr()
      } else {
        this.camposDesabilitados = false;
        this.enableInput();        
      }
      this.setDocFrenteVerso('verso', image, file?.name);
    }
  }


  setDocFrenteVerso(type: string, file: any, fileName: string) {
    if (type === 'frente') {
      this.docFrente = file;
      if (fileName) {
        if (fileName?.length > 15)
          this.nomeDocumentoFrente = fileName.substring(0, 14) + '...';
        else 
          this.nomeDocumentoFrente = fileName;
      }
      this.loadingDocFrente = false;
    } else {
      this.docVerso = file;
      if (fileName) {
        if (fileName?.length > 15)
          this.nomeDocumentoVerso = fileName.substring(0, 14) + '...';
        else this.nomeDocumentoVerso = fileName;
      }
      this.loadingDocVerso = false;
    }
    this.countdown(0, 2);
  }

  disableInput(): void {
    this.dadosUsuariAtualizacao.controls['nomeCompleto'].disable();
    this.dadosUsuariAtualizacao.controls['dataNascimento'].disable();
  }

  enableInput(): void {
    this.dadosUsuariAtualizacao.controls['nomeCompleto'].enable();
    this.dadosUsuariAtualizacao.controls['dataNascimento'].enable();
  }

  countdown(minutes: number, seconds: number) {
    const tick = () => {
      this.counter =
        minutes.toString() + ':' + (seconds < 10 ? '0' : '') + String(seconds);
      seconds--;
      if (seconds >= 0) {
        this.timeoutHandle = setTimeout(tick, 2000);
      } else if (seconds === -1 && minutes === 0) {
        this.loadingDocFrente = false;
        this.loadingDocVerso = false;
      }
    };
    tick();
  }

  exibeEmail() {
    return this.dadosUsuariAtualizacao.controls['email'].value.includes('@');
  }

  emiteDadosOcr() {
    if (this.dadosUsuariAtualizacao.valid) {
      this.enviaDadosOcr.emit(this.dadosUsuariAtualizacao.getRawValue());
    }
  }

  mascaraTelefone(): void {
    this.dadosUsuariAtualizacao.controls['celular'].setValue(Regex.maskTelefone(this.dadosUsuariAtualizacao.controls['celular'].value));
  }

  handleDoc(event: any, lado: string): void {
    this.documentoDivergente = false;
    this.documentoInvalido = false;
    this.documentoInfoFaltante = false;
    this.selecionarArquivo(event, lado);
  }
}

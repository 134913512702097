<div class="content-web-desktop">
  <table mat-table [dataSource]="currentPageData" class="mat-elevation-z8 table-container">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" style="padding-left: 0">
        <input (focus)="removeTitulo()" type="checkbox" (click)="$event.stopPropagation(); selecionaItem()" id="cb2" color="primary"
          class="checkbox-body" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
          aria-label="Selecionar" />
      </td>
    </ng-container>

    <ng-container matColumnDef="numDoc">
      <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
        Nº {{ comunicaPgtoType === 'parcelamento' ? 'do acordo' : 'da fatura' }}
      </th>
      <td class="conteudo-body" mat-cell *matCellDef="let element" tabindex="0">
        {{ comunicaPgtoType === 'parcelamento' ? element.acordo : element.numeroDocumento }}
      </td>
    </ng-container>

    <ng-container matColumnDef="numParcela">
      <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
        Nº da parcela
      </th>
      <td class="conteudo-body" mat-cell *matCellDef="let element" tabindex="0">
        {{ element.numParcela }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dataEmissao">
      <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
        Data de emissão
      </th>
      <td class="conteudo-body" mat-cell *matCellDef="let element" tabindex="0">
        {{ element.dataEmissao | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="valor">
      <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
        Valor da {{ comunicaPgtoType === 'parcelamento' ? 'parcela' : 'fatura' }}
      </th>
      <td class="conteudo-body" mat-cell *matCellDef="let element" tabindex="0">
        {{ element.valor | currency : "BRL" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dataVencimento">
      <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
        Data de vencimento
      </th>
      <td class="conteudo-body" mat-cell *matCellDef="let element" tabindex="0">
        {{ element.dataVencimento | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="situacaoDaFatura">
      <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
        Situação
      </th>
      <td tabindex="0" class="conteudo-body" [ngClass]="{
          'paga': element?.situacaoDaFatura?.toUpperCase() === 'PAGA' || element?.situacaoDaFatura?.toUpperCase() === 'PAGO COM CARTÃO CRÉDITO',
          'em-aberto': element?.situacaoDaFatura?.toUpperCase() === 'EM ABERTO',
          'em-atraso': element?.situacaoDaFatura?.toUpperCase() === 'EM ATRASO',
          'em-aguardo':
            element?.situacaoDaFatura?.toUpperCase() !== 'EM ATRASO' &&
            element?.situacaoDaFatura?.toUpperCase() !== 'EM ABERTO' &&
            element?.situacaoDaFatura?.toUpperCase() !== 'PAGA' && 
            element?.situacaoDaFatura?.toUpperCase() !== 'PAGO COM CARTÃO CRÉDITO'
        }" mat-cell *matCellDef="let element">
        {{
        element?.situacaoDaFatura?.charAt(0).toUpperCase() +
        element?.situacaoDaFatura?.slice(1).toLowerCase()
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="situacaoDaParcela">
      <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
        Situação
      </th>
      <td tabindex="0" class="conteudo-body" [ngClass]="{
          'paga': element?.situacaoDaFatura?.toUpperCase() === 'PAGA' || element?.situacaoDaFatura?.toUpperCase() === 'PAGO COM CARTÃO CRÉDITO',
          'em-aberto': element?.situacao?.toUpperCase() === 'EM ABERTO',
          'em-atraso': element?.situacao?.toUpperCase() === 'EM ATRASO',
          'em-aguardo':
            element?.situacao?.toUpperCase() !== 'EM ATRASO' &&
            element?.situacao?.toUpperCase() !== 'EM ABERTO' &&
            element?.situacao?.toUpperCase() !== 'PAGA' &&
            element?.situacao?.toUpperCase() !== 'PAGO COM CARTÃO CRÉDITO'
        }" mat-cell *matCellDef="let element">
        {{
        element?.situacao?.charAt(0).toUpperCase() +
        element?.situacao?.slice(1).toLowerCase()
        }}
      </td>
    </ng-container>

    <tr class="thead-custom" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="thbody-custom tr-tbody" mat-row *matRowDef="let row; columns: displayedColumns"
      (click)="selection.toggle(row)"></tr>
  </table>

  <ng-container *ngTemplateOutlet="notification"></ng-container>

</div>
<div class="content-web-mobile">
  <div class="items-per-page">
    <p>Itens por páginas:</p>
    <ul>
      <li *ngFor="let item of itemsPerPage" [ngClass]="{ active: item.active }"
        (click)="changeItemsPerPage(item.value)">
        {{ item.value }}
      </li>
    </ul>
  </div>

  <mat-card class="card-table" *ngFor="let element of currentPageData; let i = index">
    <table [ngClass]="{'border-dark': currentTheme === 'theme-dark'}" class="card-table__table" tabindex="0" (click)="selection.toggle(element)" (keypress)="selection.toggle(element)">
      <tr>
        <th>
          <input (focus)="removeTitulo()" type="checkbox" (click)="$event.stopPropagation(); selecionaItem()" id="cb2" color="primary"
            class="checkbox-body" (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)" tabindex="-1"/>
          Nº {{ comunicaPgtoType === 'parcelamento' ? 'do acordo' : 'da fatura' }}
        </th>
        <td>
          {{ comunicaPgtoType === 'parcelamento' ? element.acordo : element.codigoPagamento }}
        </td>
      </tr>
      <tr *ngIf="comunicaPgtoType === 'parcelamento'">
        <th>
          Nº da parcela
        </th>
        <td>
          {{ element.numParcela }}
        </td>
      </tr>
      <tr>
        <th>
          Data de emissão
        </th>
        <td>
          {{ element.dataEmissao | date : "dd/MM/yyyy" }}
        </td>
      </tr>
      <tr>
        <th>Valor da {{ comunicaPgtoType === 'parcelamento' ? 'parcela' : 'fatura' }}</th>
        <td>
          {{ element.valor | currency : "BRL" }}
        </td>
      </tr>
      <tr>
        <th>Data de vencimento</th>
        <td>
          {{ element.dataVencimento | date : "dd/MM/yyyy" }}
        </td>
      </tr>
      <tr>
        <th>Situação</th>
        <td>
          {{
            comunicaPgtoType === 'parcelamento'
            ? element?.situacao?.charAt(0).toUpperCase() + element?.situacao?.slice(1).toLowerCase()
            : element?.situacaoDaFatura?.charAt(0).toUpperCase() + element?.situacaoDaFatura?.slice(1).toLowerCase()
          }}
        </td>
      </tr>
    </table>

  </mat-card>
  <ng-container *ngTemplateOutlet="notification"></ng-container>
</div>
<div>
  <agv-pagination #paginationComponent [currentPage]="currentPage" [pageSize]="pageSize"
  [data]="comunicaPgtoType === 'parcelamento' ? parcelasDataSource : faturasDataSource"
    (pageChanged)="updateCurrentPageData($event)">
  </agv-pagination>
</div>

<ng-template #notification>
  <app-notificacao-processo [fontWeightTitle]="700" [fontWeightDescription]="700" [mensagem]="mensagemErro"
    *ngIf="comunicaPgtoType === 'parcelamento' ? parcelasDataSource?.length === 0 : faturasDataSource?.length === 0" [tipoMensagem]="'atencao'"
    [tituloMensagem]="'Nada por aqui.'"></app-notificacao-processo>
</ng-template>

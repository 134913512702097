import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { MenuServicoService } from '../../menu-servicos/menu-servico.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { slideInTop, slideOutTop } from '../../layout/animations/slide';
import { ItensMenu } from 'src/app/core/models/menuServicoItens';
import { environment } from 'src/environments/environment';
import { Regex } from 'src/app/shared/utils/regex';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'agv-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [slideInTop, slideOutTop],
})
export class HeaderSearchComponent implements OnInit {
  public opened: boolean = false;
  public searchControl: FormControl = new FormControl();
  public showOptionSearch: boolean = false;
  @Input() currentTheme: string = '';

  menuServico: MenuServicoService = new MenuServicoService();
  servicos = this.menuServico.getInfoMenuItens().items;
  termoPesquisa: string;
  servicosFiltrados: any[];
  url = environment.urlAGV;
  token: any;

  constructor(
    private route: Router,
    private tokenService: TokenStorageService
  ) {}

  ngOnInit(): void {}

  open(): void {
    if (this.opened) return;
    this.opened = true;
  }

  close(): void {
    if (!this.opened) return;

    this.searchControl.setValue('');
    this.opened = false;
  }

  // Filtra pesquisa de serviços na versão mobile
  filtrarServicos() {
    this.servicosFiltrados = this.servicos.filter((servico) =>
      Regex.removerAcentos(servico.nome.toLowerCase()).includes(
        Regex.removerAcentos(this.termoPesquisa.toLowerCase())
      )
    );
  }

  public onFocusSearch() {
    this.showOptionSearch = !this.showOptionSearch;
  }

  redirecionaServico(servico: ItensMenu): void {
    servico.interno
      ? this.route.navigateByUrl(servico.link)
      : this.redirecionaLinkExterno(servico.link);
  }

  redirecionaLinkExterno(link: string): void {
    if (this.checkToken())
      window.open(this.url + `?token=${this.token}&servico=${link}`, '_blank');
    else window.open(`${this.url}${link}`);
  }

  checkToken(): boolean {
    if (this.tokenService.getToken()) {
      this.token = this.tokenService.getToken();
      return true;
    } else {
      return false;
    }
  }
}

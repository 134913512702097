export class Acordo {
  codigoAcordo: string;
  dataParcelamento: string;
  parcelas: Parcela[];
  parcelasPendentes: number;
  quantidadeParcelas: number;
  statusAcordo: string;
  valorDesconto: number | null;
  valorPenalidade: number;
  valorPendente: number | null;
  valorTRCF: number;
  valorTotalAcordo: number;

  constructor() {
    this.codigoAcordo = '';
    this.dataParcelamento = '';
    this.quantidadeParcelas = 0;
    this.parcelasPendentes = 0;
    this.valorPendente = 0;
    this.valorTotalAcordo = 0;
    this.statusAcordo = '';
    this.valorDesconto = 0;
    this.valorPenalidade = 0;
    this.valorTRCF = 0;
    this.parcelas = [];
  }
}

export class Parcela {
  parcelaID: string;
  codeline: string;
  numParc: number;
  dataVencimento: string;
  valor: number;
  valorPago: number;
  status: string;
  linhaDigitavelParcela: string;
  codigoDeBarrasParcela: string;
  selected:boolean;

  constructor() {
    this.parcelaID = '';
    this.numParc = 0;
    this.dataVencimento = '';
    this.valor = 0;
    this.valorPago = 0;
    this.status = '';
    this.linhaDigitavelParcela = '';
    this.codigoDeBarrasParcela = '';
    this.selected = false;
  }
}

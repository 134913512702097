<div class="formInfosIncorreta">
  <div #campoDefault style="display: block;">
    <div class="agrupaInput">
      <div style="width: 81.6%;">
        <app-select label="Qual dado <b>NÃO</b> está correto? *" (emissorValue)="verificaSelectVazio($event, 'default')"
          (select)="capturaInfoSelecionada('default', $event)" [options]="informacoesCliente"
          [labelPlaceholder]="'Selecione'"
          [value]="informacaoIncorreta" [style]="{height: '65px', width: '100%'}"
          [labelStyle]="emptySelect? labelStyle.erroLabel : labelStyle.label"
          [selectFieldStyle]="emptySelect? labelStyle.selectFieldErro : labelStyle.selectField"
          [placeholderStyle]="emptySelect? labelStyle.erroPlaceholder : labelStyle.placeholder"
          [optionStyle]="{'font-weight': '500', 'font-size': '14px','color': '#626262'}">
          <ng-template let-option #option> {{ option }} </ng-template>
        </app-select>
      </div>
      <div tabindex="0" class="removeInput" (click)="removeCampo('default', informacaoIncorreta)" (keypress)="removeCampo('default', informacaoIncorreta)">
        <agv-icon [name]="'icn-remove'"></agv-icon>
        <p>Remover</p>
      </div>
    </div>
    <mat-error *ngIf="emptySelect">
      Campo obrigatório, por favor selecione uma das opções da lista.
    </mat-error>
    <div [formGroup]="formInfosIncorretas">
      <mat-form-field class="input" appearance="fill" *ngIf="informacaoIncorreta === 'Nome completo'">
        <label for="nomeCliente">Nome completo *</label>
        <input formControlName="nome" id="nomeCliente" (change)="enviaDadoCorreto()" matInput
          placeholder="Digite seu nome" aria-label="Digite seu nome completo" />
        <mat-error *ngIf="form['nome'].errors">
          Campo obrigatório, por favor digite o seu nome completo
        </mat-error>
      </mat-form-field>
      <mat-form-field class="input" appearance="fill" *ngIf="informacaoIncorreta === 'Data de Nascimento'">
        <label for="nascimentoCliente">Data de Nascimento</label>
        <input formControlName="dataNascimento" id="nascimentoCliente" (change)="enviaDadoCorreto()" matInput
          maxlength="14" type="date" class="backgroundUnable" type="date" placeholder="00/00/0000" />
        <mat-error
          *ngIf="formInfosIncorretas.controls['dataNascimento'].errors && formInfosIncorretas.controls['dataNascimento'].errors['menorIdade']">
          {{ formInfosIncorretas.controls["dataNascimento"].errors["menorIdade"] }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div #campoAdicional style="margin-top: 18px; display: none;">
    <div class="agrupaInput">
      <div style="width: 81.6%;">
        <app-select label="Qual dado <b>NÃO</b> está correto? *" (emissorValue)="verificaSelectVazio($event, 'adicional')" 
          (select)="capturaInfoSelecionada('adicional', $event)"
          [labelPlaceholder]="'Selecione'"
          [options]="informacoesCliente" [value]="infoIncorretaCampoAdicional" [style]="{height: '65px', width: '100%'}"
          [labelStyle]="emptySelectAdicional? labelStyle.erroLabel : labelStyle.label"
          [selectFieldStyle]="emptySelectAdicional? labelStyle.selectFieldErro : labelStyle.selectField"
          [placeholderStyle]="emptySelectAdicional? labelStyle.erroPlaceholder : labelStyle.placeholder"
          [optionStyle]="{'font-weight': '500', 'font-size': '14px','color': '#626262'}">
          <ng-template let-option #option> {{ option }} </ng-template>
        </app-select>
      </div>
      <div tabindex="0" class="removeInput" (click)="removeCampo('campoAdicional', infoIncorretaCampoAdicional)" (keypress)="removeCampo('campoAdicional', infoIncorretaCampoAdicional)">
        <agv-icon [name]="'icn-remove'"></agv-icon>
        <p>Remover</p>
      </div>
    </div>
    <mat-error *ngIf="emptySelectAdicional">
      Campo obrigatório, por favor selecione uma das opções da lista.
    </mat-error>
    <div [formGroup]="formInfosIncorretas">
      <mat-form-field class="input" appearance="fill" *ngIf="infoIncorretaCampoAdicional === 'Nome completo'">
        <label for="nomeCliente">Nome completo *</label>
        <input formCotrolName="nome" id="nomeCliente" (change)="enviaDadoCorreto()" matInput
          placeholder="Digite seu nome" aria-label="Digite seu nome completo" />
        <mat-error *ngIf="form['nome'].hasError('required')">
          Campo obrigatório, por favor digite o seu nome completo
        </mat-error>
      </mat-form-field>
      <mat-form-field class="input" appearance="fill" *ngIf="infoIncorretaCampoAdicional === 'Data de Nascimento'">
        <label for="nascimentoCliente">Data de Nascimento</label>
        <input formControlName="dataNascimento" id="nascimentoCliente" (change)="enviaDadoCorreto()" matInput type="date"
          class="backgroundUnable" type="date" placeholder="00/00/0000" />
        <mat-error
          *ngIf="formInfosIncorretas.controls['dataNascimento'].errors && formInfosIncorretas.controls['dataNascimento'].errors['menorIdade']">
          {{ formInfosIncorretas.controls["dataNascimento"].errors["menorIdade"] }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div tabindex="0" [ngClass]="{'disable': limiteCampoAtingido}" class="msgAdicionaCampo" (click)="adicionaNovoCampo($event)" (keypress)="adicionaNovoCampo($event)">
    <agv-icon [name]="'icn-adicionar'" *ngIf="!limiteCampoAtingido"></agv-icon>
    <agv-icon [name]="'icn-adicionarDisable'" *ngIf="limiteCampoAtingido" class="disable"></agv-icon>
    <p [ngClass]="{'disable': limiteCampoAtingido}">Deseja adicionar mais um dado?</p>
  </div>
</div>
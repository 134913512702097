<div id="perguntas-frequentes" role="group" class="perguntas-frequentes">
    <div id="perguntas-frequentes-container" role="group" class="perguntas-frequentes-container">
        <div id="perguntas-frequentes-descricao" role="group" class="perguntas-frequentes_titulos">
            <h2 class="perguntas-frequentes__titulo" tabindex="0">Perguntas frequentes</h2>
            <span class="perguntas-frequentes__subtitulo" tabindex="0">
                Leia abaixo as dúvidas mais comuns que os consumidores Sabesp têm sobre nosso serviço
            </span>
        </div>
        <mat-accordion id="perguntas-frequentes-conteudo" >
            <mat-expansion-panel id="perguntas-frequentes-painel" hideToggle *ngFor="let servico of servicos; index as indexOfelement;"
            (opened)="elementoAbriu(indexOfelement)" (closed)="elementoFechou(indexOfelement)">
                <mat-expansion-panel-header id="perguntas-frequentes-painel-header" >
                    <div id="perguntas-frequentes-painel-header-conteudo" class="box-pergunta">
                        <mat-panel-title>
                            {{servico.pergunta}}
                        </mat-panel-title>
                        <mat-panel-description id="perguntas-frequentes-painel-header-toggle" >
                            <agv-icon [name]="servico.img" id="P{{indexOfelement}}" class="icn_expandir" [ngClass]="{'dark-mode' : currentTheme === 'theme-dark'}" title="expandir" ></agv-icon>
                        </mat-panel-description>
                    </div>
                </mat-expansion-panel-header>
                <span tabindex="0" [innerHTML]="servico.resposta"></span>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
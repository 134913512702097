import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Fatura } from 'src/app/core/models/faturaCompleta';

@Injectable({
  providedIn: 'root'
})
export class FaturaService {
  // Observador responsável por notificar ouvintes de mudança
  private faturasSource = new BehaviorSubject<Fatura[]>([]);
  // Variável publica para acessar o valor e receber as alterações
  public currentFaturas = this.faturasSource.asObservable();

  // Observador responsável por notificar ouvintes de mudança
  private faturaSource = new BehaviorSubject<Fatura>(new Fatura());
  // Variável publica para acessar o valor e receber as alterações
  public currentFatura = this.faturaSource.asObservable();

  // Populando a variável com o valor gravado em memória
  constructor() {}

  // Função para alterar o valor no observador
  changeFaturas(faturas: Fatura[]) {
    this.faturasSource.next(faturas);
  }
  
  // Função para alterar o valor no observador
  changeFatura(fatura: Fatura) {
    this.faturaSource.next(fatura);  }
  
}

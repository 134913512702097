import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnInit,
  ElementRef,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Fatura } from 'src/app/core/models/faturaCompleta';
import {
  FaturaServices,
  DetalheFatura,
} from 'src/app/services/faturaServices.service';
import { Fornecimento } from 'src/app/core/models/fornecimento';
import { DialogErrorComponent } from 'src/app/shared/components/dialog-error/dialog-error.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { FaturaSelecionada } from 'src/app/shared/components/card-fatura-desktop/card-fatura-desktop.component';
import { EMPTY, Subject, switchMap, takeUntil } from 'rxjs';
import { ThemeService } from 'src/app/shared/observables/theme.service';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { Router } from '@angular/router';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { PaginationComponent } from '../pagination/pagination.component';

@Component({
  selector: 'agv-tabela-historico',
  templateUrl: './tabela-historico.component.html',
  styleUrls: ['./tabela-historico.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TabelaHistoricoComponent implements OnInit, OnChanges {
  // Controlador do tema
  currentTheme: string;

  @Input() displayedColumns: string[] = [];
  @Input() faturas: Fatura[] = [];
  @Input() fornecimento: Fornecimento;
  @Input() temCartao: boolean = true;

  private dialogLoadingRef: MatDialogRef<DialogErrorComponent>; // Manter uma referência ao diálogo de loading aberto
  public carregaBotao: boolean = false;

  faturasDataSource: Fatura[] = [];
  codPagFaturasSelecionadas: string = '';
  exibir: boolean;
  downloadExec: boolean;
  faturaZipStarted: boolean = false;
  faturaZipFinished: boolean = false;
  authToken: string | null = this.tokenService.getToken();

  @Input() filtroSelecionado!: string | null;
  selection = new SelectionModel<string>(true, []);

  mensagemErro: string = ` `;
  public buttonCopy: string;

  // Paginação
  pageSize = 10;
  pageSizeMobile = 3;
  currentPage = 1;
  currentPageData: any[];
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  @ViewChild('tabelaElement', { static: false }) tabelaElementRef!: ElementRef;
  itemsPerPage = [
    { value: '03', active: true },
    { value: '05', active: false },
    { value: '10', active: false },
  ];
  faturaSelecionadaDownload: string;
  counter: string;
  timeoutHandle: NodeJS.Timeout;
  detalheFaturaSelecionada: DetalheFatura;
  downloadExecRevisada: boolean;
  expandedMobile: boolean;
  acaoChecked: any;
  private destroy$ = new Subject<boolean>();
  paginaAtual: number = 1;
  itensPorPagina: number = 10;

  constructor(
    private breakpointService: BreakpointService,
    private readonly faturaService: FaturaServices,
    private readonly dialog: MatDialog,
    private http: HttpClient,
    private theme: ThemeService,
    private tokenService: TokenStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
    this.faturasDataSource = this.faturas;
    this.faturaSelecionadaDownload = '';
    this.detalheFaturaSelecionada = new DetalheFatura();
  }

  removerAcentuacao(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectErrorMessage(changes?.['filtroSelecionado']?.currentValue);
    const filtroSelecionado = this.filtroSelecionado?.toUpperCase();
    const dataAtual = new Date();

    if (filtroSelecionado === 'EM ATRASO') {
      this.faturasDataSource = this.faturas.filter((c: any) => {
        const situacaoFatura = c.situacaoDaFatura.toUpperCase();
        const dataVencimento = new Date(c.dataVencimento);

        if (
          situacaoFatura === 'EM ATRASO' ||
          (situacaoFatura === 'RESIDUAL A PAGAR' && dataVencimento < dataAtual)
        ) {
          return true;
        }

        return false;
      });
    } else if (filtroSelecionado === 'TODAS') {
      this.faturasDataSource = this.faturas;
    } else {
      this.faturasDataSource = this.faturas.filter(
        (c: any) => c.situacaoDaFatura.toUpperCase() === filtroSelecionado
      );
    }

    if (this.faturasDataSource.length !== 0) {
      setTimeout(() => {
        this.updatePagination();
      }, 0);
    }
    this.currentPageData = this.faturasDataSource;
  }

  private updatePagination() {
    this.breakpointService.breakpoint('900px').subscribe((result) => {
      if (this.paginationComponent) {
        this.paginationComponent.pageSize = result
          ? this.pageSizeMobile
          : this.pageSize;
      }
    });
  }

  updateCurrentPageData(data: Fatura[]) {
    setTimeout(() => {
      this.currentPageData = data;
      this.currentPageData = this.currentPageData.map((data) => ({
        ...data,
        isExpanded: false,
      }));
    });

    if (this.tabelaElementRef.nativeElement) {
      setTimeout(() => {
        this.tabelaElementRef.nativeElement.scrollIntoView({
          block: 'start',
        });
        window.scrollBy(0, -300);
      });
    }
  }

  selectErrorMessage(label: string) {
    setTimeout(() => {
      if (this.faturasDataSource.length === 0) {
        this.mensagemErro = `Não foi encontrada nenhuma fatura com o estado “${label}". <br> Caso precise de ajuda, entre em contato pelo nosso  <a  class="chat" >chat</a> ou pelo telefone 0800 055 0195.`;
      }
    });
  }

  removeTitulo() {
    let cbk = document.querySelector('#cb1');

    cbk?.removeAttribute('title');
  }

  adicionaTitulo() {
    let cbk = document.querySelector('#cb1');

    cbk?.setAttribute('aria-label', 'Selecionar todas as faturas em aberto');
  }

  openDialog(): void {
    this.dialog.open(DialogErrorComponent, {
      data: {
        img: 'icon_atencao',
        title: 'FALHA NO DOWNLOAD',
        content:
          'Ocorreu um erro e não foi possível realizar o download da sua fatura, por favor, tente novamente.<p> Caso o erro persista, favor aguardar um tempo antes de voltar a tentar novamente.</p>',
      },
    });

    //controla o funcionamento do botão de loading
    this.faturaSelecionadaDownload = '';
  }

  openDialogLoading(): void {
    this.dialogLoadingRef = this.dialog.open(DialogErrorComponent, {
      data: {
        img: 'icon_atencao',
        title: 'AGUARDE',
        content: 'Estamos buscando a sua fatura.',
      },
    });
  }

  exibeFatura(exibe: boolean) {
    this.exibir = exibe;
  }

  listDetalhesFaturas(numeroParcela: string, documentoId: string) {
    if (this.authToken) {
      this.faturaService
        .listarDetalhesFatura(documentoId, numeroParcela, this.authToken)
        .subscribe({
          next: (data) => {
            this.currentPageData.find((x) => {
              return x.documentoId === documentoId;
            }).subFatura = data;
          },
          error: (error) => {
            console.error(error);
          },
        });
    }
  }

  atualizarPaginaAtual(pagina: number) {
    this.paginaAtual = pagina;
  }

  getFaturaCompleta(item: string) {
    if (this.authToken) {
      this.faturaService
        .getUrlFaturaCompleta(this.fornecimento.codigo, item, this.authToken)
        .pipe(
          switchMap((data) => {
            if (data === null) {
              this.openDialog();
              console.error(
                'ERROR189: ===== Não foi possível efetuar o download da fatura! ====='
              );
              return EMPTY;
            }

            this.downloadExec = true;
            let Buffer = require('buffer/').Buffer;
            const decodeURL = Buffer.from(data.url64, 'base64').toString(
              'binary'
            );

            if (decodeURL) {
              if (this.exibir) {
                window.open(decodeURL, '_blank');
              } else {
                let headers = new HttpHeaders();
                headers = headers.set('Accept', 'application/pdf');
                return this.http.get(decodeURL, {
                  headers: headers,
                  responseType: 'blob',
                });
              }
            }
            return EMPTY;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (res) => {
            if (!this.exibir && res instanceof Blob) {
              let url = window.URL.createObjectURL(res);
              let a = document.createElement('a');
              a.href = url;
              a.download = `Fatura_${item}.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
            }
            this.countdown(0, 1);
            this.closeDialogLoading();
          },
          error: (error) => {
            this.openDialog();
            console.error(error);
            this.countdown(0, 1);
            this.closeDialogLoading();
          },
          complete: () => {
            this.carregaBotao = false;
            this.codPagFaturasSelecionadas = '';
            this.closeDialogLoading();
          },
        });
    }
  }

  downloadFaturaCompleta(item: Fatura, event: Event) {
    event.stopPropagation();
    this.openDialogLoading();
    this.downloadExec = false;
    this.getFaturaCompleta(item.codigoPagamento);
  }

  downloadFaturaZip() {
    if (this.authToken) {
      this.faturaService
        .getUrlFaturaCompletaZip(
          this.fornecimento.codigo,
          this.codPagFaturasSelecionadas,
          this.authToken
        )
        .subscribe({
          next: (data) => {
            if (data === null) {
              this.openDialog();
              console.error(
                'ERROR189: ===== Não foi possível efetuar o download da fatura! ====='
              );
            } else {
              this.faturaZipStarted = false;
              this.faturaZipFinished = true;
              this.downloadExec = true;
              let Buffer = require('buffer/').Buffer;
              const decodeURL = Buffer.from(data.url64, 'base64').toString(
                'binary'
              );
              if (decodeURL) window.open(decodeURL, '_blank');
              this.countdown(0, 1);
            }
          },
          error: (error) => {
            this.openDialog();
            console.error(error);
          },
          complete: () => {
            this.closeDialogLoading();
            this.codPagFaturasSelecionadas = '';
          },
        });
    }
  }

  downloadFaturaCompletaZip() {
    this.openDialogLoading();
    this.faturaZipStarted = true;
    this.faturaZipFinished = false;
    this.filterFaturaSelecionada();
    if (this.selection.selected.length > 1) {
      this.downloadFaturaZip();
    } else {
      this.carregaBotao = true;
      this.getFaturaCompleta(this.selection.selected[0]);
    }
  }

  countdown(minutes: number, seconds: number) {
    const tick = () => {
      this.counter =
        minutes.toString() + ':' + (seconds < 10 ? '0' : '') + String(seconds);
      seconds--;
      if (seconds >= 0) {
        this.timeoutHandle = setTimeout(tick, 2000);
      } else if (seconds === -1 && minutes === 0) {
        this.faturaSelecionadaDownload = '';
        this.faturaZipFinished = false;
      }
    };
    tick();
  }

  filterFaturaSelecionada(): void {
    let codPagFaturas: string = '';
    this.selection.selected.forEach((x) => {
      codPagFaturas += `${x}${encodeURIComponent(',')}`;
    });
    this.codPagFaturasSelecionadas += codPagFaturas.substring(
      0,
      codPagFaturas.length - 3
    );
  }

  handleCodPagContaRevisada(faturaSelecionada: FaturaSelecionada): void {
    switch (faturaSelecionada.action) {
      case 'adicionar':
        this.codPagFaturasSelecionadas += `${
          faturaSelecionada.codPagamento
        }${encodeURIComponent(',')}`;
        break;
      case 'excluir':
        this.codPagFaturasSelecionadas = this.codPagFaturasSelecionadas.replace(
          `${faturaSelecionada.codPagamento}${encodeURIComponent(',')}`,
          ''
        );
        break;
      default:
        break;
    }
  }

  selectFatura(event: Event) {
    event.stopPropagation();
    let faturas: any = [];
    this.faturas.forEach((fatura) => {
      if (
        fatura.situacaoDaFatura.toUpperCase() === 'EM ABERTO' ||
        fatura.situacaoDaFatura.toUpperCase() === 'RESIDUAL A PAGAR' ||
        fatura.situacaoDaFatura.toUpperCase() === 'EM ATRASO'
      ) {
        faturas.push(fatura);
      }
    });
    if (faturas.length > 0) {
      this.router.navigateByUrl('/pagamento-via-cartao', {
        state: { faturas },
      });
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.faturasDataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(
      ...this.faturas
        .filter(
          (x) =>
            x.situacaoDaFatura.toUpperCase() !== 'SUSPENSA PARA ANÁLISE' &&
            x.situacaoDaFatura.toUpperCase() !==
              'AGUARDANDO CONFIRMAÇÃO DO BANCO' &&
            x.situacaoDaFatura.toUpperCase() !== 'EM ACORDO DE PARCELAMENTO' &&
            !(
              x.situacaoDaFatura.toUpperCase() === 'EM ATRASO' &&
              x.cobrancaJuridica
            )
        )
        .map((x) => {
          return x.codigoPagamento;
        })
    );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Fatura): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${
      this.selection.isSelected(row.codigoPagamento) ? 'deselect' : 'select'
    } row ${row.codigoPagamento}`;
  }

  verificaDesabilitar(fatura: Fatura, status: string): boolean {
    return fatura.situacaoDaFatura.toUpperCase() == status.toUpperCase();
  }

  changeItemsPerPage(value: string) {
    this.itemsPerPage.forEach((i) => {
      i.active = value === i.value;
    });
    this.paginationComponent.currentPage = 1;
    this.paginationComponent.pageSize = Number(value);
    this.paginationComponent.updateCurrentPageData();
  }

  copyToClipboard(index: string) {
    this.buttonCopy = index;
    setTimeout(() => {
      this.buttonCopy = '';
    }, 5000);
  }

  closeDialogLoading() {
    if (this.dialogLoadingRef) {
      this.dialogLoadingRef?.close()
;
    }
  }
  getListaItensPaginados(): any[] {
    const indiceInicial = (this.paginaAtual - 1) * this.itensPorPagina;
    const indiceFinal = indiceInicial + this.itensPorPagina;
    return this.faturasDataSource.slice(indiceInicial, indiceFinal).length > 0
      ? this.faturasDataSource.slice(indiceInicial, indiceFinal)
      : this.faturasDataSource;
  }
}

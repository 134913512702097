import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'agv-input-imagem',
  template: `
  <div class="input-imagem" [ngClass]="{'error': error}">
    <div class="input-imagem__upload">
        <div class="input-imagem__upload__grupo">
            <label class="input-imagem__upload__grupo__label-principal" [for]="id + '__input-imagem__upload__grupo__input'">{{labelPrincipal}}</label>
                <a (click)="emitirClickAjuda(labelPrincipal)"><agv-icon [name]="'icn-ajuda'" class="input-imagem__upload__grupo__icn" *ngIf="ativaAjuda"></agv-icon></a>
        </div>
        <div class="input-imagem__upload__grupo">
            <label [for]="id + '__input-imagem__upload__grupo__input'" class="input-imagem__upload__grupo__label-input">{{labelBotao}}</label>
            <input (change)="selecionaArquivo($event)" type="file"  accept="image/jpg, image/jpeg, image/png" name="" [id]="id +'__input-imagem__upload__grupo__input'" class="input-imagem__upload__grupo__input" accept="image/jpg, image/jpeg, image/png"/>
        </div>
    </div>
    <div class="input-imagem__img" >
      <img  class="input-imagem__img__cliente" *ngIf="documento" [src]="documento"/>
      <agv-icon [name]="'icone_x'" (click)="limpaArquivo()" class=" input-imagem__img__fechaImagem" *ngIf="documento"></agv-icon>
        <agv-icon *ngIf="!documento" [name]="'no-file'" class="input-imagem__img__icone"></agv-icon>
    </div>
  </div>
  <div class="error-message" *ngIf="error">
    {{errorMessage}}
  </div>
  `,
  styles: [
    `
.input-imagem {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 474px;
  padding: 18px 23px;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  height: auto;
  background-color: var(--color-white);
  height: 87px;

  &__upload {
    display: flex;
    flex-direction: column;
    width: 70%;
    &__grupo {
      display: flex;
      flex-direction: row;
      margin-bottom: 7px;
      gap: 24px;
      &__label-principal {
        width: auto;
        display: flex;
        font-size: 0.75rem;
        text-align: left;
        line-height: 1.25rem;
        font-weight: 400;
        margin-top: -9px;
      }

      &__icn {
        display: flex;
        width: 18px;
        height: 18px;
        cursor:pointer;
      }
      &__label-input {
        display: flex;
        min-width: 115px;
        text-align: center;
        cursor: pointer;
        padding: 2px 10px;
        font-size: 0.75rem;
        line-height: 1.125rem;
        font-weight: 400;
        border: 1px solid var(--color-gray-300);
        border-radius: 4px;
      }
      &__input {
        display: none;
      }
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 30%;

    &__cliente{
      width: 45px;
    height: 34px;
    border-radius: 2px;
    }

    &__fechaImagem{
    width: 16px;
    height: 14px;
    margin-bottom: 34px;
    }
  }

  &.error {
    border: 1px solid var(--color-failed);
    .input-imagem {
      &__upload {
        &__grupo {
          &__label-principal, &__label-input {
            color: var(--color-failed);
          }
        }
      }
    }
  }
}
.error-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 475px; 
    color: var(--color-failed);
    font-size: 0.75rem;
    margin-top: 10px;
    text-align: left;
  }
`,],
})
export class InputImagemComponent implements OnInit, OnChanges {

  @Input() labelPrincipal: string;
  @Input() ativaAjuda: boolean;
  @Input() labelBotao: string;
  @Input() types: string[];
  @Input() id: string;
  @Input() file: any;
  
  errorMessage: string;
  documento: any;
  error: boolean;

  @Output() emitInfoSelecionada = new EventEmitter<string>()
  @Output() emitImgSelecionada = new EventEmitter<any>()
  @Output() emitImgLimpar = new EventEmitter<any>()

  constructor() { }


  ngOnInit() {
    const reader = new FileReader();
    if (this.file) {
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.documento = reader.result as string;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const reader = new FileReader();
    if (this.file) {
      this.errorMessage = "";
      this.error = false;
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.documento = reader.result as string;
      }
    } else {
      this.documento = undefined;
    }
  }

  selecionaArquivo(event: any) {
    const arquivos = event.target.files;

    const reader = new FileReader();
    let arquivoSelecionado = arquivos[0]
    if (arquivoSelecionado) {
      if ((arquivoSelecionado.size / 1024 / 1024) > 20) {
        this.errorMessage = "Arquivo possui mais de 10MB. Escolha um arquivo menor, no formato .jpg, .jpeg, ou .png. e tente novamente.";
        arquivoSelecionado = undefined;
        this.error = true;
      } else if (arquivoSelecionado.type !== "image/jpg" && arquivoSelecionado.type !== "image/jpeg" && arquivoSelecionado.type !== "image/png" ) {
        this.errorMessage = "Arquivo possui mais de 10MB. Escolha um arquivo menor, no formato .jpg, .jpeg, ou .png. e tente novamente.";
        arquivoSelecionado = undefined;
        this.error = true;
      } else {
        reader.readAsDataURL(arquivoSelecionado);
        reader.onload = () => {
          this.documento = reader.result as string;
        }
      }

      this.emitImgSelecionada.emit(arquivoSelecionado)
    }

  }

  limpaArquivo() {
    this.emitImgLimpar.emit();
  }

  emitirClickAjuda(label: string) {
    this.emitInfoSelecionada.emit(label)
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { PoliticaPrivacidadeService, politicaPrivacidade } from './services/politica-privacidade.service';
import { ThemeService } from 'src/app/shared/observables/theme.service';

@Component({
  selector: 'app-politica-privacidade',
  templateUrl: './politica-privacidade.component.html',
  styleUrls: ['./politica-privacidade.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PoliticaPrivacidadeComponent implements OnInit {
  // Variável para controlar o tema
  currentTheme: string;

  collapsed = false;
  setaExpand = PrimeIcons.ANGLE_DOWN;
  itensPoliticaPrivacidade: politicaPrivacidade[]

  constructor(
    private politicaPrivMock:PoliticaPrivacidadeService,
    private theme: ThemeService
    ) { }

  ngOnInit(): void { 
    this.itensPoliticaPrivacidade = this.politicaPrivMock.getPoliticaPrivacidade();
    this.theme.currentTheme.subscribe((theme) => this.currentTheme = theme);
  }

  elementoAbriu(i: number) {
    var elementAtual: string = i.toString();
    let expandirIcon: HTMLImageElement = <HTMLImageElement>(document.getElementById(elementAtual));
    expandirIcon.src = "../assets/imgs/svg/icn_remove-light-grey.svg";
  }

  elementoFechou(i: number) {
    var elementAtual: string = i.toString();
    let expandirIcon: HTMLImageElement = <HTMLImageElement>(document.getElementById(elementAtual));
    expandirIcon.src = "../assets/imgs/svg/icn_add.svg";
  }
}

import {
  InputSelect,
  InputSelectOption,
} from '../agv-material/agv-inputs/input-select/input-select.model';

import {
  Endereco,
  Tipocivico,
  Toponimo,
} from 'src/app/core/models/enderecos/endereco';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EnderecoService } from 'src/app/services/enderecos.service';

@Component({
  selector: 'app-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss'],
})
export class EnderecoComponent implements OnInit, OnChanges {
  // Actions
  @Output() sendAction = new EventEmitter();
  @Output() sendDados = new EventEmitter();

  // Inputs - Object
  @Input() endereco: Endereco = new Endereco();

  // Inputs - Boolean
  @Input() blocked: boolean = false;
  @Input() loading: boolean = false;

  // Inputs - String
  @Input() id: string = '';

  // Inputs - Lista
  @Input() listaTipoCivico: Tipocivico[];
  @Input() listaToponimo: Toponimo[];

  // Objects
  tipoToponimoOptions: InputSelect = new InputSelect();
  tipoCivicoOptions: InputSelect = new InputSelect();

  // Boolean
  showInfoCep: boolean;
  isMobile: boolean;

  // String
  valorCampoSelect: string;

  constructor(private enderecoServices: EnderecoService) {}

  ngOnInit(): void {
    if (
      this.listaTipoCivico !== undefined &&
      this.listaTipoCivico.length > 0 &&
      this.listaToponimo !== undefined &&
      this.listaToponimo.length > 0
    ) {
      this.listarTipoCivico();
      this.listarToponimo();
    }
    this.showInfoCep = true;
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
    window.onresize = () => (this.isMobile = window.innerWidth <= 768);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.listaTipoCivico !== undefined &&
      this.listaTipoCivico.length > 0 &&
      this.listaToponimo !== undefined &&
      this.listaToponimo.length > 0
    ) {
      this.listarTipoCivico();
      this.listarToponimo();
    }
  }

  listarTipoCivico() {
    this.tipoCivicoOptions.label = 'Tipo de número: *';
    this.tipoCivicoOptions.placeholder = 'Selecione';
    this.listaTipoCivico.forEach((tipoCivico) => {
      let dado: InputSelectOption = new InputSelectOption();
      dado.value = tipoCivico.tipoCivicoId;
      dado.labels = [{ value: tipoCivico.tipoCivicoDescr, img: false }];
      this.tipoCivicoOptions.options.push(dado);
    });
  }

  listarToponimo() {
    this.tipoToponimoOptions.label = 'Tipo de endereço: *';
    this.tipoToponimoOptions.placeholder = 'Selecione';
    this.enderecoServices.listaToponimoCorreio().subscribe({
      next: (res: Toponimo[]) => {
        res.forEach((x: any) => {
          let dado: InputSelectOption = new InputSelectOption();
          dado.value = x.toponimoId;
          dado.labels = [{ value: x.toponimoDescr, img: false }];
          this.tipoToponimoOptions.options.push(dado);
        });
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  buscaCep() {
    this.sendAction.emit(this.endereco);
  }

  getCep(e: any) {
    this.endereco.cepCodigo = e;
    this.sendDados.emit(this.endereco);
  }

  getUf(e: any) {
    this.endereco.estadoCodigo = e;
    this.sendDados.emit(this.endereco);
  }

  getMunicipio(e: any) {
    this.endereco.municipioDescr = e;
    this.sendDados.emit(this.endereco);
  }

  getBairro(e: any) {
    this.endereco.bairroDescr = e;
    this.sendDados.emit(this.endereco);
  }

  getToponimo(e: string) {
    this.endereco.toponimoDescr = e;
    this.listaToponimo.map((x) => {
      if (x.toponimoDescr === e) {
        this.endereco.toponimoCodigo = x.toponimoCodigo;
        this.endereco.toponimoId = x.toponimoId;
      }
    });
    this.sendDados.emit(this.endereco);
  }

  getEndereco(e: any) {
    this.endereco.logradouroDescr = e;
    this.sendDados.emit(this.endereco);
  }

  getTipoCivico(e: string) {
    this.endereco.tipoCivicoDescr = e;
    this.listaTipoCivico.map((x) => {
      if (x.tipoCivicoDescr === e) {
        this.endereco.tipoCivicoCodigo = x.tipoCivicoId;
        this.endereco.tipoCivicoDescr = x.tipoCivicoDescr;
      }
    });
    this.sendDados.emit(this.endereco);
  }

  getNumero(e: any) {
    this.endereco.tipoCivicoId = e;
    this.sendDados.emit(this.endereco);
  }

  getComplemento(e: any) {
    this.endereco.complemento = e;
    this.sendDados.emit(this.endereco);
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Regex } from 'src/app/shared/utils/regex';

@Component({
  selector: 'agv-input-telefone',
  template: `
    <div
      role="group"
      [id]="id + '__input-telefone'"
      class="input-telefone"
      [ngClass]="{ disabled: blocked }"
    >
      <label
        class="input-telefone__label"
        [for]="id + '__input-telefone__telefone'"
        >{{ label }}</label
      >
      <input
        #inputTelefone
        [id]="id + '__input-telefone__telefone'"
        [placeholder]="placeholder"
        class="input-telefone__input"
        (keyup)="changeValor($event)"
        maxlength="15"
        [disabled]="blocked"
      />
    </div>
  `,
  styles: [
    `
      .input-telefone {
        display: flex;
        height: 65px;
        width: 100%;
        position: relative;
        background-color: var(--color-normal-white);
        border: 1px solid var(--color-gray-always-weak);
        border-radius: 4px;
        padding: 10px 22px 10px 22px;

        &__label {
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.25rem;
          position: absolute;
          top: 6px;
          color: var(--color-normal-grey);
          display: flex;
          width: calc(100% - 44px);
          height: 1.25rem;
        }

        &__input {
          font-weight: 400;
          font-size: 0.875rem !important;
          line-height: 1.25rem;
          color: var(--color-black);
          background: transparent;
          border: none;
          width: calc(100% - 44px);
          height: 20px;
          bottom: 13px;
          position: absolute;
          max-width: 431px;
        }

        input::placeholder{
          color: var(--color-normal-grey);
        }

        &.disabled {
          cursor: not-allowed;
          background-color: var(--color-gray-always-weak-disable);

          input,
          label {
            cursor: not-allowed;
          }
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTelefoneComponent),
      multi: true
    }
  ]
})
export class InputTelefoneComponent implements OnInit, OnChanges {
  @Output() sendValor = new EventEmitter<any>();
  @ViewChild('inputTelefone') inputTelefone: ElementRef;
  @Input() id: string = '';
  @Input() value: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() blocked: boolean = false;
  telefone: string = '';

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue !== changes['value']?.previousValue) {
      if (this.inputTelefone && this.inputTelefone.nativeElement)
        this.inputTelefone.nativeElement.value = changes['value']?.currentValue;
    }
  }

  changeValor(e: any) {
    this.value = this.inputTelefone.nativeElement.value;
    this.onChange(this.value);
    this.telefone = Regex.maskTelefone(this.inputTelefone.nativeElement.value);
    this.inputTelefone.nativeElement.value = this.telefone;
    this.sendValor.emit(this.telefone);
  }

  writeValue(value: any): void {
    this.value = value || '';
    if (this.inputTelefone && this.inputTelefone.nativeElement) {
      this.inputTelefone.nativeElement.value = this.value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.blocked = isDisabled;
  }
}

<section class="container-signature">
    <h4>Por favor, assine aqui</h4>
    <p class="textAlign" *ngIf="signatureNeeded">Por favor, preencha o campo com a sua assinatura.</p>
    <div class="bordaCanva">
        <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="474"
            height="200"></canvas>
    </div>
    <div>
        <button class="buttonClear" (click)="clearPad()">Limpar</button>
        <button class="buttonSubmit" (click)="savePad()">Enviar</button>
    </div>
    <div class="textAlign" *ngIf="!signatureNeeded && !empty">
        <p>Assinatura formato base64</p>
        <img [src]="signatureImg "/>
    </div>
</section>
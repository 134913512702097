import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Validator } from '../../utils/validator';

@Component({
  selector: 'app-dialog-aviso-logado',
  templateUrl: './dialog-aviso-logado.component.html',
  styleUrls: ['./dialog-aviso-logado.component.scss'],
})
export class DialogAvisoLogadoComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogAvisoLogadoComponent>,
  ) {}

  ngOnInit(): void {}

  novaPagina() {
    this.dialogRef.close();
    this.router.navigate(['negocios/empresa']);
  }

  popUpNovo(e: any) {
    if (Validator.validaEventKeyPressClick(e)) {
      this.novaPagina();
    }
  }

  navegarFecharPoUp() {
    this.dialogRef.close();
    this.router.navigate(['negocios/empresa']);
  }
}

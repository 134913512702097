export const environment = {
  production: true,
  publicKeyCard: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB',
  uri: 'https://sabesp-novaagv-internal-gw-dev.engdb.com.br/st/',
  runMock: false,
  encryptionKey: 'yUZioQ6fIhEOAsbqFYV21pRxDsOxvkxV',
  siteKey: '6LdAKvwlAAAAAFcR7G_byB2FSqYDF8gEhkQGRJmp',
  urlAGV: 'https://corp1350.ti.sabesp.com.br:8443/',
  urlNoticia: 'https://sabesp-novaagv-dev.engdb.com.br/service-gestao/',
  apikey:'KahDWXgRZyJPtuFi2fsdTaS0bmIf66wy',
  disabledRecaptcha: true,
  googleAnalytics: 'G-J5L9XYQNC4',
  env: 'dev',
  url: 'https://sabesp-novaagv-dev.engdb.com.br'
};

import { MatDialogRef } from '@angular/material/dialog';
import { Validator } from '../../utils/validator';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-nova-agv-aviso',
  templateUrl: './dialog-nova-agv-aviso.component.html',
  styleUrls: ['./dialog-nova-agv-aviso.component.scss'],
})
export class DialogNovaAgvAvisoComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogNovaAgvAvisoComponent>,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  novaPagina() {
    this.dialogRef.close();
    this.router.navigate(['novidades']);
  }

  popUpNovo(e: any) {
    if (Validator.validaEventKeyPressClick(e)) {
      this.novaPagina();
    }
  }
  
  navegarFecharPoUp() {
    this.router.navigate(['/cadastrar']);
    this.dialogRef.close();
  }

  setStorage() {
    document.cookie = "showAvisoNovaAGV=false; expires="+new Date((new Date().getTime()+86400000))+" UTC; path=/";
  }
}

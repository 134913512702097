import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';

import { Notifications } from '../core/models/notification';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  public url = environment.uri;
  public headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private tokenService: TokenStorageService
  ) {}

  atualizarFotoPerfilUsuario(pictureFile: File): Observable<any> {
    const formData = new FormData();
    formData.append('fileUpload', pictureFile);

    const headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: `Bearer ${this.tokenService.getToken()}`,
    });
    if (
      this.tokenService.getToken() !== undefined &&
      this.tokenService.getToken() !== ''
    ) {
      return this.http.post<any>(
        this.url + 'v1/completa/cliente/usuario/avatar',
        formData,
        { headers: headers }
      );
    } else {
      return new Observable<any>();
    }
  }
  obterFotoPerfilUsuario(): Observable<any> {
    if (
      this.tokenService.getToken() !== undefined &&
      this.tokenService.getToken() !== ''
    ) {
      return this.http.get<any>(
        this.url + 'v1/completa/cliente/usuario/avatar'
      );
    } else {
      return new Observable<any>();
    }
  }

  getNotificacoesNaoLidas(authToken: any, codFornecimento: number) {
    let headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${authToken}`);
    return this.http.get<Notifications>(
      `${this.url}v1/iot/analise/notificacoes/nao/lidas/${codFornecimento}`, { headers }
    );
  }

  getNotificacoes(authToken: any, codFornecimento: number) {
    let headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${authToken}`);
    return this.http.get<Notifications>(
      `${this.url}v1/iot/analise/notificacoes/${codFornecimento}`, { headers }
    );
  }

  readNotification(authToken: any, codFornecimento: number, notificationId: number) {
    let headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${authToken}`);
    return this.http.post<Notifications>(
      `${this.url}v1/iot/analise/notificacoes/lida/${codFornecimento}/${notificationId}`, { headers }
    );
  }
}

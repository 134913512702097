<div #formasPagamento id="formas-pagamento" class="formas-pagamento" *ngIf="!sucessExibirPdf">
  <mat-tab-group [disableRipple]="true" (selectedTabChange)="tabChanged($event)">
    <mat-tab [disabled]='payment.disabled' *ngFor="let payment of paymentMethods" class="pagamento-tab">
      <ng-template mat-tab-label>
        <div [id]="gerarId(payment.name)" class="formas-pagamento_conteudo-flex">
          <div role="img" class="formas-pagamento_conteudo-flex_img" aria-label="Forma de Pagamento">
            <agv-icon class="img-inativo" [name]="payment.iconClass" title="{{payment.alt}}"></agv-icon>
            <agv-icon class="img-ativo" [name]="payment.iconActive" title="{{payment.altAtivo}}"></agv-icon>
          </div>
          <div class="formas-pagamento_conteudo-flex_nome" [innerHTML]="payment.name">
          </div>
        </div>
      </ng-template>
      <pagamento-fatura [paymentMethodType]="paymentMethod" (sucessoExibirPdf)="exibirPDF($event)"
        [parcela]="parcela" [logado]="logado" [banco]="bancoSelecionado"></pagamento-fatura>
    </mat-tab>
  </mat-tab-group>
</div>

<app-notificacao-processo id="pagto-fatura-msg-sucesso" *ngIf="sucessExibirPdf" [tipoMensagem]="'sucesso'"
  [tituloMensagem]="'SOLICITAÇÃO DE 2a VIA DE PARCELAS CONCLUÍDA COM SUCESSO!'"
  [fontWeightTitle]="500"
  [fontWeightDescription]="400"
  [mensagem]="'Se você realizou o pagamento, aguarde até 2 dias úteis para confirmação.'">
</app-notificacao-processo>

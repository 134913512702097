import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})


export class SidebarComponent {
  submenuVisible: boolean = false;
  activeRoute?: string;

  submenuItems = [
    { iconSubmenu: 'Right-sidebar', route: 'links-canais-digitais', label: 'Canais Digitais' },
    { iconSubmenu: 'Right-sidebar', route: 'links-servicos', label: 'Serviços' },
    { iconSubmenu: 'Right-sidebar', route: 'links-telefones', label: 'Telefones Úteis' },
    { iconSubmenu: 'Right-sidebar', route: 'links-redes-sociais', label: 'Redes Sociais' }
  ];
  

  submenuItems2 = [
    { iconSubmenu: 'Right-sidebar.svg', route: 'guia-categoria', label: 'Categoria' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'guia-condicoes', label: 'Condições' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'guia-documentos', label: 'Documentos' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'guia-local-solicitante', label: 'Local Solicitante' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'guia-pagamento', label: 'Pagamento' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'guia-prazo', label: 'Prazo' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'guia-preco', label: 'Preço' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'guia-solicitante', label: 'Solicitante' },
  ];

  menuItems = [
    { active: false, icon: 'icon-dashboard.svg', route: 'dashboard', label: 'Dashboard' },
    { active: false, icon: 'control-user.svg', route: 'controle-usuarios', label: 'Controle de Usuários' },
    { active: false, icon: 'control-perfil.svg', route: '', label: 'Controle de Perfil' },
    { active: false, icon: 'control-param.svg', route: 'controle-parametros', label: 'Controle de Parâmetros' },
    { active: false, icon: 'control-bloqueios.svg', route: 'controle-bloqueios', label: 'Controle de Bloqueios' },
    { active: false, icon: 'control-noticias.svg', route: 'controle-noticias', label: 'Controle de Notícias' },
    { active: false, icon: 'control-galeria.svg', route: 'controle-galeria', label: 'Controle de Galeria' },
    { active: false, icon: 'faq-admin.svg', route: 'perguntas-frequentes-admin', label: 'Perguntas Frequentes' },
    { active: false, icon: 'links-rodape.svg', label: 'Controle de Links Rodapé', hasSubmenu: true, submenu: this.submenuItems },
    { active: false, icon: 'links-rodape.svg', label: 'Controle de Guia de Serviços', hasSubmenu: true, submenu: this.submenuItems2 },
  ];


  constructor(private router: Router) {}

  isActive(route?: string): boolean {
    return route ? this.activeRoute === route : false;
  }

  toggleSubmenu(item: any): void {
    item.active = !item.active;
  }

  controlaItem(item: any) {
    if (item.hasSubmenu) {
      item.active = !item.active
    } else {
      this.router.navigateByUrl(item.route);
    }
  }
}

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-notificacao-processo',
  templateUrl: './notificacao-processo.component.html',
  styleUrls: ['./notificacao-processo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificacaoProcessoComponent implements OnInit {
  @Input() tipoMensagem: string = '';
  @Input() tituloMensagem: string = '';
  @Input() mensagem: string = '';
  @Input() mensagemComElementos: any;
  @Input() fontWeightTitle: number = 400;
  @Input() fontWeightDescription: number = 400;

  ngOnInit(): void {}
}

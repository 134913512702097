import { BuscaFaturasPorFornecimentoResumido } from '../../models/service/faturasPorFornecimento-resumido';
import { createAction, props } from '@ngrx/store';

export const carregaParametro = createAction(
  `[Parâmetros] carregar parâmetros`,
  props<{ codigo: string}>()
);

export const sucessoCarregaParametro = createAction(
  `[Parâmetros] sucesso ao carregar parâmetros`,
  props<{ valor: any }>()
);

export const erroCarregarParametros = createAction(
  `[Parâmetros] falha ao carregar parâmetros`,
  props<{ error: any }>()
)
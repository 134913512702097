import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  public breakpoint(breakpoint: string): Observable<boolean> {
    return this.breakpointObserver.observe([`(max-width: ${breakpoint})`]).pipe(
      map((result) => {
        return result.matches;
      })
    );
  }
}

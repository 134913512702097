import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { EventEmitterService } from 'src/app/event-emits.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input() checkboxMensagem: string = '';
  @Output() isChecked = new EventEmitter();
  checked:boolean = false;
  
  constructor() {}

  ngOnInit(): void {}

  emissorChecked() {
    this.isChecked.emit(this.checked);
    EventEmitterService.get('checked').emit(this.checked);
  }
}

<div class="confirma-pagto_container">
    <div class="confirma-pagto_content">
        <div class="confirma-pagto_content_titulo">
            <span *ngIf="status !== 'SUPRIMIDO' && status !== 'CORTADO'">Comunicação de pagamento</span>
            <span *ngIf="status === 'SUPRIMIDO'">Fornecimento suprimido</span>
            <span *ngIf="status === 'CORTADO'">Fornecimento cortado</span>
        </div>
        <div class="confirma-pagto_content_perg1" *ngIf="etapa === 1">
            <div class="confirma-pagto_content_perg1_desc">
                <span>Qual o estado da sua ligação?</span>
            </div>
            <div class="confirma-pagto_content_perg1_radios">
                <mat-radio-group [(ngModel)]="perg1">
                    <mat-radio-button value="1">
                        Visivelmente em boa condição (sem vazamento, ferrugem, peças soltas ou quebradas, etc) e não houve troca do cavalete nem do local da ligação.
                    </mat-radio-button>

                    <mat-radio-button value="2">
                        O hidrômetro está quebrado, vazando ou travando.
                    </mat-radio-button>
                    <mat-radio-button value="3">
                        O hidrômetro/medidor está desaparecido e/ou foi furtado.
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="confirma-pagto_content_confirmacao" *ngIf="etapa === 2">
            <span class="confirma-pagto_content_confirmacao_item" *ngIf="status == 'SUPRIMIDO'">
                <strong>Serviço: </strong>
                Pagamento informado com efeito de religação por débito.
            </span>
            <span class="confirma-pagto_content_confirmacao_item" *ngIf="status == 'CORTADO'">
                <strong>Serviço: </strong>
                Pagamento informado com reestabelecimento.
            </span>
            <span class="confirma-pagto_content_confirmacao_item">
                <strong>Prazo de atendimento: </strong>
                Até 48 horas. É necessário deixar acesso livre ao local e manter uma pessoa maior de 18 anos para atender nossa equipe. Caso haja necessidade de agendar o serviço, consulte nossos canais de atendimento.
            </span>
            <span class="confirma-pagto_content_confirmacao_item" *ngIf="status == 'SUPRIMIDO'">
                <strong>Preço: </strong>
                R$ 40,00 a serem cobrados na próxima fatura.
            </span>
            <span class="confirma-pagto_content_confirmacao_item" *ngIf="status == 'CORTADO'">
                <strong>Preço: </strong>
                R$ 8,00 a serem cobrados na próxima fatura.
            </span>
            <span class="confirma-pagto_content_confirmacao_item">
                <strong>Importante: </strong>
                O prazo e/ou preço informados podem variar, a depender das condições do local.
            </span>
        </div>
        <div class="confirma-pagto_content_btns">
            <button class="confirma-pagto_content_btns_cancelar" *ngIf="etapa === 1" (click)="comunicarOutro()">Cancelar</button>
            <button class="confirma-pagto_content_btns_voltar" *ngIf="etapa === 2" (click)="voltar()">Voltar</button>
            <button class="confirma-pagto_content_btns_continuar" *ngIf="etapa !== 3" (click)="continuar()"
            [disabled]="!habilitaContinuar()" [ngClass]="{'confirma-pagto_content_btns_continuar_disabled': !habilitaContinuar()}">
                Continuar
            </button>
        </div>
    </div>
</div>

<div role="group" id="menu-parcelamento" class="menu-parcelamento">
  <div role="group" id="menu-parcelamento-content" class="menu-parcelamento__content">
    <div role="group" id="menu-parcelamento-titulo" class="menu-parcelamento__content__titulo">
      <agv-icon 
        [name]="'icn-parcelamento'" 
        [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }"
        tabindex="-1">
      </agv-icon>
      <p>
        Meus parcelamentos
      </p>
    </div>
    <button mat-raised-button color="primary" (click)="acessarParcelamentos()" id="menu-parcelamento-botao-acessar"
      aria-label="Clique aqui para acessar a página de Meus parcelamentos">
      Acessar
    </button>
  </div>
</div>
import { AbstractControl, ValidationErrors } from '@angular/forms';

interface Errors {
  upperCase?: string;
  lowerCase?: string;
  numberCharacters?: string;
  specialCharacter?: string;
  forbiddenSpecialCharacters?: string;
}

export const SenhasValidator = (control: AbstractControl): any | null => {
  let value: string = control.value || '';
  if (!value) {
    return null;
  }
  let errors: Errors = {};

  let upperCaseCharacters = /[A-Z]+/g;
  if (upperCaseCharacters.test(value) === false) {
    errors.upperCase = `text has to contine Upper case characters,current value ${value}`;
  }

  let lowerCaseCharacters = /[a-z]+/g;
  if (lowerCaseCharacters.test(value) === false) {
    errors.lowerCase = `text has to contine lower case characters,current value ${value}`;
  }

  let numberCharacters = /[0-9]+/g;
  if (numberCharacters.test(value) === false) {
    errors.numberCharacters = `text has to contine number characters,current value ${value}`;
  }

  let specialCharacters = /[!@#$%^&*()_+\-=]+/;
  if (specialCharacters.test(value) === false) {
    errors.specialCharacter = `text has to contine special character,current value ${value}`;
  }
  let forbiddenSpecialCharacters = /[^a-zA-z0-9!@#$%^&*()_+\-=]+/;
  if (forbiddenSpecialCharacters.test(value) || /\s/.test(value)) {
    errors.forbiddenSpecialCharacters = `text has forbidden special character, current value ${value}`;
  }
    return errors;
};
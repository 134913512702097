import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  NgZone,
} from '@angular/core';
import { LoginService } from './login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Regex } from '../../utils/regex';
import { MensagemComponent } from '../mensagem-erro/mensagem-erro.component';
import { Observable, first, filter, take, takeUntil, distinctUntilChanged, Subject } from 'rxjs';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { Store } from '@ngrx/store';
import { getClientInformation } from 'src/app/core/store/user/user.selectors';
import { Validator } from '../../utils/validator';
import { CarregamentoService } from 'src/app/services/carregamento.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeService } from '../../observables/theme.service';
import { ClienteService } from '../../observables/cliente.service';
import { carregaFornecimentos, sucessoCarregaFornecimentos } from 'src/app/core/store/supply/supply.actions';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  // Controlador do tema
  currentTheme: string;
  senhaInvalida: boolean = false;
  @ViewChild('inputCpf') inputCpf!: ElementRef;
  @ViewChild('inputSenha') inputSenha!: ElementRef;
  @Output() login: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('divRecaptcha')
  divRecaptcha!: ElementRef<HTMLDivElement>;
  @ViewChild(MensagemComponent, { static: false })
  mensagemComponent: MensagemComponent;
  public client$: Observable<any> = this.store.select(getClientInformation);
  cpf: string = '000.000.000-00';
  senha: string = '';
  mensagemErro: string = '';
  manterConectado: boolean = false;
  hideSenha = true;
  loadLogin: boolean = false;
  estaCarregando$ = this._carregamentoService.estaCarregando();
  recaptchaSelecionado: boolean = false;
  mostrarRecaptcha: boolean = false;
  cpfValido: boolean = false;
  lodingRecaptha: boolean = false;
  mensagemErroInput: string | null = null;
  tokenRecaptcha: string = '';
  destroyed$ = new Subject<boolean>();

  constructor(
    private readonly loginService: LoginService,
    private router: Router,
    private tokenService: TokenStorageService,
    private store: Store,
    private _carregamentoService: CarregamentoService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private updates$: Actions,
    private ngZone: NgZone,
    private theme: ThemeService,
    private cliObs: ClienteService
  ) {
    this.estaCarregando$.subscribe((carregando) => {
      this.loadLogin = carregando;
    });

    if (environment.disabledRecaptcha) {
      this.recaptchaSelecionado = true;
    }
  }

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
    this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      const servico = params['servico'] ? params['servico'] : '';
      const tipo = params['tipo'] ? params['tipo'] : '';
      if (token) {
        this.authService.authJWT(token).subscribe({
          next: (res) => {
            this.tokenService.saveExternalToken(token);
            this.cliObs.currentCliente.subscribe((client) => {
              this.router.navigate(['/', servico]);
              if (tipo) {
                setTimeout(() => {
                  const element = document.getElementById(tipo);
                  if (element) {
                    const offset = element.offsetTop - 50;
                    window.scrollTo({ top: offset, behavior: 'smooth' });
                  }
                }, 500);
              }
            });
          },
          error: (err) => {
            this.router.navigate(['/']);
          },
        });
      } else {
        if (!this.router.url.includes('negocios')) {
          this.router.navigate(['/', servico]);
        }
      }
    });

    this.manterConectado = JSON.parse(
      this.tokenService.getManterConectado() || 'false'
    );
  }
  get grecaptcha(): any {
    const w = window as any;
    return w['grecaptcha'];
  }

  renderizarReCaptcha() {
    this.ngZone.runOutsideAngular(() => {
      if (!this.grecaptcha || !this.divRecaptcha) {
        setTimeout(() => {
          this.renderizarReCaptcha();
        }, 500);

        return;
      }

      const idElemento = this.divRecaptcha?.nativeElement?.getAttribute('id');

      if (this.grecaptcha && this.grecaptcha.enterprise) {
        this.grecaptcha?.enterprise?.render(idElemento, {
          sitekey: environment.siteKey,
          callback: (response: string) => {
            this.ngZone.run(() => {
              this.recaptchaSelecionado = true;
              this.tokenRecaptcha = response;
            });
          },
        });
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderizarReCaptcha();
    }, 800);
  }

  verificaLoginRecaptcha() {
    this.senhaInvalida = false;
    this.mostrarRecaptcha = false;
    this.mensagemErroInput = null;

    // Validações de CPF e Senha
    this.validarCpf();
    this.validarSenha();

    // Se CPF ou Senha não forem válidos, sair do método
    if (!this.cpfValido || this.mensagemErroInput) {
      return;
    }

    this.lodingRecaptha = true;
    setTimeout(() => {
      this.renderizarReCaptcha();

      this.lodingRecaptha = false;
      this.mostrarRecaptcha = true;
    }, 600);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  validarCpf() {
    this.cpfValido = Validator.validarCPF(Regex.removeMaskCpf(this.cpf));
    if (!this.cpfValido) {
      this.mensagemErroInput =
        'O número de CPF é Inválido, por favor verifique e digite novamente.';
      document.getElementById('login_input_cpf')?.classList.add('failed');
    } else {
      document.getElementById('login_input_cpf')?.classList.remove('failed');
    }
  }

  validarSenha() {
    if (this.senha.length < 6 || this.senha.length > 16) {
      this.mensagemErroInput = `A senha deve conter de 6 à 16 caracteres`;
      document.getElementById('login_input_senha')?.classList.add('failed');
      this.loadLogin = false;
    } else {
      document.getElementById('login_input_senha')?.classList.remove('failed');
    }
  }

  logar() {
    this.senhaInvalida = false;
    this.mensagemErro = '';
    document.getElementById('login_input_cpf')?.classList.remove('failed');
    document.getElementById('login_input_senha')?.classList.remove('failed');
    this.loadLogin = true;

    this.loginService
      .autenticarLogin(
        this.cpf,
        this.senha,
        this.tokenRecaptcha,
        this.manterConectado
      )
      .pipe(first())
      .subscribe({
        next: () => {
          let returnPage = `${localStorage.getItem('pageReturn')}`;
          if (returnPage != 'fatura-completa' && returnPage != 'null') {
            this.store.dispatch(carregaFornecimentos());
            this.updates$
              .pipe(
                ofType(sucessoCarregaFornecimentos),
                takeUntil(this.destroyed$),
                take(1),
                distinctUntilChanged()
              )
              .subscribe((action: any) => {
                localStorage.removeItem('pageReturn');
                this.loadLogin = false;
                this.router.navigateByUrl(`/${returnPage}`);
              });
          } else {
            this.router.navigate(['/fatura-completa']);
          }
        },
        error: (error) => {
          if (error === 401) {
            this.senhaInvalida = true;
            this.mostrarRecaptcha = false;

            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.mensagemErro = `CPF ou Senha não correspondem em nossa base de dados, por favor verifique se os números do CPF e Senha estão corretos  e selecione uma opção abaixo.`;
            setTimeout(() => {
              document
                .getElementById('login_input_cpf')
                ?.classList.add('failed');
              document
                .getElementById('login_input_senha')
                ?.classList.add('failed');
            }, 200);

            this.resetRecaptcha();
            this.loadLogin = false;
          } else {
            this.mostrarRecaptcha = false;
            this.mensagemErro = `Houve um problema e não conseguimos processar seu pedido. Por favor, tente novamente mais tarde.`;
            setTimeout(() => {
              document
                .getElementById('login_input_cpf')
                ?.classList.add('failed');
              document
                .getElementById('login_input_senha')
                ?.classList.add('failed');
            }, 200);

            this.cpf = '';
            this.senha = '';
            this.loadLogin = false;
            this.resetRecaptcha();
          }
          if (this.mensagemErro != '') {
            this.mensagemComponent?.focusMensagem();
            this.loadLogin = false;
          }
          this.mostrarRecaptcha = false;
        },
      });
  }

  redirecionar() {
    this.router.navigate(['/cadastrar']);
  }

  disableBtn(): boolean {
    return (
      !this.recaptchaSelecionado ||
      this.cpf === '' ||
      this.senha === '' ||
      !Validator.validarCPF(this.cpf)
    );
  }

  disableBtnVerificaPasswordLogin(): boolean {
    return (
      this.cpf == '' ||
      this.cpf === '000.000.000-00' ||
      this.senha === '' ||
      !this.cpfValido
    );
  }

  validaClick(event: Event) {
    if (Validator.validaEventKeyPressClick(event)) {
      this.hideSenha = !this.hideSenha;
    }
  }

  recuperar() {
    this.router.navigate(['/recuperar-senha']);
  }

  removerReCaptcha() {
    const elementoReCaptcha = document.getElementById('recaptcha');
    if (elementoReCaptcha && this.grecaptcha) {
      this.grecaptcha?.enterprise?.reset();
      this.recaptchaSelecionado = false;
    }
  }

  resetRecaptcha() {
    this.removerReCaptcha();
    setTimeout(() => {
      this.renderizarReCaptcha();
    }, 800);
  }

  inputMaskCpf() {
    this.cpf = Regex.maskCpf(this.inputCpf.nativeElement.value);
    if (!Validator.validarCPF(this.cpf)) {
      this.mensagemErroInput = `O número de CPF é Inválido, por favor verifique e digite novamente.`;
      this.cpfValido = false;
    } else {
      this.cpfValido = true;
      this.mensagemErroInput = null;
    }
  }

  clean() {
    if (this.cpf == '000.000.000-00') {
      this.cpf = '';
    }
  }
}

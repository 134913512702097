<div class="filtro-faturas">
    <div class="filtro-fatura-content">
        <div class="filtro-faturas_titulos">
            <p fxHide fxShow.gt-sm class="filtro-faturas_titulo" tabindex="0">Histórico de faturas</p>
        </div>
        <div class="filtro-faturas_filtros">
            <mat-radio-group fxHide fxShow.gt-sm class="filtro-faturas_radio"
                (ngModelChange)="userNamengmodelchange($event)" [(ngModel)]="filtroSelecionado">
                <mat-radio-button [attr.tabindex]="tabIndexValue === 0 ? 0 : -1" (keydown.Tab)="setTabIndex(1)"
                    (keydown.enter)="marcarOpcao('todas')" value="todas">Todas</mat-radio-button>
                <mat-radio-button *ngFor="let filtros of filtrosFatura; let i = index"
                    [attr.tabindex]="tabIndexValue === i + 1 ? 0 : -1" (keydown.Tab)="setTabIndex(i + 2)"
                    (keydown.enter)="marcarOpcao(filtros)" [value]="filtros">
                    {{filtros}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div id="selecao-situacao-conteudo" role="group" class="filtro-faturas__content" fxHide.gt-sm fxShow>
            <div id="container-selecao" role="group" class="selectContainer" clickOutside
                (clickOutside)="closeSelect()">
                <div id="campo-selecao" role="group" tabindex="0" class="select-field" (click)="toggleSelect()"
                    (keypress)="abrirSituacao($event)" #situacao>
                    <div id="campo-selecao-conteudo" role="group" class="select-field__content">
                        <div role="group" class="filtro-label">
                            Situação
                        </div>
                        <div id="campo-selecao-situacoes" role="group" class="filtro-value">
                            <span class="filtro-value__label1">
                                {{ filtroSelecionado }}
                            </span>
                        </div>
                    </div>
                    <div class="icon-arrow">
                        <agv-icon class="icon" [ngClass]="{'rotate-180': selectOpened}" title="Abrir Situação da Fatura" [name]="'arrow_black'"></agv-icon>
                    </div>
                </div>

                <div id="container-selecao-aberta" *ngIf="selectOpened" class="select-field__opened">
                    <div class="container-filtro" role="list">
                        <div id="container-seleciona-situacao-todas" tabindex="0" role="listitem" class="filtro"
                            (click)="marcarOpcao('todas')" (keypress)="selecionarFiltro($event,'todas')" #todas
                            aria-label="Endereço">
                            <div id="seleciona-situacao-info-situacao" role="group" class="filtro__description">
                                TODAS
                            </div>
                            <div class="separator"></div>
                        </div>
                        <div id="container-seleciona-situacao-{{i}}" tabindex="0" role="listitem"
                            *ngFor="let filtro of filtrosFatura; let i = index" class="filtro"
                            (click)="marcarOpcao(filtro)" (keypress)="selecionarFiltro($event,filtro)"
                            aria-label="Endereço">
                            <div id="seleciona-situacao-info-situacao" role="group" class="filtro__description">
                                {{ filtro }}
                            </div>
                            <div class="separator"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export type MaskInput = (a: string) => string;

export class InfoDescricao {
  titulo: string;
  descricao: string;

  constructor() {
    this.titulo = '';
    this.descricao = '';
  }
}

@Component({
  selector: 'app-input-busca-principal',
  templateUrl: './input-busca-principal.component.html',
  styleUrls: ['./input-busca-principal.component.scss']
})
export class InputBuscaPrincipalComponent implements OnInit {

  @Input() maskInput: MaskInput;
  @Input() label: string;
  @Input() qtdCaracterInput: number;
  @Input() labelBtn: string;
  @Input() msgError: string;
  @Input() infoDescricao: InfoDescricao | null = null;

  @Output() submit: EventEmitter<string> = new EventEmitter<string>();
  @Output() reset: EventEmitter<boolean> = new EventEmitter<boolean>();
  valueInput: string = '';
  submitFeito: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  mascaraCPF() {
    this.valueInput = this.maskInput(this.valueInput);
  }

  click() {
    this.submitFeito = true;
    this.submit.emit(this.valueInput)
  }

  limpar() {
    this.valueInput = '';
    this.submitFeito = false;
    this.msgError = '';
    this.reset.emit(true);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { ThemeService } from '../../observables/theme.service';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss'],
})
export class HeaderAdminComponent implements OnInit {
  constructor( private _route: Router, private _serviceToken: TokenStorageService, private theme: ThemeService ) {}
  @Input() cliente: any;
  @Input() estaLogado: boolean = false;
  isMenuUserOpen: boolean = false;
  public showEditaFoto: boolean = false;
  currentTheme: string;

  ngOnInit(): void {
    this.theme.currentTheme.subscribe(theme => this.currentTheme = theme)
  }



  openUserMenu() {
    window.scroll(0, 0);
    this.isMenuUserOpen = !this.isMenuUserOpen;
    const ariaLabel = this.isMenuUserOpen
      ? 'Menu do usuário aberto'
      : 'menu do usuário fechado';
    const elemento = document.getElementById('texto-logar');
    if (elemento !== null) {
      elemento.setAttribute('aria-label', ariaLabel);
    }
  }

  public signOut() {
    this._serviceToken.signOut();
    this._route.navigate(['/admin']);
  }

}

import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'agv-cardSemFornec',
  templateUrl: './cardSemFornec.component.html',
  styleUrls: ['./cardSemFornec.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CardSemFornec implements OnInit {
  public url = environment.urlAGV;
  public token: string | null;

  constructor(private tokenService: TokenStorageService) {}

  ngOnInit(): void {}

  redirecionaLinkExterno(): void {
    if (this.checkToken())
      window.open(this.url + `?token=${this.token}&servico=alterar-nome-fatura`, '_blank');
    else window.open(`${this.url}alterar-nome-fatura`);
  }

  checkToken(): boolean {
    if (this.tokenService.getToken()) {
      this.token = this.tokenService.getToken();
      return true;
    } else {
      return false;
    }
  }
}

import {
  saveFormParcelamento,
  sucessoSimulaParcelamento,
} from './parcelamento.actions';
import { ParcelamentoSimulado } from './../../models/parcelamentoSimulado';
import { createReducer, on } from '@ngrx/store';
import { PayloadEfetivaParcelamento } from '../../models/reqBody/parcelamento/payloadEfetivaParcelamento';

export interface IParcelamentoState {
  parcelamento?: ParcelamentoSimulado;
  saveFormParcelamento?: PayloadEfetivaParcelamento;
}

export const parcelamentoKey = 'Parcelamento';

export const parcelamentoInitialState: IParcelamentoState = {
  parcelamento: undefined,
  saveFormParcelamento: undefined,
};

export const ParcelamentoReducer = createReducer(
  parcelamentoInitialState,
  on(saveFormParcelamento, (state, action) => {
    return Object.assign({}, state, { saveFormParcelamento: action.payload })
  }),
  on(sucessoSimulaParcelamento, (state, action) => {
    const newState = Object.assign({}, state, { parcelamento: action.dados });
    return newState;
  })
);

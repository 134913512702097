import { Fatura } from 'src/app/core/models/faturaCompleta';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { format, formatISO, sub } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { ThemeService } from '../../observables/theme.service';
import { find } from 'rxjs';
setDefaultOptions({ locale: pt });
Chart.register(ChartDataLabels);

@Component({
  selector: 'agv-chart-js',
  templateUrl: './chart-js.component.html',
  styleUrls: ['./chart-js.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartJSComponent implements OnInit, OnChanges, OnDestroy {
  // Controlador do tema
  currentTheme: string;

  public chart: any;
  public coordenates: any[] = [];
  public labelDates: any[] = [];

  // Variáveis de configuração da paginação
  public pageSize: any = 4; // Tamanho da página (quantidade de pontos de dados por página)
  public currentPage: number = 1; // Página atual
  public totalPages: number = 0;

  eleRefId: any;
  indexMonth?: any = null;
  lastMonth?: any = null;
  chartInitialized: boolean = false;

  @ViewChild('chevronRight') chevronRight: ElementRef;
  @ViewChild('chevronLeft') chevronLeft: ElementRef;
  @ViewChild('charts', { static: true }) charts: ElementRef<HTMLDivElement>;

  @ViewChild('containerBody', { static: true })
  containerBody: ElementRef<HTMLDivElement>;
  ariaLabelText: string = '';
  @Output() emitValueDate = new EventEmitter<String>();

  @Input() faturas: Fatura[] = [];
  @Input() dataFaturas: [] = [];
  isMobile: boolean = false;
  innerWidth = window.innerWidth;
  @HostListener('click', ['$event']) onClick(e: any) {
    e.stopPropagation();
    this.carregaCard(e);
  }

  @HostListener('keypress', ['$event']) onKeyPress(e: any) {
    var key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.carregaCard(e);
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.verificaTamanhoTela();
  }

  constructor(private renderer: Renderer2, private theme: ThemeService) {}

  ngOnDestroy(): void {
    if (this.chart) this.chart.destroy();
  }

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => {
      this.currentTheme = theme;
      if (this.chart) this.initializeChart();
    });
  }

  verificaTamanhoTela() {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 280) {
      this.pageSize = 1;
    } else if (windowWidth <= 390) {
      this.pageSize = 2;
    } else if (windowWidth <= 700) {
      this.pageSize = 3;
    } else {
      this.pageSize = 4;
    }

    if (this.chart) this.initializeChart();
  }

  configuraFatura() {
    const tamanhoFaturas = Math.min(12, this.faturas.length); // Limite de 12 ou tamanho real, o que for menor
    // propriedade coordenates é atualizada mapeando os elementos da matriz faturas para um novo objeto com as coordenadas, valor e código de pagamento. A função format é usada para formatar a data de vencimento no formato 'dd/MM/yyyy'. O método slice(0, 12) é usado para limitar o número de elementos a 12, garantindo que haja no máximo 12 coordenadas.
    this.coordenates = this.faturas
      ?.reduce((uniqueFaturas: any[], fatura: any) => {
        const formattedDate = format(
          new Date(fatura.dataVencimento),
          'dd/MM/yyyy'
        );
        return [
          ...uniqueFaturas,
          {
            y: fatura.valor,
            x: fatura.codigoPagamento,
            z: formattedDate,
          },
        ];
      }, [])
      .slice(0, tamanhoFaturas);
    // A propriedade labelDates é atualizada mapeando os elementos da matriz faturas para as datas formatadas no formato 'dd/MM/yyyy'. Novamente, o método slice(0, 12) é usado para limitar o número de elementos a 12.
    this.labelDates = this.faturas
      .reduce((uniqueDates: any[], fatura: any) => {
        return [...uniqueDates, fatura.codigoPagamento];
      }, [])
      .slice(0, tamanhoFaturas);

    this.createChart(this);
  }

  async ngOnChanges(changes: SimpleChanges) {
    // Verifica se a propriedade chart existe e, se sim, destrói o gráfico existente utilizando o método destroy(). Isso garante que o gráfico anterior seja limpo antes de prosseguir.

    if (this.chart) {
      this.chart.destroy();
    }
    if (changes['faturas']?.currentValue?.length > 0) {
      this.faturas = changes['faturas']?.currentValue;
      this.configuraFatura();
      this.verificaTamanhoTela();
    }
  }

  obterPaginacaosDoGraficoAtual() {
    const startIndex = Math.max(
      0,
      this.coordenates.length - this.currentPage * this.pageSize
    );
    const endIndex = Math.min(
      startIndex + this.pageSize,
      this.coordenates.length
    );
    const pageData = this.coordenates.slice(startIndex, endIndex).reverse();

    return pageData;
  }

  calcularTotalPages() {
    this.totalPages = Math.ceil(this.coordenates.length / this.pageSize);
  }

  obterPaginacaoGrafico(page: number) {
    this.currentPage = page;

    if (this.chart && this.chart?.options) {
      const currentPageData = this.obterPaginacaosDoGraficoAtual();
      const firstItem = currentPageData[currentPageData.length - 1].x;
      const lastItem = currentPageData[0].x;

      // Atualize as escalas x do gráfico com os valores da página atual
      this.chart.options.scales.x.min = firstItem;
      this.chart.options.scales.x.max = lastItem;

      // Atualize o gráfico com os pontos de dados da página atual
      this.chart.data.datasets[0].data = currentPageData;
      this.calcularTotalPages();

      this.chart.update();
      this.validateChevron();
    }
  }

  initializeChart() {
    this.obterPaginacaoGrafico(this.currentPage ?? 1);
  }

  handlePage() {
    if (!this.chartInitialized) {
      this.initializeChart();
      this.chartInitialized = true;
    }
  }

  carregaCard(e: any) {
    if (e.srcElement.parentNode.classList.contains('card-items')) {
      const elementId = e.srcElement.parentNode.id;
      this.eleRefId = elementId;
      this.emitValueDate.emit(this.eleRefId.split('-').pop());
      let elements = document.querySelectorAll('.card-items');
      this.removeActiveTab(elements);
      this.activeTab(elementId);
    } else if (e.srcElement.classList.contains('card-items')) {
      const elementId = e.srcElement.id;
      this.eleRefId = elementId;
      this.emitValueDate.emit(this.eleRefId.split('-').pop());
      let elements = document.querySelectorAll('.card-items');
      this.removeActiveTab(elements);
      this.activeTab(elementId);
    }
  }

  activeTab(elementId: string) {
    if (document.getElementById(`${elementId}`)?.classList.contains('active')) {
      document.getElementById(`${elementId}`)?.classList.remove('active');
    } else {
      document.getElementById(`${elementId}`)?.classList.add('active');
    }
  }

  removeActiveTab(elements: any) {
    elements.forEach((element: any) => {
      if (element.classList.contains('active')) {
        element.classList.remove('active');
      }
    });
  }

  createChart(scope: any) {
    const obterFaturasPorID = (index: any) => {
      if (this.chart && this.chart?.config)
        return scope.faturas.find(
          (el: any) =>
            el.codigoPagamento ===
            this.chart.config.data.datasets[0].data[index].x
        );
    };

    const contarPalavras = (str: string) => {
      return str.split(/\s+/).length;
    };

    const criarTextos = (ctx: any, x: number, y: number, findData: any) => {
      if (window.innerWidth >= 700) {
        criaStatusTexto(ctx, x, y, findData);
      } else {
        criarStatusTextoMobile(ctx, x, y, findData);
      }

      criaValorTexto(ctx, x, y, findData);
      criaConsumoTexto(ctx, x, y, findData);
    };

    const criarStatusTextoMobile = (
      ctx: any,
      x: number,
      y: number,
      findData: any
    ) => {
      ctx.textAlign = 'center';
      ctx.font = '600 14px Satoshi';
      ctx.fillStyle = obterCor(findData?.situacaoDaFatura?.toLowerCase());

      const situacaoFatura = findData?.situacaoDaFatura || '';
      const limite = 9;
      if (situacaoFatura.length > limite) {
        const posicaoEspaco1 = situacaoFatura.indexOf(' ', limite);
        const posicaoEspaco2 = situacaoFatura.indexOf(' ', posicaoEspaco1 + 1);

        if (
          posicaoEspaco1 !== -1 &&
          posicaoEspaco2 !== -1 &&
          contarPalavras(situacaoFatura.substring(posicaoEspaco1 + 1)) >= 2
        ) {
          const primeiraParte = situacaoFatura.substring(0, posicaoEspaco1);
          const segundaParte = situacaoFatura.substring(
            posicaoEspaco1 + 1,
            posicaoEspaco2
          );
          const terceiraParte = situacaoFatura.substring(posicaoEspaco2 + 1);

          ctx.fillText(
            `${
              primeiraParte.charAt(0).toUpperCase() +
              primeiraParte.slice(1).toLowerCase()
            }`,
            x,
            y - 22
          );

          ctx.fillText(
            `${
              segundaParte.charAt(0).toLowerCase() +
              segundaParte.slice(1).toLowerCase()
            }`,
            x,
            y - 5
          );

          if (terceiraParte) {
            ctx.fillText(
              `${
                terceiraParte.charAt(0).toLowerCase() +
                terceiraParte.slice(1).toLowerCase()
              }`,
              x,
              y + 10
            );
          }
        } else {
          ctx.fillText(
            `${
              findData?.situacaoDaFatura?.charAt(0).toUpperCase() +
              findData?.situacaoDaFatura?.slice(1).toLowerCase()
            }`,
            x,
            y - 5
          );
        }
      } else {
        ctx.fillText(
          `${
            findData?.situacaoDaFatura?.charAt(0).toUpperCase() +
            findData?.situacaoDaFatura?.slice(1).toLowerCase()
          }`,
          x,
          y - 5
        );
      }
    };

    const criaStatusTexto = (ctx: any, x: number, y: number, findData: any) => {
      ctx.textAlign = 'center';
      ctx.font = '600 14px Satoshi';
      ctx.fillStyle = obterCor(findData?.situacaoDaFatura?.toLowerCase());

      const situacaoFatura = findData?.situacaoDaFatura || '';
      const limite = 12;
      if (situacaoFatura.length > limite) {
        const posicaoEspaco = situacaoFatura.indexOf(' ', limite);

        if (posicaoEspaco !== -1) {
          const primeiraParte = situacaoFatura.substring(0, posicaoEspaco);
          const segundaParte = situacaoFatura.substring(posicaoEspaco + 1);

          ctx.fillText(
            `${
              primeiraParte.charAt(0).toUpperCase() +
              primeiraParte.slice(1).toLowerCase()
            }`,
            x,
            y - 20
          );

          ctx.fillText(
            `${
              segundaParte.charAt(0).toUpperCase() +
              segundaParte.slice(1).toLowerCase()
            }`,
            x,
            y - 5
          );
        } else {
          ctx.fillText(
            `${
              findData?.situacaoDaFatura?.charAt(0).toUpperCase() +
              findData?.situacaoDaFatura?.slice(1).toLowerCase()
            }`,
            x,
            y - 20
          );
        }
      } else {
        ctx.fillText(
          `${
            findData?.situacaoDaFatura?.charAt(0).toUpperCase() +
            findData?.situacaoDaFatura?.slice(1).toLowerCase()
          }`,
          x,
          y - 20
        );
      }
    };

    const obterCor = (situation: string) => {
      const changeColor = (color: string) => {
        return getComputedStyle(scope.chart.canvas).getPropertyValue(color);
      };

      const defaultColor = changeColor('--color-medium-black');

      const paidColor = changeColor('--color-font-green');

      const overdueColor = changeColor('--color-failed');

      const analysisColor = changeColor('--color-font-orange');

      switch (situation?.toLowerCase()) {
        case 'paga':
          return paidColor;
        case 'em atraso':
          return overdueColor;
        case 'em acordo de parcelamento' ||
          'residual a pagar' ||
          'pago com cartão crédito' ||
          'em pagamento cartão':
          return analysisColor;
        default:
          return defaultColor;
      }
    };

    const criaValorTexto = (ctx: any, x: number, y: number, findData: any) => {
      const isWideScreen = window.innerWidth >= 700;
      const fontSize = isWideScreen ? '26px' : '16px';
      const offsetY = isWideScreen ? 25 : 32;
      ctx.font = `600 ${fontSize} Satoshi`;

      if (this.currentTheme === 'dark-theme') {
        ctx.fillStyle = getComputedStyle(scope.chart.canvas).getPropertyValue(
          '--color-white'
        );
      } else {
        ctx.fillStyle = getComputedStyle(scope.chart.canvas).getPropertyValue(
          '--color-black'
        );
      }

      const valorText =
        findData?.valor?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }) || '';

      ctx.fillText(valorText, x, y + offsetY);
    };

    const criaConsumoTexto = (
      ctx: any,
      x: number,
      y: number,
      findData: any
    ) => {
      ctx.textAlign = 'center';
      ctx.font = '600 18px Satoshi';
      if (this.currentTheme === 'dark-theme') {
        ctx.fillStyle = getComputedStyle(scope.chart.canvas).getPropertyValue(
          '--color-white'
        );
      } else {
        ctx.fillStyle = getComputedStyle(scope.chart.canvas).getPropertyValue(
          '--color-black'
        );
      }

      if (findData?.consumo === undefined) {
        const mensagemConsumo = 'consumo indisponível';
        const posicaoEspaco = mensagemConsumo.indexOf(' ');

        const primeiraParte = mensagemConsumo.substring(0, posicaoEspaco);
        const segundaParte = mensagemConsumo.substring(posicaoEspaco + 1);

        ctx.fillText(
          `${
            primeiraParte.charAt(0).toUpperCase() +
            primeiraParte.slice(1).toLowerCase()
          }`,
          x,
          y + 45
        );

        ctx.fillText(
          `${
            segundaParte.charAt(0).toLowerCase() +
            segundaParte.slice(1).toLowerCase()
          }`,
          x,
          y + 60
        );
      } else {
        ctx.fillText(`${findData?.consumo + ' m³'}`, x, y + 50);
      }
    };

    const criarCardElement = (
      chart: any,
      datasetIndex: number,
      XPosition: number,
      YPosition: number
    ) => {
      const el = scope.renderer.createElement('a');
      const cardId = `card-${chart.config.data.datasets[0].data[datasetIndex].x}`;
      const ariaLabel = generateAriaLabel(chart, datasetIndex);

      scope.renderer.setAttribute(el, 'id', cardId);
      scope.renderer.setAttribute(el, 'class', 'card-items');
      scope.renderer.setAttribute(el, 'testeid', `card-${datasetIndex}`);
      scope.renderer.setAttribute(el, 'aria-label', ariaLabel);
      scope.renderer.setAttribute(el, 'role', 'button');
      scope.renderer.setAttribute(el, 'tabindex', '0');

      const topPosition = `${YPosition}px`;
      const leftPosition =
        chart.width < 680 ? `${XPosition - 45}px` : `${XPosition - 60}px`;

      scope.renderer.setStyle(el, 'position', 'absolute');
      scope.renderer.setStyle(el, 'top', topPosition);
      scope.renderer.setStyle(el, 'left', leftPosition);

      return el;
    };

    const criarStatusElement = () => {
      const span = scope.renderer.createElement('span');
      scope.renderer.setAttribute(span, 'class', 'value');

      const textStatus = scope.renderer.createText('Ver Fatura');
      scope.renderer.appendChild(span, textStatus);
      return span;
    };

    const criarValorElement = (findData: any) => {
      const span2 = scope.renderer.createElement('span');
      scope.renderer.setAttribute(span2, 'class', 'status');

      const textValue = scope.renderer.createText(
        findData
          ? 'Venc.: ' + format(new Date(findData.dataVencimento), 'dd/MM/yyyy')
          : ''
      );
      scope.renderer.appendChild(span2, textValue);

      return span2;
    };

    const appendCardElement = (el: any) => {
      scope.renderer.appendChild(scope.charts.nativeElement, el);
    };

    const updateActiveElement = () => {
      if (scope.eleRefId) {
        document.getElementById(`${scope.eleRefId}`)?.classList.add('active');
      }
    };

    const generateAriaLabel = (chart: any, datasetIndex: number) => {
      const datasets = chart.config.data.datasets[0].data;
      const faturas = scope.faturas;

      const findData = faturas.find(
        (c: any) =>
          format(new Date(c.dataVencimento), 'dd/MM/yyyy') ===
          datasets[datasetIndex].z
      );

      if (findData) {
        const { dataVencimento, situacaoDaFatura, valor, consumo } = findData;
        const formattedValor = valor.toString().replace(/\./g, ',');
        return `Consumo com vencimento para a data ${format(
          new Date(dataVencimento),
          'dd/MM/yyyy'
        )}, Status ${situacaoDaFatura}, Valor R$ ${formattedValor}, Consumo ${consumo} metros cúbicos.`;
      }

      return '';
    };

    const floatingLabelsTop = {
      id: 'floatingLabelsTop',
      afterDatasetsDraw(chart: any) {
        const {
          ctx,
          canvas,
          chartArea: { top },
        } = chart;
        if (chart) {
          for (
            let i = 0;
            i < chart?.config?.data?.datasets[0].data.length;
            i++
          ) {
            const XPosition = chart.getDatasetMeta(0).data[i].x;
            const YPosition = top;
            let findData = obterFaturasPorID(i);

            if (findData) {
              ctx.save();

              //Criar Status do Texto
              criarTextos(ctx, XPosition, YPosition, findData);
            }
          }
        }
      },
      beforeDraw(chart: any) {
        const {
          ctx,
          chartArea: { top },
        } = chart;

        const changeColor = (color: string) => {
          if (scope && scope?.chart)
            return getComputedStyle(scope?.chart?.canvas).getPropertyValue(
              color
            );
          return null;
        };

        if (chart && scope && scope?.chart) {
          const lineColor = changeColor('--color-vertical-chart-line');
          const lineWidth = 0.5;
          const lineOffset = 60;
          const lineLength = 178;

          for (
            let i = 0;
            i < chart?.config?.data?.datasets[0]?.data?.length;
            i++
          ) {
            const XPosition = chart.getDatasetMeta(0).data[i].x;
            const YPosition = top;
            ctx.save();
            ctx.beginPath();
            scope.tema === 'theme-dark'
              ? ctx.setLineDash([5, 8])
              : ctx.setLineDash([]);
            ctx.moveTo(XPosition, YPosition + lineOffset);
            ctx.lineTo(XPosition, YPosition + lineLength);
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = lineColor;
            ctx.stroke();
          }
        }
      },
      afterRender() {
        scope.handlePage();
      },
      afterBuildTicks(chart: any) {
        let elements: any = document.querySelectorAll('.card-items');

        elements.forEach((element: any) => {
          element.remove();
        });

        chart.canvas.parentNode.style.height = '250px';
      },
    };

    const floatingLabelsBottom = {
      id: 'floatingLabelsBottom',
      afterDraw(chart: any) {
        const {
          chartArea: { bottom },
        } = chart;

        if (chart && chart?.config) {
          const cardItems = document.querySelectorAll('.card-items');
          cardItems.forEach((element: any) => {
            element.remove();
          });

          const datasets = chart.config?.data?.datasets[0]?.data;
          const faturas = scope?.faturas;

          for (let i = 0; i < datasets.length; i++) {
            const XPosition = chart.getDatasetMeta(0).data[i].x;
            const YPosition = bottom;
            const el = criarCardElement(chart, i, XPosition, YPosition);

            const span = criarValorElement(
              faturas.find(
                (c: any) =>
                  format(new Date(c.dataVencimento), 'dd/MM/yyyy') ===
                    datasets[i].z && c.valor === datasets[i].y
              )
            );
            scope.renderer.appendChild(el, span);

            const span2 = criarStatusElement();

            scope.renderer.appendChild(el, span2);

            appendCardElement(el);
          }
          updateActiveElement();
        }
      },
    };

    this.chart = new Chart('MyChart', {
      type: 'line', //this denotes tha type of chart
      plugins: [ChartDataLabels, floatingLabelsTop, floatingLabelsBottom],
      data: {
        labels: this.labelDates.reverse(),
        datasets: [
          {
            label: 'Consumo',
            data: this.coordenates.reverse(),
            pointBackgroundColor: 'rgba(255,255, 255)',
            backgroundColor: '#fff',
            borderColor: () => {
              return this.currentTheme == 'theme-dark' ? '#fff' : '#06506f';
            },
            pointHoverRadius: 13,
            fill: false,
            pointRadius: 8,
            pointBorderWidth: () => {
              return this.currentTheme == 'theme-dark' ? 0 : 3;
            },
            pointHoverBorderWidth: () => {
              return this.currentTheme == 'theme-dark' ? 0 : 8;
            },
            pointStyle: 'circle',
            tension: 0.4,
            borderWidth: 2,
            cubicInterpolationMode: 'monotone',
            order: 1,
            datalabels: {
              display: false,
            },
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grace: 0,
            offset: true,
            border: {
              display: false,
            },

            grid: {
              display: false,
            },
            ticks: {
              z: 1,
              display: false,
            },
          },
          y: {
            min: 0.5,
            beginAtZero: true,
            grace: '200%',
            stacked: true,
            border: {
              display: false,
            },
            ticks: {
              display: false,
              z: 1,
            },
            grid: {
              display: false,
              drawTicks: false,
            },
          },
        },
        layout: {
          padding: {
            top: 32,
            bottom: 32,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            opacity: 1,
            color: '#fff',
          },
          tooltip: {
            enabled: false,
          },
        },
        onClick: (e: any) => {
          const points = this.chart.getElementsAtEventForMode(
            e,
            'nearest',
            { intersect: true },
            true
          );
          if (points.length) {
            const firstPoint = points[0];
            const label =
              this.chart.config.data.datasets[0].data[firstPoint.index].x;
            this.clicKEventTab(label);
          }
        },
      },
    });
  }

  mostrarTabMax() {
    if (this.labelDates.length > this.pageSize) {
      let lastDateString = this.labelDates[this.labelDates.length - 1];
      let lastDate = sub(new Date(lastDateString), {
        months: 3,
      });
      return formatISO(new Date(lastDate)).toString();
    } else {
      return this.labelDates[this.labelDates.length - 1];
    }
  }

  clicKEventTab(elementId: any) {
    let elements = document.querySelectorAll('.card-items');
    elements.forEach((element: any) => {
      if (element.classList.contains('active')) {
        element.classList.remove('active');
      }
    });
    this.eleRefId = `card-${elementId}`;
    this.emitValueDate.emit(elementId);
  }

  changeLeitura(e: any, tipo: string) {
    var key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      if (tipo === 'previous') this.onPreviousClick();
      else if (tipo === 'next') {
        this.onNextClick();
      }
    }
  }

  onPreviousClick() {
    if (this.currentPage < this.totalPages) {
      this.obterPaginacaoGrafico(this.currentPage + 1);
      this.validateChevron();
    }
  }
  onNextClick() {
    if (this.currentPage > 1) {
      this.obterPaginacaoGrafico(this.currentPage - 1);
      this.validateChevron();
    }
  }

  validateChevron() {
    if (this.renderer && this.chevronRight && this.chevronLeft) {
      if (this.labelDates.length < this.pageSize || this.totalPages === 0) {
        this.renderer.setStyle(
          this.chevronRight?.nativeElement,
          'display',
          'none'
        );
        this.renderer.setStyle(
          this.chevronLeft?.nativeElement,
          'display',
          'none'
        );
        return;
      }

      this.renderer.setStyle(
        this.chevronLeft?.nativeElement,
        'display',
        'flex'
      );
      this.renderer.setStyle(
        this.chevronRight?.nativeElement,
        'display',
        'flex'
      );

      const rightColor = this.currentPage === 1 ? '#D6D6D6' : '#005C84';

      this.renderer.setStyle(
        this.chevronRight?.nativeElement,
        'color',
        rightColor
      );

      const leftColor =
        this.currentPage === this.totalPages ? '#D6D6D6' : '#005C84';

      this.renderer.setStyle(
        this.chevronLeft?.nativeElement,
        'color',
        leftColor
      );
    }
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { CarregamentoService } from 'src/app/services/carregamento.service';

@Injectable()
export class CarregamentoInterceptor implements HttpInterceptor {

  constructor(private _carregamento: CarregamentoService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._carregamento.iniciarCarregamento();
    return next.handle(req).pipe(
      finalize(() => this._carregamento.pararCarregamento())
    );
  }
}

<div class="tabela-admin-content">
  <div class="table-header-wrapper">
    <agv-page-size
      (itensPorPaginaChange)="atualizarItensPorPagina($event)"
    ></agv-page-size>
    <agv-input-buscar
      (filtroServicoChange)="aplicarFiltro($event)"
      [placeholder]="placeholder"
    ></agv-input-buscar>
  </div>

  <AGV-tabela
    (edit)="editItem($event)"
    (remove)="removeItem($event)"
    [alinhamento]="alinhamento"
    [colunas]="colunas"
    [dados]="getListaItensPaginados()"
  >
  </AGV-tabela>

  <agv-paginacao-tabela
    [itensPorPagina]="itensPorPagina"
    [totalItens]="dados?.length ?? 1"
    (paginaAtualEvento)="atualizarPaginaAtual($event)"
  ></agv-paginacao-tabela>
</div>

import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  distinctUntilChanged,
  take,
  takeUntil,
} from 'rxjs';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';

@Injectable({
  providedIn: 'root',
})
export class EnderecosObservableService {
  private unsubscribe$ = new Subject<void>();

  private EnderecosSource: BehaviorSubject<any> = new BehaviorSubject<
    any | undefined
  >(null);

  private enderecosFornecimento = new BehaviorSubject<any>(null);

  // Variável publica para acessar o valor e receber as alterações
  public currentEnderecos = this.EnderecosSource.asObservable();

  public currentEnderecosFornecimento =
    this.enderecosFornecimento.asObservable();

  // Populando a variável com o valor gravado em memória
  constructor(private token: TokenStorageService) {}

  // Função para alterar o valor no observador
  changeEnderecos(partialEnderecos: Partial<any>){
    const currentEnderecos = this.EnderecosSource.value || {};
    const newEnderecos = { ...currentEnderecos, ...partialEnderecos };
    this.token.setEnderecos(newEnderecos); // Salva no localStorage
    this.EnderecosSource.next(newEnderecos);
  }

  getEnderecos(): Observable<any | undefined> {
    this.currentEnderecos
      .pipe(takeUntil(this.unsubscribe$), take(1), distinctUntilChanged())
      .subscribe((Enderecos) => {
        if (!Enderecos) {
          let Enderecos = this.token.getEnderecos();
          if (Enderecos) {
            this.EnderecosSource.next(Enderecos);
          }
        }
      });
    return this.currentEnderecos;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

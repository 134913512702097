<section id="componentSeletMetodoPag">
    <agv-input-text id="componentSeletMetodoPag_valor-original_fatura" label="Valor original total"
        [value]="valoresMulta?.valorOriginal ?? 0 | currency" [blocked]="true" value=""></agv-input-text>
    <div tabindex="0" id="componentSeletMetodoPag_campo-juros" class="campo-input-juros">
        <agv-input-text id="componentSeletMetodoPag_valor-juros_fatura" label="Total de Juros, multa e encargos"
            [value]="totalJuros() | currency" [blocked]="true"
            value=""></agv-input-text>
        <agv-icon tabindex="0" [name]='icone' id="componentSeletMetodoPag_campo-juros_abrir-detalhamento"
            class="icon-abrir" (click)="abreCampoJuros()"></agv-icon>
        <section tabindex="0" id="componentSeletMetodoPag_campo-juros_detalhamento" *ngIf="abreJuros"
            class="campo-juros">
            <div class="conteudo">
                <section id="componentSeletMetodoPag_campo-juros_detalhamento_multa" tabindex="0">
                    <p><b>Multa</b></p>
                    <p>{{valoresMulta.valorMulta | currency}}</p>
                </section>
                <section tabindex="0" id="componentSeletMetodoPag_campo-juros_detalhamento_juros">
                    <p><b>Juros</b></p>
                    <p>{{valoresMulta.valorJuros | currency}}</p>
                </section>
                <section tabindex="0" id="componentSeletMetodoPag_campo-juros_detalhamento_atm">
                    <div class="campo-icone-ajuda">
                        <p><b>ATM</b></p>
                        <agv-icon tabindex="0" matTooltip="Atualização monetária" name='icn-ajuda'></agv-icon>
                    </div>
                    <p>{{valoresMulta.valorATM | currency}}</p>
                </section>
                <section tabindex="0" id="componentSeletMetodoPag_campo-juros_detalhamento_trcf">
                    <div class="campo-icone-ajuda">
                        <p><b>TRCF</b></p>
                        <agv-icon tabindex="0" matTooltip="Taxa de regulação, controle e fiscalização"
                            name='icn-ajuda'></agv-icon>
                    </div>
                    <p>{{valoresMulta.valorTRCF | currency}}</p>
                </section>
            </div>
        </section>
    </div>
    <div>
        <agv-input-select [structor]="inputQuantidadeParcelas" (enviaValorObjeto)="capturaValorSelectParcelas($event)"></agv-input-select>
        <mat-error *ngIf="mensagemErroqtdParcela">{{mensagemErroqtdParcela}}</mat-error>
    </div>
</section>
<div role="group" id="pagto-fatura" class="pagto-fatura-container" *ngIf="fatura !== undefined || parcela !== undefined "
  (mouseenter)="descongela()">
  <div role="group" id="pagto-fatura-conteudo" class="content">
      <div role="group" class="row__colunaDividida" >
        <div role="group" id="pagto-fatura-pagto-app" class="content__pagto-app mr-3" [ngClass]="{'displayAppPagtoColunaUnica': banco !== 0}"
        *ngIf="!hiddeInfosPagto">
          <app-pagto-app [banco]="banco" [valor]="paymentMethodType === 'parcelamento' ? parcela.valor : fatura.valor"
            *ngIf="
            fatura.situacaoDaFatura.toLocaleUpperCase() !== 'PAGA'"></app-pagto-app>
        </div>
        <div role="group" id="pagto-fatura-por-email" class="content__por-email" *ngIf="logado">
          <app-por-email *ngIf="banco === 0"></app-por-email>
        </div>
      </div>
      <div role="group" class="content__row ">
        <div role="group" id="pagto-fatura-vencimento" class="content__codigo-barras">
          <app-codigo-barras
           [dataVencimento]="
            paymentMethodType === 'parcelamento'
              ? (parcela.dataVencimento| date: 'dd/MM/yyyy')
              : (fatura.dataVencimento | date: 'dd/MM/yyyy')
          " [total]="
            paymentMethodType === 'parcelamento' ? parcela.valor : fatura.valor
          " [codBarras]="
            paymentMethodType === 'parcelamento'
              ? parcela.codigoDeBarrasParcela
              : fatura.codigoDeBarras
          " [linhaDigitavel]="
            paymentMethodType === 'parcelamento'
              ? parcela.codigoDeBarrasParcela
              : fatura.linhaDigitavel
          " [status]="
            paymentMethodType === 'parcelamento'
              ? parcela.status
              : fatura.situacaoDaFatura
          " [identificaParcela]="
            paymentMethodType === 'parcelamento'
              ? parcela.numParc 
              : fatura.codigoPagamento
          " [cobrancaJuridica]="
            paymentMethodType === 'parcelamento'
              ? false
              : fatura.cobrancaJuridica
          " [tipo]="paymentMethodType" 
            (sucessoExibirPdf)="exibirPDF($event)">
          </app-codigo-barras>
        </div>
      </div>
  </div>
</div>

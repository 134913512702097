export class DadosPagtoInfo {
    codigoFornecimento: string;
    codelineFaturas: string[];
    nomeBanco: string;
    dataPagamento: string;
    tipoPessoa: string;
    subtipoProcesso: string;
    cnpj?: string;

    constructor(){
        this.codigoFornecimento = '';
        this.codelineFaturas = [];
        this.nomeBanco = '';
        this.dataPagamento = '';
        this.tipoPessoa = '';
        this.subtipoProcesso = '0';
        this.cnpj = '';
    }
}

export class DadosPagtoInfoProtocolo {
    protocolo: string;

    constructor() {
        this.protocolo = '';
    }
}

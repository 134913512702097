export interface ItensPerguntasFrequentes {
  pergunta: string;
  resposta: string;
  img: string;
}

export function factoryItensMenu(
  pergunta: string,
  resposta: string,
  img: string
): ItensPerguntasFrequentes {
  return {
    pergunta,
    resposta,
    img
  };
}

export class PerguntasFrequentesItens {
  items: ItensPerguntasFrequentes[];

  constructor(items: ItensPerguntasFrequentes[]) {
    this.items = items;
  }
}

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ThemeService } from '../../observables/theme.service';

@Component({
  selector: 'agv-meus-parcelamentos-menu',
  templateUrl: './meus-parcelamentos-menu.component.html',
  styleUrls: ['./meus-parcelamentos-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeusParcelamentosMenuComponent implements OnInit {
  currentTheme: string;

  @Output() botaoEmit: EventEmitter<void> = new EventEmitter<void>();

  constructor(private theme: ThemeService) {}

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
  }

  acessarParcelamentos() {
    this.botaoEmit.emit();
  }
}

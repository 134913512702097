<nav id="sidebar" class="sidebar">
  <ul id="sidebar__itens" class="sidebar__itens">
    <li id="sidebar__itens__item-{{index}}" class="sidebar__itens__item" [ngClass]="{'opened': item.active}" (click)="controlaItem(item)" *ngFor="let item of menuItems; let index = index">
      <div id="sidebar__itens__item-{{index}}__title-{{index}}" class="sidebar__itens__item__title">
        <agv-icon id="sidebar__itens__item-{{index}}__title__icon" class="sidebar__itens__item__title__icon" [name]="item.icon"></agv-icon>
        <span id="sidebar__itens__item-{{index}}__title__text" class="sidebar__itens__item__title__text">{{item.label}}</span>
        <agv-icon id="sidebar__itens__item-{{index}}__title__arrow" class="sidebar__itens__item__title__arrow" [name]="'arrow_black'"></agv-icon>
      </div>
      <ul id="sidebar__itens__item-{{index}}__subitens" class="sidebar__itens__item__subitens" *ngIf="item.hasSubmenu && item.active">
        <li id="sidebar__itens__item-{{index}}__subitens__subitem-{{index2}}" class="sidebar__itens__item__subitens__subitem" [routerLink]="subitem.route" *ngFor="let subitem of item.submenu; let index2 = index">
          <agv-icon [name]="'icn_arrow'"></agv-icon>
          {{subitem.label}}
        </li>
      </ul>
    </li>
  </ul>
</nav>
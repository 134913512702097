import { PayloadEfetivaParcelamento } from 'src/app/core/models/reqBody/parcelamento/payloadEfetivaParcelamento';
import { RequestSimularReparcelamento } from 'src/app/core/models/reqBody/parcelamento/reqSimulaReparcelamento';
import { RequestSimularParcelamento } from 'src/app/core/models/reqBody/parcelamento/reqSimulaParcelamento';
import { IResponseSimularParcelamento } from 'src/app/core/models/parcelamento';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ParcelamentoService {
  private _url: string = environment.uri;
  constructor(private _httpClient: HttpClient) {}

  simularParcelamento(
    param: RequestSimularParcelamento
  ): Observable<IResponseSimularParcelamento> {
    return this._httpClient.post<IResponseSimularParcelamento>(
      `${this._url}v2/completa/fatura/parcelamento/simular`,
      param
    );
  }

  simularReparcelamento(
    param: RequestSimularReparcelamento
  ): Observable<IResponseSimularParcelamento> {
    return this._httpClient.post<IResponseSimularParcelamento>(
      `${this._url}v2/completa/fatura/reparcelamento/simular`,
      param
    );
  }

  efetivarParcelamento(
    param: PayloadEfetivaParcelamento
  ): Observable<{protocolo : string}> {
    return this._httpClient.post<{protocolo : string}>(
      `${this._url}v1/completa/fatura/parcelamento/efetivar`,
      param
    )
  }

  efetivarReparcelamento(
    param: PayloadEfetivaParcelamento
  ): Observable<{protocolo : string}> {
    return this._httpClient.post<{protocolo : string}>(
      `${this._url}v1/completa/fatura/reparcelamento`,
      param
    )
  }

  buscarTermoAcordo(
    param: string
  ): Observable<{url64 : string}> {
    return this._httpClient.get<{url64 : string}>(
      `${this._url}v1/completa/fatura/parcelamento/termo-acordo/${param}`
    )
  }

  downloadParcela(
    fornecimento: string,
    codigoAcorodo: string,
    idparcela: string
  ): Observable<{url64 : string}> {
    return this._httpClient.get<{url64 : string}>(
      `${this._url}/v1/completa/fornecimento/${fornecimento}/acordo/${codigoAcorodo}/parcela/${idparcela}/download`
    )
  }
}

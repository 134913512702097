<div role="group" id="menu-admin-principal" class="menu-admin-principal">
    <mat-accordion class="menu-admin-principal__opcoes" multi>
        <mat-expansion-panel class="menu-admin-principal__opcoes__servico" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title class="menu-admin-principal__opcoes__servico__icone">
                    <mat-icon>dashboard</mat-icon>
                </mat-panel-title>
                <mat-panel-description class="menu-admin-principal__opcoes__servico__descricao">
                    <div class="menu-admin-principal__opcoes__servico__descricao__titulo">
                        Dashboard
                    </div>
                    <div class="menu-admin-principal__opcoes__servico__descricao__icone">
                        >
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="menu-admin-principal__opcoes__servico__conteudo">
                <ul>
                    <li (click)="redirect('')" [ngClass]="{'link-selected': url === raiz}">Dashboard</li>
                    <li (click)="redirect('noticia/novo')" [ngClass]="{'link-selected': url.includes('noticia/novo')}">Notícias</li>
                    <li (click)="redirect('galeria')" [ngClass]="{'link-selected': url.includes('galeria')}">Galeria</li>
               <!-- <li (click)="redirect('conteudo')" [ngClass]="{'link-selected': url.includes('conteudo')}">Conteúdo</li> -->
                </ul>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="menu-admin-principal__opcoes__servico" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title class="menu-admin-principal__opcoes__servico__icone">
                    <mat-icon>apps</mat-icon>
                </mat-panel-title>
                <mat-panel-description class="menu-admin-principal__opcoes__servico__descricao">
                    <div class="menu-admin-principal__opcoes__servico__descricao__titulo">
                        Serviços
                    </div>
                    <div class="menu-admin-principal__opcoes__servico__descricao__icone">
                        >
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="menu-admin-principal__opcoes__servico__conteudo">
                <ul>
                    <li (click)="redirect('bloqueio')" [ngClass]="{'link-selected': url.includes('bloqueio')}">Bloqueio</li>
                    <li (click)="redirect('parametros')" [ngClass]="{'link-selected': url.includes('parametros')}">Parametrização</li>
                    <!-- <li (click)="redirect('guia-servico')" [ngClass]="{'link-selected': url.includes('guia-servico')}">Guia de Serviços</li> -->
                </ul>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="menu-admin-principal__opcoes__servico" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title class="menu-admin-principal__opcoes__servico__icone">
                    <mat-icon>contacts</mat-icon>
                </mat-panel-title>
                <mat-panel-description class="menu-admin-principal__opcoes__servico__descricao">
                    <div class="menu-admin-principal__opcoes__servico__descricao__titulo">
                        Usuários
                    </div>
                    <div class="menu-admin-principal__opcoes__servico__descricao__icone">
                        >
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="menu-admin-principal__opcoes__servico__conteudo">
                <ul>
                    <li (click)="redirect('grupos')" [ngClass]="{'link-selected': url.includes('grupos')}">Grupos</li>
                    <li (click)="redirect('usuarios')" [ngClass]="{'link-selected': url.includes('usuarios')}">Usuários</li>
                </ul>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

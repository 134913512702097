<section class="containerDadosCartao">
    <div class="cartaoItem" *ngFor="let cartao of listaCartao">
        <div tabindex="0" class="campoCartao"
            [ngClass]="{'pointer': !gerenciarCartao, 'stylesFluxoPagamento': !gerenciarCartao}"
            (click)="gerenciarCartao? '' : emitirIdCartao(cartao, $event)"
            (keypress)="gerenciarCartao? '' : emitirIdCartao(cartao, $event)">
            <input *ngIf="!gerenciarCartao" class="checkInput" type="radio" [checked]="cartaoSelecionado === cartao"
                (click)="gerenciarCartao? '' : emitirIdCartao(cartao, $event)"
                (keypress)="gerenciarCartao? '' : emitirIdCartao(cartao, $event)">
            <agv-icon id="icnBandeiraFluxoCartaoSalvo" class="bandeiraCartao " role="img"
                [name]="cartao.brand? cartao.brand : 'cartao-credito'"></agv-icon>
            <div tabindex="0" class="infoCartao">
                <p>{{cartao.brand.charAt(0).toUpperCase() + cartao.brand.slice(1)}} **** {{cartao.last_digits}}</p>
                <p>Vencimento: {{cartao.exp_month}}/{{cartao.exp_year}}</p>
            </div>
        </div>
        <button class="botaoExcluir" *ngIf="gerenciarCartao" (click)="emitirIdCartao(cartao,  $event)"
            (keypress)="emitirIdCartao(cartao, $event)">
            <agv-icon id="icnExluiCartao" class="icnExcluir" role="img" name="icn-remove"></agv-icon>
            Remover
        </button>
    </div>
    <div tabindex="0" class="campoCartao stylesFluxoPagamento pointer" *ngIf="!gerenciarCartao"
        (click)="emitirIdCartao(null, $event)" (keypress)="emitirIdCartao(null, $event)">
        <input class="checkInput" type="radio" [checked]="cartaoSelecionado === null"
            (click)="emitirIdCartao(null, $event)" (keypress)="emitirIdCartao(null, $event)">
        <agv-icon id="icnBandeiraFluxoCartaoSalvo" class="bandeiraCartao " role="img" name="cartao-credito"></agv-icon>
        <div tabindex="0" class="infoCartao">
            <p>Novo Cartão</p>
            <p>Adicione cartão</p>
        </div>
    </div>
</section>
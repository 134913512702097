import {
  FooterColumns,
  ItemsColumn,
} from './../../../core/models/footerColumns';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ThemeService } from '../../observables/theme.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  // Controlador do tema
  currentTheme: string;
  linkDefault: string = environment.urlAGV;

  constructor(private theme: ThemeService) {}

  public getInfoColumnsFooter(): FooterColumns[] {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));

    const stringItemCanais: ItemsColumn[] = [
      {
        description: 'Atendimento Chat - Sabesp',
        alt: 'Irá abrir uma nova aba para o chat da Sabesp',
        image: '',
        imageDark: '',
        link: `https://sabesp-chat.sabesp.com.br/chat-externo`,
        interno: false,
        classCustom: 'null',
      },
      {
        description: 'Atendimento via Whatsapp',
        alt: 'Irá abrir uma nova aba para o whatsapp da Sabesp',
        image: '',
        imageDark: '',
        link: `https://wa.me/551133888000`,
        interno: false,
        classCustom: null,
      },
      {
        description: 'Perguntas Frequentes',
        alt: 'Irá abrir uma nova aba onde você pode consultar todas as perguntas mais frequentes feitas para a Sabesp',
        image: '',
        imageDark: '',
        link: `https://site.sabesp.com.br/site/fale-conosco/faq.aspx`,
        interno: false,
        classCustom: null,
      },
      {
        description: 'Fale Conosco',
        alt: 'Irá abrir uma nova aba onde você pode falar conosco caso não encontre o serviço procurado',
        image: '',
        imageDark: '',
        link: `http://site.sabesp.com.br/site/fale-conosco/Default.aspx?secaoId=71`,
        interno: false,
        classCustom: null,
      },
      {
        description: 'Atendimento em Libras',
        alt: 'Atendimento em Canal para Libras',
        image: '',
        imageDark: '',
        link: `https://icom.app/sabesp-site`,
        interno: false,
        classCustom: null,
      },
      {
        description: 'Baixe o app sabesp mobile',
        alt: '',
        image: '',
        imageDark: '',
        link: ``,
        interno: false,
        classCustom: 'highlight-app',
      },
      {
        description: `logo google app`,
        alt: 'Link para o aplicativo Sabesp da play store',
        image: 'google-play-logo',
        imageDark: 'google-play-logo_white',
        link: `https://play.google.com/store/apps/details?id=br.com.sabesp.android.sabespmobile&hl=pt_BR&gl=US&pli=1`,
        interno: false,
        classCustom: null,
      },
      {
        description: `logo apple app`,
        alt: 'Link para o aplicativo Sabesp da apple store',
        image: 'apple-store-logo',
        imageDark: 'apple-store-logo',
        link: `https://apps.apple.com/br/app/sabesp-mobile/id1184961285`,
        interno: false,
        classCustom: null,
      },
    ];
    const footerDigital = new FooterColumns(
      'Canais Digitais',
      'canais-atendimento',
      stringItemCanais
    );

    const stringServices: ItemsColumn[] = [
      {
        description: 'Consulte seus débitos',
        alt: 'Redirecionamento para consultar seus débitos',
        image: '',
        imageDark: '',
        link: `fatura-completa`,
        interno: true,
        classCustom: null,
      },
      {
        description: 'Guia de serviços',
        image: '',
        imageDark: '',
        alt: 'Redirecionamento para guia de serviços',
        link: `guia-de-servicos`,
        interno: true,
        classCustom: null,
      },
      {
        description: 'Tarifas',
        image: '',
        imageDark: '',
        link: `tarifas`,
        interno: true,
        alt: 'Redirecionamento para tarifas',
        classCustom: null,
      },
      {
        description: 'Termos de uso',
        image: '',
        imageDark: '',
        link: `termo-de-uso`,
        interno: true,
        alt: 'Redirecionamento para termos de uso',
        classCustom: null,
      },
      {
        description: 'Política de privacidade',
        image: '',
        imageDark: '',
        alt: 'Redirecionamento para política de privacidade',
        link: `politica-privacidade`,
        interno: true,
        classCustom: null,
      },
      {
        description: 'Perguntas frequentes',
        image: '',
        imageDark: '',
        alt: 'Redirecionamento para perguntas frequentes',
        link: ``,
        focus: 'perguntas-frequentes',
        interno: true,
        classCustom: null,
      },
      {
        description: 'Fale conosco',
        image: '',
        imageDark: '',
        alt: 'Redirecionamento para fale conosco',
        link: `http://site.sabesp.com.br/site/fale-conosco/Default.aspx?secaoId=71`,
        interno: false,
      },
      {
        description: 'Canais de atendimento',
        image: '',
        imageDark: '',
        alt: 'Redirecionamento para canais de atendimento',
        link: `canais-atendimento`,
        interno: true,
        classCustom: null,
      },
    ];

    const footerService = new FooterColumns(
      'Serviços',
      'servicos',
      stringServices
    );
    const stringSocial: ItemsColumn[] = [
      {
        description: null,
        image: 'icn_facebook',
        imageDark: '',
        alt: 'Link para a página de Facebook da Sabesp',
        link: `https://www.facebook.com/SabespOficial`,
        interno: false,
        classCustom: 'null',
      },
      {
        description: null,
        image: 'icn_instagram',
        imageDark: '',
        alt: 'Link para a página de Instagram da Sabesp',
        link: `https://www.instagram.com/sabespcia/`,
        interno: false,
        classCustom: 'null',
      },
      {
        description: null,
        image: 'icn_twitter',
        imageDark: '',
        alt: 'Link para a página de Twitter da Sabesp',
        link: `https://twitter.com/sabesp`,
        interno: false,
        classCustom: 'null',
      },
      {
        description: null,
        image: 'icn_youtube',
        imageDark: '',
        alt: 'Link para a página de Youtube da Sabesp',
        link: `https://www.youtube.com/@SabespCia`,
        interno: false,
        classCustom: 'null',
      },
    ];

    const footerSocial = new FooterColumns(
      'Siga a Sabesp',
      'redes-sociais',
      stringSocial
    );

    const stringPhone: ItemsColumn[] = [

      {
        description: 'Central de atendimento <strong>0800 055 0195</strong>',
        alt: 'Central de atendimento 0800 055 0195',
        image: '',
        imageDark: '',
        link: ``,
        interno: false,
        classCustom: 'highlight-phone',
      },
      {
        description:
          'Deficientes auditivos e de fala <strong>0800 016 0195</strong>',
        alt: 'Deficientes auditivos e de fala 0800 016 0195',
        image: '',
        imageDark: '',
        link: ``,
        interno: false,
        classCustom: 'highlight-phone',
      },
      {
        description:
          'Ouvidoria <span class="textCapitalize">sabesp</span> <strong>0800 055 0565</strong>',
        image: '',
        imageDark: '',
        alt: 'Ouvidoria sabesp 0800 055 0565',
        link: ``,
        interno: false,
        classCustom: 'highlight-phone',
      },
      {
        description: 'Arsesp <strong>0800 771 6883</strong>',
        image: '',
        imageDark: '',
        link: ``,
        interno: false,
        alt: 'Arsesp 0800 771 6883',
        classCustom: 'highlight-phone',
      },
    ];
    const footerPhone = new FooterColumns(
      'Telefone úteis',
      'contato',
      stringPhone,
      footerSocial
    );

    return [footerDigital, footerService, footerPhone];
  }
}

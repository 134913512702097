export class InputSelect {
    label: string;
    value: string | number;
    placeholder: string;
    options: InputSelectOption[]

    constructor() {
        this.label = '';
        this.value = '';
        this.placeholder = '';
        this.options = [];
    }
}

export class InputSelectOption {
    value: string | number;
    id?: string | number
    labels: InputSelectOptionLabel[];

    constructor() {
        this.value = '';
        this.labels = [];
    }
}

export class InputSelectOptionLabel {
    value: string;
    img: boolean;

    constructor() {
        this.value = '';
        this.img = false;
    }
}


import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DadosPagamento } from 'src/app/core/models/condicaoPagamentoParcela';
import { CartaoEncriptar } from 'src/app/core/models/pagamentoCartao';

@Component({
  selector: 'app-checkout-cartao',
  templateUrl: './checkout-cartao.component.html',
  styleUrls: ['./checkout-cartao.component.scss'],
})

export class CheckoutComponent implements OnInit {
  @Input() dadosCartao: CartaoEncriptar;
  @Input() valorJuros: number = 0;
  @Input() dadosPagamento: DadosPagamento;
  @Output() retornaFluxo: EventEmitter<string> = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  retornFluxo(event: string): void {
    //Lógica para usar NgSwitchCase
    this.retornaFluxo.emit(event);
  }
}
<div id="selectEnderecoContainer" role="group" class="selectContainer" [ngClass]="{'habilitaSelect': !habilita}">
    <div id="campoSelect" role="group" tabindex="0" class="campoSelect" [ngClass]="{'selectErro': msgErro || erro,'habilitaSelect': !habilita}" clickOutside
        (clickOutside)="closeSelect()" (blur)="validaEntrada()" (keypress)="openSelect($event)" (click)="openSelect($event)">
        <div id="campoSelectConteudo" role="group" class="campoSelectInput">
            <div role="group">
                Endereço
            </div>
            <div  [ngClass]="{'erro': erro}"  id="selectEnderecoValor" role="group">
                {{fornecChoosed? fornecChoosed : 'Selecione um fornecimento'}}
            </div>
        </div>
        <div class="arrow" [ngClass]="{ 'rotate-180': showOptions}">
            <agv-icon class="icon" [name]="'arrow_black'"></agv-icon>
        </div>
    </div>
    <p *ngIf="msgErro" class="erro">
        {{msgErro}}
    </p>
    <div id="container-selecao-aberta" class="campoSelectAberto" *ngIf="showOptions">
        <section *ngIf="listaFornec">
            <div *ngFor="let fornec of listaFornec" role="list" class="campoSelectValue">
                <div tabindex="0" role="listitem" aria-label="Endereço" class="selectValue"
                    (click)="chooseFornec($event, fornec)" (keypress)="chooseFornec($event, fornec)">
                    <div role="group" class="endereco" style="text-transform: capitalize;">
                       {{fornec.endereco}}, {{fornec.numero}} - {{fornec.cidade}} - {{fornec.uf}}
                    </div>
                    <div role="group" class="numFornec">
                        Fornecimento {{fornec.codigo}}
                    </div>
                </div>
            </div>
            <div *ngIf="mostraNovaOpcao" (click)="chooseFornec($event, 'Outro Fornecimento')" (keypress)="chooseFornec($event, 'Outro Fornecimento')" class="campoSelectValue">
               <b>Outro Fornecimento</b> 
            </div>
        </section>
        <section *ngIf="!listaFornec">
            <div role="list" class="campoSelectNone">
                <div tabindex="0" role="listitem" aria-label="Endereço">
                    Nenhum endereço encontrado
                </div>
            </div>
        </section>
    </div>
    <agv-icon *ngIf="loading" class="loading" [name]="'button-loading16'"></agv-icon>
</div>

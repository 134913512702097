<link rel="stylesheet" href="footer.component.scss">
<footer id="rodape" class="footer" *ngIf="!isAdmin">
  <div class="grid">
    <div class="col-12 md:col-3 col-image">
      <div role="group" class="footer__img-sabesp">
        <ng-template [ngIf]="!isMobile" [ngIfElse]="logo_mobile">
          <agv-icon role="img" id="footer-img-sabesp" class="logo-sabesp" [ngClass]="{'dark-mode': currentTheme === 'theme-dark' }" alt="Logo Sabesp" [name]="'logo'"></agv-icon>
        </ng-template>
        <ng-template #logo_mobile>
            <agv-icon role="img" id="footer-img-sabesp" class="logo-sabesp" [ngClass]="{'dark-mode': currentTheme === 'theme-dark' }" alt="Logo Sabesp" [name]="'logo'"></agv-icon>
        </ng-template>
      </div>
      <div role="group" class="footer__img-sao-paulo">
        <ng-template [ngIf]="!isMobile" [ngIfElse]="logo_sp_mobile">
          <agv-icon role="img" id="footer-img-bandeira-sao-paulo" alt="Bandeira de São Paulo" [name]="'logo_sao_paulo'" *ngIf="currentTheme !== 'theme-dark'"></agv-icon>
          <agv-icon role="img" id="footer-img-bandeira-sao-paulo" alt="Bandeira de São Paulo" [name]="'logo_sao_paulo_white'" *ngIf="currentTheme === 'theme-dark'"></agv-icon>
        </ng-template>
        <ng-template #logo_sp_mobile>
          <agv-icon role="img" id="footer-img-bandeira-sao-paulo"  [ngClass]="{'dark-mode': currentTheme === 'theme-dark' }" alt="Bandeira de São Paulo" [name]="'logo-gov'"></agv-icon>
        </ng-template>
      </div>
    </div>
    <div class="col-12 md:col-3 {{'footer_'+column.classCustom}}" *ngFor="let column of columns; index as indexOfelement; let last = last;">
      <hr *ngIf="isMobile && indexOfelement != 0" class="divisor" >
      <div class="line box-footer">
        <h2 class="box-footer__title" role="text">{{(column.name == 'Telefone úteis' && isMobile) ? 'Fale conosco' : column.name}}</h2>
        <ul class="box-footer__list" role="list">
          <li id="{{geraId(item.description)}}" role="listitem" tabindex="0" *ngFor="let item of column.items"
            [attr.aria-label]="item.alt" [attr.aria-describedby]="item.alt" [attr.aria-description]="item.alt"
            [ngClass]="item.classCustom" (click)="linkRedirect(item)"
          >
            <span *ngIf="item.image === ''" [innerHTML]="item.description"></span>
            <agv-icon role="img" *ngIf="item.image !== ''" [name]="getNomeImg(item)" [ngClass]="{'dark-mode': currentTheme === 'theme-dark' && verifyImg(item) }" (click)="onRedirect(item)" (keydown.enter)="onRedirect(item)"></agv-icon>
          </li>
        </ul>
      </div>
      <div class="col col-child" *ngIf="column.footerChild">
        <hr *ngIf="isMobile && last" class="divisor ultimo">
        <div class="line box-footer">
          <h2 class="box-footer__title">{{ column.footerChild.name }}</h2>
          <ul class="box-footer__list__child" role="list">
            <li id="{{geraId(itemChild.description)}}" role="listitem" tabindex="0" *ngFor="let itemChild of column.footerChild.items" (click)="onRedirect(itemChild)"  (keydown.enter)="onRedirect(itemChild)" >
              {{ itemChild.description }}
              <agv-icon role="img" [ngClass]="{'dark-mode': currentTheme === 'theme-dark' }" title="{{itemChild.alt}}" [name]="itemChild.image"></agv-icon>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
<div class="copyRight">
  Sabesp © 2021 - Direitos de cópia reservados.
</div>
<div class="faixa"></div>

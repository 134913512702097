import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'agv-input-buscar',
  templateUrl: './input-buscar.component.html',
  styleUrls: ['./input-buscar.component.scss']
})
export class InputBuscarComponent implements OnInit {
  @Input() placeholder: string;
  @Input() filtroServico: string;

  @Output() filtroServicoChange = new EventEmitter<string>();
  
  constructor() { }

  aplicarFiltroTabela(filtro: string){
    this.filtroServico = filtro;
    this.filtroServicoChange.emit(filtro)
  }

  ngOnInit(): void {
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Fatura } from 'src/app/core/models/faturaCompleta';

import { PagamentoFaturaService } from './pagamento-fatura.service';
import { Parcela } from 'src/app/core/models/acordo';

@Component({
  selector: 'pagamento-fatura',
  templateUrl: './pagamento-fatura.component.html',
  styleUrls: ['./pagamento-fatura.component.scss'],
})
export class PagamentoFaturaComponent implements OnInit {
  constructor(
    private readonly pagamentoFaturaService: PagamentoFaturaService
  ) {}

  @Output() sucessoExibirPdf: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() fornecimento: string = '';
  @Input() paymentMethodType: string | null;
  @Input() fatura: Fatura = new Fatura();
  @Input() parcela: Parcela = new Parcela();
  @Input() banco: number = 0;
  @Input() logado: boolean = false;
  mostraPagamento: boolean = false;
  downloadFatura: boolean = false;
  hiddeInfosPagto: boolean = false;
  isMobile: boolean = false;
  mensagemFat: string = '';

  ngOnInit(): void {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
    window.onresize = () => (this.isMobile = window.innerWidth <= 768);
    if (
      this.fatura.situacaoDaFatura.toLocaleUpperCase() ==
      'SUSPENSA PARA ANÁLISE'
    ) {
      this.mensagemFat = 'Esta fatura está em análise e não pode ser exibida';
    } else if (
      this.fatura.situacaoDaFatura.toLocaleUpperCase() ==
      'AGUARDANDO CONFIRMAÇÃO DO BANCO'
    ) {
      this.mensagemFat =
        'Esta fatura está aguardando confirmação do banco e não pode ser exibida.';
    } else if (
      this.fatura.cobrancaJuridica &&
      this.fatura.situacaoDaFatura.toLocaleUpperCase() ==
      'EM ATRASO'
    ) {
      this.mensagemFat =
        'Esta fatura está em cobrança jurídica e não pode ser exibida.';
    } else if (
      this.fatura.situacaoDaFatura.toLocaleUpperCase() ==
      'EM ACORDO DE PARCELAMENTO'
    ) {
      this.mensagemFat =
        'A fatura encontra-se em acordo de parcelamento, por favor acesse os parcelamentos.';
    } else if (
      this.fatura.situacaoDaFatura.toLocaleUpperCase() ==
      'EM PAGAMENTO CARTÃO'
    ) {
      this.mensagemFat =
        'O pagamento está em processo de confirmação bancária!';
    } else if (
      this.fatura.situacaoDaFatura.toLocaleUpperCase() ==
      'PAGA'
    ) {
      this.mensagemFat =
        'O pagamento está em processo de confirmação bancária!';
    } else {
      this.mensagemFat = '';
    }
    this.hiddeInfosPagto = this.mensagemFat !== '';
  }

  exibirPDF(exibe: boolean) {
    this.sucessoExibirPdf.emit(exibe);
  }

  //usado para descongelar a tela após fechar o datepicker
  descongela() {
    document.documentElement.style.overflow = 'initial';
  }
}

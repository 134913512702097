<div #tabelaElement class="table-historico__container">
  <div class="content-web-desktop" [ngClass]="{ 'border-dark-mode': currentTheme === 'theme-dark' }">
    <table mat-table [dataSource]="getListaItensPaginados()" class="mat-elevation-z8 table-container"
      multiTemplateDataRows>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <input (focus)="adicionaTitulo()" type="checkbox" (change)="$event ? toggleAllRows() : null" id="cb1"
            color="primary" class="checkbox-header" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" />
        </th>
        <td mat-cell *matCellDef="let row" style="padding-left: 0" [ngClass]="{ 'expanded-cell': row.isExpanded }">
          <input (focus)="removeTitulo()" type="checkbox" (click)="$event.stopPropagation()" id="cb2" color="primary"
            class="checkbox-body" (change)="$event ? selection.toggle(row.codigoPagamento) : null"
            [checked]="selection.isSelected(row.codigoPagamento)" aria-label="Selecionar a Fatura" [disabled]="
              verificaDesabilitar(row, 'SUSPENSA PARA ANÁLISE') ||
              verificaDesabilitar(row, 'AGUARDANDO CONFIRMAÇÃO DO BANCO') ||
              verificaDesabilitar(row, 'EM ACORDO DE PARCELAMENTO') ||
              (row.cobrancaJuridica && verificaDesabilitar(row, 'EM ATRASO'))
            " [ngClass]="{
              'checkbox-body_disabled':
                verificaDesabilitar(row, 'SUSPENSA PARA ANÁLISE') ||
                verificaDesabilitar(row, 'AGUARDANDO CONFIRMAÇÃO DO BANCO') ||
                verificaDesabilitar(row, 'EM ACORDO DE PARCELAMENTO') ||
                (row.cobrancaJuridica && verificaDesabilitar(row, 'EM ATRASO'))
            }" />
        </td>
      </ng-container>

      <ng-container matColumnDef="dataEmissao">
        <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
          Data de emissão
        </th>
        <td class="conteudo-body" mat-cell *matCellDef="let element" tabindex="0">
          {{ element.dataEmissao | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="valor">
        <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
          Valor da fatura
        </th>
        <td class="conteudo-body" mat-cell *matCellDef="let element" tabindex="0">
          {{ element.valor | currency : "BRL" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dataVencimento">
        <th class="conteudo-header" mat-header-cell *matHeaderCellDef tabindex="0">
          Data de vencimento
        </th>
        <td class="conteudo-body" mat-cell *matCellDef="let element" tabindex="0">
          {{ element.dataVencimento | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="situacaoDaFatura">
        <th tabindex="0" class="conteudo-header" mat-header-cell *matHeaderCellDef>
          Situação
        </th>
        <td tabindex="0" class="conteudo-body" [ngClass]="{
            paga:
              element.situacaoDaFatura.toUpperCase() === 'PAGA' ||
              element.situacaoDaFatura.toUpperCase() ===
                'PAGO COM CARTÃO CRÉDITO',
            'em-aberto': element.situacaoDaFatura.toUpperCase() === 'EM ABERTO',
            'em-atraso': element.situacaoDaFatura.toUpperCase() === 'EM ATRASO',
            'em-aguardo':
              element.situacaoDaFatura.toUpperCase() !== 'EM ATRASO' &&
              element.situacaoDaFatura.toUpperCase() !== 'EM ABERTO' &&
              element.situacaoDaFatura.toUpperCase() !== 'PAGA' &&
              element.situacaoDaFatura.toUpperCase() !==
                'PAGO COM CARTÃO CRÉDITO'
          }" mat-cell *matCellDef="let element">
          {{
          element.situacaoDaFatura.charAt(0).toUpperCase() +
          element.situacaoDaFatura.slice(1).toLowerCase()
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="button">
        <th class="action-column-header" mat-header-cell *matHeaderCellDef></th>
        <td class="action-column" mat-cell *matCellDef="let element">
          <div class="action-column__btns">
            <button id="copyButton" class="btn-custom" [ngStyle]="{
                cursor:
                  verificaDesabilitar(element, 'PAGA') ||
                  verificaDesabilitar(element, 'SUSPENSA PARA ANÁLISE') ||
                  verificaDesabilitar(
                    element,
                    'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                  ) ||
                  verificaDesabilitar(element, 'EM ACORDO DE PARCELAMENTO') ||
                  (element.cobrancaJuridica &&
                    verificaDesabilitar(element, 'EM ATRASO'))
                    ? 'initial'
                    : 'pointer'
              }" [disabled]="
                verificaDesabilitar(element, 'PAGA') ||
                verificaDesabilitar(element, 'SUSPENSA PARA ANÁLISE') ||
                verificaDesabilitar(
                  element,
                  'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                ) ||
                verificaDesabilitar(element, 'EM ACORDO DE PARCELAMENTO') ||
                (element.cobrancaJuridica &&
                  verificaDesabilitar(element, 'EM ATRASO'))
              " [cdkCopyToClipboard]="element.linhaDigitavel" (cdkCopyToClipboardCopied)="
                copyToClipboard(element.codigoPagamento)
              " (click)="$event.stopPropagation()">
              <div>
                <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" class="trocaCor"
                  *ngIf="element.codigoPagamento !== buttonCopy" [name]="'icn_copy'" title="Ícone de copiar"></agv-icon>
                <agv-icon *ngIf="element.codigoPagamento === buttonCopy" class="trocaCor" [name]="'icon_sucesso_bold'"
                  title="Ícone de sucesso"></agv-icon>
                <span [ngStyle]="{
                    color: currentTheme === 'theme-dark' ? '#fff' : '#000'
                  }">{{
                  element.codigoPagamento === buttonCopy
                  ? "Copiado"
                  : "Copiar"
                  }}</span>
              </div>
            </button>

            <button (click)="
                exibeFatura(true); downloadFaturaCompleta(element, $event)
              " class="btn-custom" [ngStyle]="{
                cursor:
                  verificaDesabilitar(element, 'SUSPENSA PARA ANÁLISE') ||
                  verificaDesabilitar(
                    element,
                    'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                  ) ||
                  verificaDesabilitar(element, 'EM ACORDO DE PARCELAMENTO') ||
                  (element.cobrancaJuridica &&
                    verificaDesabilitar(element, 'EM ATRASO'))
                    ? 'initial'
                    : 'pointer'
              }" [disabled]="
                verificaDesabilitar(element, 'SUSPENSA PARA ANÁLISE') ||
                verificaDesabilitar(
                  element,
                  'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                ) ||
                verificaDesabilitar(element, 'EM ACORDO DE PARCELAMENTO') ||
                (element.cobrancaJuridica &&
                  verificaDesabilitar(element, 'EM ATRASO'))
              ">
              <div>
                <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" class="trocaCor"
                  [name]="'icn_lupa'" title="Ícone de visualizar"></agv-icon>
                <span [ngStyle]="{
                    color: currentTheme === 'theme-dark' ? '#fff' : '#000'
                  }">
                  Detalhes</span>
              </div>
            </button>

            <button class="btn-custom" (click)="
                exibeFatura(false); downloadFaturaCompleta(element, $event)
              " [fxHide]="element.codigoPagamento === faturaSelecionadaDownload" [ngStyle]="{
                cursor:
                  verificaDesabilitar(element, 'SUSPENSA PARA ANÁLISE') ||
                  verificaDesabilitar(
                    element,
                    'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                  ) ||
                  verificaDesabilitar(element, 'EM ACORDO DE PARCELAMENTO') ||
                  (element.cobrancaJuridica &&
                    verificaDesabilitar(element, 'EM ATRASO'))
                    ? 'initial'
                    : 'pointer'
              }" [disabled]="
                verificaDesabilitar(element, 'SUSPENSA PARA ANÁLISE') ||
                verificaDesabilitar(
                  element,
                  'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                ) ||
                verificaDesabilitar(element, 'EM ACORDO DE PARCELAMENTO') ||
                (element.cobrancaJuridica &&
                  verificaDesabilitar(element, 'EM ATRASO'))
              ">
              <div>
                <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" class="trocaCor"
                  [name]="'icn-download'" title="Ícone de baixar"></agv-icon>
                <span [ngStyle]="{
                    color: currentTheme === 'theme-dark' ? '#fff' : '#000'
                  }">Baixar</span>
              </div>
            </button>

            <button [fxHide]="element.codigoPagamento !== faturaSelecionadaDownload" class="loadingDownload btn-custom">
              <div>
                <div class="loadingDownloadLayout">
                  <agv-icon [fxHide]="downloadExec" class="loadingDownload__img" [name]="'button-loading16'"></agv-icon>
                  <agv-icon [fxHide]="!downloadExec" [name]="'button-check16'"></agv-icon>
                  <p>Aguarde</p>
                </div>
              </div>
            </button>

            <button class="btn-custom" (click)="
                $event.stopPropagation();
                exibeFatura(false);
                selectFatura($event)
              " *ngIf="temCartao" [fxHide]="element.codigoPagamento === faturaSelecionadaDownload" [ngStyle]="{
                cursor:
                  verificaDesabilitar(element, 'SUSPENSA PARA ANÁLISE') ||
                  verificaDesabilitar(
                    element,
                    'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                  ) ||
                  verificaDesabilitar(element, 'EM ACORDO DE PARCELAMENTO') ||
                  (element.cobrancaJuridica &&
                    verificaDesabilitar(element, 'EM ATRASO'))
                    ? 'initial'
                    : 'pointer'
              }" [disabled]="
                verificaDesabilitar(element, 'SUSPENSA PARA ANÁLISE') ||
                verificaDesabilitar(element, 'PAGA') ||
                verificaDesabilitar(
                  element,
                  'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                ) ||
                verificaDesabilitar(element, 'EM ACORDO DE PARCELAMENTO') ||
                verificaDesabilitar(element, 'pago com cartão crédito') ||
                (element.cobrancaJuridica &&
                  verificaDesabilitar(element, 'EM ATRASO'))
              ">
              <div>
                <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" class="trocaCor"
                  name="attach_money" title="Ícone para pagamento via cartão"></agv-icon>
                <span [ngStyle]="{
                    color: currentTheme === 'theme-dark' ? '#fff' : '#000'
                  }">Pagar
                </span>
              </div>
            </button>

            <button class="btnExpanded" [disabled]="
                !verificaDesabilitar(element, 'EM ATRASO') &&
                !verificaDesabilitar(element, 'RESIDUAL A PAGAR')
              ">
              <div (click)="
                  $event.stopPropagation();
                  element.isExpanded = !element.isExpanded;
                  listDetalhesFaturas(
                    element.numeroParcela,
                    element.documentoId
                  )
                ">
                <mat-icon *ngIf="!element.isExpanded" [ngClass]="{
                    'keyboard_arrow_down-disabled':
                      !verificaDesabilitar(element, 'EM ATRASO') &&
                      !verificaDesabilitar(element, 'RESIDUAL A PAGAR')
                  }">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="element.isExpanded">keyboard_arrow_up</mat-icon>
              </div>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td [ngClass]="{ 'expanded-cell': element.isExpanded }" mat-cell *matCellDef="let element"
          [attr.colspan]="displayedColumns.length" style="padding: 0px">
          <div class="example-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'"
            style="display: flex; flex-direction: column">
            <agv-cardFaturaDesktop [cobrancaJuridica]="element.cobrancaJuridica"
              [faturaRevisadaData]="element.subFatura" [codigoFornec]="fornecimento.codigo"
              (emitItemDownload)="handleCodPagContaRevisada($event)"></agv-cardFaturaDesktop>
          </div>
        </td>
      </ng-container>

      <tr class="thead-custom" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="thbody-custom tr-tbody" mat-row *matRowDef="let element; columns: displayedColumns"
        [class.example-expanded-row]="element.isExpanded" (click)="
          verificaDesabilitar(element, 'SUSPENSA PARA ANÁLISE') ||
          verificaDesabilitar(element, 'AGUARDANDO CONFIRMAÇÃO DO BANCO') ||
          verificaDesabilitar(element, 'EM ACORDO DE PARCELAMENTO') ||
          (element.cobrancaJuridica &&
            verificaDesabilitar(element, 'EM ATRASO'))
            ? null
            : selection.toggle(element.codigoPagamento)
        "></tr>
      <tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>

    <ng-container *ngTemplateOutlet="notification"></ng-container>

    <div *ngIf="faturasDataSource.length > 0" class="container-button">
      <button *ngIf="!faturaZipStarted || !carregaBotao" (click)="downloadFaturaCompletaZip()" mat-raised-button
        class="downloadButton" color="primary" [disabled]="selection.isEmpty() && codPagFaturasSelecionadas.length < 1">
        <div>
          <div *ngIf="!faturaZipFinished || !carregaBotao" class="faturasSelecionadasButton">
            <agv-icon class="icnDownloadFaturas" [name]="'icn-download'" title="Ícone de download branco"></agv-icon>
            <p>Baixar faturas selecionadas</p>
          </div>
          <div *ngIf="faturaZipFinished && carregaBotao" class="faturasSelecionadasButton">
            <agv-icon [name]="'button-check-branco16'"></agv-icon>
            <p>Aguarde</p>
          </div>
        </div>
      </button>
      <button *ngIf="faturaZipStarted && carregaBotao" mat-raised-button color="primary">
        <div class="faturasSelecionadasButton">
          <agv-icon class="icnLoadingFaturas" title="Loading branco" [name]="'icn-load-branco16'"></agv-icon>
          <p>Aguarde</p>
        </div>
      </button>
    </div>
  </div>

  <div class="content-web-mobile">
    <div class="items-per-page">
      <p tabindex="0">Itens por páginas:</p>
      <ul>
        <li tabindex="0" *ngFor="let item of itemsPerPage" [ngClass]="{ active: item.active }"
          (click)="changeItemsPerPage(item.value)">
          {{ item.value }}
        </li>
      </ul>
    </div>

    <mat-card class="card-table" *ngFor="let fatura of currentPageData; let i = index" tabindex="0">
      <table [ngClass]="{ 'border-dark': currentTheme === 'theme-dark' }" class="card-table__table" tabindex="0">
        <thead>
          <tr>
            <th role="columnheader">Data de emissão</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td role="cell" tabindex="0">
              {{ fatura.dataEmissao | date : "dd/MM/yyyy" }}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th role="columnheader">Valor da fatura</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td role="cell" tabindex="0">
              {{ fatura.valor | currency : "BRL" }}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th role="columnheader">Data de vencimento</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td role="cell" tabindex="0">
              {{ fatura.dataVencimento | date : "dd/MM/yyyy" }}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th role="columnheader">Situação</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td role="cell" tabindex="0" [ngClass]="{
                paga:
                  fatura.situacaoDaFatura.toUpperCase() === 'PAGA' ||
                  fatura.situacaoDaFatura.toUpperCase() ===
                    'PAGO COM CARTÃO CRÉDITO',
                'em-aberto':
                  fatura.situacaoDaFatura.toUpperCase() === 'EM ABERTO',
                'em-atraso':
                  fatura.situacaoDaFatura.toUpperCase() === 'EM ATRASO',
                'em-aguardo':
                  fatura.situacaoDaFatura.toUpperCase() !== 'EM ATRASO' &&
                  fatura.situacaoDaFatura.toUpperCase() !== 'EM ABERTO' &&
                  fatura.situacaoDaFatura.toUpperCase() !== 'PAGA' &&
                  fatura.situacaoDaFatura.toUpperCase() !==
                    'PAGO COM CARTÃO CRÉDITO'
              }">
              {{
              fatura.situacaoDaFatura.charAt(0).toUpperCase() +
              fatura.situacaoDaFatura.slice(1).toLowerCase()
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <mat-expansion-panel hideToggle [tabindex]="0" class="mat-expansion-card">
        <mat-expansion-panel-header [tabindex]="0" class="mat-expansion-card__header">
          <div class="card-table__btns">
            <button class="btn-custom" [ngStyle]="{
                cursor:
                  verificaDesabilitar(fatura, 'PAGA') ||
                  verificaDesabilitar(fatura, 'SUSPENSA PARA ANÁLISE') ||
                  verificaDesabilitar(
                    fatura,
                    'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                  ) ||
                  verificaDesabilitar(fatura, 'EM ACORDO DE PARCELAMENTO')
                    ? 'initial'
                    : 'pointer'
              }" [disabled]="
                verificaDesabilitar(fatura, 'PAGA') ||
                verificaDesabilitar(fatura, 'SUSPENSA PARA ANÁLISE') ||
                verificaDesabilitar(
                  fatura,
                  'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                ) ||
                verificaDesabilitar(fatura, 'EM ACORDO DE PARCELAMENTO') ||
                fatura.cobrancaJuridica
              " (click)="$event.stopPropagation()" [cdkCopyToClipboard]="fatura.linhaDigitavel"
              (cdkCopyToClipboardCopied)="
                copyToClipboard(fatura.codigoPagamento)
              ">
              <div tabindex="0">
                <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }"
                  *ngIf="fatura.codigoPagamento !== buttonCopy" title="Ícone de copiar" class="trocaCor"
                  [name]="'icn_copy'"></agv-icon>
                <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" class="trocaCor"
                  *ngIf="fatura.codigoPagamento === buttonCopy" title="Ícone de sucesso"
                  [name]="'icon_sucesso_bold'"></agv-icon>
                {{
                fatura.codigoPagamento === buttonCopy ? "Copiado" : "Copiar"
                }}
              </div>
            </button>

            <button (click)="
                $event.stopPropagation();
                exibeFatura(true);
                downloadFaturaCompleta(fatura, $event)
              " class="btn-custom" [ngStyle]="{
                cursor:
                  verificaDesabilitar(fatura, 'SUSPENSA PARA ANÁLISE') ||
                  verificaDesabilitar(fatura, 'AGUARDANDO CONFIRMAÇÃO DO BANCO')
                    ? 'initial'
                    : 'pointer'
              }" [disabled]="
                verificaDesabilitar(fatura, 'SUSPENSA PARA ANÁLISE') ||
                verificaDesabilitar(
                  fatura,
                  'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                ) ||
                fatura.cobrancaJuridica ||
                verificaDesabilitar(fatura, 'EM ACORDO DE PARCELAMENTO')
              ">
              <div tabindex="0">
                <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" class="trocaCor"
                  title="Ícone de visualizar" [name]="'icn_lupa'"></agv-icon>
                Exibir
              </div>
            </button>

            <button (click)="
                $event.stopPropagation();
                exibeFatura(false);
                downloadFaturaCompleta(fatura, $event)
              " class="btn-custom" [ngStyle]="{
                cursor:
                  verificaDesabilitar(fatura, 'SUSPENSA PARA ANÁLISE') ||
                  verificaDesabilitar(
                    fatura,
                    'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                  ) ||
                  verificaDesabilitar(fatura, 'EM ACORDO DE PARCELAMENTO')
                    ? 'initial'
                    : 'pointer'
              }" [disabled]="
                verificaDesabilitar(fatura, 'SUSPENSA PARA ANÁLISE') ||
                verificaDesabilitar(
                  fatura,
                  'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                ) ||
                verificaDesabilitar(fatura, 'EM ACORDO DE PARCELAMENTO') ||
                fatura.cobrancaJuridica
              ">
              <div tabindex="0">
                <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" class="trocaCor"
                  [name]="'icn-download'" title="Ícone de baixar"></agv-icon>
                Baixar
              </div>
            </button>
            <button *ngIf="temCartao" (click)="$event.stopPropagation(); selectFatura($event)" class="btn-custom"
              [ngStyle]="{
                cursor:
                  verificaDesabilitar(fatura, 'SUSPENSA PARA ANÁLISE') ||
                  verificaDesabilitar(
                    fatura,
                    'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                  ) ||
                  verificaDesabilitar(fatura, 'EM ACORDO DE PARCELAMENTO')
                    ? 'initial'
                    : 'pointer'
              }" [disabled]="
                verificaDesabilitar(fatura, 'SUSPENSA PARA ANÁLISE') ||
                verificaDesabilitar(fatura, 'PAGA') ||
                verificaDesabilitar(fatura, 'pago com cartão crédito') ||
                verificaDesabilitar(
                  fatura,
                  'AGUARDANDO CONFIRMAÇÃO DO BANCO'
                ) ||
                verificaDesabilitar(fatura, 'EM ACORDO DE PARCELAMENTO') ||
                fatura.cobrancaJuridica
              ">
              <div tabindex="0">
                <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" class="trocaCor"
                  name="attach_money" title="Ícone para pagar via cartão"></agv-icon>
                Pagar
              </div>
            </button>

            <button (click)="
                expandedMobile = !expandedMobile;
                listDetalhesFaturas(fatura.numeroParcela, fatura.documentoId)
              " class="btn-custom arrow-btn">
              <mat-icon *ngIf="!expandedMobile">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedMobile">keyboard_arrow_up</mat-icon>
            </button>
          </div>
        </mat-expansion-panel-header>
        <div>
          <agv-cardFaturaMobile [cobrancaJuridica]="fatura.cobrancaJuridica" [faturaRevisadaData]="fatura.subFatura"
            [codigoFornec]="fornecimento.codigo"></agv-cardFaturaMobile>
        </div>
      </mat-expansion-panel>
    </mat-card>
    <ng-container *ngTemplateOutlet="notification"></ng-container>
  </div>
</div>

<ng-template #notification>
  <app-notificacao-processo [fontWeightTitle]="700" [fontWeightDescription]="700" [mensagem]="mensagemErro"
    *ngIf="faturasDataSource?.length === 0" [tipoMensagem]="'atencao'"
    [tituloMensagem]="'Nada por aqui.'"></app-notificacao-processo>
</ng-template>

<agv-paginacao-tabela [itensPorPagina]="itensPorPagina" [totalItens]="faturasDataSource.length"
  (paginaAtualEvento)="atualizarPaginaAtual($event)"></agv-paginacao-tabela>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CartaoEncriptar, CorpoEfetivaPag, CorpoGerarTokenCliente, CorpoRetornoSimularParc, CorpoSimularParcCartao, CorpoValidacaoDados, TokenClienteCredito } from '../core/models/pagamentoCartao';
import { TokenStorageService } from './tokenStorageServices.service';

@Injectable({
  providedIn: 'root',
})
export class CartaoServices {
  private readonly BASE_URL = `${environment.uri}`;
  private apikey: string = `${environment.apikey}`;
  constructor(private http: HttpClient,
    private tokenService: TokenStorageService) { }

  private getRequestOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${this.tokenService.getToken()}`, 'apikey': this.apikey, }),
    };
  }

  private getRequestCartaoOptions(autenticacao:TokenClienteCredito): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${autenticacao.AccessToken}`,
        'ReferenceId': `${autenticacao.ReferenceId}`,
      }),
    };
  }

  geraTokenCliente(corpoCartao: CorpoGerarTokenCliente) {
    return this.http.post<TokenClienteCredito>(
      `${this.BASE_URL}v1/completa/fatura/pagamento/cartao/iniciartoken`, corpoCartao, this.getRequestOptions()
    );
  }

  verificaCartaoCliente(autenticacaoCartao: TokenClienteCredito, corpoValidacaoDados:CorpoValidacaoDados) {
    return this.http.post<any>(
      `https://api.homol.flexgateway.flexpag.com/api/v1/cards/tokenize`, corpoValidacaoDados, this.getRequestCartaoOptions(autenticacaoCartao)
    );
  }

  capturaListaCartao(authToken: string) {
    return this.http.get<CartaoEncriptar[]>(
      `${this.BASE_URL}v1/completa/fatura/pagamento/cartao/lista`, this.getRequestOptions()
    );
  }

  excluiCartaoSelecionado(authToken: string, idCartao: string) {
    return this.http.post<CartaoEncriptar[]>(
      `${this.BASE_URL}v1/completa/fatura/pagamento/cartao/excluir`, { idCartao }, this.getRequestOptions()
    );
  }

  atualizaValoresParcelamento(authToken: string, faturaPag: CorpoSimularParcCartao) {
    return this.http.post<CorpoRetornoSimularParc>(
      `${this.BASE_URL}v1/completa/fatura/pagamento/cartao/simular`, faturaPag, this.getRequestOptions()
    );
  }

  efetuaPagamentoCartao(authToken: string, pagamento: CorpoEfetivaPag) {
    return this.http.post<any>(
      `${this.BASE_URL}v1/completa/fatura/pagamento/cartao/pagar`, pagamento, this.getRequestOptions()
    );
  }
}

import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemCookie } from 'src/app/core/models/@types/ItemCookies';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { PrivacyStorageServiceService } from 'src/app/services/privacy-storage-service.service';

@Component({
  selector: 'app-gerenciador-cookies-dialog',
  templateUrl: './gerenciador-cookies-dialog.component.html',
  styleUrls: ['./gerenciador-cookies-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GerenciadorCookiesDialogComponent implements OnInit {
  public mostrarTextoCompleto: boolean = false;

  itens: ItemCookie[] = [];

  constructor(
    public privacyStorageServiceService: PrivacyStorageServiceService,
    private googleAnalitics: GoogleAnalyticsService,
    public dialogRef: MatDialogRef<GerenciadorCookiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.itens = data;
  }

  ngOnInit(): void {}
  toggleItem(item: any): void {
    item.expandido = !item.expandido;
  }

  salvarPreferencias() {
    this.itens.forEach((item) => {
      if (item.autorizado && item.cookie === 'cookiesAnaliticos') {
        this.googleAnalitics.init();
      }
    });
    this.privacyStorageServiceService.consentimentoCookies('true');
    this.dialogRef.close();
  }

  aceitarTodos() {
    this.privacyStorageServiceService.consentimentoCookies('true');
    this.googleAnalitics.init();
    this.dialogRef.close();
  }

  rejeitarTodos() {
    this.privacyStorageServiceService.consentimentoCookies('false');
    this.dialogRef.close();
  }
}

<div #formasPagamento id="formas-pagamento" class="formas-pagamento" *ngIf="!sucessExibirPdf">
  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab [disabled]='payment.disabled' *ngFor="let payment of paymentMethods" class="pagamento-tab">
      <ng-template mat-tab-label>
        <div [id]="gerarId(payment.name)" class="formas-pagamento_conteudo-flex" (click)="payment.disabled? '' : selectPayment(payment.id)">
          <div role="img" class="formas-pagamento_conteudo-flex_img" aria-label="Forma de Pagamento">
            <agv-icon [name]="payment.icon" class="img-inativo" title="{{payment.alt}}"></agv-icon>
            <agv-icon [name]="payment.iconActive" class="img-ativo" title="{{payment.altAtivo}}"></agv-icon>
          </div>
          <div class="formas-pagamento_conteudo-flex_nome" [innerHTML]="payment.name">
          </div>
        </div>
      </ng-template>
      <pagamento-fatura *ngIf="mostrarFaturaComponent(payment.id)"
        [paymentMethodType]="paymentMethod"
        [fatura]="fatura"
        (sucessoExibirPdf)="exibirPDF($event)"
        [parcela]="parcela"
        [logado]="logado"
        [banco]="bancoSelecionado"
      ></pagamento-fatura>
      <app-cartao-dados-pagamento [fatura]="fatura" *ngIf="mostrarFormularioCartao"></app-cartao-dados-pagamento>
    </mat-tab>
  </mat-tab-group>
</div>

<app-notificacao-processo id="pagto-fatura-msg-sucesso" *ngIf="sucessExibirPdf" [tipoMensagem]="'sucesso'"
  [tituloMensagem]="'SOLICITAÇÃO DE 2a VIA DE FATURA CONCLUÍDA COM SUCESSO!'"
  [fontWeightTitle]="500"
  [fontWeightDescription]="400"
  [mensagem]="'Se você realizou o pagamento, aguarde até 2 dias úteis para confirmação.'">
</app-notificacao-processo>

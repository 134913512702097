import { createAction, props } from "@ngrx/store";
import { FaturaEmail, ResponseProcotolo } from "../../models/service/faturaEmail";

export const alteraFaturaEmail = createAction(
    `[Email] altera email`,
    props<{ req: FaturaEmail}>()
);

export const sucessoAlteraFaturaEmail = createAction(
    `[Email] sucesso ao alterar email`,
    props<{ protocolo: ResponseProcotolo }>()
);

export const erroAlteraFaturaEmail = createAction(
    `[Email] falhou ao alterar email`,
    props<{ error: any }>()
  );
import { HttpClient } from "@angular/common/http";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { environment } from "src/environments/environment";
import { comunicaPagamento, erroComunicaPagamento, sucessoComunicaPagamento } from "./pagto-informado.actions";
import { catchError, map, mergeMap, of } from "rxjs";
import { Injectable } from "@angular/core";
import { DadosPagtoInfoProtocolo } from "../../models/dadosPagtoInformado";

@Injectable({
    providedIn: 'root',
})

export class PagtoInformadoEffectService {
    private readonly BASE_URL = `${environment.uri}`;

    constructor(
        private actions$: Actions,
        private http: HttpClient
    ) { }

    comunicarPagamento = createEffect(() =>
        this.actions$.pipe(
            ofType(comunicaPagamento),
            mergeMap((action) =>{
                return this.http
                    .post<DadosPagtoInfoProtocolo>(
                        `${this.BASE_URL}v1/completa/fatura/fornecimento/cliente/pagamentoinformado`,
                        action.req
                    )
                    .pipe(
                        map((response) =>
                            sucessoComunicaPagamento({ response: response})
                        ),
                        catchError(error => {
                            return of(erroComunicaPagamento({ error: error }))
                        })
                    )
                })
        )
    );
}

import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { format, parse } from 'date-fns';
export function maioridadeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let data = control.value
    if(data.includes('/')) {
      const dataParseada = parse(control.value, 'dd/MM/yyyy', new Date());
        data = format(dataParseada, 'yyyy-MM-dd');
    }
    
    const dataNascimento = new Date(data);
    const hoje = new Date();
    let idade = hoje.getFullYear() - dataNascimento.getFullYear();

    if (hoje.getMonth() < dataNascimento.getMonth() ||
        (hoje.getMonth() === dataNascimento.getMonth() && hoje.getDate() < dataNascimento.getDate())) {
      idade--;
    }

    return idade >= 18 ? null : { menorIdade: 'Menores de idade não podem se cadastrar.' };
  };
}

import { noticiaKey } from './noticias.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export interface NoticiaState {
  noticias: any[];
}

export const selectNoticiaState =
  createFeatureSelector<NoticiaState>(noticiaKey);

export const selectNoticia = createSelector(selectNoticiaState, (state) => {
  return state.noticias;
});

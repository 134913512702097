<div class="content-titulo">
  <h2 class="lista-check_titulo content-titulo__titulo" tabindex="0">{{titulo}}</h2>
</div>
<div role="group" class="lista-check_dados"  tabindex="0">
    <button class="lista-check_editar" (click)="edit()" *ngIf="editar">Editar</button>
    <div role="group" class="lista-check_row" *ngFor="let dado of dados; let i=index">
        <mat-checkbox role="checkbox" class="lista-check_checkbox" checked="{{dado.check}}" color="primary">
            <span class="lista-check_label">{{dado.label}}</span>
        </mat-checkbox>
    </div>
</div>

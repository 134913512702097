import { Fornecimento } from 'src/app/core/models/fornecimento';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from 'src/app/event-emits.service';
import { AddressWarningComponent } from './address-warning/address-warning.component';
import { Store, select } from '@ngrx/store';
import { ISupplyState } from 'src/app/core/store/supply/supply.reducer';
import { Actions, ofType } from '@ngrx/effects';
import {
  carregaFaturasFornecimentoCNPJ,
  carregaFaturasFornecimento,
  sucessoFaturasFornecimento,
} from 'src/app/core/store/supply/supply.actions';
import { Subject, takeUntil } from 'rxjs';
import { FornecimentoService } from '../../observables/fornecimento.service';
import { FaturaService } from '../../observables/fatura.service';
import { AcordoService } from '../../observables/acordo.service';
import { Router } from '@angular/router';
import { CNPJService } from '../../observables/cnpj.service';
import { CnpjService } from 'src/app/services/cnpj.service';

export interface IAddress {
  description: string;
  supply: string;
  inactive: boolean;
  nextDueDate: string;
  cut: boolean;
  finishedClosed: boolean;
}

@Component({
  selector: 'app-selecionar-fornecimento',
  templateUrl: './selecionar-fornecimento.component.html',
  styleUrls: ['./selecionar-fornecimento.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelecionarFornecimentoComponent implements OnInit, OnChanges {
  // String
  labelAddress1: string = '- - - -';
  labelAddress2: string = '';
  filtro: string = '';

  innerWidth: number = window.innerWidth;

  // Boolean
  isRepresentanteLegal: boolean = false;
  selectOpened: boolean = false;
  loadingForn: boolean = true;
  cnpjOpened: boolean = false;
  isMobile: boolean = false;

  faturaAtual: any = null;
  // Lista
  @Input() fornecimentos: Fornecimento[] = [];
  fornecimentosFiltrados: Fornecimento[] = [];
  @Input() cnpjs: any[] = [];
  // Object
  fornecimentoSelecionado: Fornecimento = new Fornecimento();

  // Outros
  @Output() enviarFornecimento: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('vencimentoFatura') vencimentoFaturaElement: ElementRef;
  destroyed$ = new Subject<boolean>();
  cnpjSelected: any;

  constructor(
    public modal: MatDialog,
    private datePipe: DatePipe,
    private store: Store<{ supply: ISupplyState }>,
    private updates$: Actions,
    private fornObs: FornecimentoService,
    private fatObs: FaturaService,
    private acordoObs: AcordoService,
    private router: Router,
    private cnpjObs: CNPJService, 
    private cnpjService: CnpjService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isRepresentanteLegal = this.router.url.includes(
      'negocios/empresa'
    );
    if (changes['fornecimentos']?.currentValue?.length > 0) {
      this.selectAddress(
        changes['fornecimentos']?.currentValue.find((x: any) => x.default)
      );
      this.selectOpened = false;
      this.fornecimentosFiltrados = this.fornecimentos.sort(
        this.compararStatus
      );
    }
  }

  ngOnInit(): void {
    if (this.innerWidth <= 960) this.isMobile = true;
    else this.isMobile = false;

    this.fornObs.currentFornecimentos.subscribe(
      (fornecimentos) => (this.fornecimentos = fornecimentos)
    );
    this.fornObs.currentFornecimento.subscribe((fornecimento) => {
      this.fornecimentoSelecionado = fornecimento;
      this.faturaAtual =
        this.fornecimentoSelecionado && this.fornecimentoSelecionado.faturas
          ? this.fornecimentoSelecionado.faturas[0]
          : [];
      if (this.fornecimentoSelecionado?.enderecoFornecimento) {
        this.labelAddress1 = `${this.fornecimentoSelecionado.enderecoFornecimento.replace(
          ' N ',
          ' Nº '
        )}`;
      } else {
        this.labelAddress1 = `${this.fornecimentoSelecionado.endereco}, ${this.fornecimentoSelecionado.numero} ${this.fornecimentoSelecionado.bairro} - ${this.fornecimentoSelecionado.uf}`;
      }
      setTimeout(() => {
        //Carrega o focus na mensagem de erro
        this.vencimentoFaturaElement?.nativeElement?.focus();
      }, 0);
      this.acordoObs.changeAcordos(fornecimento.acordos);
    });
    setTimeout(() => {
      this.loadingForn = false;
    }, 2000);
    this.cnpjObs.currentCNPJ.subscribe((cnpj) => {
      this.cnpjSelected = cnpj;
      if (this.isRepresentanteLegal) {
        if (cnpj && cnpj.fornecimentos) {
          this.fornecimentos = cnpj?.fornecimentos;
          this.fornecimentosFiltrados = this.fornecimentos.sort(
            this.compararStatus
          );
        } else {
          this.fornecimentos = [];
        }
      }
    });
    this.cnpjObs.currentCNPJS.subscribe((cnpjs) => (this.cnpjs = cnpjs));
    setTimeout(() => {
      this.loadingForn = false;
    }, 2000);
  }

  compararStatus(a: any, b: any) {
    const OrdemStatus = [
      'ATIVO',
      'AGUARDANDO ATIVAÇÃO',
      'CORTADO',
      'SUPRIMIDO - A PEDIDO',
      'SUPRIMIDO - POR DÉBITO',
      'ENCERRAMENTO EM ANDAMENTO',
      'ENCERRADO A FATURAR',
      'ENCERRADO',
      'ENCERRADO E FATURADO',
    ];

    const indexA = OrdemStatus.indexOf(a.status);
    const indexB = OrdemStatus.indexOf(b.status);

    if (indexA < indexB) {
      return -1;
    } else if (indexA > indexB) {
      return 1;
    } else {
      return 0;
    }
  }

  toggleSelect() {
    this.selectOpened = !this.selectOpened;
  }

  toggleSelectCnpj() {
    this.cnpjOpened = !this.cnpjOpened;
  }

  closeSelectCnpj() {
    this.cnpjOpened = false;
  }

  closeSelect() {
    this.selectOpened = false;
  }

  selectCnpj(cnpj: any) {
    this.cnpjSelected = cnpj;
    this.loadingForn = true;
    this.closeSelectCnpj();
    this.store.dispatch(
      carregaFaturasFornecimentoCNPJ({
        cnpj: cnpj.cnpj,
      })
    );
    this.updates$
      .pipe(ofType(sucessoFaturasFornecimento), takeUntil(this.destroyed$))
      .subscribe({
        next: (action) => {
          this.cnpjObs.changeCNPJ(cnpj);
          this.loadingForn = false;
        },
      });
  }

  // TODO Arrumar código para remover duplicata de code
  selectAddress(fornecimento: Fornecimento) {
    this.loadingForn = true;
    this.closeSelect();
    fornecimento = fornecimento ? fornecimento : this.fornecimentos[0];
    if (fornecimento) {
      this.fornecimentoSelecionado = fornecimento;
      if (!fornecimento.default) {
        this.fornecimentoSelecionado.faturas = [];
        this.cnpjService.listaFaturasPorFornecimento(fornecimento.codigo).subscribe({
          next: (action) => {
            if (action) {
              this.fatObs.changeFaturas(action.fornecimentos.faturas);
              this.fornObs.changeFornecimento(action.fornecimentos);
              this.acordoObs.changeAcordos(action.fornecimentos.acordos);
            }
              this.selectOpened = false;

              if (this.fornecimentoSelecionado?.enderecoFornecimento) {
                this.labelAddress1 = `${this.fornecimentoSelecionado.enderecoFornecimento.replace(
                  ' N ',
                  ' Nº '
                )}`;
              } else {
                this.labelAddress1 = `${this.fornecimentoSelecionado.endereco}, ${this.fornecimentoSelecionado.numero} ${this.fornecimentoSelecionado.bairro} - ${this.fornecimentoSelecionado.uf}`;
              }
              setTimeout(() => {
                //Carrega o focus na mensagem de erro
                this.vencimentoFaturaElement?.nativeElement?.focus();
              }, 0);
              this.loadingForn = false;
              this.enviarDados();
            },
            error: (erro) => {
              this.loadingForn = false;
              console.log(erro);
            },
        });
      } else {
        this.selectOpened = false;
        if (this.fornecimentoSelecionado?.enderecoFornecimento) {
          this.labelAddress1 = `${this.fornecimentoSelecionado.enderecoFornecimento.replace(
            ' N ',
            ' Nº '
          )}`;
        } else {
          this.labelAddress1 = `${this.fornecimentoSelecionado.endereco}, ${this.fornecimentoSelecionado.numero} ${this.fornecimentoSelecionado.bairro} - ${this.fornecimentoSelecionado.uf}`;
        }
        this.fornObs.changeFornecimento(
          this.fornecimentos.filter((x) => x.default)[0]
        );
        this.fatObs.changeFaturas(
          this.fornecimentos.filter((x) => x.default)[0].faturas
        );
        this.acordoObs.changeAcordos(
          this.fornecimentos.filter((x) => x.default)[0].acordos
        );
        setTimeout(() => {
          //Carrega o focus na mensagem de erro
          this.vencimentoFaturaElement?.nativeElement?.focus();
        }, 0);
        this.loadingForn = false;
        this.enviarDados();
      }
    }
  }

  showInfo() {
    this.modal.open(AddressWarningComponent, {
      width: '90%',
      maxWidth: '475px',
      panelClass: 'modal-aviso',
    });
  }

  abrirEndereco(e: any) {
    let key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.toggleSelect();
    }
  }

  abrirCnpj(e: any) {
    let key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.toggleSelectCnpj();
    }
  }

  selecionarEndereco(e: any, fornecimento: Fornecimento) {
    let key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.selectAddress(fornecimento);
    }
  }

  selecionarCnpj(e: any, cnpj: any) {
    let key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.selectCnpj(cnpj);
    }
  }

  enderecoPopup(e: any) {
    let key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.showInfo();
    }
  }

  enviarDados() {
    localStorage.setItem(
      'codFornecimento',
      this.fornecimentoSelecionado.codigo
    );
    localStorage.setItem(
      'fornecimento',
      JSON.stringify(this.fornecimentoSelecionado)
    );
    this.enviarFornecimento.emit(this.fornecimentoSelecionado);
  }

  createAriaLabel(step: any): string {
    if (step === 1) {
      if (this.fornecimentoSelecionado.proximoVencimento !== undefined) {
        return `Próximo vencimento: ${this.formataData(
          this.fornecimentoSelecionado.proximoVencimento
        )}`;
      } else {
        return `Próximo vencimento: Sem data programada`;
      }
    } else if (step === 2) {
      return `Caixa de seleção de fornecimento. Aqui você pode escolher qual o fornecimento quer visualizar as faturas e qual a previsão para o próximo vencimento.`;
    } else if (step === 3) {
      if (this.fornecimentoSelecionado?.enderecoFornecimento !== undefined) {
        return `Endereço selecionado: ${this.fornecimentoSelecionado?.enderecoFornecimento?.replace(
          ' N ',
          ' Número '
        )}. Clique aqui para selecionar outro fornecimento.`;
      } else {
        return `Endereço selecionado: ${this.fornecimentoSelecionado}. Clique aqui para selecionar outro fornecimento.`;
      }
    } else if (step === 4) {
      if (
        (this.fornecimentos !== undefined &&
          this.fornecimentos !== null &&
          this.fornecimentos?.length > 0) ||
        (this.fornecimentoSelecionado !== undefined &&
          this.fornecimentoSelecionado?.codigo !== '')
      ) {
        return `Fornecimento: ${
          this.fornecimentos?.length > 0 && this.fornecimentos[0] ? this.fornecimentoSelecionado?.codigo : ''
        }.`;
      } else {
        return `Fornecimento: Não tem nenhum fornecimento listado`;
      }
    }
    return '';
  }

  formataData(data: any): string {
    return `${this.datePipe.transform(data, 'dd/MM/yyy')}`;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    const w = event.target as Window;
    const width = w.innerWidth;
    if (width <= 960) this.isMobile = true;
    else this.isMobile = false;
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { fromEvent, Subscription } from 'rxjs';
import { Parcela } from 'src/app/core/models/acordo';
import { Fatura } from 'src/app/core/models/faturaCompleta';
import { ThemeService } from '../../observables/theme.service';
import { Router } from '@angular/router';




interface DOMRectI {
  bottom: number;
  height: number;
  left: number; // position start of element
  right: number; // position end of element
  top: number;
  width: number; // width of element
  x?: number;
  y?: number;
}

@Component({
  selector: 'agv-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {

  // Controlador do tema
  currentTheme: string;

  @Output() sucessoExibirPdf: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() paymentMethod: string | null = null;
  @Input() parcela: Parcela = new Parcela();
  @Input() sucessExibirPdf: boolean = false;
  @Input() fatura: Fatura = new Fatura();
  @Input() logado: boolean = false;

  @ViewChild('widgetsContent') widgetsContent: ElementRef | undefined;
  @ViewChild('formasPagamento') formasPagamentoElement: ElementRef;
  @ViewChild('tabGroup') tabGroupElement: ElementRef;

  public subs = new Subscription();
  public paymentMethods: any = [];
  public payments: any = [];
  bancoSelecionado: number = 0;
  public mostrarFormularioCartao: boolean = false;


  constructor(
    private element: ElementRef,
    private theme: ThemeService,
    private router: Router,
  ) {
    this.subs.add(
      fromEvent(this.element.nativeElement, 'click').subscribe(
        (clickedContainer: any) => {
          let scrollContainer;
          if (element.nativeElement.querySelector('.img-inativo')) {
            scrollContainer =
              element.nativeElement.parentNode.querySelector('.mat-tab-list');
          } else {
            scrollContainer =
              this.element.nativeElement.querySelector('.mat-tab-list');
          }
          const currentScrolledContainerPosition: number =
            scrollContainer.scrollLeft;
          // const newPositionScrollTo = this.calcScrollValue(clickedContainer, currentScrolledContainerPosition);
          const newPositionScrollTo = this.calcScrollToCenterValue(
            clickedContainer,
            currentScrolledContainerPosition
          );

          scrollContainer.scroll({
            left: newPositionScrollTo,
            behavior: 'smooth',
          });
        }
      )
    );
  }

  ngOnInit(): void {
    this.theme.currentTheme.subscribe(theme => this.currentTheme = theme);
    this.getTipoPagamento();
    this.paymentMethods = this.payments
      .filter((c: any) => c.show)
      .map((c: any) => c);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  gerarId(nome: string): string {
    return `formas-pgto-metodo-escolhido-${nome.replace(/\s/g, '')}`;
  }

  focusFormPag() {
    setTimeout(() => {
      //Carrega o focus no campo de fatura do formas de pagamento
      this.formasPagamentoElement?.nativeElement.children[0].children[0].children[1].children[0].children[0].children[0].focus();
    }, 300);
  }

  calcScrollToCenterValue(
    clickedContainer: any,
    currentScrolledContainerPosition: any
  ): number {
    const scrolledButton: DOMRectI = (
      clickedContainer.target as HTMLElement
    ).getBoundingClientRect();
    const leftXOffset = (window.innerWidth - scrolledButton.width) / 2;
    const currentVisibleViewportLeft = scrolledButton.left;
    const neededLeftOffset = currentVisibleViewportLeft - leftXOffset;
    const newValueToSCroll =
      currentScrolledContainerPosition + neededLeftOffset;
    return newValueToSCroll;
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.bancoSelecionado = tabChangeEvent.index;
  };

  getSvg(menu: any) {
    return `assets/images/${menu.icon}`;
  }

  exibirPDF(exibe: boolean){
    this.sucessoExibirPdf.emit(exibe);
  }

  selectPayment(paymentId: number): void {
    this.mostrarFormularioCartao = paymentId === 2;
    this.bancoSelecionado = paymentId;
  }

  mostrarFaturaComponent(paymentId: number): boolean {
    return paymentId !== 2 && !this.mostrarFormularioCartao;
  }

  getTipoPagamento() {
    this.payments = [
      {
        id: 1,
        name: 'Conta <br/> Sabesp',
        icon: 'icn_fatura_sabesp_inativo',
        alt: 'Ícone de leitura de fatura desativado',
        iconActive: 'icn_fatura_sabesp_ativo',
        altAtivo: 'Ícone de leitura de fatura ativado',
        disabled: false,
        url: '',
        active: 'true',
        component: '',
        show: true,
      },
      {
        id: 2,
        name: 'Pagamento via<br/> cartão',
        icon: 'icn_cartao_credito_ativo',
        altAtivo: 'Ícone de cartão de crédito ativado',
        iconActive: 'icn_cartao_credito_ativo',
        alt: 'Ícone de cartão de crédito ativado',
        disabled: !this.fatura.cartao || this.fatura.situacaoDaFatura.toLowerCase() === 'paga' ||
        this.fatura.situacaoDaFatura.toLowerCase() === 'em pagamento cartão' || this.fatura.situacaoDaFatura.toLowerCase() === 'em acordo de parcelamento' || this.fatura.situacaoDaFatura.toLowerCase() === "pago com cartão crédito" ,
        url: '',
        active: 'false',
        component: '',
        show: true,

      },
      {
        id: 3,
        name: 'Caixa <br/> Econômica',
        icon: 'icn_caixa_inativo',
        altAtivo: 'Ícone da caixa econômica federal desativado',
        iconActive: 'icn_caixa_ativo',
        alt: 'Ícone da caixa econômica federal ativado',
        disabled: false,
        url: '',
        active: 'false',
        component: '',
        show: true,
      },
      {
        id: 4,
        name: 'Banco do <br/> Brasil',
        icon: 'icn_banco_brasil_inativo',
        altAtivo: 'Ícone do banco do brasil desativado',
        iconActive: 'icn_banco_brasil_ativo',
        alt: 'Ícone do banco do brasil desativado',
        disabled: false,
        url: '',
        active: 'false',
        component: '',
        show: true,
      },
      {
        id: 5,
        name: 'Banco <br/> Bradesco',
        icon: 'icn_bradesco_inativo',
        altAtivo: 'Ícone do bradesco desativado',
        iconActive: 'icn_bradesco_ativo',
        alt: 'Ícone do bradesco desativado',
        disabled: false,
        url: '',
        active: 'false',
        component: '',
        show: true,
      },
      {
        id: 6,
        name: 'Banco <br/> Itaú',
        icon: 'icn_itau_inativo',
        altAtivo: 'Ícone do itaú desativado',
        iconActive: 'icn_itau_ativo',
        alt: 'Ícone do itaú ativado',
        disabled: false,
        url: '',
        active: 'false',
        component: '',
        show: true,
      },
      {
        id: 7,
        name: 'Banco <br/> Santander',
        icon: 'icn_santander_inativo',
        altAtivo: 'Ícone do santander desativado',
        iconActive: 'icn_santander_ativo',
        alt: 'Ícone do santander ativado',
        disabled: false,
        url: '',
        active: 'false',
        component: '',
        show: true,
      },
      {
        id: 8,
        name: 'PIX',
        icon: 'icn_pix_inativo',
        altAtivo: 'Ícone do pix desativado',
        iconActive: 'icn_pix_ativo',
        alt: 'Ícone do pix ativado',
        disabled: true,
        url: '',
        active: 'false',
        component: '',
        show: true,
      },
    ];
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validator } from 'src/app/shared/utils/validator';

@Component({
  selector: 'app-address-warning',
  templateUrl: './address-warning.component.html',
  styleUrls: ['./address-warning.component.scss'],
})
export class AddressWarningComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  clicaTroca() {
    this.router.navigateByUrl('/troca-titularidade/identificacao');
  }

  pressChat(e:any){
    if (Validator.validaEventKeyPressClick(e)) {
      document.getElementById('chat')?.click();
    }
  }

  pressTroca(e: any) {

    if (Validator.validaEventKeyPressClick(e)) {

      document.getElementById('troca-titularidade')?.click();
    }
  }
}

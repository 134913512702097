<ng-container *ngIf="display$ | async as display">
  <section  *ngIf="display === 'open'" class="video-dialog">
    <div (click)="$event.stopPropagation()" class="video-dialog-content">
      <div  class="video-dialog-header">
        <button class="close" type="button" (click)="close()">X</button>
      </div>
      <div class="video">
          <iframe [src]="videoUrl +'?rel=0&autoplay=1&mute=1&enablejsapi=1&playsinline=1&lazyload=1'| safe:'url'" allowfullscreen>
          Seu navegador não suporta vídeo HTML5.
        </iframe>
      </div>

    </div>

  </section>
</ng-container>

<div role="group" id="por-email" class="por-email-container">
  <div
    role="group"
    id="por-email-conteudo"
    class="content"
    [ngStyle]="{
      'justify-content': cadastrado && isMobile ? 'initial' : 'space-between'
    }"
  >
    <div role="group" id="por-email-msg-principal" class="row" tabindex="0">
      <p class="text__title" *ngIf="!cadastrado || (cadastrado && !isMobile)">
        Receber fatura por E-mail
      </p>
      <div class="box-mensagem">
        <app-mensagem
          *ngIf="erro.invalid"
          [mensagem]="erro.message"
        ></app-mensagem>
      </div>
      <p
        class="text__desc"
        *ngIf="!erro.invalid && !cadastrado && !erroCadastroEmail"
      >
        Ativando esse serviço você terá a comodidade de receber a sua fatura por
        e-mail
      </p>
    </div>
    <ng-template [ngIf]="!cadastrado && !erroCadastroEmail">
      <div
        [formGroup]="emailForm"
        role="group"
        id="por-email-box-inputs"
        class="row input"
      >
        <div
          role="group"
          id="por-email-box-input-email"
          [ngClass]="{
            'has-error-input':
              erro.invalid &&
              erro.type !== 'not equals' &&
              erro.type !== 'terms not accepted'
          }"
          class="box-input"
        >
          <label class="text__label" for="email">E-mail</label>
          <input
            formControlName="email"
            id="por-email-input-email"
            class="text__input"
            type="email"
            id="email"
            name="email"
            placeholder="Digite seu e-mail"
            (blur)="verificaErro()"
          />
        </div>
        <div
          role="group"
          id="por-email-box-input-email-conf"
          [ngClass]="{ 'has-error-input': erro.type === 'not equals' }"
          class="box-input"
        >
          <label class="text__label" for="confirma-email"
            >Confirme o e-mail</label
          >
          <input
            formControlName="emailConfirmar"
            id="por-email-input-email-confirmacao"
            class="text__input"
            type="text"
            id="confirma-email"
            name="confirma-email"
            placeholder="Confirme seu e-mail"
            (blur)="verificaErro()"
          />
        </div>
      </div>
      <div role="group" id="por-email-box-termo" class="row row__termo-mob">
        <span
          role="link"
          id="por-email-link-termo-aceite"
          class="text__ver-termo link-termo mt-3"
          (click)="abrirPopUpTermo()"
          >Ver Termo de Serviço</span
        >
        <div
          role="group"
          id="por-email-box-input-termo"
          class="box-termo mb-3"
          tabindex="0"
          (keypress)="aceitaTermo($event)"
          #checkTermo
        >
          <label class="switch">
            <input
              id="por-email-input-termo-aceite"
              type="checkbox"
              [(ngModel)]="aceite"
              tabindex="-1"
            />
            <span class="slider round">.</span>
          </label>
          <p
            class="text__label-termo"
            [ngClass]="{ 'has-error': erro.type === 'terms not accepted' }"
          >
            Aceito os termos de serviços
          </p>
        </div>
      </div>
      <div role="group" id="por-email-box-cadastrar-email" class="row">
        <agv-button
          [text]="
            carregando
              ? 'Carregando...'
              : isEncerramento
              ? 'Continuar'
              : 'Cadastrar e-mail'
          "
          (sendAction)="cadastrar()"
          [habilita]="aceite && !carregando"
          [type]="'2'"
        ></agv-button>
      </div>
    </ng-template>
    <ng-template #mensagemErro [ngIf]="erroCadastroEmail && !cadastrado">
      <div role="group" id="por-email-sucesso" class="mensagem-erro">
        <agv-icon
          [name]="'icn-exclamacao'"
          title="Ícone de sucesso"
          class="icn-mensagem"
        ></agv-icon>
        <span class="text mensagem">
          Falha de comunicação com o sistema, por favor tente novamente mais
          tarde.
        </span>
      </div>
    </ng-template>
    <ng-template #mensagemSucesso [ngIf]="!erroCadastroEmail && cadastrado">
      <div role="group" id="por-email-sucesso" class="mensagem-sucesso">
        <agv-icon
          [name]="'icon_sucesso'"
          title="Ícone de sucesso"
          class="icn-mensagem"
        ></agv-icon>
        <span class="text mensagem"
          >E-mail cadastrado com sucesso. <br />
          A partir de agora, suas faturas serão enviadas para {{ email }}
          <br /><br />
        </span>
        <span class="text procotolo">
          Foi gerado o protocolo de <br />
          atendimento nº {{ nProtocolo }}.
        </span>
      </div>
    </ng-template>
  </div>
</div>
<app-pop-up
  *ngIf="showPopUpTermo"
  (eventClose)="abrirPopUpTermo()"
  [titulo]="'Termo Aceite'"
  [texto]="textoPopUp"
>
</app-pop-up>

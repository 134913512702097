import { Router } from '@angular/router';
import { ThemeService } from '../../observables/theme.service';
import {
  FooterColumns,
  ItemsColumn,
} from './../../../core/models/footerColumns';
import { FooterService } from './footer.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  // Controlador do tema
  currentTheme: string;

  columns: FooterColumns[] = [];
  isMobile: boolean = false;
  @Input() isAdmin: boolean = false;

  constructor(
    private readonly footerService: FooterService,
    private theme: ThemeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
    this.columns = this.footerService.getInfoColumnsFooter();

    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
    window.onresize = () => (this.isMobile = window.innerWidth <= 768);
  }

  onRedirect(item: ItemsColumn) {
    if (item.link && item.link !== '') window.open(item.link, '_blank');
  }

  geraId(nome: any): string {
    return nome?.replace(/\s/g, '').toLowerCase();
  }

  getNomeImg(item: any): string {
    return `${
      this.currentTheme === 'theme-dark' ? item.imageDark : item.image
    }`;
  }

  verifyImg(item: any): boolean {
    return item.image.includes('apple');
  }

  linkRedirect(item: ItemsColumn) {
    if (item.interno) {
      this.router.navigate([`/${item.link}`]);
      if (item.focus) {
        const element = document.getElementById(`${item.focus}`);
        if (element) {
          const offset = element.offsetTop - 50;
          window.scrollTo({ top: offset, behavior: 'smooth' });
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      window.open(`${item.link}`, '_blank');
    }
  }
}

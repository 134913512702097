<div
  role="group"
  id="comunica-pagto"
  class="comunica-pagto-container"
  *ngIf="!checkMensagemExiste() && !this.comunicaSucesso"
>
  <div
    *ngIf="comunica"
    role="group"
    id="comunica-pagto-conteudo"
    class="content"
    (mouseenter)="congela(false)"
  >
    <div role="group" id="comunica-pagto-mensagem" class="row row__title">
      <p class="text__title" tabindex="0">
        Quer comunicar o pagamento
        {{
          comunicaPgtoType === "parcelamento"
            ? "do acordo de parcelamento"
            : "da fatura"
        }}?
      </p>
      <p class="text__desc" tabindex="0">
        Por favor, selecione a
        {{ comunicaPgtoType === "parcelamento" ? "parcela" : "fatura" }} que
        você deseja comunicar o pagamento
      </p>
    </div>
    <div role="group" id="comunica-pagto-info-fat" class="row">
      <app-pagto-table
        [parcelas]="parcelas"
        [displayedColumns]="columnstable"
        (itensSelecionados)="capturaItem($event)"
        [comunicaPgtoType]="comunicaPgtoType"
      >
      </app-pagto-table>
    </div>
    <div role="group" *ngIf="msgErroItem !== ''" class="row end-text">
      <span class="msg-error-fatura error-item">{{ msgErroItem }}</span>
    </div>
    <div
      role="group"
      id="comunica-pagto-mensagem"
      class="row row__title"
      tabindex="0"
    >
      <p class="text__title">
        Por favor, preencha as informações para prosseguir
      </p>
    </div>
    <div role="group" class="row row__input-pagto">
      <div
        role="group"
        id="comunica-pagto-selecao-banco"
        [ngClass]="{ 'has-error-datepicker': erroSelectedBanco }"
        class="box-input"
        tabindex="0"
        (click)="toggleSelect($event)"
        (keypress)="toggleSelect($event)"
        (blur)="loseFocusSelect()"
      >
        <label class="text__label" for="local" style="padding-top: 7px"
          >Banco *</label
        >

        <div role="group" class="banco-select">
          <div role="group" class="banco-select__content">
            <div role="group" class="select-container">
              <div
                style="cursor: pointer"
                role="group"
                class="select-container"
                #listaBancos
              >
                <div class="labelBancoSelecionado">
                  {{ local === "" ? "Selecione a opção" : local }}
                </div>
              </div>
              <div class="arrow-image">
                <agv-icon
                  class="icon"
                  [ngClass]="{ 'rotate-180': selectOpened }"
                  [name]="'arrow_black'"
                  title="Abrir opções de banco"
                ></agv-icon>
              </div>
              <div
                role="group"
                *ngIf="selectOpened"
                class="select-field__opened"
              >
                <div
                  role="group"
                  *ngFor="let banco of bancos; let indexOfelement = index"
                  class="banco"
                  (click)="bancoSelecionado(banco.nome)"
                  (keypress)="bancoSelecionado(banco.nome)"
                  tabindex="0"
                >
                  <div role="group" class="banco__description">
                    {{ banco.nome }}
                  </div>
                  <div
                    role="group"
                    class="banco__supply"
                    [ngClass]="{ 'font-hard-gray': banco.inactive }"
                  >
                    <agv-icon
                      class="banco__supply__icon"
                      [name]="banco.img"
                      title="{{ banco.alt }}"
                    ></agv-icon>
                  </div>
                  <div role="group" class="separator"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        role="group"
        id="comunica-pagto-input-data-pagamento"
        [ngClass]="{ 'has-error-datepicker': msgErroDate !== '' }"
        class="box-input__date"
        tabindex="0"
        (focus)="loseFocusSelect()"
      >
        <div role="group" class="agrupa-text-datepicker">
          <label class="text__label" for="data">Data do pagamento *</label>
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="Selecionar a data de pagamento"
            id="data"
            name="data"
            [(ngModel)]="data"
            (dateInput)="changeDate($event)"
            disabled
          />
        </div>
        <div role="group" class="agrupa-icon-datepicker">
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            (click)="congela(true)"
            (mouseleave)="descongelaMobile()"
            [tabindex]="0"
            aria-label="abrir o calendário"
          >
            <agv-icon
              class="icon-calendar"
              [name]="'icn_data'"
              [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }"
              matDatepickerToggleIcon
              title="'Ícone calendário'"
            ></agv-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker [disabled]="false"></mat-datepicker>
        </div>
      </div>
    </div>
    <div role="group" *ngIf="erroSelectedBanco" class="row init-text">
      <div></div>
      <span class="msg-error-fatura">Selecione um banco</span>
    </div>
    <div role="group" *ngIf="msgErroDate !== ''" class="row end-text">
      <div></div>
      <span class="msg-error-fatura">{{ msgErroDate }}</span>
    </div>
    <div role="group" class="row">
      <agv-button
        class="btn__comunicar"
        [id]="'btn__comunicar'"
        [text]="carregando ? 'Carregando...' : 'Comunicar pagamento'"
        [type]="'2'"
        [habilita]="habilitaBotao() && !carregando"
        (sendAction)="comunicar()"
      ></agv-button>
    </div>
  </div>
  <app-confirma-pagto
    *ngIf="!comunica"
    [fornecimento]="fornecimento"
    [dados]="dados"
    (comunica)="capturaComunica($event)"
    [protocolo]="protocolo"
    (comunicaOutro)="capturaComunicaOutro($event)"
  >
  </app-confirma-pagto>
</div>
<div
  role="group"
  id="comunica-pagto-sucesso"
  class="comunica-pagto-container-sucesso"
  *ngIf="comunicaSucesso"
>
  <span class="titulo">Comunicação de pagamento</span>
  <app-notificacao-processo
    [tipoMensagem]="'sucesso'"
    [tituloMensagem]="tituloProcesso"
    [mensagem]="mensagemProcesso"
  ></app-notificacao-processo>
  <button
    class="confirma-pagto_content_btns_comunicar"
    (click)="capturaComunicaOutro(true)"
  >
    Comunicar outro pagamento
  </button>
</div>

<div
  role="group"
  id="comunica-pagto-atencao"
  class="comunica-pagto-container-atencao"
  *ngIf="checkMensagemExiste()"
>
  <app-notificacao-processo
    [tipoMensagem]="'atencao'"
    [tituloMensagem]="tituloProcesso"
    [mensagem]="mensagemProcesso"
  ></app-notificacao-processo>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  mergeMap,
  of,
  pipe,
  switchMap,
  tap,
  filter,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BuscaFaturasPorFornecimentoResumido } from '../../models/service/faturasPorFornecimento-resumido';
import {
  carregaFornecimento,
  sucessoCarregaForencimento,
  erroCarregarFornecimento,
  carregaFornecimentos,
  sucessoCarregaFornecimentos,
  sucessoFaturasFornecimento,
  erroFaturasFornecimento,
  carregaFaturasFornecimento,
  carregaCNPJFornecimento,
  carregaFaturasFornecimentoCNPJ,
} from './supply.actions';
import { ISupplyState } from './supply.reducer';
import { FornecimentoService } from 'src/app/shared/observables/fornecimento.service';
import { FaturaService } from 'src/app/shared/observables/fatura.service';
import { ClienteService } from 'src/app/shared/observables/cliente.service';
import { AcordoService } from 'src/app/shared/observables/acordo.service';
import { CNPJService } from 'src/app/shared/observables/cnpj.service';
import { Fornecimento } from '../../models/fornecimento';

@Injectable({
  providedIn: 'root',
})
export class SupplyEffectService {
  private readonly BASE_URL = `${environment.uri}`;

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<{ supply: ISupplyState }>,
    private fornecimentos: FornecimentoService,
    private faturas: FaturaService,
    private cliente: ClienteService,
    private acordos: AcordoService,
    private cnpjs: CNPJService
  ) {}

  carregaFornecimentoPorCodigo = createEffect(() =>
    this.actions$.pipe(
      ofType(carregaFornecimento),
      mergeMap((action) => {
        const headers = new HttpHeaders({
          'X-ReCaptcha-Token': action.recaptcha,
        });
        return this.http
          .get<BuscaFaturasPorFornecimentoResumido>(
            `${this.BASE_URL}v1/simplificada/fatura/fornecimento/${action.fornecimento}`,
            { headers }
          )
          .pipe(
            map((fornecimento) => {
              return sucessoCarregaForencimento({ dados: fornecimento });
            }),
            catchError((error) => {
              return of(erroCarregarFornecimento({ error: error }));
            })
          );
      })
    )
  );

  carregaFornecimentosPorCpf = createEffect(() =>
    this.actions$.pipe(
      ofType(carregaFornecimentos),
      mergeMap((action) =>
        this.http
          .get<any>(
            `${this.BASE_URL}v1/completa/fatura/fornecimento/cliente/cpf`
          )
          .pipe(
            map((dados) => {
              this.cliente.changeCliente(dados.cliente);
              this.fornecimentos.changeFornecimentos(dados.fornecimentos);
              if (dados.fornecimentos.length > 0) {
                this.fornecimentos.changeFornecimento(
                  dados.fornecimentos.filter((x: any) => x.default)[0]
                );
                this.faturas.changeFaturas(
                  dados.fornecimentos.filter((x: any) => x.default)[0].faturas
                );
              } else {
                this.fornecimentos.changeFornecimento(dados.fornecimentos[0]);
                this.faturas.changeFaturas(dados.fornecimentos[0].faturas);
              }
              if (
                dados.fornecimentos.filter((x: any) => x.default)[0].temAcordo
              ) {
                this.acordos.changeAcordos(
                  dados.fornecimentos.filter((x: any) => x.default)[0].acordos
                );
              }
              return sucessoCarregaFornecimentos({ dados: dados });
            }),
            catchError((error) => {
              return of(erroCarregarFornecimento({ error: error }));
            })
          )
      )
    )
  );

  carregaFornecimentoPorCNPJ = createEffect(() =>
    this.actions$.pipe(
      ofType(carregaCNPJFornecimento),
      mergeMap(() => {
        return this.http
          .get<any>(
            `${this.BASE_URL}v1/completa/fatura/fornecimento/cnpj/cliente`
          )
          .pipe(
            map((dados) => {
              this.fornecimentos.changeFornecimento(new Fornecimento());
              this.fornecimentos.changeFornecimentos([]);
              this.faturas.changeFaturas([]);
              
              this.cnpjs.changeRepresentante(dados.representante);
              this.cnpjs.changeCNPJS(dados.cnpjs);

              let cnpjDefault = dados.cnpjs.find((cnpj: any) => cnpj.default);
              if (cnpjDefault) {
                this.cnpjs.changeCNPJ(cnpjDefault);
                this.fornecimentos.changeFornecimentos(
                  cnpjDefault.fornecimentos
                );
                this.fornecimentos.changeFornecimento(
                  cnpjDefault.fornecimentos.find((x: any) => x.default)
                );
                this.faturas.changeFaturas(
                  cnpjDefault.fornecimentos.find((x: any) => x.default).faturas
                );
                if (cnpjDefault.fornecimentos[0]?.temAcordo) {
                  this.acordos.changeAcordos(
                    cnpjDefault.fornecimentos[0]?.acordos
                  );
                }
              } else {
                let fornecimentoDefault = dados.cnpj.filter(
                  (cnpj: any) => cnpj.fornecimentos.length > 0
                )[0];
                this.fornecimentos.changeFornecimento(fornecimentoDefault);
                this.faturas.changeFaturas(fornecimentoDefault[0].faturas);
              }
              return sucessoCarregaFornecimentos({ dados: dados });
            }),
            catchError((error) => {
              return of(erroCarregarFornecimento({ error: error }));
            })
          );
      })
    )
  );

  listarFaturasForencimento = createEffect(() =>
    this.actions$.pipe(
      ofType(carregaFaturasFornecimento),
      mergeMap((action) =>
        this.http
          .get<any>(
            `${this.BASE_URL}v2/completa/fatura/fornecimento/${action.codigoFornecimento}/cliente/listafaturas`
          )
          .pipe(
            map((response) => {
              this.fornecimentos.changeFornecimento(response.fornecimentos);
              this.faturas.changeFaturas(response.fornecimentos.faturas);
              if (response.fornecimentos.temAcordo) {
                this.acordos.changeAcordos(response.fornecimentos.acordos);
              }
              return sucessoFaturasFornecimento({ dados: response });
            }),
            catchError(() => of(erroFaturasFornecimento()))
          )
      )
    )
  );

  listarFaturasFornecimentoCNPJ = createEffect(() =>
    this.actions$.pipe(
      ofType(carregaFaturasFornecimentoCNPJ),
      mergeMap((action) =>
        this.http
          .get<any>(
            `${this.BASE_URL}/v1/completa/fatura/fornecimento/listafornecimentos/${action.cnpj}`
          )
          .pipe(
            map((response) => {
              this.fornecimentos.changeFornecimento(new Fornecimento());
              this.fornecimentos.changeFornecimentos([]);
              this.faturas.changeFaturas([]);
              this.fornecimentos.changeFornecimentos(response.fornecimentos);

              let fornecimentoDefault = response.fornecimentos.find(
                (cnpj: any) => cnpj.default
              );

              if (fornecimentoDefault) {
                this.fornecimentos.changeFornecimento(fornecimentoDefault);
                let faturas = fornecimentoDefault.faturas;

                if (faturas.length > 0) {
                  this.faturas.changeFaturas(faturas);
                }
              }

              if (fornecimentoDefault?.temAcordo) {
                this.acordos.changeAcordos(fornecimentoDefault?.acordos);
              }
              return sucessoFaturasFornecimento({ dados: response });
            }),
            catchError(() => of(erroFaturasFornecimento()))
          )
      )
    )
  );
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  DadosRepresentante,
  DadosRepresentanteFornecimentoCnpj,
} from 'src/app/core/models/empresas/empresa';
import { Representante } from 'src/app/core/models/service/representante/representante.model';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';

@Injectable({
  providedIn: 'root',
})
export class CNPJService {
  private cnpjsSource = new BehaviorSubject<any[]>([]);
  private cnpjSource = new BehaviorSubject<any>(null);
  private faturaCnpjSource = new BehaviorSubject<any[]>([]);
  private solicitacoesSource = new BehaviorSubject<any[]>([]);
  private representanteSource = new BehaviorSubject<Representante>(
    new Representante()
  );
  private repFornecimentoCnpj = new BehaviorSubject<DadosRepresentante>(
    new DadosRepresentanteFornecimentoCnpj()
  );

  public currentCNPJS = this.cnpjsSource.asObservable();
  public currentCNPJ = this.cnpjSource.asObservable();
  public currentRepresentante = this.representanteSource.asObservable();
  public currentFaturasCnpj = this.faturaCnpjSource.asObservable();
  public currentRepFornecimento = this.repFornecimentoCnpj.asObservable();
  public solicitacoes = this.solicitacoesSource.asObservable();

  constructor(private token: TokenStorageService) { }

  changeCNPJS(cnpjs: any) {
    this.cnpjsSource.next(cnpjs);
  }

  changeCNPJ(cnpj: any) {
    this.cnpjSource.next(cnpj);
  }

  changeRepresentante(representante: Representante) {
    this.representanteSource.next(representante);
  }

  changeFaturas(faturas: any) {
    this.faturaCnpjSource.next(faturas);
  }

  changeSolicitacoes(solicitacoes: any) {
    this.solicitacoesSource.next(solicitacoes);
  }

  changeRepFornecimento(representante: DadosRepresentante) {
    this.repFornecimentoCnpj.next(representante);
  }

  getRepFornecimento(): Observable<any> {
    return this.currentRepFornecimento;
  }

  getFaturas(): Observable<any> {
    return this.currentFaturasCnpj;
  }

  getSolicitacoes(): Observable<any> {
    return this.solicitacoes;
  }

  getRepresentante(): Observable<any | undefined> {
    this.repFornecimentoCnpj.subscribe((cnpj) => {
      if (!cnpj || (cnpj.cnpj === '' && this.token.checkToken())) {
        let representante = this.token.getCNPJRepresentante();
        if (representante) {
          this.repFornecimentoCnpj.next(representante);
        }
      }
    });
    return this.repFornecimentoCnpj;
  }
}

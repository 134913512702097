<div role="group" id="menuServicos" class="menu-servico">
    <h2 role="text" class="menu-servico_titulo" tabindex="0">Serviços <span><b>Sabesp</b></span></h2>
    <ul role="list" id="menu-servico-servicos" class="menu-servico_servicos _hidden grid spacing-grid-mobile">
        <ng-container *ngFor="let servico of servicos">
            <li role="list" [id]="'menu-servico-' + servico.nome" tabindex="0" *ngIf="servico.nome !== 'Atualização cadastral' || !isLogado" class="col col-6 md:col-4" [ngClass]="servico.classCustom" (click)="$event.stopPropagation() ; trataRota(servico)">
                <img [ngClass]="{'dark-mode': currentTheme === 'theme-dark'}" id="menu-servico-img" class="menu-servico_servicos_icone" src="../../../../assets/imgs/png/{{servico.imagem}}.png"
                alt="{{servico.alt_img}}" title="{{servico.title_img}}. Clique para acessar o serviço." />
                <span id="menu-servico-nome" class="menu-servico_servicos_nome" [innerHTML]="servico.nome"></span>
                <a [id]="'menu-servico-link-acesso-' + servico.nome" class="menu-servico_servicos_link">Acesse aqui</a>
            </li>
        </ng-container>
    </ul>
    <span id="menu-servico-ver-mais" tabindex="0" class="menu-servico_ver-mais" (click)="expandir()">{{textoServicos}}<span><i class="{{setaExpand}}" id="seta_expandir"></i></span></span>
</div>

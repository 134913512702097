import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { maioridadeValidator } from '../../utils/maioridade.validator';
import { EventEmitterService } from 'src/app/event-emits.service';
import { Validator } from '../../utils/validator';
import { GetSelectFielStyle } from './selecStyleFormInfo.component';
interface correcaoInfoCliente {
  nome: string;
  dataNascimento: string;
}

@Component({
  selector: 'app-form-infos-incorretas',
  templateUrl: './form-infos-incorretas.component.html',
  styleUrls: ['./form-infos-incorretas.component.scss'],
})

export class FormInformacoesIncorretas implements OnInit {

  @ViewChild('campoAdicional') campoAdicional: ElementRef;
  @ViewChild('campoDefault') campoDefault: ElementRef;
  @Output() emitDadoCorreto = new EventEmitter<any>();

  formularioNovasInfos: correcaoInfoCliente;
  informacoesCliente: string[];
  informacaoIncorreta: string;
  infoIncorretaCampoAdicional: string;

  limiteCampoAtingido: boolean;
  emptySelect: any;
  emptySelectAdicional: any;
  selecLabelError: any;
  labelStyle: any 

  formInfosIncorretas: FormGroup = new FormBuilder().group({
    nome: ['', [Validators.required]],
    dataNascimento: ['', [maioridadeValidator()]]
  });

  constructor(private selectorStyle:GetSelectFielStyle){}

  ngOnInit(): void {
    this.labelStyle = this.selectorStyle.getStyle();
    this.informacoesCliente = ['Nome completo', 'Data de Nascimento'];
  }

  get form(): { [key: string]: AbstractControl; } {
    return this.formInfosIncorretas.controls;
  }

  adicionaNovoCampo(event:any): void {
    if(Validator.validaEventKeyPressClick(event)){
      if (this.campoDefault.nativeElement.style.display === 'block' && this.campoAdicional.nativeElement.style.display === 'none') {
        this.campoAdicional.nativeElement.style.display = 'block';
      } if (this.campoDefault.nativeElement.style.display === 'none') {
        this.campoDefault.nativeElement.style.display = 'block';
      }
      this.limiteCampoAtingido = this.campoDefault.nativeElement.style.display === 'block' && this.campoAdicional.nativeElement.style.display === 'block';
    }
  }

  removeCampo(campo: string, campoValue: string): void {
    switch (campo) {
      case 'default':
        this.campoDefault.nativeElement.style.display = 'none';
        this.informacaoIncorreta = ''
        break;
      case 'campoAdicional':
        this.campoAdicional.nativeElement.style.display = 'none';
        this.infoIncorretaCampoAdicional = ''
        break;
    }
    this.limiteCampoAtingido = this.campoDefault.nativeElement.style.display === 'block' && this.campoAdicional.nativeElement.style.display === 'block';
    this.verificaDadoRemovido(campoValue);
    this.enviaDadoCorreto();
  }

  verificaDadoRemovido(campoValue: string) {
    if (campoValue === 'Nome completo') {
      this.formInfosIncorretas.controls['nome'].setValue('');
    } else {
      this.formInfosIncorretas.controls['dataNascimento'].setValue('');
    }
  }

  enviaDadoCorreto(): void {
    this.formularioNovasInfos = {
      nome: this.formInfosIncorretas.controls['nome'].value,
      dataNascimento: this.formInfosIncorretas.controls['dataNascimento'].value
    };
    this.emitDadoCorreto.emit(this.formularioNovasInfos);
  };

  capturaInfoSelecionada(campo: string, infoSelecionada: string) {
    this.setValueCampoSelecionado(campo, infoSelecionada)
  }

  setValueCampoSelecionado(campo: string, info: string) {
    switch (campo) {
      case 'default':
        this.informacaoIncorreta = info;
        break;
      case 'adicional':
        this.infoIncorretaCampoAdicional = info;
        break;
    }
  }

  verificaSelectVazio(e: any, campo: string) {
    EventEmitterService.get('emissorValue').subscribe((valor) => {
      if (campo === 'default') {
        this.emptySelectAdicional = false;
        this.emptySelect = valor;
        { this.emptySelect ? this.emptySelect = false : this.emptySelect = true };
      }
      if (campo === 'adicional') {
        this.emptySelect = false;
        this.emptySelectAdicional = valor;
        { this.emptySelectAdicional ? this.emptySelectAdicional = false : this.emptySelectAdicional = true };
      }
    });
  }
}

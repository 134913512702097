import { ItensMenu } from './../../../core/models/menuServicoItens';
import { MenuItens } from '../../../core/models/menuServicoItens';
import { inject, Injectable } from '@angular/core';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { Router } from '@angular/router';
import { TabService } from '../tab/tab.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuServicoService {
  private tokenService = inject(TokenStorageService);
  private tabService = inject(TabService);
  private route = inject(Router);
  private token: string | null = this.tokenService.getToken();
  public url = environment.urlAGV;
  private router: Router;

  checkToken(): boolean {
    if (this.tokenService.getToken()) {
      this.token = this.tokenService.getToken();
      return true;
    } else {
      return false;
    }
  }

  redirecionaLinkExterno(link: string): void {
    if (this.checkToken())
      window.open(this.url + `?token=${this.token}&servico=${link}`, '_blank');
    else window.open(`${this.url}${link}`);
  }

  public getInfoMenuItens(): MenuItens {
    const stringItemMenu: ItensMenu[] = [
      {
        imagem: 'icn_rep_legal',
        alt_img: 'clique aqui para cadastrar o representante legal da conta',
        title_img: '',
        nome: 'Cadastro representante legal',
        link: '/representante-legal/identificacao',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'representante-legal');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_acompanhe_solicitacao',
        alt_img: 'clique aqui para acompanhar sua solicitação',
        title_img: '',
        nome: 'Acompanhe sua solicitação',
        link: 'minhas-solicitacoes',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'minhas-solicitacoes');
          const element = document.getElementById('minhas-solicitacoes');
          if (element) {
            const offset = element.offsetTop - 50;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          }
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_parcela_mono',
        alt_img: 'clique aqui pra acessar o serviço de parcelamento',
        title_img: '',
        nome: 'Meus parcelamentos',
        link: 'meus-parcelamentos',
        interno: true,
        acao: () => {
          const element = document.getElementById('parcel-debts');
          if (element) {
            const offset = element.offsetTop - 50;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          }
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_pag_informado',
        alt_img: 'clique aqui para acessar os erviço de pagamento informado',
        title_img: '',
        nome: 'Informar pagamento',
        link: 'fatura-completa',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'fatura-completa');
          const element = document.getElementById('confirma-pagamento');
          if (element) {
            const offset = element.offsetTop - 50;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          } else {
            this.scrollToLogin();
          }
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_reparcelamento',
        alt_img: 'clique aqui para acessar o serviço de Reparcelamento',
        title_img: '',
        nome: 'Reparcelamento',
        link: 'meus-parcelamentos',
        interno: true,
        acao: () => {
          const element = document.getElementById('parcelamentos-painel');
          if (element) {
            const offset = element.offsetTop - 50;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          } else {
            this.scrollToLogin();
          }
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_segunda_via',
        alt_img: 'clique aqui para acessar o serviço da segunda via de fatura',
        title_img: '',
        nome: 'Segunda via de fatura',
        link: 'fatura-completa',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'fatura-completa');
          const element = document.getElementById('consumo-pagamento');
          if (element) {
            const offset = element.offsetTop - 50;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          } else {
            this.scrollToLogin();
          }
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_agencia_mais_perto',
        alt_img:
          'clique aqui para ver as perguntas mais frequentes de nossos usurios',
        title_img: '',
        nome: 'Perguntas frequentes',
        link: 'fatura-completa',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'fatura-completa');
          const element = document.getElementById('perguntas-frequentes');
          if (element) {
            const offset = element.offsetTop - 50;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          }
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_segundavia_email',
        alt_img:
          'clique aqui para acessar o serviço de segunda via de fatura por e-mail',
        title_img: '',
        nome: 'Segunda via de fatura por e-mail',
        link: 'fatura-completa',
        interno: true,
        acao: (link = '') => {
          localStorage.setItem('pageReturn', 'fatura-completa');
          const element = document.getElementById('consumo-pagamento');
          if (element) {
            const offset = element.offsetTop - 50;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          } else {
            this.scrollToLogin();
          }
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_codigo_barras',
        alt_img:
          'clique aqui para acessar o serviço de Exibir código de barras de faturas/parcelas',
        title_img: '',
        nome: 'Exibir código de barras de faturas/parcelas',
        link: 'fatura-completa',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'fatura-completa');
          const element = document.getElementById('consumo-pagamento');
          if (element) {
            const offset = element.offsetTop - 50;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          } else {
            this.scrollToLogin();
          }
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_historico_consumo',
        alt_img: 'clique aqui para acessar o serviço de histórico de consumo',
        title_img: '',
        nome: 'Histórico de consumo',
        link: 'fatura-completa',
        interno: true,
        acao: () => {
          if (this.checkToken()) {
            localStorage.setItem('pageReturn', 'fatura-completa');
            const element = document.getElementById('consumo-pagamento');
            if (element) {
              const offset = element.offsetTop - 50;
              window.scrollTo({ top: offset, behavior: 'smooth' });
            }
          } else {
            this.tabService.changeTab(1);
            setTimeout(() => {
              this.scrollToLogin();
            }, 400);
          }
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_atualizacao_cadastral',
        alt_img: 'clique aqui para atualizar seus dados',
        title_img: '',
        nome: 'Atualização cadastral',
        link: 'atualizacao-cadastral',
        interno: true,
        acao: () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_troca_titular',
        alt_img: 'Aqui você pode acessar os serviços de troca de titularidade.',
        title_img: '',
        nome: 'Transferência de titularidade',
        link: 'troca-titularidade/identificacao',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'troca-titularidade');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: false,
      },
      {
        imagem: 'icn_pla',
        alt_img: 'clique aqui para solicitar a Primeira ligação de água e esgoto',
        title_img: '',
        nome: 'Pedido de ligação de água.',
        link: 'primeira-ligacao/questionario',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'primeira-ligacao/questionario');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_conserto',
        alt_img: 'Clique aqui para solicitar um conserto e manutenção',
        title_img: '',
        nome: 'Consertos e manutenção (lacre, registro, hidrômetro e cavalete)',
        link: 'conserto',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'consertos');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_qualidadeAgua_cor',
        alt_img:
          'clique aqui para acessar o serviço de Qualidade de água – cor, gosto, cheiro',
        title_img: '',
        nome: 'Qualidade de água – cor, gosto, cheiro',
        link: 'qualidade-agua',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_agencia_mais_perto',
        alt_img: 'clique aqui para localizar a agencia mais próxima de você',
        title_img: '',
        nome: 'Procure uma agência mais próxima de você',
        link: 'canais-atendimento',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_mais_servicos',
        alt_img:
          'clique aqui para encontrar mais serviços que prestamos a vocês',
        title_img: '',
        nome: 'Encontre mais serviços que prestamos a você',
        link: 'canais-atendimento',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_conserto_cavalete',
        alt_img: 'clique aqui para acessar o serviço de cavalete vazando',
        title_img: '',
        nome: 'Cavalete vazando',
        link: 'conserto',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_des_esgoto',
        alt_img: 'clique aqui caso queria desobstruir ramal do esgoto',
        title_img: '',
        nome: 'Desobstruir ramal de esgoto',
        link: 'vazamentos',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_enc_contratual',
        alt_img: 'clique aqui para acessar o serviço encerramento contratual',
        title_img: '',
        nome: 'Encerramento contratual',
        link: '/encerramento-contratual/servico',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'encerramento-contratual');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_comunica_falta',
        alt_img: 'clique aqui para acessar o serviço estou sem agua.',
        title_img: '',
        nome: 'Falta de água',
        link: '/falta-agua',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'falta-agua');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_hidrometro',
        alt_img:
          'aqui você pode acessar os serviços se o Hidrometro estiver quebrado, vazando, embaçado, invertido, parado.',
        title_img: '',
        nome: 'Hidrômetro quebrado, vazando, embaçado, invertido, parado.',
        link: 'conserto',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_hidrometro_desaparecido',
        alt_img:
          'aqui você pode acessar o serviço para instalar o hidômetro,ou se ele está desaparecido/furtado',
        title_img: '',
        nome: 'Instalar hidrômetro desaparecido/furtado',
        link: 'conserto',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_instalar_lacre',
        alt_img: 'clique aqui para acessar o serviço de instalar lacres',
        title_img: '',
        nome: 'Instalar lacre diversos',
        link: 'conserto',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_pressao_agua',
        alt_img:
          'clique aqui para acessar o serviço de pressão de agua',
        title_img: '',
        nome: 'Pressão de água',
        link: '/pressao-agua',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'pressao-agua');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_pouca_presao',
        alt_img:
          'clique aqui para acessar o serviço de pouca pressão da agua geral',
        title_img: '',
        nome: 'Pouca pressão de água geral',
        link: '/pressao-agua',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'pressao-agua');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_pressao_local',
        alt_img:
          'clique aqui para acessar o serviço de pouca pressão da agua local',
        title_img: '',
        nome: 'Pouca pressão de água local',
        link: '/pressao-agua',
        interno: true,
        acao: () => {
          localStorage.setItem('pageReturn', 'pressao-agua');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_registro_cavalete',
        alt_img: 'clique aqui para acessar o serviço de Registro do cavalete',
        title_img: '',
        nome: 'Registro do cavalete',
        link: 'conserto',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_religar_agua',
        alt_img: 'clique aqui para acessar o serviço de Religar a água',
        title_img: '',
        nome: 'Religar água',
        link: 'religar-agua',
        interno: true,
        acao: () => {},
        pf: true,
        pj: false,
      },
      {
        imagem: 'icn_desligamento_temporario',
        alt_img:
          'clique aqui para acessar o serviço de desligamento temporário',
        title_img: '',
        nome: 'Desligamento temporário',
        link: 'desligamento-temporario',
        interno: true,
        acao: () => {},
        pf: true,
        pj: false,
      },
      {
        imagem: 'icn_vazamento',
        alt_img: 'clique aqui para acessar o serviço de vazamento de água',
        title_img: '',
        nome: 'Vazamento de água',
        link: 'vazamentos',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_alterar_dia',
        alt_img:
          'clique aqui para acessar o serviço de Alterar dia de vencimentop da fatura',
        title_img: '',
        nome: 'Alterar dia de vencimento da fatura',
        link: 'alterar-vencimento',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_alterar_endereco_fatura',
        alt_img:
          'clique aqui para acessar o serviço de Alterar endereço de entrega das faturas e endereço para entrega de correspondências ',
        title_img: '',
        nome: 'Alterar endereço de entrega das faturas e endereço para entrega de correspondências',
        link: 'alterar-endereco/selecao-servico',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_verificar_proximas_leituras',
        alt_img:
          'clique aqui para acessar o serviço de Verificação de data das próximas faturas',
        title_img: '',
        nome: 'Verificação de data das próximas leituras',
        link: 'proximas-leituras/selecao-fornecimento',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_guia_servicos',
        alt_img: 'clique aqui para acessar o guia de serviços',
        title_img: '',
        nome: 'Guia de serviços',
        link: 'guia-de-servicos',
        interno: true,
        acao: () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_tabela_de_tarifas',
        alt_img: 'clique aqui para acessar o serviço da tabela de tarifas',
        title_img: '',
        nome: 'Tabela de tarifas',
        link: 'tarifas',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_preco_prazo',
        alt_img: ' clique aqui para Consultar o preço e prazo de cada serviço',
        title_img: '',
        nome: 'Consulta de preços e prazos para cada serviço',
        link: 'preco-prazo/consulta',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_consulta_agencias',
        alt_img:
          'clique aqui para acessar o serviço de consulta de agências de atendimento',
        title_img: '',
        nome: 'Consulta de agências de atendimento',
        link: 'canais-atendimento',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_consulta_grandes_consumidores',
        alt_img:
          'clique aqui para acessar o serviço de Consulta de agências de atendimento para grande consumidores',
        title_img: '',
        nome: 'Consulta de agências de atendimento para grandes consumidores',
        link: 'canais-atendimento',
        interno: true,
        acao: () => {},
        pf: true,
        pj: true,
      },
    ];
    const menuServico = new MenuItens(stringItemMenu);

    return menuServico;
  }

  scrollToLogin() {
    const element = document.getElementById('login-component');
    if (element) {
      const offset = element.offsetTop - 50;
      window.scrollTo({ top: offset, behavior: 'smooth' });
    }
  }
}

import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, type: string = 'url'): any {
    switch (type) {
      case 'url':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      default:
        throw new Error(`Tipo de dado desconhecido: ${type}`);
    }
  }
}

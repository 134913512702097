import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-login-mensagem',
  templateUrl: './login-mensagem.component.html',
  styleUrls: ['./login-mensagem.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginMensagemComponent implements OnInit {

  @Input() mensagemErro: string = ''
  constructor() { }

  ngOnInit() {
  }

}

<div class="mensagem-erro-login__container">
  <div class="mensagem-erro-login__container__content">
    <div class="mensagem-erro-login__container__content__header">
      <agv-icon
        id="icone-erro"
        role="img"
        title="Ícone exclamação de erro"
        [name]="'icn_erro94'"
      ></agv-icon>
      <p
        id="conteudo-mensagem"
        class="conteudo-mensagem"
        [innerHTML]="mensagemErro"
      ></p>
    </div>
    <div class="mensagem-erro-login__container__content__actions">
      <button
        role="button"
        id="login-component"
        mat-stroked-button
        color="primary"
        tabindex="0"
        aria-label="Já tenho cadastro, mas esqueci a senha"
        [routerLink]="['/recuperar-senha']"
      >
        <span> Já tenho cadastro, </span><span> mas esqueci a senha</span>
      </button>
      <button
        color="primary"
        role="button"
        id="login-component"
        mat-stroked-button
        tabindex="0"
        aria-label="Não tenho cadastro. Meu primeiro acesso"
        [routerLink]="['/cadastrar']"
      >
        <span>Não tenho cadastro. </span><span> Meu primeiro acesso</span>
      </button>
    </div>
  </div>
</div>

export class Responsavel {
  tipo?: string;
  nome: string;
  sobrenome: string;
  celular: string;
  solicitante: string;
  email?: string;
  telefone?: string;

  constructor() {
    this.tipo = '';
    this.nome = '';
    this.sobrenome = ''
    this.celular = '';
    this.solicitante = '';
    this.email = '';
    this.telefone = '';
  }
}

import { HttpResponseBase } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CheckToken, DadosCadastroCliente, DadosReenvioToken, ReqCheckToken, ReqCadastraSenha, Cliente, ResponseProcotolo, BuscaEmail, ClienteCompleto } from 'src/app/core/models/service/cadastroCliente';


export const guardaCpf = createAction(
  `[CPF] guardar cpf`,
  props<{ cpf: string }>()
);

export const carregaEmail = createAction(
  `[Email] carregar email`,
  props<{ cpf: string, recaptcha: string }>()
);

export const sucessoCarregaEmail = createAction(
  `[Email] sucesso ao carregar email`,
  props<{ dados: BuscaEmail | null, status: number}>()
);

export const erroCarregarEmail = createAction(
  `[Email] falhou ao carregar email`,
  props<{ error: any }>()
);

export const checkToken = createAction(
  `[Token] checar token`,
  props<{ req: ReqCheckToken }>()
);

export const sucessoCheckToken = createAction(
  `[Token] sucesso ao checar token`,
  props<{ dados: CheckToken }>()
);

export const erroCheckToken = createAction(
  `[Token] falhou ao checar token`,
  props<{ error: any }>()
);

export const reenviaToken = createAction(
  `[Token] reenvia token`,
  props<{ cpf: string, recaptcha: string }>()
);

export const sucessoReenviarToken = createAction(
  `[Token] sucesso ao reenviar token`,
  props<{ dados: DadosReenvioToken }>()
);

export const erroReenviarToken = createAction(
  `[Token] falhou ao reenviar token`,
  props<{ error: any }>()
);

export const carregaDadosCliente = createAction(
  `[Dados] carregar Email`,
  props<{ cpf: string }>()
);

export const sucessoCarregarDadosCliente = createAction(
  `[Dados] sucesso ao carregar dados cliente`,
  props<{ dados: DadosCadastroCliente }>()
);

export const erroCarregarDadosCliente = createAction(
  `[Email] falhou ao carregar dados cliente`,
  props<{ error: any }>()
);
export const cadastraSenha = createAction(
  `[Senha] cadastrar senha`,
  props<{ req : ReqCadastraSenha }>()
);

export const sucessoCadastraSenha = createAction(
  `[Senha] sucesso ao cadastrar senha`
);

export const erroCadastraSenha = createAction(
  `[Senha] falhou ao cadastrar senha`,
  props<{ error: any }>()
);

export const confirmaDadosCliente = createAction(
  `[Dados] confirma dados`,
  props<{ clienteDados: Cliente }>()
);

export const sucessoConfirmaDadosCliente = createAction(
  `[Dados] sucesso ao confirmar dados cliente`,
  props<{ protocolo: ResponseProcotolo }>()
);

export const erroConfirmaDadosCliente = createAction(
  `[Email] falhou ao confirmar dados cliente`,
  props<{ error: any }>()
);

export const cadastroClienteNeta = createAction(
  '[Dados] cadastro cliente',
  props<{clienteDadosCompleto: ClienteCompleto}>()
)

export const sucessoCadastroClienteNeta = createAction(
  `[Dados] sucesso ao cadastrar cliente`,
  props<{ protocolo: ResponseProcotolo }>()
);

export const erroCadastroClienteNeta = createAction(
  `[Dados] falhou ao cadastrar cliente`,
  props<{ error: any }>()
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  atualizaFormularioCorrespondencia,
  atualizaFormularioImovel,
  atualizaFormularioPLA,
  atualizaResponsavel,
  deletaFormularioPLA,
  getFormularioPla,
  sucessoCarregarFormulario,
} from './primeira-ligacao.actions';
import {
  catchError,
  from,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { FormularioPLAState } from './primeira-ligacao.reducers';
import { erroCarregarDadosCliente } from '../cadastro/cadastro-cliente.actions';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { selectFormularioPLA } from './primeira-ligacao.selectors';

@Injectable({
  providedIn: 'root',
})
export class PrimeiraLigacaoEffects {
  constructor(
    private _acoes$: Actions,
    private _store: Store,
    private storageService: TokenStorageService
  ) {}

  carregarFormularios$ = createEffect(() =>
    this._acoes$.pipe(
      ofType(getFormularioPla),
      switchMap(() =>
        from(this.storageService.carregaFormularioPLA()).pipe(
          map((formularios: FormularioPLAState) => {
            return sucessoCarregarFormulario({ formularios: formularios });
          }),
          catchError((error) => of(erroCarregarDadosCliente({ error })))
        )
      )
    )
  );

  salvaFormularios$ = createEffect(
    () =>
      this._acoes$.pipe(
        ofType(
          atualizaFormularioCorrespondencia,
          atualizaFormularioImovel,
          atualizaResponsavel,
          atualizaFormularioPLA
        ),
        withLatestFrom(this._store.select(selectFormularioPLA)),
        switchMap(([action, form]) => {
          return from(this.storageService.salvaFormularioPLA(form));
        })
      ),
    { dispatch: false }
  );

  deletaFormulario$ = createEffect(
    () =>
      this._acoes$.pipe(
        ofType(deletaFormularioPLA),
        tap(() => {
          this.storageService.removeFormularioPLA();
        })
      ),
    { dispatch: false }
  );
}
    
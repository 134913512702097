import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'agv-input-email',
  template: `
    <div
      role="group"
      [id]="id + '__input-email'"
      class="input-email"
      [ngClass]="{ disabled: blocked }"
    >
      <label class="input-email__label" [for]="id + '__input-email__text'">{{
        label
      }}</label>
      <input
        #inputEmail
        [id]="id + '__input-email__text'"
        [placeholder]="placeholder"
        class="input-email__input"
        (keyup)="changeValor()"
        (keydown)="changeValor()"
        (keypress)="changeValor()"
        (blur)="changeValor()"
        [disabled]="blocked"
        type="email"
      />
    </div>
    <ng-content></ng-content>
  `,
  styles: [
    `
      .input-email {
        display: flex;
        height: 65px;
        width: 100%;
        position: relative;
        background-color: var(--color-normal-white);
        border: 1px solid var(--color-gray-always-weak);
        border-radius: 4px;
        padding: 10px 22px 10px 22px;

        &__label {
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.25rem;
          position: absolute;
          top: 6px;
          color: var(--color-normal-grey);
          display: flex;
          width: calc(100% - 44px);
          height: 1.25rem;
        }

        &__input {
          font-weight: 400;
          font-size: 0.875rem !important;
          line-height: 1.25rem;
          color: var(--color-black);
          background: transparent;
          border: none;
          width: calc(100% - 44px);
          height: 20px;
          bottom: 13px;
          position: absolute;
          max-width: 431px;
        }

        &.disabled {
          cursor: not-allowed;
          background-color: var(--color-gray-weak);

          input,
          label {
            cursor: not-allowed;
          }
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEmailComponent),
      multi: true,
    },
  ],
})
export class InputEmailComponent implements OnInit, OnChanges {
  @Output() sendValor = new EventEmitter<any>();
  @ViewChild('inputEmail') inputEmail: ElementRef;
  @Input() id: string = '';
  @Input() value: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() blocked: boolean = false;

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue !== changes['value']?.previousValue) {
      if (this.inputEmail && this.inputEmail.nativeElement)
        this.inputEmail.nativeElement.value = changes['value']?.currentValue;
    }
  }

  changeValor() {
    this.value = this.inputEmail.nativeElement.value;
    this.onChange(this.value);
    this.sendValor.emit(this.inputEmail.nativeElement.value);
  }

  writeValue(value: any): void {
    this.value = value || '';
    if (this.inputEmail && this.inputEmail.nativeElement) {
      this.inputEmail.nativeElement.value = this.value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.blocked = isDisabled;
  }
}

<div class="card-fatura-atual">
  <div class="card-fatura-atual__content">
    <span class="titulo">Fatura Atual</span>
    <span class="mes-referente"
      >Referente a {{ faturaAtual?.dataEmissao | date : "MMM/yyyy" }}</span
    >
    <span class="valor">{{ faturaAtual?.valor | currency : "BRL" }}</span>
    <span class="consumo">Consumo {{ faturaAtual?.consumo }} m³</span>
    <span class="vencimento"
      >Vencimento {{ faturaAtual?.dataVencimento | date : "dd/MM/yyyy" }}</span
    >
    <span
      [ngClass]="'status ' + getStatus(faturaAtual?.situacaoDaFatura ?? '')"
    >
      {{ capitalize(faturaAtual?.situacaoDaFatura ?? "") }}
    </span>
    <div class="card-fatura-atual__content__actions">
      <button
      [disabled]="baixarDisabled(faturaAtual)"
      [ngClass]="{
        disabled: baixarDisabled(faturaAtual)
      }"
        (click)="baixarFatura()"
        tabindex="0"
        class="detalhes"
      >
        <agv-icon
          [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }"
          [name]="'icn-download'"
          title="Ícone de visualizar"
        ></agv-icon
        >Baixar
      </button>
      <button
        [disabled]="isButtonDisabled()"
        [ngClass]="{
          disabled: isButtonDisabled()
        }"
        [cdkCopyToClipboard]="faturaAtual?.linhaDigitavel ?? ''"
        (cdkCopyToClipboardCopied)="
          copyToClipboard(faturaAtual?.linhaDigitavel ?? '')
        "
        (click)="$event.stopPropagation()"
        tabindex="0"
        class="detalhes"
      >
        <agv-icon
          [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }"
          [name]="'icn_copy'"
          title="Ícone de baixar"
        ></agv-icon
        >{{ botaoCopiado ? "Copiado" : "Copiar código" }}
      </button>
    </div>
  </div>
</div>

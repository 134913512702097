import { erroReenviarToken, reenviaToken, sucessoReenviarToken } from 'src/app/core/store/cadastro/cadastro-cliente.actions';
import { Component,OnDestroy,OnInit,Output,EventEmitter,ViewChild,Input,ElementRef } from '@angular/core';
import { CheckToken, ReqCheckToken } from 'src/app/core/models/service/cadastroCliente';
import { ICadastroState } from 'src/app/core/store/cadastro/cadastro-cliente.reducer';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { MensagemComponent } from './../mensagem-erro/mensagem-erro.component';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { Regex } from '../../utils/regex';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-verificacao-usuario',
  templateUrl: './verificacao-usuario.component.html',
  styleUrls: ['./verificacao-usuario.component.scss'],
})
export class VerificacaoUsuarioComponent implements OnInit, OnDestroy {
  // String
  codigoConfirmacao: string = '';
  documento: string | null = '';
  mensagemErro: string;
  cpf: string | null;
  counter: string;

  // Boolean
  codVerificaoValidado: boolean = false;
  errovalidacao: boolean = false;
  showSendAgain: boolean = false;
  isMobile: boolean = false;
  
  // Inputs - String
  @Input() repassaCpfCarregado: string = '';
  @Input() mensagem: string | SafeHtml = '';
  @Input() emailCliente: string = '';
  @Input() origemFluxo: string = '';
  @Input() nome: string = '';

  // Inputs - Boolean
  @Input() fluxoRecSenha: boolean = false;
  @Input() showInputCpf: boolean = true;
  @Input() cpfValidado: boolean = false;
  @Input() showVoltar: boolean = false;
  @Input() recuperar: boolean = false;

  // Actions
  @Output() redicionaVerificaocao: EventEmitter<any> = new EventEmitter<CheckToken>();
  @Output() limpaDadosCliente: EventEmitter<any> = new EventEmitter<string>();
  @Output() enviaEmail: EventEmitter<any> = new EventEmitter<string>();
  @Output() setVoltar: EventEmitter<any> = new EventEmitter<boolean>();
  
  // Views
  @ViewChild(MensagemComponent, { static: false }) mensagemComponent: MensagemComponent;
  @ViewChild('titulo') tituloElement: ElementRef;
  
  // Outros
  destroyed$ = new Subject<boolean>();
  otpInput: any = {};
  timeoutHandle: any;

  ngOnInit(): void {
    this.documento = this.route.snapshot.paramMap.get('cpf');

    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
    window.onresize = () => (this.isMobile = window.innerWidth <= 768);

    if (this.isMobile) {
      this.otpInput = { border: '0.66px solid #DADADA' };
    } else {
      this.otpInput = { border: '1px solid #E3E3E3' };
    }

    if (this.origemFluxo == 'pla') {
      this.documento = this.repassaCpfCarregado;
      this.cpfValidado = true;
      this.countdown(2, 0);
    }
  }

  constructor(
    private store: Store<{ cadastro: ICadastroState }>,
    private tokenService: TokenStorageService,
    private route: ActivatedRoute,
    private updates$: Actions,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutHandle);
  }

  limparCampos(): void {
    if (this.origemFluxo == 'pla') {
      this.cpfValidado = false;
      this.showVoltar = false;
    } else if (this.origemFluxo == 'primeiro-acesso' || this.origemFluxo == 'recuperar-senha') {
      this.cpf = '';
      this.limpaDadosCliente.emit();
    } else {
      location.reload();
    }
  }

  countdown(minutes: number, seconds: number) {
    const tick = () => {
      this.counter =
        minutes.toString() + ':' + (seconds < 10 ? '0' : '') + String(seconds);
      seconds--;
      if (seconds >= 0) {
        this.timeoutHandle = setTimeout(tick, 1000);
      } else if (seconds === -1 && minutes === 0) {
        this.showSendAgain = true;
      } else {
        if (minutes >= 1) {
          setTimeout(() => {
            this.countdown(minutes - 1, 59);
          }, 1000);
        }
      }
    };
    tick();
  }

  cpfMask() {
    return this.documento;
  }

  handleChange(e: string) {
    this.codigoConfirmacao = e;
  }

  validaCpf(cpf: string): void {
    this.documento = cpf;
    localStorage.setItem('cpf', Regex.removeMaskCpf(this.documento));
    this.countdown(2, 0);
    if (this.origemFluxo === 'pla') {
      this.showVoltar = true;
      return
    } else if (this.origemFluxo === 'primeiro-acesso' || this.origemFluxo === 'recuperar-senha') {
      setTimeout(() => {
        this.enviaEmail.emit(localStorage.getItem('cpf'));
      }, 0);
      return
    } 
  }

  validaCodVerificacao(): void {
    let req: ReqCheckToken = new ReqCheckToken();
    this.cpf = localStorage.getItem('cpf');
    if (this.cpf != null) req.cpf = Regex.removeMaskCpf(this.cpf);
    req.token = this.codigoConfirmacao;
    this.redicionaVerificaocao.emit(req);
  }

  getRecaptcha() {
    let elemento_recaptcha: any = document.getElementsByClassName(
      'g-recaptcha-response'
    );
    this.mensagem = '';
    let element: any;
    if (elemento_recaptcha) {
      for (let index = 0; index < elemento_recaptcha.length; index++) {
        if (elemento_recaptcha[index].value)
          element = elemento_recaptcha[index];
      }
    }
    return element?.value;
  }

  getDadosClienteReenvioToken(cpf: string): void {
    const recaptcha = this.getRecaptcha();
    this.mensagemErro = '';
    this.store.dispatch(reenviaToken({ cpf: cpf, recaptcha }));
    this.updates$
      .pipe(ofType(sucessoReenviarToken), takeUntil(this.destroyed$))
      .subscribe({
        next: (action) => { },
        error: (erro) => {
          console.log(erro);
        },
      });

    this.updates$
      .pipe(ofType(erroReenviarToken), takeUntil(this.destroyed$))
      .subscribe({
        next: (action) => {
          if (action.error.status === 204) {
            this.mensagemErro = `Dados do usuário não encontrado.`;
          } else if (action.error.status === 400) {
            this.mensagemErro = `Solicitação enviada invalida.`;
          } else if (action.error.status === 406) {
            this.mensagemErro = `Valor retornado não é valido para valores pré-definidos.`;
          } else if (action.error.status === 408) {
            this.mensagemErro = `Erro de TIMEOUT.`;
          } else if (action.error.status === 500) {
            this.mensagemErro = `Problema no servidor.`;
          } else if (action.error.status === 530) {
            this.mensagemErro = `Dados de autenticação estão incorretos.`;
          } else if (action.error.status === 550) {
            this.mensagemErro = `Envio de e-mail negado.`;
          }
        },
      });
  }

  reenviarCodigo() {
    this.showSendAgain = false;
    this.countdown(2, 0);
    const cpf = localStorage.getItem('cpf');
    if (cpf) {
      this.getDadosClienteReenvioToken(Regex.removeMaskCpf(cpf));
    }
  }

  voltar() {
    this.setVoltar.emit();
  }

  acessarAtualziacao() {
    this.router.navigate(['/atualizacao-cadastral'])
  }

  keyAcessarAtualziacao(e: any) {
    let key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.acessarAtualziacao();
    }
  }

  redirectCanais() {
    window.open(environment.urlAGV + `?servico=canais-atendimento`, '_blank');
  }
}

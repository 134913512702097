import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NoticiaService {
  private jsonUrl = `${environment.urlNoticia}home-noticia/ultimas-noticias.json`;

  constructor(private http: HttpClient) {}

  getNoticias(): Observable<any> {
    return this.http.get<any>(this.jsonUrl);
  }
}

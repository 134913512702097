import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload-arquivo',
  templateUrl: './upload-arquivo.component.html',
  styleUrls: ['./upload-arquivo.component.scss']
})
export class UploadArquivoComponent {

  @Input() label: string;
  @Input() showBtnInfo: boolean;

  @Output() clickInfo = new EventEmitter();
  @Output() chooseFile = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  imgSrc: string;
  fileName: string;
  messageError: string;
  invalid: boolean;

  openFolder() {
    this.fileInput.nativeElement.click()
  }

  selectFile(event: any) {
    const file: File = event.target.files[0];
    if (file) {

      if (file.size > 50 * 1024 * 1024) {
        this.messageError = 'Arquivo possui mais de 50MB. Escolha um arquivo menor, no formato .jpg, .jpeg, ou .png. e tente novamente.'
        this.invalid = true;
      } else {
        this.invalid = false;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imgSrc = reader.result as string
        }
        this.fileName = file.name;
        const data = {label: this.label, file: file}
        this.chooseFile.emit(data);
      }
    }
  }

  removeFile() {
    this.fileName = '';
    this.imgSrc = '';
    this.fileInput.nativeElement.value = '';
    this.chooseFile.emit(null);
  }

  get imgNameSuffix() {
    return this.invalid ? '-error' : '';
  }
}

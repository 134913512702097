import { PodeAcordo } from './podeAcordo';
import { Acordo } from './acordo';
import { Fatura } from './faturaCompleta';

export class Fornecimento {
  default: boolean;
  codigo: string;
  cartao: boolean;
  cartaoMinimo: number;
  cartaoMaximo: number;
  cartaoVenc: number;
  status: string;
  proximoVencimento: Date | undefined;
  endereco: string;
  enderecoFornecimento: string;
  bairro: string;
  numero: string;
  uf: string;
  statusFatura: string[];
  faturas: Fatura[];
  acordos: Acordo[];
  temAcordo: boolean;
  podeAcordo: PodeAcordo;
  cidade?: string;
  dataFaturas: [];
  dataFornecimento: number;
  codigoMunicipio: number;
  produto: string;
  codigoTipoFornecimento: number;
  tipoFornecimento: string;
  tipoMercado: string;
  categoriaUso: string;
  economias: string;
  enderecoFornecimentoCompleto: string;
  codigoPde: number;
  tipoLigacao: string;
  tipoPde: string;
  tipoEntidade: string;
  descTipoEntidade: string;
  cep: number;
  complemento: string;

  constructor() {
    this.default = false;
    this.cartao = false;
    this.cartaoMinimo = 0;
    this.cartaoMaximo = 0;
    this.cartaoVenc = 0;
    this.codigo = '';
    this.status = '';
    this.proximoVencimento = undefined;
    this.endereco = '';
    this.enderecoFornecimento = '';
    this.bairro = '';
    this.numero = '';
    this.uf = '';
    this.statusFatura = [];
    this.dataFaturas = [];
    this.faturas = [];
    this.acordos = [];
    this.temAcordo = false;
    this.podeAcordo = new PodeAcordo();
  }
}

<div id="pagto-app" class="pagto-app-container" [ngSwitch]="banco">
    <div id="pagto-app-conteudo-0" class="content" *ngSwitchCase="0">
        <div id="pagto-app-conteudo-0__mensagem-pagar-app" role="group" tabindex="0" class="row row__title">
            <img id="pagto-app-conteudo-0__mensagem-pagar-app__icn" role="img"
                src="../../../../assets/imgs/png/{{outros.titleIcnPagarApp}}.png" alt="{{outros.altIcnPagarApp}}"
                class="icn_barcode_app">
            <p class="text__title">Pague pelo aplicativo do seu banco</p>
        </div>
        <div id="pagto-app-conteudo-0__msg-escolha-banco" role="group" tabindex="0" class="row">
            <span class="text__desc">Escolha o banco de sua preferência e pague pelo código de barras.</span>
        </div>
        <div id="pagto-app-conteudo-0__steps" class="box-steps">
            <div id="pagto-app-conteudo-0__steps__1" role="group" tabindex="0" class="row row__steps">
                <span class="text__number">1</span>
                <p class="text__step">Abra o aplicativo do seu banco</p>
            </div>
            <div id="pagto-app-conteudo-0__steps__2" role="group" tabindex="0" class="row row__steps">
                <span class="text__number">2</span>
                <p class="text__step">Escolha a opção fazer o pagamento com código de barras</p>
            </div>
            <div id="pagto-app-conteudo-0__steps__3" role="group" tabindex="0" class="row row__steps">
                <span class="text__number">3</span>
                <p class="text__step">Posicione o leitor sobre o código de barras na tela</p>
            </div>
            <div id="pagto-app-conteudo-0__steps__4" role="group" tabindex="0" class="row row__steps">
                <span class="text__number">4</span>
                <p class="text__step">Verifique se as informações estão corretas</p>
            </div>
            <div id="pagto-app-conteudo-0__steps__5" role="group" tabindex="0" class="row row__steps">
                <span class="text__number">5</span>
                <p class="text__step">Efetue o pagamento</p>
            </div>
        </div>
    </div>
    <div id="pagto-app-conteudo-2" class="content" *ngSwitchCase="2">
        <div id="pagto-app-conteudo-2__mensagem-pagar-app" role="group" tabindex="0" class="content__banco">
            <agv-icon id="icn-pagar-app" role="img" 
                class="content__banco__img" [name]="'banco-pag-caixa'" 
                title="Ícone do banco caixa econômica"></agv-icon>
            <div class="content__banco__texto">
                <p class="content__banco__texto__titulo">Pague pela Caixa econômica</p>
                <p class="content__banco__texto__subtitulo">Você sera redirecionado para o aplicativo do banco para
                    realizar o pagamento</p>
                </div>
            </div>
        <div  class="duasColunas">
            <div id="pagto-app-conteudo-2__steps" class="box-steps">
                <div id="pagto-app-conteudo-2__steps__1" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">1</span>
                    <p class="text__step">Abra o aplicativo do seu banco</p>
                </div>
                <div id="pagto-app-conteudo-2__steps__2" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">2</span>
                    <p class="text__step">Faça o login na página da instituição</p>
                </div>
                <div id="pagto-app-conteudo-2__steps__3" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">3</span>
                    <p class="text__step">Escolha a opção realizar o pagamento</p>
                </div>
                <div id="pagto-app-conteudo-2__steps__4" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">4</span>
                    <p class="text__step">Posicione o leitor sobre o código de barras na tela ou digite </p>
                </div>
                <div id="pagto-app-conteudo-2__steps__5" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">5</span>
                    <p class="text__step">Verifique se as informações estão corretas e finalize o pagamento</p>
                </div>
            </div>
            <div class="agrupaBotao">
                <div id="pagto-app-conteudo-2__valor" class="valor-debito" tabindex="0">
                    <span class="valor-debito__label">Total a pagar </span>
                    <span class="valor-debito__valor">{{ valor | currency: 'BRL'}}</span>
                </div>
                <button (click)="redirecionaBanco('caixaEconomica')" id="pagto-app-conteudo-2__btn" type="button"
                class="btn-debito">Acessar o Banco</button>
            </div>
        </div>
    </div>
    <div id="pagto-app-conteudo-3" class="content" *ngSwitchCase="3">
        <div id="pagto-app-conteudo-3__mensagem-pagar-app" role="group" tabindex="0" class="content__banco">
            <agv-icon id="banco-pag-brasil" role="img" 
                class="content__banco__img" [name]="'banco-pag-brasil'" 
                title="Ícone do banco do brasil econômica"></agv-icon>
            <div class="content__banco__texto">
                <p class="content__banco__texto__titulo">Pague pelo Banco do Brasil</p>
                <p class="content__banco__texto__subtitulo">Você sera redirecionado para o aplicativo do banco para
                    realizar o pagamento</p>
                </div>
            </div>
        <div class="duasColunas">
            <div id="pagto-app-conteudo-3__steps" class="box-steps">
                <div id="pagto-app-conteudo-3__steps__1" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">1</span>
                    <p class="text__step">Abra o aplicativo do seu banco</p>
                </div>
                <div id="pagto-app-conteudo-3__steps__2" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">2</span>
                    <p class="text__step">Faça o login na página da instituição</p>
                </div>
                <div id="pagto-app-conteudo-3__steps__3" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">3</span>
                    <p class="text__step">Escolha a opção realizar o pagamento</p>
                </div>
                <div id="pagto-app-conteudo-3__steps__4" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">4</span>
                    <p class="text__step">Posicione o leitor sobre o código de barras na tela ou digite </p>
                </div>
                <div id="pagto-app-conteudo-3__steps__5" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">5</span>
                    <p class="text__step">Verifique se as informações estão corretas e finalize o pagamento</p>
                </div>
            </div>
            <div class="agrupaBotao">
                <div id="pagto-app-conteudo-3__valor" class="valor-debito" tabindex="0">
                    <span class="valor-debito__label">Total a pagar </span>
                    <span class="valor-debito__valor">{{ valor | currency: 'BRL'}}</span>
                </div>
                <button id="pagto-app-conteudo-3__btn" (click)="redirecionaBanco('bancoBrasil')" type="button"
                class="btn-debito">Acessar o Banco</button>
            </div>
        </div>
    </div>
    <div id="pagto-app-conteudo-4" class="content" *ngSwitchCase="4">
        <div id="pagto-app-conteudo-4__mensagem-pagar-app" role="group" tabindex="0" class="content__banco">
            <agv-icon id="banco-pag-brasil" role="img" 
                class="content__banco__img" [name]="'banco-pag-bradesco'" 
                title="Ícone do banco bradesco econômica"></agv-icon>
            <div class="content__banco__texto">
                <p class="content__banco__texto__titulo">Pague pelo Bradesco</p>
                <p class="content__banco__texto__subtitulo">Você sera redirecionado para o aplicativo do banco para
                    realizar o pagamento</p>
                </div>
            </div>
        <div  class="duasColunas">
            <div id="pagto-app-conteudo-4__steps" class="box-steps">
                <div id="pagto-app-conteudo-4__steps__1" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">1</span>
                    <p class="text__step">Abra o aplicativo do seu banco</p>
                </div>
                <div id="pagto-app-conteudo-4__steps__2" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">2</span>
                    <p class="text__step">Faça o login na página da instituição</p>
                </div>
                <div id="pagto-app-conteudo-4__steps__3" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">3</span>
                    <p class="text__step">Escolha a opção realizar o pagamento</p>
                </div>
                <div id="pagto-app-conteudo-4__steps__4" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">4</span>
                    <p class="text__step">Posicione o leitor sobre o código de barras na tela ou digite </p>
                </div>
                <div id="pagto-app-conteudo-4__steps__5" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">5</span>
                    <p class="text__step">Verifique se as informações estão corretas e finalize o pagamento</p>
                </div>
            </div>
            <div class="agrupaBotao">
                <div id="pagto-app-conteudo-4__valor" class="valor-debito" tabindex="0">
                    <span class="valor-debito__label">Total a pagar </span>
                    <span class="valor-debito__valor">{{ valor | currency: 'BRL'}}</span>
                </div>
                <button id="pagto-app-conteudo-4__btn" (click)="redirecionaBanco('bradesco')" type="button"
                class="btn-debito">Acessar o Banco</button>
            </div>
        </div>
    </div>
    <div id="pagto-app-conteudo-5" class="content" *ngSwitchCase="5">
        <div id="pagto-app-conteudo-5__mensagem-pagar-app" role="group" tabindex="0" class="content__banco">
            <agv-icon id="banco-pag-brasil" role="img" 
                class="content__banco__img" [name]="'banco-pag-itau'" 
                title="Ícone do banco itau econômica"></agv-icon>
            <div class="content__banco__texto">
                <p class="content__banco__texto__titulo">Pague pelo Itaú</p>
                <p class="content__banco__texto__subtitulo">Você sera redirecionado para o aplicativo do banco para
                    realizar o pagamento</p>
                </div>
            </div>
        <div class="duasColunas">
            <div id="pagto-app-conteudo-5__steps" class="box-steps">
                <div id="pagto-app-conteudo-5__steps__1" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">1</span>
                    <p class="text__step">Abra o aplicativo do seu banco</p>
                </div>
                <div id="pagto-app-conteudo-5__steps__2" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">2</span>
                    <p class="text__step">Faça o login na página da instituição</p>
                </div>
                <div id="pagto-app-conteudo-5__steps__3" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">3</span>
                    <p class="text__step">Escolha a opção realizar o pagamento</p>
                </div>
                <div id="pagto-app-conteudo-5__steps__4" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">4</span>
                    <p class="text__step">Posicione o leitor sobre o código de barras na tela ou digite </p>
                </div>
                <div id="pagto-app-conteudo-5__steps__5" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">5</span>
                    <p class="text__step">Verifique se as informações estão corretas e finalize o pagamento</p>
                </div>
            </div>
            <div class="agrupaBotao">
                <div id="pagto-app-conteudo-5__valor" class="valor-debito" tabindex="0">
                    <span class="valor-debito__label">Total a pagar </span>
                    <span class="valor-debito__valor">{{ valor | currency: 'BRL'}}</span>
                </div>
                <button id="pagto-app-conteudo-5__btn" (click)="redirecionaBanco('itau')" type="button"
                class="btn-debito">Acessar o Banco</button>
            </div>
        </div>
    </div>
    <div id="pagto-app-conteudo-6" class="content" *ngSwitchCase="6">
        <div id="pagto-app-conteudo-6__mensagem-pagar-app" role="group" tabindex="0" class="content__banco">
            <agv-icon id="banco-pag-brasil" role="img" 
                class="content__banco__img" [name]="'banco-pag-santander'" 
                title="Ícone do banco santander econômica"></agv-icon>
            <div class="content__banco__texto">
                <p class="content__banco__texto__titulo">Pague pelo Santander</p>
                <p class="content__banco__texto__subtitulo">Você sera redirecionado para o aplicativo do banco para
                    realizar o pagamento</p>
            </div>
        </div>
        <div class="duasColunas">
            <div id="pagto-app-conteudo-6__steps" class="box-steps">
                <div id="pagto-app-conteudo-6__steps__1" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">1</span>
                    <p class="text__step">Abra o aplicativo do seu banco</p>
                </div>
                <div id="pagto-app-conteudo-6__steps__2" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">2</span>
                    <p class="text__step">Faça o login na página da instituição</p>
                </div>
                <div id="pagto-app-conteudo-6__steps__3" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">3</span>
                    <p class="text__step">Escolha a opção realizar o pagamento</p>
                </div>
                <div id="pagto-app-conteudo-6__steps__4" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">4</span>
                    <p class="text__step">Posicione o leitor sobre o código de barras na tela ou digite </p>
                </div>
                <div id="pagto-app-conteudo-6__steps__5" role="group" tabindex="0" class="row row__steps">
                    <span class="text__number">5</span>
                    <p class="text__step">Verifique se as informações estão corretas e finalize o pagamento</p>
                </div>
                </div>
            <div class="agrupaBotao">
                <div id="pagto-app-conteudo-6__valor" class="valor-debito" tabindex="0">
                    <span class="valor-debito__label">Total a pagar </span>
                    <span class="valor-debito__valor">{{ valor | currency: 'BRL'}}</span>
                </div>
                <button id="pagto-app-conteudo-6__btn" (click)="redirecionaBanco('santander')" type="button"
                    class="btn-debito">Acessar o Banco</button>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.scss'],
})
export class MenuAdminComponent implements OnInit {
  url: string = window.location.pathname;
  constructor(private router: Router) {}
  raiz: string = '/admin'

  ngOnInit(): void {}

  redirect(path:string) {
    if(path !== '')
      this.router.navigate([`${this.raiz}/${path}`])
    else
      this.router.navigate([`${this.raiz}`])
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Validator } from 'src/app/shared/utils/validator';

@Component({
  selector: 'agv-input-file',
  template: `
    <div class="input-file">
      <div class="arquivo-wrapper">
        <div class="seleciona-arquivo">
          <label class="label_texto">
            {{ tituloBotaoFile }}
          </label>
          <div tabindex="0" class="button-image">
            <input
              id="fileInput"
              type="file"
              style="display: none"
              formControlName="valor"
              (change)="onFileSelected($event)"
              accept="image/jpeg, image/jpg"
            />
            <label for="fileInput" class="label_texto">{{
              textoBotaoFile
            }}</label>
          </div>
        </div>
        <div *ngIf="base64String" class="image-file">
          <img [src]="base64String" alt="Miniatura" class="image-min" />
          <agv-icon
            *ngIf="base64String"
            class="arquivo_icn"
            [name]="'icn_limpar'"
            tabindex="0"
            title="Remover documento frente"
            (click)="limparAquivo('base64String', $event)"
          ></agv-icon>
        </div>
        <div class="img-container" *ngIf="!base64String">
          <agv-icon [name]="'icn-foto'"></agv-icon>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .input-file {
        width: 100%;
        height: auto;
        max-width: 474px;
        .arquivo-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: auto;
          border: 1px solid var(--color-border-label-details);
          border-radius: 4px;
          padding: 18px 23px 18px 23px;

          .seleciona-arquivo {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;

            .label_texto {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              padding: 7px;
              cursor: pointer;
            }

            .button-image {
              border: 1px solid var(--color-border-label-details);
              padding: 2px 10px;
              border-radius: 4px;
              width: fit-content;
              cursor: pointer !important;
              transition: all 0.3s;

              .button-text {
                cursor: pointer;
              }

              &:hover {
                opacity: 0.8;
              }
            }
          }
          .image-file {
            display: flex;
            flex-direction:row;
            .image-min {
              max-width: 76px;
              max-height: 56px;
              border: 1px solid #ddd;
              margin-top: 10px;
            }
            .arquivo_icn {
              color: white;
              cursor: pointer;
              width: 20px;
              margin-bottom: 22px;
            }
          }
          .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 12px;
            width: 48px;
            height: 48px;
          }
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true,
    },
  ],
})
export class InputFileComponent implements OnInit, OnChanges {
  fileName: string | undefined;
  @Input() base64String: string | null = null;
  @Input() textoBotaoFile: string;
  @Input() tituloBotaoFile: string;
  @Output() enviaFile: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      // Verifica se é uma imagem JPEG/JPG
      if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
        const reader = new FileReader();

        reader.onload = () => {
          this.base64String = reader.result as string; // String Base64
          this.enviaFile.emit(this.base64String);
        };

        reader.onerror = (error) => {
          console.error('Erro ao ler o arquivo:', error);
        };

        reader.readAsDataURL(file);
      } else {
        alert('Por favor, selecione uma imagem no formato JPG ou JPEG.');
      }
    }
  }
  limparAquivo(tipo: string, evento: any) {
    if (Validator.validaEventKeyPressClick(evento)) {
      this.base64String = null;
    }
  }
}

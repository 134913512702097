import { TotalCalculoConta } from './totalCalculoConta';
import { CalculoConta } from './calculoConta';
import { DiscriminacaoFatura } from './discriminacaoFatura';
import { DadosFatura } from './dadosFatura';
import { DetalheFatura } from 'src/app/services/faturaServices.service';

export class Fatura {
  situacaoDaFatura: string;
  numeroDocumento: string;
  codigoPagamento: string;
  valor: number;
  consumo: number;
  dataEmissao: Date;
  dataVencimento: Date;
  codigoDeBarras: string;
  linhaDigitavel: string;
  periodoConsumo: number;
  dadosFatura: DadosFatura;
  discriminacaoFatura: DiscriminacaoFatura[];
  calculoConta: CalculoConta[];
  valorAgua: TotalCalculoConta;
  valorEsgoto: TotalCalculoConta;
  documentoId: string;
  cobrancaJuridica: boolean;
  debitoAutomatico: boolean;
  subFatura: DetalheFatura | undefined;
  condicaoFaturamento?: string;
  cartao:boolean;
  constructor() {
    this.situacaoDaFatura = '';
    this.numeroDocumento = '';
    this.codigoPagamento = '';
    this.valor = 0;
    this.consumo = 0;
    this.dataEmissao = new Date();
    this.dataVencimento = new Date();
    this.codigoDeBarras = '';
    this.linhaDigitavel = '';
    this.periodoConsumo = 0;
    this.dadosFatura = new DadosFatura();
    this.discriminacaoFatura = [];
    this.calculoConta = [];
    this.valorAgua = new TotalCalculoConta();
    this.valorEsgoto = new TotalCalculoConta();
    this.documentoId = '';
    this.cobrancaJuridica = false;
    this.debitoAutomatico = false;
    this.subFatura = undefined;
    this.condicaoFaturamento = '';
    this.cartao = false;
  }
}


export interface FaturaCnpj {
  codigoPagamento: string,
  cnpj: string,
  fornecimento: string,
  situacaoFornecimento: string,
  numeroDocumento: string,
  statusFatura: string,
  estadoSaldoPagamento: string,
  contestacao: string,
  codigoExtrato: string,
  pagamentoInformado: string,
  acordo: string,
  valor: number,
  dataVencimento: string,
  dataEmissao: string,
  codigoDeBarras: string,
  documentoId: string,
  idPartition: string,
  idPartition2: string,
  linhaDigitavel: string,
  numeroParcela: string
  cobrancaJuridica: boolean;
  subFatura: any;
}

export class FaturaCnpjClass {
  codigoPagamento: string;
  cnpj: string;
  fornecimento: string;
  situacaoFornecimento: string;
  numeroDocumento: string;
  statusFatura: string;
  estadoSaldoPagamento: string;
  contestacao: string;
  codigoExtrato: string;
  pagamentoInformado: string;
  acordo: string;
  valor: number;
  dataVencimento: string;
  dataEmissao: string;
  codigoDeBarras: string;
  documentoId: string;
  idPartition: string;
  idPartition2: string;
  linhaDigitavel: string;
  numeroParcela: string
  cobrancaJuridica: boolean;
  subFatura: any;

  constructor(){
    this.codigoPagamento = '';
    this.cnpj = '';
    this.fornecimento = '';
    this.situacaoFornecimento = '';
    this.numeroDocumento = '';
    this.statusFatura = '';
    this.estadoSaldoPagamento = '';
    this.contestacao = '';
    this.codigoExtrato = '';
    this.pagamentoInformado = '';
    this.acordo = '';
    this.valor = 0;
    this.dataVencimento = '';
    this.dataEmissao = '';
    this.codigoDeBarras = '';
    this.documentoId = '';
    this.idPartition = '';
    this.idPartition2 = '';
    this.linhaDigitavel = '';
    this.numeroParcela = '';
    this.cobrancaJuridica = false;
    this.subFatura = ''
  }
}
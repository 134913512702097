<app-acessibilidade [isHomolog]="isHomolog" [isQuality]="isQuality"></app-acessibilidade>
<div id="header-principal" role="group" class="container">
  <div id="header-principal-conteudo" role="group" fxLayout="row" fxLayoutAlign="space-between center"
    class="header__container">
    <div id="conteudo-header-logo-sabesp" tabindex="0" role="group" class="header__logo-wrapper" [routerLink]="checkToken() ? '/fatura-completa' : '/'" aria-label="Link para a página inicial">
      <div role="img" class="header__logo-wrapper__sabesp">
        <agv-icon  *ngIf="!isHomolog" role="img" id="img-logo-sabesp" class="logo-sabesp" alt="Logo da Sabesp" [name]="'logo'"></agv-icon>
        <agv-icon *ngIf="isHomolog" role="img" id="img-logo-sabesp" class="logo-sabesp" alt="Logo da Sabesp" [name]="'logo-hml'"></agv-icon>
      </div>
      <p-divider fxHide.xs layout="vertical" class="header__divider"></p-divider>
      <div id="conteudo-texto-logo" role="group" class="header__text-content">
        <h1 id="texto-descritivo-logo">
          <span class="d-desktop">Agência Virtual</span>
          <span class="d-mobile">Sabesp Mobile</span>
        </h1>
      </div>
    </div>

    <div id="conteudo-header-secoes-home" role="group" fxHide fxShow.gt-md class="header__tab_group">
      <div id="navegacao-secoes" role="tablist" mat-tab-nav-bar [tabPanel]="tabPanel">
        <span [id]="'secao-' + link" role="tab" mat-tab-link *ngFor="let link of links" (click)="clickTabs(link)"
          [active]="activeLink == link">
          {{link}}
        </span>
      </div>
      <mat-tab-nav-panel id="evidencia-secao-selecionada" #tabPanel></mat-tab-nav-panel>
    </div>

    <div id="conteudo-header-busca" role="group" fxFlex="25" fxHide fxShow.gt-md class="header__search-input">
      <mat-form-field id="campo-busca-header">
        <input id="texto-campo-busca" type="text" [(ngModel)]="termoPesquisa" (input)="filtrarServicos()" matInput
          placeholder="O que você procura?" tabindex="0" aria-label="o que você procura?">
        <mat-icon id="img-campo-busca" matSuffix>search</mat-icon>
      </mat-form-field>
      <div id="opcoes-campo-busca" *ngIf="(termoPesquisa != '' && termoPesquisa != null)"
        class="header__search-input__option">
        <ul *ngIf="(termoPesquisa != '' && termoPesquisa != null)">
          <li>
            <p class="header__search-input__option__titulo">Resultados encontrados</p>
            <p class="header__search-input__option__descricao">Foram encontrados {{servicosFiltrados.length}} resultados
              para o termo: {{termoPesquisa}}</p>
          </li>
          <li *ngFor="let servico of servicosFiltrados" (click)="$event.stopPropagation(); redirecionaServico(servico); servico.acao()">
            <a>{{ servico.nome }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex">
      <div class="notificacaoHolder" (click)="toggleDropdown()">
        <agv-icon
        class="notificacaoBtn notificationDesktop invert"
        [name]="'icon_notification'"
        title="Ícone de uma fatura"
        [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }"
      ></agv-icon>
        <agv-icon
        class="notificacaoBtn notificationMobile"
        [name]="'icon_notification'"
        title="Ícone de uma fatura"
        [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }"
      ></agv-icon>
        <div class="dropdown" *ngIf="dropdownOpen">
          <div class="dropdown-header">Notificações</div>
          <div class="notifications-list">
            <div *ngFor="let notification of notificationsResponse.all | slice:0:3" class="notification" [class.unread]="notification.isNew" role="listitem">
              <div class="notification-content">
                <img class="icon_notification" [src]="icon_notification" alt="ícone de notificação" tabindex="0" aria-label="ícone de notificação">
                <div>
                  <h4>{{ notification.title }}</h4>
                  <p>{{ notification.description }}</p>
                  <span class="date">{{ notification.date }}</span>
                </div>
                <div *ngIf="notification.isNew" class="unread-notification-indicator" aria-label="Nova notificação">●</div>
              </div>
            </div>
          </div>
          <div class="redirect_notifications flex">
            <p (click)="redirectToAllNotifications()">Ver todas as notificações</p>
          </div>
        </div>
        <div *ngIf="notificationsResponse.unreadCount > 0" class="unread-indicator" aria-label="Nova notificação">●</div>
      </div>

      <div #buttonToggle id="conteudo-header-logar-desktop" (click)="$event.stopPropagation() ;openUserMenu()" (keypress)="openUserMenuKeyPress($event)" role="group" tabindex="0" attr.aria-label="{{createAriaLabel(1)}}" fxHide fxShow.gt-md class="header__button-actions">
        <span id="texto-logar" title="Logar na agência virtual" >
          <span class="texto-header">{{estaLogado ? cliente?.nome : 'Entrar'}}</span>
          <agv-icon *ngIf="!editUserIcon" [name]="'account_circle'" class="edita-foto-header" tabindex="0" aria-label="Ícone de avatar do usuário"></agv-icon>
          <img *ngIf="editUserIcon" class="edita-foto-header" [src]="editUserIcon" alt="edit" tabindex="0" aria-label="Foto de perfil header">
        </span>
      </div>
    </div>

    <div id="conteudo-header-mobile" role="group" fxHide.gt-md fxShow.xs class="header__toolbars-icons">
      <agv-header-search [currentTheme]="currentTheme"></agv-header-search>
      <button mat-icon-button id="botao-abrir-sidenav" role="button" class="header-menu__button" title="Menu"
        (click)="onToggleSidenav();">
        <agv-icon [name]="'icn_menu_mobile'" *ngIf="!isSidenavOpen" id="img-menu" class="header-menu-icon" alt="Abrir Menu" [ngClass]="{'dark-mode' : currentTheme === 'theme-dark'}"></agv-icon>
        <mat-icon id="img-menu" class="header-menu-close" *ngIf="isSidenavOpen">close</mat-icon>
      </button>
    </div>
  </div>
</div>

<ng-template [ngIf]="isMenuUserOpen && estaLogado">
  <div #userMenu class="menu-header-user__content">
    <div class="header">
      <button (click)="isMenuUserOpen = false" mat-icon-button tabindex="0"
        aria-label="botão para fechar aba de menu de usuário ">
        <mat-icon> closed</mat-icon>
      </button>
    </div>
    <div tabindex="0" class="icon">
      <agv-icon *ngIf="showIconAvatar()" [name]="'account_circle'" (mouseenter)="showEditaFoto = true" (mouseleave)="hoverIconOut()" title="User" aria-label="Clicando aqui poderá selecionar, do seu computador ou celular, uma imagem para o seu perfil."></agv-icon>
      <img *ngIf="!showIconAvatar()" (mouseenter)="showEditaFoto = true" (mouseleave)="hoverIconOut()" [src]="editUserIcon" alt="user" aria-label="Clicando aqui poderá selecionar, do seu computador ou celular, uma imagem para o seu perfil."/>
      <a (click)="$event.stopPropagation(); fileInput.click()">
        <agv-icon *ngIf="showEditaFoto" (mouseenter)="showEditaFoto = true" (mouseleave)="hoverIconOut()" class="edita-foto" title="edit" tabindex="0" [name]="'icon-edit-photo'"></agv-icon>
        <input #fileInput accept="image/*" type="file" (change)="enviaFoto($event)" style="display:none;" tabindex="0">
      </a>
    </div>


    <div class="user-list-options">
      <div tabindex="0" class="item" [ngStyle]="{'padding-bottom': name.length >= 21 ? '10px': '0'}">
        <span class="nome-cliente">{{name}}</span>
      </div>
      <a tabindex="0" class="item">
        <span (click)="redirectMinhaConta()">Minha conta</span>
      </a>
      <a tabindex="0" class="item">
        <span (click)="redirectMinhasSolicitacoes()">Minhas solicitações</span>
      </a>
      <a tabindex="0" class="item">
        <span (click)="redirectAlteraSenha()">Alterar senha</span>
      </a>

      <a tabindex="0" (click)="signOut()" class="item">
        <span>Sair</span>
      </a>
    </div>

  </div>
</ng-template>

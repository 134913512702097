import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'agv-resumo-processo',
  template: ` <div [id]="'__resumo-processo'" class="resumo-processo">
  <div>
    <h3 [id]="'__resumo-processo__title'" class="resumo-processo__title" tabindex="0">
      {{ title }}
    </h3>
  </div>
    <div [id]="'__resumo-processo__dados'" class="resumo-processo__dados">
      <div *ngIf="showBotao">
          <agv-button
          [id]="'__resumo-processo'"
          class="resumo-processo__botao"
          [text]="'Editar'"
          [type]="'2'"
          [habilita]="true"
          (sendAction)="replicar($event)"
        ></agv-button>
      </div>
      <div
        [id]="'__resumo-processo__dados__bloco'"
        class="resumo-processo__dados__bloco" [ngClass]="{'w75': i <= 1}"
        *ngFor="let dado of dados; index as i"

      >
        <p
          [id]="'__resumo-processo__dados__bloco__label'"
          class="resumo-processo__dados__bloco__label"
          tabindex="0"
        >
          {{ dado.label }}
        </p>
        <p
          tabindex="0"
          [id]="'__resumo-processo__dados__bloco__value'"
          class="resumo-processo__dados__bloco__value"
        >
          {{ dado.value }}
        </p>
      </div>
    </div>
  </div>`,
  styles: [
    `
      .resumo-processo {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 475px;
        justify-content: center;

        &__title {
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 1.33rem;
          margin: auto;
          margin-bottom: 33px;
          text-align: center;
        }


        &__dados {
          display: flex;
          flex-direction: column;
          gap: 28px;
          padding: 30px 0;
          border-top: 1px solid var(--color-gray-300);
          border-bottom: 1px solid var(--color-gray-300);


          &__bloco {
            width: 100%;
            max-width: 410px;

            &.w75 {
              width: 75% !important;
            }

            &__label {
              font-weight: 700;
              color: var(--color-grey);
              font-size: 0.875rem;
              line-height: 1.25rem;
              margin-bottom: 10px;
            }

            &__value {
              font-weight: 700;
              color: var(--color-medium-black);
              font-size: 1.125rem;
              line-height: 1.25rem;
            }
          }
        }

        &__botao {
          position: absolute;
          top: 75px;
          right: 10px;
          width: 100px;

          ::ng-deep.button-cancelar {
            height: 33px;
          }
        }
      }
    `,
  ],
})
export class ResumoProcessoComponent implements OnInit {
  @Output() sendAction = new EventEmitter();
  @Input() id: string = '';
  @Input() title: string = '';
  @Input() dados: {
    label: string;
    value: string;
  }[];
  @Input() showBotao: boolean = true;

  ngOnInit(): void {}

  replicar(e: any) {
    this.sendAction.emit(e);
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
export type TxtButtonConfig = {
  styles?: any,
  text?: string,
  type?:string,
  src?: string
}


@Component({
  selector: 'button-sabesp',
  templateUrl: './button-sabesp.component.html',
  styleUrls: ['./button-sabesp.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonSabespComponent implements OnInit , OnChanges{
  @Input() buttonConfig?: any
  @Input() isDisabled: boolean = false;


  @Output() onClick = new EventEmitter<any>();
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit() {}


  onClickButton(event: any) {
    this.onClick.emit(event);
  }

}

<h2 class="lista-dados_titulo" tabindex="0">{{titulo}}</h2>
<div role="group" class="lista-dados_dados"  tabindex="0">
    <div role="group" class="lista-dados_row" *ngFor="let dado of dados; let i=index">
        <div role="group" class="lista-dados_box">
            <div class="lista-dados_line" *ngIf="dado.div"></div>
            <span class="lista-dados_label " [ngClass] = "{'lista-dados_bold' : dado.bold}">{{dado.label}}</span>
            <span class="lista-dados_value" [ngClass] = "{'lista-dados_bold' : dado.bold}">{{dado.valor}}</span>
        </div>
        <button class="lista-dados_editar" *ngIf="i == 0 && editar" (click)="edit()">Editar</button>
    </div>
</div>

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Regex } from 'src/app/shared/utils/regex';

@Component({
  selector: 'agv-input-cpf',
  template: `
    <div
      role="group"
      [id]="id + '__input-cpf'"
      class="input-cpf"
      [ngClass]="{ disabled: blocked }"
    >
      <label class="input-cpf__label" [for]="id + '__input-cpf__cpf'">{{
        label
      }}</label>
      <input
        #inputCpf
        [id]="id + '__input-cpf__cpf'"
        [placeholder]="placeholder"
        class="input-cpf__input"
        (keydown)="onKeydown($event)"
        (input)="onInput($event)"
        maxlength="14"
        [value]="value"
        [disabled]="blocked"
      />
    </div>
    <ng-content></ng-content>
  `,
  styles: [
    `
      .input-cpf {
        display: flex;
        height: 65px;
        width: 100%;
        position: relative;
        background-color: var(--color-normal-white);
        border: 1px solid var(--color-gray-always-weak);
        border-radius: 4px;
        padding: 6px 22px 13px 22px;

        &.disabled {
          background-color: var(--color-gray-always-weak-disable) !important;

          input {
            background-color: var(--color-gray-always-weak-disable) !important;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px var(--color-gray-always-weak-disable)
              inset !important;
          }
        }

        &__label {
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.25rem;
          position: absolute;
          top: 6px;
          color: var(--color-normal-grey);
          display: flex;
          width: 100%;
          height: 1.25rem;
        }

        &__input {
          font-weight: 400;
          font-size: 0.875rem !important;
          line-height: 1.25rem;
          color: var(--color-black);
          background: transparent;
          border: none;
          width: calc(100% - 44px);
          height: 20px;
          bottom: 13px;
          position: absolute;
          max-width: 431px;
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCpfComponent),
      multi: true,
    },
  ],
})
export class InputCpfComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  @Output() sendValor = new EventEmitter<any>();
  @ViewChild('inputCpf') inputCpf: ElementRef;
  @Input() id: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() blocked: boolean = false;
  @Input() value: string = '';
  cpf: string = '';

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue !== changes['value']?.previousValue) {
      if (this.inputCpf && this.inputCpf.nativeElement) {
        this.inputCpf.nativeElement.value =
          changes['value']?.currentValue || '';
      }
    }
  }

  onKeydown(event: KeyboardEvent) {
    // prevent default behavior if necessary
  }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this.cpf = Regex.maskCpf(input.value);
    input.value = this.cpf;
    this.value = this.cpf;
    this.onChange(this.cpf);
    this.sendValor.emit(this.cpf);
  }

  writeValue(value: any): void {
    this.value = value || '';
    if (this.inputCpf && this.inputCpf.nativeElement) {
      this.inputCpf.nativeElement.value = this.value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.blocked = isDisabled;
  }
}

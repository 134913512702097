import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  InputSelect,
  InputSelectOption
} from './input-select.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'agv-input-select',
  template: `
    <div
      [id]="id + '__select'"
      class="select"
      [ngClass]="{ disabled: blocked }"
      (click)="open = !open && !blocked"
      (keypress)="open = !open && !blocked"

    >
      <div
        [id]="id + '__select__content'"
        class="select__content"
        (click)="open = !open && !blocked"
        (keypress)="open = !open && !blocked"

      >
        <label
          [id]="id + '__select__content__label'"
          class="select__content__label"
          (click)="open = !open && !blocked"
          (keypress)="open = !open && !blocked"
          [for]="id + '__select__content__input'"
          [ngClass]="{ disabled: blocked }"
          >{{ structor.label }}</label

        >
        <input
          #inputSelect
          [id]="id + '__select__content__input'"
          class="select__content__input"
          [value]="value ?? ''"
          (click)="open = !open && !blocked"
          (keypress)="open = !open && !blocked"
          [placeholder]="structor.placeholder"
          readonly="true"
          [ngClass]="{ disabled: blocked }"
          tabindex="0"
        />
        <span
          id="select__content__arrow"
          class="select__content__arrow"
          [ngClass]="{ open: open }"
          (click)="open = !open && !blocked"
          (keypress)="open = !open && !blocked"
        >
          <agv-icon [name]="'arrow_black'"></agv-icon>
        </span>
      </div>

      <ul
        id="select__list-options"
        class="select__list-options"
        [ngClass]="{ open: open }"
      >
        <li
          tabindex="0"
          class="select__list-options__option"
          (click)="changeValor(option)"
          (keypress)="changeValor(option)"
          *ngFor="let option of structor.options"
        >
          <div
            class="select__list-options__option__value"
            (click)="changeValor(option)"
            (keypress)="changeValor(option)"
            tabindex="0"
          >
            {{ option.value }}
          </div>
          <div
            class="select__list-options__option__label"
            [ngClass]="{ img: label.img }"
            *ngFor="let label of option.labels"
            (click)="changeValor(option)"
            (keypress)="changeValor(option)"
            tabindex="0"
          >
            {{ label.value }}
          </div>
        </li>
      </ul>
    </div>
  `,
  styles: [
    `
      .select {
        display: flex;
        position: relative;
        min-height: 65px;
        width: 100%;
        border: 1px solid var(--btn-disabled);
        border-radius: 4px;
        padding: 10px 22px;
        background-color: var(--color-white-input-background);
        max-height: 65px;

        &.disabled {
          cursor: not-allowed;
          background-color: var(--color-gray-weak);
        }

        &__content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &__label {
            position: absolute;
            top: 10px;
            left: 22px;
            font-size: 0.75rem;

            &.disabled {
              cursor: not-allowed;
            }
          }

          &__input {
            position: absolute;
            top: 30px;
            left: 22px;
            font-size: 0.875rem !important;
            border: none;
            background-color: transparent;
            width: calc(100% - 73px);
            color: var(--color-black);
            &.disabled {
              cursor: not-allowed;
            }
          }

          &__arrow {
            display: flex;
            position: absolute;
            right: 22px;
            top: 25px;
            transition: all 1s;

            ::ng-deep.agv-icon {
              width: 16px;
            }

            &.open {
              transform: rotate(180deg);
            }
          }
        }

        &__list-options {
          display: none;
          flex-direction: column;
          position: absolute;
          width: 100%;
          max-width: 475px;
          background-color: var(--color-white-input-background);
          height: auto;
          max-height: 150px;
          overflow-y: auto;
          left: 0px;
          top: 50px;
          transition: all 1s;
          border: 1px solid var(--btn-disabled);
          border-top: none;

          @media (max-width: 768px) {
            min-width: 314px;
            max-width: none;
          }
          &::-webkit-scrollbar-track {
          background-color: var(--color-webkit-scrollbar);
          border-radius: 150px;
          width: 5px;
          height: 60px;
        }

        &::-webkit-scrollbar {
          border-radius: 150px;
          width: 13px;
          height: 60px;
        }

        &::-webkit-scrollbar-thumb {
          width: 5px;
          border-radius: 150px;
          background: var( --color-txt-disable);
          border: 3px solid var(--color-webkit-scrollbar);
          height: 60px;
        }



          &.open {
            display: flex;
            z-index: 2;
          }

          &__option {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: start;
            padding: 22.5px 20px ;
            font-size: 0.9rem;
            font-weight: 700;
            cursor: pointer;
            border-bottom: 1px solid var(--color-gray-weak);

            &__value {
              display: none;
            }

            &:hover {
              background-color: var(--color-gray-light-backgroud-textarea);
            }
          }
        }

        ::-webkit-scrollbar-track {
          background-color: var(--color-webkit-scrollbar);
          border-radius: 150px;
          width: 9px;
        }

        ::-webkit-scrollbar {
            border-radius: 150px;
            width: 13px;
        }

        ::-webkit-scrollbar-thumb {
            width: 9px;
            border-radius: 150px;
            background: var(--color-webkit-scrollbar-background);
            border: 3px solid var(--color-webkit-scrollbar);
        }
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true
    }
  ]
})
export class InputSelectComponent implements OnInit, OnChanges {
  @Output() sendValor = new EventEmitter<any>();
  @Output() enviaValorObjeto = new EventEmitter<any>();
  @Output() enviaValorObjetoCompleto = new EventEmitter<any>();

  @ViewChild('inputSelect') inputSelect: ElementRef;
  @Input() id: string = '';
  @Input() value: string = '';
  @Input() blocked: boolean = false;
  @Input() structor: InputSelect;

  open: boolean = false;

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  private valorChangeSubject = new Subject<any>();

  constructor() {
    this.valorChangeSubject.pipe(
      debounceTime(300) // Debounce para evitar chamadas repetidas
    ).subscribe(e => this.handleValorChange(e));
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue !== changes['value']?.previousValue) {
      if (this.inputSelect && this.inputSelect.nativeElement)
        this.inputSelect.nativeElement.value = changes['value']?.currentValue;
    }
  }

  getValorList(): string {
    let valor = this.structor?.options.forEach((option: InputSelectOption) => {
      return option.value === this.value ? option.labels[0] : '';
    });
    return `${valor !== undefined ? valor : ''}`;
  }

  changeValor(e: any) {
    this.valorChangeSubject.next(e);
  }

  private handleValorChange(e: any) {
    let valor = '';
    let filtro = this.structor?.options.filter(
      (option: any) => option.value === e.value
    );
    if (filtro) {
      this.inputSelect.nativeElement.value = filtro[0].labels[0].value;
      valor = filtro[0].labels[0].value;
    }
   
    this.onChange(e.value);
    
    this.sendValor.emit(`${valor}`);
    this.enviaValorObjeto.emit(e.value);
    this.enviaValorObjetoCompleto.emit(e);
  }

  writeValue(value: any): void {
    this.value = value || '';
    if (this.inputSelect && this.inputSelect.nativeElement) {
      this.inputSelect.nativeElement.value = this.value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.blocked = isDisabled;
  }
}
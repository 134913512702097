<div id="selecao-cvv-msg-erro" role="group" tabindex="0" class="cvv-warning">
  <div id="selecao-cvv-msg-erro-titulo" role="group" class="cvv-warning__title" aria-hidden="true">Código de Segurança</div>
  <div id="selecao-cvv-msg-erro-descricao" role="group" class="cvv-warning__content" aria-hidden="true">
    <img src="'../../../../assets/imgs/svg/codigo_seguranca.svg" alt="" class="codigo-seguranca">
    <span>O CVV é o código de 3 a 4 dígitos impresso na frente ou no verso do seu cartão.</span>
  </div>
  <div id="selecao-cvv-msg-erro-fechar" role="group" class="cvv-warning__footer">
    <button id="selecao-cvv-msg-erro-botao-fechar" title="Botão Fechar pop-up" mat-button class="close-button"
      mat-dialog-close [mat-dialog-close]="true">
      <mat-icon class="close_icn" [title]="'Fechar modal'">close</mat-icon>
    </button>
  </div>
</div>

import { Fornecimento } from './fornecimento';

export class ClienteFornecimento {
  codigo: string;
  nome: string;
  sobrenome: string;
  tipoPessoa: any;
  email: string;
  cpf: string;
  cnpj: string;
  celular: string;

  constructor() {
    this.codigo = '';
    this.nome = '';
    this.sobrenome = '';
    this.tipoPessoa = '';
    this.email = '';
    this.cpf = '';
    this.cnpj = '';
    this.celular = '';
  }
}

export class FornecimentoCliente {
  cliente: ClienteFornecimento;
  fornecimentos: Fornecimento[];
}
import { createReducer, on } from '@ngrx/store';
import * as AppActions from './app.actions';

export const appKey = 'app';

export interface IAppState {
  name: string;
}

export const appInitialState: IAppState = {
  name: 'AGV',
};

export const AppReducer = createReducer(
  appInitialState,
  on(AppActions.changeName, (state) => ({ name: state.name }))
);

export interface DetalhesFatura {
  venc: string;
  total: string;
  barcode: string;
  titleImgBarcode: string;
  altImgBarcode: string;
  titleIcnCopiar: string;
  altIcnCopiar: string;
  titleIcnBaixar: string;
  altIcnBaixar: string;
  linkBaixar: string;
}

export function factoryDetalhesFatura(
  venc: string,
  total: string,
  barcode: string,
  titleImgBarcode: string,
  altImgBarcode: string,
  titleIcnCopiar: string,
  altIcnCopiar: string,
  titleIcnBaixar: string,
  altIcnBaixar: string,
  linkBaixar: string
): DetalhesFatura {
  return {
    venc,
    total,
    barcode,
    titleImgBarcode,
    altImgBarcode,
    titleIcnCopiar,
    altIcnCopiar,
    titleIcnBaixar,
    altIcnBaixar,
    linkBaixar,
  };
}

export interface OutrosDetalhes {
  titleIcnPagarApp: string;
  altIcnPagarApp: string;
  linkTermo: string;
}

export function factoryOutrosDetalhes(
  titleIcnPagarApp: string,
  altIcnPagarApp: string,
  linkTermo: string
): OutrosDetalhes {
  return {
    titleIcnPagarApp,
    altIcnPagarApp,
    linkTermo,
  };
}

export interface DetalhesPagto {
  codigoFatura: string;
  dataEmissao: string;
  valor: string;
  dataVenc: string;
  status: string;
}

export function factoryDetalhesPagto(
  codigoFatura: string,
  dataEmissao: string,
  valor: string,
  dataVenc: string,
  status: string
): DetalhesPagto {
  return {
    codigoFatura,
    dataEmissao,
    valor,
    dataVenc,
    status,
  };
}

export class PagamentoFaturaItens {
  detalhes: DetalhesFatura;
  outrosDetalhes: OutrosDetalhes;
  detalhesPagto: DetalhesPagto;

  constructor(
    detalhes: DetalhesFatura,
    outrosDetalhes: OutrosDetalhes,
    detalhesPagto: DetalhesPagto
  ) {
    this.detalhes = detalhes;
    this.outrosDetalhes = outrosDetalhes;
    this.detalhesPagto = detalhesPagto;
  }
}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { VideoDialogService } from './video-dialog.service';

@Component({
  selector: 'agv-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoDialogComponent implements OnInit {
  @Input() videoUrl: string = 'https://www.youtube.com/embed/OLXBEGqJGqA';

  display$: Observable<'open' | 'close' | undefined >;

  constructor(
      private dialogVideoService: VideoDialogService
  ) {}

  ngOnInit() {
    this.display$ = this.dialogVideoService.watch();
  }

  close() {
    this.dialogVideoService.close();
  }
}

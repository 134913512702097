<div class="dialog-aviso-logado" aria-hidden="true">
  <div class="dialog-aviso-logado__img">
    <agv-icon [name]="'icon_atencao'" title="Ícone de exclamação"> </agv-icon>
  </div>
  <div class="dialog-aviso-logado__title" tabindex="0">
    <span>PARA NEGÓCIO</span>
  </div>
  <div class="dialog-aviso-logado__content" tabindex="0">
    <p>
      Prezado Representante Legal, para acessar sua área exclusiva, clique em
      <a role="link" (click)="novaPagina()" (keypress)="popUpNovo($event)" tabindex="0"
        aria-label="clique aqui para ser direcionado para a página de novidades"><span> Para Negócios</span>
      </a>
    </p>
  </div>
  <div class="dialog-aviso-logado__button">
    <button mat-icon-button mat-dialog-close [mat-dialog-close]="true" class="button_close"
      aria-label="Fechar a caixa de dialogo" id="fechaPop">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ItensMenu } from 'src/app/core/models/menuServicoItens';
import { PerguntasFrequentesService } from './perguntas-frequentes.service';
import { PrimeIcons } from 'primeng/api';
import { ItensPerguntasFrequentes } from 'src/app/core/models/perguntasFrequentesItens';
import { ThemeService } from '../../observables/theme.service';

@Component({
  selector: 'app-perguntas-frequentes',
  templateUrl: './perguntas-frequentes.component.html',
  styleUrls: ['./perguntas-frequentes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PerguntasFrequentesComponent implements OnInit {
  // Controlador do tema
  currentTheme: string;

  collapsed = false;
  servicos: ItensPerguntasFrequentes[] = [];
  setaExpand = PrimeIcons.ANGLE_DOWN;
  src: string = 'icn_add';

  constructor(
    private readonly perguntasFrequentesService: PerguntasFrequentesService,
    private theme: ThemeService
  ) {}

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
    this.servicos =
      this.perguntasFrequentesService.getInfoPerguntasFrequentesItens().items;
  }

  onRedirect(item: ItensMenu) {
    if (item.link && item.link !== '') window.location.href = item.link;
  }

  elementoAbriu(i: number) {
    this.servicos[i].img = 'icn_remove-light-grey';
  }

  elementoFechou(i: number) {
    this.servicos[i].img = 'icn_add';
  }
}

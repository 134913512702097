import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Fatura } from 'src/app/core/models/faturaCompleta';
import { Regex } from '../../utils/regex';
import { ThemeService } from '../../observables/theme.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogErrorComponent } from '../dialog-error/dialog-error.component';
import { FaturaServices } from 'src/app/services/faturaServices.service';

interface StatusMapping {
  [key: string]: string;
}

@Component({
  selector: 'agv-card-fatura',
  templateUrl: './card-fatura.component.html',
  styleUrls: ['./card-fatura.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardFaturaComponent implements OnInit {
  currentTheme: string;
  private dialogLoadingRef: MatDialogRef<DialogErrorComponent>; // Manter uma referência ao diálogo de loading aberto
  faturaSelecionadaDownload: any = '';
  exibir: boolean;
  exibirPdfExec: boolean = false;
  faturaEmAtraso: boolean = false;
  activeButton: string | null = null;
  showError: boolean = false;
  downloadExec: boolean;

  botaoCopiado: boolean = false;
  botaoCopy: string = '';

  @Input() faturaAtual!: Fatura;
  @Input() fornecimentoCodigo: any;

  faturaSituacoes: StatusMapping = {
    PAGA: 'paga',
    'PAGO COM CARTÃO CRÉDITO': 'paga',
    'EM ABERTO': 'em-aberto',
    'EM ATRASO': 'em-atraso',
    'EM ACORDO DE PARCELAMENTO': 'em-acordo',
    // Adicione mais situações conforme necessário
  };

  constructor(
    private theme: ThemeService,
    private faturaService: FaturaServices,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
  }

  capitalize(value: string) {
    return Regex.capitalize(value);
  }

  getStatus(status: string): string {
    return this.faturaSituacoes[status?.toUpperCase().trim()] || '';
  }

  baixarFatura() {
    if (this.faturaAtual) {
      //set valor default
      this.exibirPdfExec = false;
      this.downloadExec = false;
      this.openDialogLoading();
      this.faturaService
        .getUrlFaturaCompleta(
          this.fornecimentoCodigo ?? '',
          this.faturaAtual.codigoPagamento
        )
        .subscribe({
          next: async (data) => {
            if (data === null) {
              this.activeButton = '';
              this.openDialog();
              console.error(
                'ERROR189: ===== Não foi possível efetuar o download da fatura! ====='
              );
            } else {
              this.exibirPdfExec = true;
              this.activeButton = '';

              let Buffer = require('buffer/').Buffer;
              const decodeURL = Buffer.from(data.url64, 'base64').toString(
                'binary'
              );
              if (decodeURL) {
                if (this.exibir) {
                  //show pdf
                  window.open(decodeURL, '_blank');
                } else {
                  //dowload pdf
                  this.downloadExec = true;
                  let Buffer = require('buffer/').Buffer;
                  const decodeURL = Buffer.from(data.url64, 'base64').toString(
                    'binary'
                  );

                  let blob = await fetch(decodeURL).then((r) => r.blob());
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `Fatura_${this.faturaAtual?.codigoPagamento}.pdf`;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  a.remove();
                }
                this.closeDialogLoading();
              }
            }
          },
          error: (error) => {
            this.openDialog();
            this.exibirPdfExec = true;
            this.activeButton = '';
            console.error(error);
            this.closeDialogLoading();
          },
          complete: () => {
            this.exibirPdfExec = true;
            this.activeButton = '';
            window.scrollTo(0, 0);
            this.closeDialogLoading();
          },
        });
    }
  }

  copyToClipboard(index: string) {
    this.botaoCopiado = true;
    this.botaoCopy = index;
    setTimeout(() => {
      this.botaoCopiado = false;
      this.botaoCopy = '';
    }, 5000);
  }

  openDialogLoading(): void {
    this.dialogLoadingRef = this.dialog.open(DialogErrorComponent, {
      data: {
        img: 'icon_atencao',
        title: 'AGUARDE',
        content: 'Estamos buscando a sua fatura.',
      },
    });
  }

  closeDialogLoading() {
    if (this.dialogLoadingRef) {
      this.dialogLoadingRef?.close();
    }
  }

  baixarDisabled(element: Fatura ):boolean{
    const situacao = this.faturaAtual?.situacaoDaFatura?.toLocaleLowerCase();
    return situacao === 'suspensa para análise' ||
           situacao === 'aguardando confirmação do banco' ||
           situacao === 'em acordo de parcelamento' ||
           situacao === 'revisada' ||
           (situacao === 'em atraso' && element?.cobrancaJuridica) 
           
  }

  isButtonDisabled(): boolean {
    const situacao = this.faturaAtual?.situacaoDaFatura?.toLocaleLowerCase();
    return situacao === 'suspensa para análise' ||
           situacao === 'aguardando confirmação do banco' ||
           situacao === 'em acordo de parcelamento' ||
           situacao === 'paga';
           
  }


  openDialog(): void {
    this.dialog.open(DialogErrorComponent, {
      data: {
        img: 'icon_atencao',
        title: 'FALHA NO DOWNLOAD',
        content:
          'Ocorreu um erro e não foi possível realizar o download da sua fatura, por favor, tente novamente.<p> Caso o erro persista, favor aguardar um tempo antes de voltar a tentar novamente.</p>',
      },
    });
    this.faturaSelecionadaDownload = '';
  }
}

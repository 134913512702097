<div role="group" id="login-component" class="login" [ngStyle]="{'margin-bottom': (isLastComponent && isMobile) ? 'initial' : '85px'}">

  <h3 class="title-registered" tabindex="0">{{name}}, Identificamos seu cadastro!</h3>
  <span class="sub-registered" tabindex="0">Identificamos que você possui um cadastro e uma senha de acesso.</span>
  <div role="group" id="login-component_formulario" class="login_inputs">

    <div *ngIf="senhaIncorreta">
      <app-mensagem [mensagem]="mensagemErro"></app-mensagem>
    </div>

    <mat-form-field appearance="fill" class="login_input_senha" id="login_input_senha" [ngClass]="{'failed': senhaIncorreta}">
      <label for="senha" class="login_input_senha__label">Informe sua senha *</label>

      <input [type]="showPass ? 'text': 'password'" id="senha" type="password" matInput #first [(ngModel)]="password"
        placeholder="Digite sua senha" />
      <mat-icon tabindex="0" matSuffix (click)="showPass = !showPass" (keypress)="showPass = !showPass" >{{showPass ? 'visibility' : 'visibility_off'}}
      </mat-icon>
    </mat-form-field>
  </div>
  <div role="group" id="login-component_acoes" class="login_action">
    <div role="group" class="login_action__recovery" tabindex="0">
      <span id="login-component_recuperar-senha" (click)="recuperar()">Esqueci minha senha</span>
    </div>
    <!-- <div role="group" class="login_action_mantem-login">
      <mat-checkbox (change)="keep()" role="checkbox" id="manterConectado"
        title="Manter o usuário conectado na Agência Virtual" color="primary" [(ngModel)]="manterConectado" tabIndex="0">
        Mantenha-me conectado
      </mat-checkbox>
    </div> -->
  </div>
  <button role="button" (click)="entrar()" id="login-component_logar" class="login_btn-entrar" mat-raised-button
    color="primary" [disabled]="password === '' || (password != '' && senhaIncorreta)" [ngClass]="{'disabled': password === '' || (password != '' && senhaIncorreta)}" tabindex="0">{{labelBtn}}</button>
  
  <button class="login_btn-voltar" *ngIf="showVoltar" (click)="voltar()">Voltar</button>
</div>

<div role="group" id="parcel-debts" class="parcel-debts" *ngIf="faturasAtraso.length >= 1">
  <div role="group" id="parcel-debts-header" class="parcel-debts__header" tabindex="0">
    <h2>Verifique as condições de parcelamento para seus débitos.</h2>
    <div role="group" class="slide-toggle" tabindex="0" [ngClass]="{'disabled-click' : !verificarFaturaAberta()}">
      <input role="checkbox" type="checkbox" name="slideToggle" id="slideToggle" (change)="changeCheckbox($event)" aria-label="Selecione esse campo para adicionar as faturas em aberto no parcelamento." [disabled]="!verificarFaturaAberta()">
      <label for="slideToggle">Desejo incluir a fatura em aberto no parcelamento</label>
    </div>
  </div>

  <div role="group" id="parcel-debts-conteudo" class="parcel-debts__content" tabindex="0" >
    <div role="group" id="parcel-debts-conteudo-numero-fatura" class="content1">
      <span class="content-description">NÚMERO DE FATURAS</span>
      <p class="invoice-count">
        {{ qtdFaturas }} - faturas
      </p>
    </div>
    <div role="group" id="parcel-debts-conteudo-valor-debitos" class="content2">
      <span class="content-description">VALOR DOS DÉBITOS</span>
      <p class="debts-value">{{ debitos | currency: 'BRL' }}</p>
    </div>
    <div role="group" class="content3">
      <button [disabled]="setLoading" id="parcel-debts-botao-simulacao" role="button" aria-label="Clique aqui para simular o parcelamento dos seu débitos" mat-button (click)="navigateToInstallment()" (keypress)="pressSimula($event)">
        <div *ngIf="!setLoading">
          Simular
        </div>
        <div class="loadingButton" *ngIf="setLoading">
          <agv-icon [name]="'spinner'"></agv-icon>
          <p>Aguarde</p>
        </div>
      </button>
    </div>
  </div>
</div>

<div role="group" id="card-info-fornec-container" class="card-info-fornec__container">
    <div role="group" id="card-info-fornec-content" class="card-info-fornec__container__content" #infosFornec tabindex="0">
        <h2 *ngIf="isRepLegal" id="content-fornec-cod" class="card-info-fornec__container__content__title">Dados da Empresa</h2>
        <h2 *ngIf="!isRepLegal" id="content-fornec-cod" class="card-info-fornec__container__content__title"><span>Fornecimento</span>{{codFornecimento}}</h2>
        <div role="group" id="content-fornec-details">
            <p id="content-fornec-owner" class="card-info-fornec__container__content__owner"> {{nomeCliente}}</p>
            <p id="content-fornec-address" class="card-info-fornec__container__content__address">{{endereco}}</p>
        </div>
    </div>
</div>

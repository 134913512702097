import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Acordo } from 'src/app/core/models/acordo';

@Injectable({
  providedIn: 'root',
})
export class AcordoService {
  // Observador responsável por notificar ouvintes de mudança
  private acordoSource = new BehaviorSubject<Acordo>(new Acordo());
  private obsSource = new BehaviorSubject<string>('');

  // Variável publica para acessar o valor e receber as alterações
  public currentAcordo = this.acordoSource.asObservable();
  public currentObsSolicitacao = this.obsSource.asObservable();

  // Observador responsável por notificar ouvintes de mudança
  private acordosSource = new BehaviorSubject<Acordo[]>([]);
  // Variável publica para acessar o valor e receber as alterações
  public currentAcordos = this.acordosSource.asObservable();

  // Populando a variável com o valor gravado em memória
  constructor() {}

  // Função para alterar o valor no observador
  changeAcordo(acordo: Acordo) {
    this.acordoSource.next(acordo);
  }

  // Função para alterar o valor no observador
  changeAcordos(acordos: Acordo[]) {
    this.acordosSource.next(acordos);
  }

  changeObs(obs: string) {
    this.obsSource.next(obs);
  }

  getObs(){
    return this.currentObsSolicitacao;
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild,} from '@angular/core';
import { Validator } from '../../utils/validator';
import { InfosModalOpcoes } from 'src/app/core/models/infosModalOpcoes';

@Component({
  selector: 'app-modal-info-doc',
  templateUrl: './modalInfoDoc.component.html',
  styleUrls: ['./modalInfoDoc.component.scss'],
})
export class ModalInfoDocComponent implements AfterViewInit {

  @ViewChild('popUpDoc') popUpDoc: ElementRef;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  mostrarOpcoes: boolean = false;
  infosModal: InfosModalOpcoes;

  constructor() {}

  ngAfterViewInit(): void {
    this.popUpDoc?.nativeElement?.focus();
  }
  
  closedModal(event: any) {
    if(Validator.validaEventKeyPressClick(event)) {
      this.closeModal.emit(true);
    }
  }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FornecimentoCorreio } from 'src/app/core/models/fornecimento-correio';
import { EventEmitterService } from 'src/app/event-emits.service';
import { Regex } from 'src/app/shared/utils/regex';

@Component({
  selector: 'app-endereco-corresp',
  templateUrl: './endereco-corresp.component.html',
  styleUrls: ['./endereco-corresp.component.scss']
})
export class EnderecoCorrespComponent implements OnInit {
  @Output() enderecoEnvio = new EventEmitter();
  @Input() fornecimentoLigacao:FornecimentoCorreio;
  @Input() checked: boolean = false;

  valorCampoSelect: any;

  isCampoSelectVazio: boolean;
  showCampoEndereco: any;
  isMobile: boolean;

  mensagemErro: string;

  validacaoEnderecoCorreio:FormGroup = new FormBuilder().group({
    cep:['', Validators.required],
    endereco: ['', Validators.required],
    numero: ['', Validators.required],
    bairro: ['', Validators.required]
  });

  fornecimento: FornecimentoCorreio = {
    selecionado: false,
    cep: '',
    codigoFornecimento: '',
    idToponimo: '',
    endereco: '',
    idTipoNumero: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: 'SP',
  };

  style: any = {
    selectField: {
      'padding': '6px 19px 12px ',
      'border': '1px solid var(--color-gray-weak)'
    },
    selectFieldErro: {
      'padding': '6px 19px 12px',
      'border': '1px solid var(--color-failed)'
    },
    content: {
      'text-align': 'start'
    },
    contentErro: {
      'text-align': 'start',
      'color': 'var(--color-failed)'
    },
    label: {
      'font-weight': '400',
      'font-size': '0.75rem',
      'line-height': '20px',
      'margin-bottom': '6.25px',
      'width': 'max-content'
    },
    placeholder: {
      'font-weight': '400',
      'font-size': '0.875rem',
      'line-height': '20px',
      'color': '#626262'
    },
    placeholderErro: {
      'font-weight': '400',
      'font-size': '0.875rem',
      'line-height': '20px',
      'color': 'var(--color-failed)'
    },
    img: {
      'width': '12px',
      'height': '6px'
    },
    option: {
      'font-weight': '400',
      'font-size': '0.875rem',
      'line-height': '20px',
      'color': '#626262',
      'text-align': 'start'
    }
  };
  enderecoFornecimento: { selecionado: boolean; cep: string; codigoFornecimento: string; idToponimo: string; endereco: string; idTipoNumero: string; numero: string; complemento: string; bairro: string; cidade: string; uf: string; };
  constructor() { }

  ngOnInit(): void {
    if (window.innerWidth <= 600) {
      this.isMobile = true;
    }

    window.onresize = () => (this.isMobile = window.innerWidth <= 600);
  }

  validaCep(): void {
    this.fornecimento.cep = Regex.maskCEP(this.fornecimento.cep);
    this.erroCep();
    this.cepEncontrado()
  }

  //MOCK PARA ERRO
  erroCep(): void{
    if (this.fornecimento.cep == '13339-401') {
      this.mensagemErro = 'O CEP digitado não foi encontrado. Por favor, confira se está correto.';
      return;
    } if (this.fornecimento.cep == '13339-400') {
      this.mensagemErro = 'O número do CEP digitado não é válido.';
      return;
    }
    this.mensagemErro = '';
  }

  //MOCK PARA RETORNO DE DADO
  cepEncontrado(): void{
    if (this.fornecimento.cep == '13339-545') {
      this.fornecimento = {
        selecionado: false,
        cep: '13339-545',
        codigoFornecimento: '134567890',
        idToponimo: 'Rua',
        endereco: 'Zuma de Sá Fernandes',
        idTipoNumero: 'Número',
        numero: '350',
        complemento: 'apto 54 torre 1',
        bairro: 'Presidente Altino',
        cidade: 'Osasco',
        uf: 'SP',
      }
    }
  }

  verificaSelecVazio(e: any) {
    EventEmitterService.get('emissorValue').subscribe((valor) => {
      this.valorCampoSelect = valor;
      if (!this.valorCampoSelect) {
        this.isCampoSelectVazio = true;
      } else {
        this.isCampoSelectVazio = false;
      }
    });
  }

  handleNovoEnderecoCorreio(e: string) {
    this.showCampoEndereco = e;
    if( e=== 'Igual ao da ligação' ){
      this.showCampoEndereco = null
    }
    if (e !== 'Endereço do fornecimento') {
      this.enderecoFornecimento = {
        selecionado: false,
        cep: '',
        codigoFornecimento: '',
        idToponimo: '',
        endereco: '',
        idTipoNumero: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: 'SP'
      }
    } else {
      this.showCampoEndereco = false;
      this.fornecimento = this.fornecimentoLigacao;
      this.emissorEnderecoEnvio();
    }
  }

  handleEndereco(e: any) {
    this.fornecimento.idToponimo = e;
  }

  emissorEnderecoEnvio() {
    if(!this.showCampoEndereco || (this.validacaoEnderecoCorreio.valid && this.fornecimento.idToponimo)){
      this.enderecoEnvio.emit(this.fornecimento);
      EventEmitterService.get('enderecoEnvio').emit(this.fornecimento);
    }
  }
}

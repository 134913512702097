<agv-icon class="solicitacao-concluida_container_content_img" role="img" [name]="'icon_sucesso'" title="Ícone de sucesso"></agv-icon>
<h2 class="solicitacao-concluida_container_content_titulo">{{tituloMensagem}}</h2>
<span class="solicitacao-concluida_container_content_descricao" [innerHTML]="solicitacao.descricao.desc"></span>
<div class="solicitacao-concluida_container_content_dados">
    <div class="solicitacao-concluida_container_content_row" *ngFor="let dado of solicitacao.dados">
        <div class="solicitacao-concluida_container_content_line" *ngIf="dado.div"></div>
        <span class="solicitacao-concluida_container_content_label" 
        [ngClass] = "{'solicitacao-concluida_container_content_bold' : dado.bold}">{{dado.label}}</span>
        <span class="solicitacao-concluida_container_content_value" 
        [ngClass] = "{'solicitacao-concluida_container_content_bold' : dado.bold}">{{dado.valor}}</span>
    </div>
</div>

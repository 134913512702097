import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalyticsService } from './google-analytics.service';
@Injectable({
  providedIn: 'root',
})
export class PrivacyStorageServiceService {
  constructor(
    private cookieService: CookieService,
    private googleAnalitics: GoogleAnalyticsService
  ) {}

 
  configurarGoogleAnalytics() {
    this.googleAnalitics.init();
  }

  consentimentoCookies(consent: string) {
    this.cookieService.set('consent', consent);
  }

  getConsetimento(): string {
    return this.cookieService.get('consent');
  }
}

<div class="cadastroOCR">
  <div id="componenteOCR" class="ocrContent">
    <div class="agrupaDocs">
      <div id="componenteOCR_campoImgDocFrente" class="docsContainer" [ngClass]="{'docsContainerErro': erroDocFrente}">
        <div role="group" *ngIf="loadingDocFrente" class="loadingService">
          <agv-icon [name]="'button-loading16'" class="loadingService__img"></agv-icon>
        </div>
        <section *ngIf="!loadingDocFrente" class="displayRow displayColumnMobile">
          <div id="componenteOCR_campoinputDocFrente" class="docs" tabindex="0"
            aria-label="Carregar arquivo frente (Cada arquivo deve ter no máximo 20MB e estar no formato .jpg, .jpeg, ou .png.)">
            <label for="docFrente" class="labelTitulo">
              Documento *
            </label>
            <div id="componenteOCR_inputDocFrente" role="group" class="buttonArquivos labelTitulo" aria-label="Carregar arquivo frente botão.">
              <label for="docFrente" tabindex="0" [ngClass]="{'docsContainerErro': erroDocFrente}" title="Carregar arquivo frente" class="carregar-arquivo">
                {{nomeDocumentoFrente}}
              </label>
              <input role="button" id="docFrente" fxHide type="file" accept="image/jpg, image/jpeg, image/png"
                (change)="handleDoc($event, 'frente')" />
            </div>
          </div>
          <div id="componenteOCR_campoImgFrente" role="group" class="docs-arquivos">
            <img for="docFrente" *ngIf="docFrente && !erroDocFrente && !documentoDivergente"
              alt="foto da frente do documento" [src]="docFrente" class="imgDoc" [ngClass]="{'dark-mode': currentTheme === 'dark-mode'}" />
            <agv-icon [name]="'documento_frente'" *ngIf="!docFrente && !erroDocFrente"
              title="foto da frente do Documento" class="imgDoc"></agv-icon>
            <agv-icon [name]="'doc_frente_vermelho'" for="docFrente" *ngIf="erroDocFrente"
              title="foto da frente do Documento" class="imgDoc"></agv-icon>
            <agv-icon [name]="'icn_limpar'" *ngIf="docFrente && !erroDocFrente" tabindex="0"
              [title]="'Remover documento frente'" (keypress)="limparAquivo('frente', $event)" class="removeIcon"
              (click)="limparAquivo('frente', $event)"></agv-icon>
          </div>
        </section>
      </div>
      <div id="componenteOCR_campoImgDocVerso" role="group" class="docsContainer">
        <div role="group" *ngIf="loadingDocVerso" class="loadingService">
          <agv-icon [name]="'button-loading16'" class="loadingService__img"></agv-icon>
        </div>
        <section *ngIf="!loadingDocVerso" class="displayRow displayColumnMobile">
          <div id="componenteOCR_campoinputDocVerso" role="group" class="docs" tabindex="0"
            aria-label="Carregar arquivo Verso (Cada arquivo deve ter no máximo 20MB e estar no formato .jpg, .jpeg, ou .png.)">
            <label for="docVerso" class="labelTitulo" [ngClass]="{'txtDisable': bloquearVerso}">
              Documento * 
            </label>
            <div id="componenteOCR_inputDocVerso" role="group" class="buttonArquivos labelTitulo" aria-label="Carregar arquivo verso botão">
              <label for="docVerso" tabindex="0" class="nome-arquivo carregar-arquivo"
                [ngClass]="{'txtDisable': bloquearVerso}">
                {{nomeDocumentoVerso}}
              </label>
              <input id="docVerso" role="button" fxHide type="file" accept="image/jpg, image/jpeg, image/png"
                (change)="handleDoc($event, 'verso')" [disabled]="bloquearVerso" />
            </div>
          </div>
          <div id="componenteOCR_campoImgVerso" role="group" class="docs-arquivos">
            <img for="docVerso" *ngIf="docVerso && docFrente && !erroDocVerso" alt="foto do verso do documento"
              [src]="docVerso" class="imgDoc" />
            <agv-icon [name]="'documento_verso'" *ngIf="!docVerso && !erroDocVerso && !bloquearVerso"
              title="foto da frente do Documento" class="imgDoc"></agv-icon>
            <agv-icon [name]="'doc_verso_vermelho'" for="docVerso" *ngIf="erroDocVerso"
              title="foto do verso do documento" class="imgDoc"></agv-icon>
            <agv-icon [name]="'icn-doc-verso-inativo'" for="docVersoInativo"
              *ngIf="!docVerso && !erroDocVerso && bloquearVerso" title="foto do verso do documento inativo"
              class="imgDoc"></agv-icon>
            <agv-icon [name]="'icn_limpar'" *ngIf="docVerso && !erroDocVerso" tabindex="0" class="removeIcon"
              [title]="'Remover documento verso'" (click)="limparAquivo('verso', $event)"
              (keypress)="limparAquivo('verso', $event)"></agv-icon>
          </div>
        </section>
      </div>
    </div>
    <div id="componenteOCR_mensagemErroDoc" role="group" class="textoErro"
      *ngIf="arquivoTamExcedido || tipoArquivoInvalido || documentoInvalido || documentoInfoFaltante || documentoDivergente">
      <p>{{setMessageErrorDoc()}}</p>
    </div>
    <section id="componenteOCR_campoDadosNomeCpf" [formGroup]="dadosUsuariAtualizacao">
      <mat-form-field class="input" [ngClass]="{'backgroundUnable': camposDesabilitados}" appearance="fill"
        [tabindex]="0" required>
        <label for="nomeCliente">Nome completo *</label>
        <input id="nomeCliente" matInput formControlName="nomeCompleto"
          [ngClass]="{'backgroundUnable': camposDesabilitados}" placeholder="Digite seu nome"
          aria-label="Digite seu nome completo" (keyup)="emiteDadosOcr()" class="capitalize"/>
        <mat-error *ngIf="form['nomeCompleto'].hasError('required')">
          Este campo é obrigatório.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="input backgroundUnable" appearance="fill" [tabindex]="0">
        <label for="cpfCliente">CPF</label>
        <input id="cpfCliente" matInput formControlName="documento" class="backgroundUnable" aria-label="Digite seu cpf"
          placeholder="000.000.000-00" />
      </mat-form-field>
    </section>
    <section [formGroup]="dadosUsuariAtualizacao" class="agrupaComponentes">
      <div class="duasColunas" id="componenteOCR_campoDadosTelDataNascimento">
        <mat-form-field class="inputDivido" [ngClass]="{'backgroundUnable': camposDesabilitados}" appearance="fill"
          [tabindex]="0">
          <label role="group" for="nascimentoCliente">Data de Nascimento</label>
          <input id="nascimentoCliente" [ngClass]="{'backgroundUnable': camposDesabilitados}" matInput
            formControlName="dataNascimento" type="date" type="text" placeholder="00/00/0000" (keyup)="emiteDadosOcr()"/>
            <mat-error
              *ngIf="dadosUsuariAtualizacao.controls['dataNascimento'].errors && dadosUsuariAtualizacao.controls['dataNascimento'].errors['menorIdade']">
              {{ dadosUsuariAtualizacao.controls["dataNascimento"].errors["menorIdade"] }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="inputDivido" appearance="fill" [tabindex]="0" required>
          <label role="group" for="telefoneCliente">Celular *</label>
          <input id="telefoneCliente" formControlName="celular" matInput type="tel" placeholder="(00) 00000-0000" (keyup)="mascaraTelefone();emiteDadosOcr()"/>
          <mat-error class="matErrorTelefone" *ngIf="form['celular'].errors">
            Celular inválido. Digite um celular válido para prosseguir.
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field id="componenteOCR_campoDadosEmail" class="input" appearance="fill" required>
        <label for="emailCliente">E-mail *</label>
        <input id="emailCliente" matInput formControlName="email" placeholder="Digite seu e-mail"
          aria-label="Digite seu e-mail" [matAutocomplete]="auto" (keyup)="emiteDadosOcr()"/>
        <mat-autocomplete #auto="matAutocomplete">
          <div *ngIf="!exibeEmail()">
            <mat-option *ngFor="let email of listaEmail"
              [value]="dadosUsuariAtualizacao.controls['email'].value  + email">
              {{ dadosUsuariAtualizacao.controls['email'].value + email }}
            </mat-option>
          </div>
        </mat-autocomplete>
        <mat-error *ngIf="form['email'].hasError('required') || form['email'].hasError('email') ">
          E-mail inválido. Digite um e-mail válido para prosseguir
        </mat-error>
      </mat-form-field>
      <p tabindex="0" class="textoInformativo">Campos marcados com * são obrigatórios</p>
    </section>
  </div>
</div>
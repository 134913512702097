export class GetSelectFielStyle {
    style = {
        selectField: {
            'padding': '0 19px',
            'color': 'var(--color-grey)'
        },
        selectFieldErro: {
            'padding': '0 19px',
            'color': '#626262',
            'border': '1px solid var(--color-failed)'
        },
        label: {
            'font-size': '0.75rem',
            'margin-bottom': '5px',
            'margin-top': '0px',
        },
        erroLabel: {
            'font-size': '0.75rem',
            'margin-bottom': '5px',
            'color': 'var(--color-failed)',
            'margin-top': '0px',
        },
        placeholder: {
            'font-weight': '500',
            'font-size': '14px'
        },
        erroPlaceholder: {
            'font-weight': '500',
            'font-size': '14px',
            'color': 'var(--color-failed)'
        }
    }

    getStyle():any {
        return this.style
    }
}
import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Fatura } from 'src/app/core/models/faturaCompleta';
import { PaginationComponent } from '../pagination/pagination.component';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { EventEmitterService } from 'src/app/event-emits.service';
import { Parcela } from 'src/app/core/models/acordo';
import { ThemeService } from '../../observables/theme.service';
import { FaturaService } from '../../observables/fatura.service';
import { AcordoService } from '../../observables/acordo.service';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';

@Component({
  selector: 'app-pagto-table',
  templateUrl: './pagto-table.component.html',
  styleUrls: ['./pagto-table.component.scss'],
})
export class PagtoTableComponent implements OnChanges, OnInit {
  // Controlador do tema
  currentTheme: string;

  @Input() displayedColumns: string[] = [];
  @Input() parcelas: Parcela[] = [];
  @Input() comunicaPgtoType: string = '';
  @Output() itensSelecionados: EventEmitter<any> = new EventEmitter();

  faturasDataSource: Fatura[] = [];
  parcelasDataSource: any[] = [];
  codPagFaturasSelecionadas: string = '';
  authToken: string | null = this.tokenService.getToken();

  selection = new SelectionModel<any>(true, []);

  mensagemErro: string = ` `;
  public buttonCopy: boolean[] = [];

  // Paginação
  pageSize = 10;
  pageSizeMobile = 3;
  currentPage = 1;
  currentPageData: any[];
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  itemsPerPage = [
    { value: '03', active: true },
    { value: '05', active: false },
    { value: '10', active: false },
  ];
  counter: string;

  constructor(
    private breakpointService: BreakpointService,
    private theme: ThemeService,
    private faturas: FaturaService,
    private acordos: AcordoService,
    private tokenService: TokenStorageService
  ) {}

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
    this.faturas.currentFaturas.subscribe((faturas) => {
      if (faturas) {
        this.faturasDataSource = faturas.filter(
          (x) =>
            x.situacaoDaFatura.toUpperCase() === 'EM ABERTO' ||
            x.situacaoDaFatura.toUpperCase() === 'RESIDUAL A PAGAR' ||
            x.situacaoDaFatura.toUpperCase() === 'CONTA REFATURADA' ||
            (x.situacaoDaFatura.toUpperCase() === 'EM ATRASO' &&
              !x.cobrancaJuridica)
        );
      } else {
        this.faturasDataSource = [];
      }
      this.currentPageData = this.faturasDataSource;
    });

    this.acordos.currentAcordos.subscribe((acordos) => {
      if (acordos) {
        this.parcelasDataSource = [];
        acordos.forEach((acordo) => {
          if(!acordo.statusAcordo.toUpperCase().includes('ROMPIDO')) {
            acordo.parcelas.forEach((parcela) => {
              if(parcela.status.toUpperCase() === 'EM ABERTO' ||
                  parcela.status.toUpperCase() === 'RESIDUAL A PAGAR' ||
                  parcela.status.toUpperCase() === 'EM ATRASO')
                this.parcelasDataSource = this.parcelasDataSource.concat({
                  acordo: acordo.codigoAcordo, numParcela: parcela.numParc, dataEmissao: acordo.dataParcelamento,
                  valor: parcela.valor, dataVencimento: parcela.dataVencimento, situacao: parcela.status, codeline: parcela.codeline
                })
            })
          }
        })
      } else {
        this.parcelasDataSource = [];
      }
      this.currentPageData = this.parcelasDataSource;
    });
  }

  selecionaItem() {
    let fats: Fatura[] = [];
    let parcelas: Parcela[] = [];
    if (this.comunicaPgtoType === 'parcelamento') {
      setTimeout(() => {
        for (let parcela of this.selection.selected) {
          parcelas.push(parcela);
        }
        this.itensSelecionados.emit(parcelas);
        EventEmitterService.get('parcelasSelecionadas').emit(parcelas);
      }, 0);
    } else {
      setTimeout(() => {
        for (let fatura of this.selection.selected) {
          fats.push(fatura);
        }
        this.itensSelecionados.emit(fats);
        EventEmitterService.get('faturasSelecionadas').emit(fats);
      }, 0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  private updatePagination() {
    this.breakpointService.breakpoint('900px').subscribe((result) => {
      if (this.paginationComponent) {
        this.paginationComponent.pageSize = result
          ? this.pageSizeMobile
          : this.pageSize;
      }
    });
  }

  updateCurrentPageData(data: any[]) {
    setTimeout(() => {
      this.currentPageData = data;
    });
  }

  removeTitulo() {
    let cbk = document.querySelector('#cb1');

    cbk?.removeAttribute('title');
  }

  changeItemsPerPage(value: string) {
    this.itemsPerPage.forEach((i) => {
      i.active = value === i.value;
    });
    this.paginationComponent.currentPage = 1;
    this.paginationComponent.pageSize = Number(value);
    this.paginationComponent.updateCurrentPageData();
  }
}

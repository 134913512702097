<div role="group" id="parc-path" class="parc-path">
  <div role="group" id="parc-path-content" class="parc-path__content">
    <div role="group" id="parc-path-titulo" class="parc-path__content__titulo">
      <agv-icon 
        [name]="'icn-parcelamento'" 
        [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }"
        tabindex="-1">
      </agv-icon>
      <p>
        Meus parcelamentos
      </p>
    </div>
    <button mat-raised-button color="primary" (click)="onclick()" id="parc-path-botao-acessar"
      aria-label="Clique aqui para acessar a página de Meus parcelamentos">
      Acessar
    </button>
  </div>
</div>
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-mensagem',
  templateUrl: './mensagem-erro.component.html',
  styleUrls: ['./mensagem-erro.component.scss']
})
export class MensagemComponent implements OnInit {
  @Input() mensagem: string | SafeHtml | null;
  @ViewChild('erro') erroElement: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  focusMensagem() {
    setTimeout(() => { //Carrega o focus na mensagem de erro
      this.erroElement?.nativeElement?.focus();
    }, 600);
  }
}

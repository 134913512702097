import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HandleErrorService } from 'src/app/services/handle-error.service';

@Injectable()
export class ExcecaoErroInterceptor implements HttpInterceptor {
  constructor(private _erro: HandleErrorService) {}
  intercept(
    requisicao: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return new Observable((observer) => {
      next.handle(requisicao).subscribe({
        next: (resp: HttpEvent<any>) => {
          if (resp instanceof HttpResponse) {
            observer.next(resp);
          }
        },
        error: (msg) => {
          this._erro.handlerError(msg);
          observer.error(msg);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }
}

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { ClienteService } from 'src/app/shared/observables/cliente.service';
import { IResponseAvatar } from 'src/app/shared/models/user.model';
import { UserService } from './../../../../services/user.service';
import { Validator } from 'src/app/shared/utils/validator';
import { MatAccordion } from '@angular/material/expansion';
import { TabService } from '../../tab/tab.service';
import { Regex } from 'src/app/shared/utils/regex';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogNovaAgvAvisoCNPJComponent } from '../../dialog-nova-agv-aviso-cnpj/dialog-nova-agv-aviso-cnpj.component';

@Component({
  selector: 'agv-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit, OnChanges {
  // Actions
  @Output() sidenavClose = new EventEmitter();

  // String
  public editUserIcon = 'icon-edit-photo';
  public userIcon = 'account_circle';

  // Boolean
  public panelOpenState: boolean = false;
  public showEditaFoto: boolean = false;
  public collapse: boolean = false;
  hideAgvIcon: boolean = false;
  expanded: boolean = false;
  invalid: boolean;

  // Views
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;

  // Inputs
  @Input() isLogged: boolean = false;
  @Input() avatar: IResponseAvatar;
  @Input() cliente: any;

  // Lista
  imagens: UserService[] = [];

  // Outros
  public destroyed$ = new Subject<boolean>();

  constructor(
    private _serviceToken: TokenStorageService,
    private UserService: UserService,
    private _serviceTab: TabService,
    private cliObs: ClienteService,
    private route: Router,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.avatar) {
      this.hideAgvIcon = true;
      this.editUserIcon = this.avatar?.urlImg;
      this.userIcon = this.editUserIcon;
    }
  }

  ngOnInit() {
    this.cliObs.currentCliente.subscribe((cliente) => {
      this.cliente = cliente;
      this.isLogged = cliente !== undefined && cliente.nome !== '';
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    const w = event.target as Window;
    const width = w.innerWidth;
    if (width >= 912) this.sidenavClose.emit();
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };

  abrirUsuario() {
    if (this.isLogged) {
      this.collapse = !this.collapse;
    } else {
      this.collapse = false;
      this.sidenavClose.emit();
      this.route.navigate(['/']);
    }
  }

  enviaFoto(event: any) {
    const pictureFile = event.target.files[0] as File;

    if (pictureFile) {
      const allowedTypes = ['image/png', 'image/jpeg'];
      const maxSize = 6 * 1024 * 1024; // 6 MB

      if (!allowedTypes.includes(pictureFile.type)) {
      } else if (pictureFile.size > maxSize) {
      } else {
        this.invalid = false;
        this.UserService.atualizarFotoPerfilUsuario(pictureFile).subscribe({
          next: (data: any) => {
            this.imagens = this.imagens.concat(data);
            this.editUserIcon = data.urlImg;
            this.userIcon = data.urlImg;
            localStorage.setItem('userImageUrl', this.editUserIcon);
            this.showEditaFoto = true;
          },
          error: (error: any) => {
            console.error('Erro ao fazer upload da foto.', error);
          },
        });
      }
    }
  }

  irFaturaCompleta(e: Event) {
    e.stopPropagation();
    if (Validator.validaEventKeyPressClick(e)) {
      this.sidenavClose.emit();
      this._serviceTab.changeTab(1);
      localStorage.removeItem('codFornecimento');
      localStorage.removeItem('fornecimento');
      this.route.navigateByUrl('/fatura-completa');
    }
  }

  redirecionaPrivacidade() {
    window.open(
      'http://site.sabesp.com.br/site/interna/politica_privacidade.aspx',
      '_blank'
    );
  }

  redirecionaAviso(e: Event) {
    if (Validator.validaEventKeyPressClick(e)) {
      this.sidenavClose.emit();
      const element = document.getElementById('noticia');
      if (element) {
        const navbarHeight =
          document.getElementById('navegacao-secoes')?.offsetHeight || 0;
        const offset = element.offsetTop - navbarHeight + 35;
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
    }
  }

  public signOut(e: Event) {
    if (Validator.validaEventKeyPressClick(e)) {
      this.cliObs.changeCliente(undefined);
      this._serviceToken.signOut();
      this.route.navigate(['/']);
    }
  }

  redirectAlteraSenha(e: Event) {
    if (Validator.validaEventKeyPressClick(e)) {
      this.route.navigateByUrl('/alterar-senha');
    }
  }

  goToNegocios(e: Event): void {
    if (Validator.validaEventKeyPressClick(e)) {
      this.sidenavClose.emit();
      localStorage.removeItem('codFornecimento');
      localStorage.removeItem('fornecimento');
      if (
        this._serviceToken.checkToken() &&
        this._serviceToken.getTipoUsuario() === 'PF,PJ'
      )  {
        localStorage.setItem('FluxoPJ', 'true');
        this.route.navigateByUrl('/negocios/empresa');
      } else {
        localStorage.removeItem('FluxoPJ');
        this.dialog.open(DialogNovaAgvAvisoCNPJComponent, {
          maxWidth: '500px',
          panelClass: 'dialog-aviso-cnpj',
        });
        this.route.navigate(['/fatura-completa']);

      }
    }
  }

  redirectMinhasSolicitacoes() {
    this.route.navigateByUrl('/minhas-solicitacoes');
  }

  redirecMinhaConta(e: Event) {
    if (Validator.validaEventKeyPressClick(e)) {
      this.route.navigateByUrl('/minha-conta');
    }
  }

  capitalize(text: string): string {
    return Regex.capitalize(text);
  }
}

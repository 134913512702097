<div
  role="group"
  id="etapas-processo"
  class="etapas-processo"
  *ngIf="showStepper"
>
  <div
    tabindex="0"
    role="group"
    id="etapas-processo__etapa"
    class="etapas-processo__etapa"
    [ngClass]="{ active: etapa.ativa }"
    *ngFor="let etapa of etapas; let last = last"
  >
    <div class="bolinha"></div>
    <ng-container *ngIf="isMobile; else desktopView">
      <div *ngIf="etapa?.rotaAtiva " class="etapa">{{ etapa.etapa }}</div>
    </ng-container>
    <ng-template #desktopView>
      <div class="etapa">{{ etapa.etapa }}</div>
    </ng-template>    <div class="divisao" *ngIf="!last"></div>
  </div>
</div>

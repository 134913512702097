<div id="selecao-endereco-msg-erro" role="group" tabindex="0" class="address-warning">
  <div id="selecao-endereco-msg-erro-titulo" role="group" class="address-warning__title" aria-hidden="true">endereço não encontrado</div>

  <div id="selecao-endereco-msg-erro-descricao" role="group" class="address-warning__content" aria-hidden="true">
    Se você não encontrou algum endereço ou fornecimento na lista, é porque não
    é o titular dele. Caso você entenda que deveria ser, acesse o serviço de
    <strong id="troca-titularidade" (click)="clicaTroca()" (keypress)="pressTroca($event)" mat-dialog-close [mat-dialog-close]="true"
      class="underline"
      tabindex="0"
      title="será direcionado para a tela de troca de titularidade"
      >Troca de titularidade</strong
    >. Caso você já seja o titular ou o problema persistir, entre em contato
    pelo nosso
    <strong><span class="underline" title="será direcionado para o chat de ajuda ao consumidor" id="chat" (keypress)="pressChat($event)" ><a [routerLink]="['https://sabesp-chat.sabesp.com.br/']" target="_blank" mat-dialog-close>chat</a></span></strong>
    ou pelo telefone
    <strong><a tabindex="0" title="telefone para contato" target="_blank" class="telefone" [routerLink]="['tel:0800 055 0195']" mat-dialog-close>0800 055 0195</a></strong>,
    para que possamos ajudá-lo a resolver esta situação.
  </div>

  <div id="selecao-endereco-msg-erro-fechar" role="group" class="address-warning__footer">
    <button id="selecao-endereco-msg-erro-botao-fechar" title="Botão Fechar pop-up" mat-button class="close-button"
      mat-dialog-close [mat-dialog-close]="true">
      <mat-icon class="close_icn" [title]="'Fechar modal'">close</mat-icon>
    </button>
  </div>
</div>
import { createReducer, on } from '@ngrx/store';
import { setNoticias } from './noticias.actions';

export const initialState: any[] = [];

export const noticiaKey = 'noticia';

export const NoticiaReducer = createReducer(
  initialState,
  on(setNoticias, (state, { noticias }) => {
    return { ...state, noticias };
  })
);

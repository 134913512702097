import { Validator } from 'src/app/shared/utils/validator';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Regex } from 'src/app/shared/utils/regex';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EventEmitterService } from 'src/app/event-emits.service';
import { ClienteCompleto } from 'src/app/core/models/service/cadastroCliente';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { TrocaTitularService } from 'src/app/services/troca-titularidade.service';

@Component({
  selector: 'agv-cadastrar-cliente',
  templateUrl: './cadastrar-cliente.component.html',
  styleUrls: ['./cadastrar-cliente.component.scss'],
})
export class CadastrarClienteComponent implements OnInit {
  @Output() infoCliente = new EventEmitter();
  clienteNovoNeta: ClienteCompleto = new ClienteCompleto();
  arquivosSelecionados?: FileList;
  validacoes: FormGroup;

  nomeArquivoFrente: string = 'Carregar arquivo';
  nomeArquivoVerso: string = 'Carregar arquivo';
  nomeDocumentoFrente: string;
  nomeDocumentoVerso: string;
  authToken: string;

  arquivoTamExcedido: boolean;
  dadosIncorretos: boolean;
  dataCorrecaoValid: boolean;
  enableButton: boolean;
  versoDesabilitado: boolean;
  campoDesabilitado: boolean;
  tipoArquivoInvalido: boolean;
  documentoDivergente: boolean;
  documentoInvalido: boolean;
  documentoInfoFaltante: boolean;
  loadingFrente: boolean;
  loadingVerso: boolean;
  erroDocVerso: boolean;
  erroDocFrente: boolean;
  docFrente: any;
  docVerso: any;

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer, private router: Router,
    private readonly ocrService: TrocaTitularService, private storageService: TokenStorageService) {
    this.matIconRegistry.addSvgIcon
    this.matIconRegistry.addSvgIcon(
      'date_check_in',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../assets/imgs/svg/icn_data.svg'
      )
    );
    this.validacoes = new FormBuilder().group({
      nome: ['', Validators.required],
      dataNascimento: ['', [Validators.required, this.ValidateDataNascimento]],
      celular: ['', [Validators.minLength(14)]],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    this.validacoes.controls['nome'].disable();
    this.validacoes.controls['dataNascimento'].disable();
    this.versoDesabilitado = true;
    this.campoDesabilitado = true;
    const token: string | null = this.storageService.getToken();
    token ? this.authToken = token : '';
    const storagedCpf = this.storageService.getUser()
    if (storagedCpf) {
      this.clienteNovoNeta.cpf = Regex.maskCpf(storagedCpf.cpf);
    }
  }

  get form(): { [key: string]: AbstractControl; } {
    return this.validacoes.controls;
  }

  setMessageErrorDoc(): string {
    if (this.arquivoTamExcedido) {
      return 'Arquivo possui mais de 20MB. Escolha um arquivo menor, no formato .jpg, .jpeg, ou .png. e tente novamente.';
    }
    if (this.tipoArquivoInvalido) {
      return 'Formato não suportado. Escolha um arquivo de até 20MB, no formato .jpg, .jpeg, ou .png. e tente novamente.';
    }
    if (this.documentoDivergente) {
      return 'Documento selecionado é diferente o CPF inserido, por favor verifique o documento e tente novamente.';
    }
    if (this.documentoInvalido) {
      return 'O documento selecionado é inválido porque não possui CPF, selecione um documento válido e tente novamente.';
    }
    if (this.documentoInfoFaltante) {
      return 'O documento selecionado é inválido porque não possui CPF, selecione um documento válido e tente novamente.';
    } else return '';
  }

  private verificarDadosCapturadosDaFrente(): boolean {
    if (this.clienteNovoNeta.nome && this.clienteNovoNeta.cpf) {
      return true;
    } else {
      return false;
    }
  }

  ValidateDataNascimento(control: FormControl): { [key: string]: boolean } | null {
    let data = control.value;
    let difIdade = Date.now() - new Date(data).getTime();
    if (difIdade < 568080000000) {
      return { validIdade: true };
    }
    return null;
  }

  selecionarArquivo(event: any, tipo: string): void {
    this.setLoading(tipo);
    this.limparAquivo(tipo, event);
    this.arquivosSelecionados = event.target.files;
    this.validacoes.controls['nome'].disable();
    this.validacoes.controls['dataNascimento'].disable();
    this.arquivoTamExcedido = false;
    this.campoDesabilitado = true;
    if (this.arquivosSelecionados) {
      const file: File | null = this.arquivosSelecionados.item(0);
      if (file) {
        this.validaImagem(file, tipo);
        const formDataDoc = new FormData();
        formDataDoc.append('fileBase64', file, file.name);
        if (tipo === 'frente') {
          this.ocrService.getInfosDocumentoFrente(formDataDoc, this.authToken).subscribe({
            next: (data) => {
              if (data.tipo) {
                if (!data.cpf && data.tipo !== 'rg' && data.tipo !== 'carteira-de-identidade') {
                  this.documentoInfoFaltante = true;
                  this.erroDocFrente = true;
                  return
                }
                if (data.cpf) {
                  if (data.cpf !== Regex.removeMaskCpf(this.clienteNovoNeta.cpf)) {
                    this.documentoDivergente = true;
                    this.erroDocFrente = true;
                    return
                  }
                }
                this.clienteNovoNeta.nome = data.nome ? data.nome : this.clienteNovoNeta.nome;
                this.clienteNovoNeta.dataNascimento = data.dataNascimento ? data.dataNascimento.split('-').reverse().join('/') : this.clienteNovoNeta.dataNascimento;
                this.setDocFrenteVerso('frente', file, file.name);
              } else {
                this.versoDesabilitado = true;
                this.campoDesabilitado = false;
                this.validacoes.controls['nome'].enable();
                this.validacoes.controls['dataNascimento'].enable();
                this.setDocFrenteVerso('frente', file, file.name);
              }
            },
            error: (erro) => {
              this.loadingFrente = false;
              this.erroDocFrente = true;
              if (erro.status === 550) {
                this.documentoInvalido = true;
              }
            },
          });
        } else if (tipo === 'verso') {
          this.ocrService.getInfosDocumentoVerso(formDataDoc, this.authToken).subscribe({
            next: (data) => {
              if (!data.cpf && data.tipo !== 'cnh') {
                this.documentoInfoFaltante = true;
                this.erroDocVerso = true;
                return
              }
              if (data.cpf) {
                if (data.cpf !== Regex.removeMaskCpf(this.clienteNovoNeta.cpf)) {
                  this.documentoDivergente = true;
                  this.erroDocVerso = true;
                  return
                }
              }
              this.clienteNovoNeta.nome = data.nome ? data.nome : this.clienteNovoNeta.nome;
              this.clienteNovoNeta.dataNascimento = data.dataNascimento ? data.dataNascimento.split('-').reverse().join('/') : this.clienteNovoNeta.dataNascimento;
              this.setDocFrenteVerso('verso', file, file.name);
            },
            error: (erro) => {
              this.loadingVerso = false;
              this.erroDocVerso = true;
              if (erro.status === 550) {
                this.documentoInvalido = true;
              }
            },
          });
        }
      }
    }
  }

  setLoading(tipo: string): void {
    if (tipo === 'frente') {
      this.loadingFrente = true;
    } else {
      this.loadingVerso = true;
    }
  }

  limparAquivo(tipo: string, evento: any) {
    if (Validator.validaEventKeyPressClick(evento)) {
      if (tipo === 'frente') {
        this.docFrente = '';
        this.nomeArquivoFrente = 'Carregar arquivo';
      }
      else {
        this.docVerso = '';
        this.nomeArquivoVerso = 'Carregar arquivo';
      }
    }
  }

  validaImagem(file: File, tipo:string): void {
    this.tipoArquivoInvalido = false;
    this.arquivoTamExcedido = false;
    const fileSize = file.size / 1024 / 1024; // para mb
    if (fileSize > 20) {
      this.arquivoTamExcedido = true;
      if( tipo=== 'frente'){
        this.erroDocFrente = true;
      } else{
        this.erroDocVerso = true;
      }
    }
    if (file.type != 'image/jpg' && file.type != 'image/jpeg' && file.type != 'image/png') {
      this.tipoArquivoInvalido = true;if( tipo=== 'frente'){
        this.erroDocFrente = true;
      } else{
        this.erroDocVerso = true;
      }
    }
  }

  setDocFrenteVerso(type: string, file: any, fileName: string) {
    const dadosCapturadosDaFrente = this.verificarDadosCapturadosDaFrente();
    this.versoDesabilitado = !dadosCapturadosDaFrente;
    this.convertFileToBase64(file, type);
    if (type === 'frente') {
      if (fileName.length > 15) {
        this.nomeDocumentoFrente = fileName.substring(0, 14) + '...';
      } else this.nomeDocumentoFrente = fileName;
      this.loadingFrente = false;
    } else {
      if (fileName.length > 15)
        this.nomeDocumentoVerso = fileName.substring(0, 14) + '...';
      else this.nomeDocumentoVerso = fileName;
    }
  }

  emitirDadosCliente(): void {
    if (this.validacoes.valid) {
      this.infoCliente.emit(this.clienteNovoNeta);
      EventEmitterService.get('infoCliente').emit(this.clienteNovoNeta);
    }
  }

  mascaraTelefone() {
    this.clienteNovoNeta.celular = Regex.maskTelefone(this.clienteNovoNeta.celular);
  }

  selecionarLabelArquivo(tipo: string, evento: any) {
    if (Validator.validaEventKeyPressClick(evento)) {
      let element = document.getElementById(tipo);
      element?.click();
    }
  }

  convertFileToBase64(file: File, campo: string): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      if (campo === 'frente') {
        this.docFrente = event.target.result;
      } else {
        this.docVerso = event.target.result;
      }
    };
    reader.readAsDataURL(file);
  }

  capturaNovosdados(e: any) {
    this.clienteNovoNeta.nomeCorrecao = e.nome;
    this.clienteNovoNeta.dataNascimentoCorrecao = e.dataNascimento.split('-').reverse().join('/');
    let difIdade = Date.now() - new Date(e.dataNascimento).getTime();
    this.dataCorrecaoValid = difIdade > 568080000000;
  }
}

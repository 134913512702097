import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GerenciadorCookiesDialogComponent } from '../gerenciador-cookies-dialog/gerenciador-cookies-dialog.component';
import { PrivacyStorageServiceService } from 'src/app/services/privacy-storage-service.service';
import { ItemCookie } from 'src/app/core/models/@types/ItemCookies';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';

@Component({
  selector: 'app-aviso-cookies',
  templateUrl: './aviso-cookies.component.html',
  styleUrls: ['./aviso-cookies.component.scss'],
})
export class AvisoCookiesComponent implements OnInit {
  public mostrar: boolean = true;
  constructor(
    public dialog: MatDialog,
    private tokenService: TokenStorageService,
    private privacyStorageServiceService: PrivacyStorageServiceService
  ) {}
  itens: ItemCookie[] = [
    {
      titulo: 'Cookies Necessários',
      texto:
        'Os cookies necessários são cruciais para as funções básicas do site e o site não funcionará como pretendido sem eles. Esses cookies não armazenam nenhum dado pessoalmente identificável.',
      expandido: true,
      autorizado: true,
      cookie: 'cookiesNecessarios',
    },
    {
      titulo: 'Cookies Funcionais',
      texto:
        'Cookies funcionais ajudam a executar certas funcionalidades, como compartilhar o conteúdo do site em plataformas de mídia social, coletar feedbacks e outros recursos de terceiros.',
      expandido: false,
      autorizado: false,
      cookie: 'cookiesFuncionais',
    },
    {
      titulo: 'Cookies Analíticos',
      texto:
        'Cookies analíticos são usados para entender como os visitantes interagem com o site. Esses cookies ajudam a fornecer informações sobre métricas o número de visitantes, taxa de rejeição, fonte de tráfego, etc.',
      expandido: false,
      autorizado: false,
      cookie: 'cookiesAnaliticos',
    },
    {
      titulo: 'Cookies Anúncios',
      texto:
        'Cookies funcionais ajudam a executar certas funcionalidades, como compartilhar o conteúdo do site em plataformas de mídia social, coletar feedbacks e outros recursos de terceiros.',
      expandido: false,
      autorizado: false,
      cookie: 'cookiesAnuncios',
    },
  ];
  ngOnInit(): void {}

  abrirGerenciadorCookies() {
    this.dialog.open(GerenciadorCookiesDialogComponent, {
      panelClass: 'cookies-dialog',
      width: '600px',
      data: this.itens,
    });
  }

  aceitarTodos() {
    this.privacyStorageServiceService.consentimentoCookies('true');
    this.privacyStorageServiceService.configurarGoogleAnalytics();
    this.mostrar = false;
  }


  rejeitarTodos() {
    this.mostrar = false;
    this.privacyStorageServiceService.consentimentoCookies('false');
  }
}

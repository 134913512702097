import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-cancelamento',
  templateUrl: './dialog-cancelamento.component.html',
  styleUrls: ['./dialog-cancelamento.component.scss'],
})
export class DialogCancelamentoComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogCancelamentoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dados: any
  ) {}

  ngOnInit(): void {}

  cancel(e: Event) {
    this.router.navigateByUrl('fatura-completa');
    this.dialogRef.close();
  }
  closeModal() {
    this.dialogRef.close();
  }
}

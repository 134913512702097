import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { carregaNoticias } from 'src/app/core/store/noticias/noticias.actions';
import { selectNoticia } from 'src/app/core/store/noticias/noticias.selectors';
@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss'],
})
export class NoticiaComponent implements OnInit {
  noticiasCards: any;
  noticiasSubscription$: Observable<any> = this.store.select(selectNoticia);
  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.store.dispatch(carregaNoticias());

    this.noticiasSubscription$.subscribe((response: any) => {
      this.noticiasCards = response?.noticias;
    });
  }

  abrirNoticia(noticia: any) {
    this.router.navigate(['/sabesp-informa', noticia.slug]);
  }
}

import { Component, Input } from '@angular/core';
import { Validator } from '../../utils/validator';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-modal-home',
  templateUrl: './modal-home.component.html',
  styleUrls: ['./modal-home.component.scss'],
})
export class ModalHomeComponent {
  constructor(private router: Router) { }
  @Input() tituloPagina: String = 'adiciona aqui o titulo da pagina';
  @Input() tituloModal: String = ' adicione o titulo do modal '
  @Input() descricaoModal: String = 'escreva a descrição do modal';
  @Input() mostraBotao: boolean = false;


  ngOnInit(): void { }

  fecharModal(event: Event): void {
    if (Validator.validaEventKeyPressClick(event)) {
    }
  }
  redireciona() {
    this.router.navigateByUrl('/fatura-completa')
  }



}

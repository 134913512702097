<div class="pagination-container" *ngIf="data.length > pageSize">
  <div
    tabindex="0"
    class="pagination-container__previous"
    (click)="onFirstPage()"
  >
    <img
      [ngStyle]="{
        filter: currentTheme === 'theme-dark' ? ' brightness(0) invert(1)' : 'inherit'
      }"
      src="assets/imgs/png/previous.png"
      alt="Icone de voltar a primeira tela"
    />
  </div>

  <div
    tabindex="0"
    class="pagination-container__previous-one"
    (click)="onPreviousPage()"
  >
    <img
      [ngStyle]="{
        filter: currentTheme === 'theme-dark' ? ' brightness(0) invert(1)' : 'inherit'
      }"
      src="assets/imgs/png/previous-one.png"
      alt="Icone de voltar para a tela anterior"
    />
  </div>

  <div
    tabindex="0"
    class="without-border-left"
    *ngFor="let page of pages"
    [ngClass]="[page === currentPage ? 'number-current' : 's']"
    (click)="onChangePage(page, $event)"
  >
    <span>{{ page }}</span>
  </div>

  <div
    tabindex="0"
    class="pagination-container__next-one without-border-left"
    (click)="onNextPage()"
  >
    <img src="assets/imgs/png/next-one.png" alt="Icone de avançar uma tela" />
  </div>

  <div
    tabindex="0"
    class="pagination-container__next without-border-left"
    (click)="onLastPage()"
  >
    <img
      [ngStyle]="{
        filter: currentTheme === 'theme-dark' ? ' brightness(0) invert(1)' : 'inherit'
      }"
      src="assets/imgs/png/next.png"
      alt="Icone de avancar para a ultima tela"
    />
  </div>
</div>

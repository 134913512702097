<div id="login-component" class="login">
  <div
    role="group"
    id="login-component_titulo"
    class="login_info"
    tabindex="0"
    aria-label="Bem-vindo à Sabesp, Agência Virtual. Insira seu login e senha para ter acesso completo aos serviços disponíveis"
  >
    <h2 tabindex="-1" aria-hidden="true" class="agencia-virtual-titulo">
      Bem-vindo à Sabesp | Agência Virtual
    </h2>
    <p tabindex="-1" aria-hidden="true">
      Mantenha seus dados sempre atualizados. Realize sua
      <a [routerLink]="'atualizacao-cadastral'" class="link"
        >Atualização cadastral</a
      >. Caso não tenha acesso à Agência Virtual, clique em
      <a
        tabindex="0"
        aria-label="clique aqui para realizar sua atualização cadastral"
        role="link"
        tabindex="0"
        routerLink="/cadastrar"
        class="link"
        >Meu Primeiro Acesso</a
      >
    </p>
  </div>

  <app-mensagem
    *ngIf="mensagemErroInput"
    [mensagem]="mensagemErroInput"
  ></app-mensagem>

  <app-login-mensagem
    *ngIf="mensagemErro && senhaInvalida"
    [mensagemErro]="mensagemErro"
  ></app-login-mensagem>

  <div
    role="form"
    id="login-component_formulario"
    class="login_inputs"
    *ngIf="!mostrarRecaptcha && !lodingRecaptha"
  >
    <mat-form-field
      appearance="fill"
      class="login_input_cpf"
      id="login_input_cpf"
    >
      <label for="cpf" class="login_input_cpf__label">CPF</label>
      <input
        tabindex="0"
        aria-label="Informe seu CPF"
        id="cpf"
        type="search"
        matInput
        #first
        maxlength="14"
        (ngModel)="(cpf)"
        [value]="cpf"
        (focus)="clean()"
        (keyup)="inputMaskCpf()"
        (keydown)="inputMaskCpf()"
        (input)="inputMaskCpf()"
        #inputCpf
        [ngClass]="{ fakePlaceholder: cpf === '000.000.000-00' }"
        placeholder="Digite seu CPF"
      />
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="login_input_senha"
      id="login_input_senha"
    >
      <div>
        <label for="senha" class="login_input_senha__label">Senha</label>
        <input
          tabindex="0"
          aria-label="Informe sua senha de acesso"
          id="senha"
          [type]="hideSenha ? 'password' : 'text'"
          matInput
          #first
          [(ngModel)]="senha"
          placeholder="Digite sua senha"
        />
      </div>
      <mat-icon
        id="botao-show-nova-senha-confirmacao"
        class="icone-olho"
        (click)="validaClick($event)"
        (keypress)="validaClick($event)"
        >{{ hideSenha ? "visibility_off" : "visibility" }}</mat-icon
      >
    </mat-form-field>
  </div>
  <div
    role="group"
    id="login-component_acoes"
    class="login_action"
    *ngIf="!mostrarRecaptcha && !lodingRecaptha"
  >
    <div
      class="login_action__recovery"
      tabindex="0"
      aria-label="Caso tenha esquecido a senha clique aqui e será redirecionado para a recuperação de senha!"
    >
      <span id="login-component_recuperar-senha" (click)="recuperar()"
        >Esqueci minha senha</span
      >
    </div>
  </div>

  <div class="recaptcha-wrapper">
    <div class="texto-recaptcha" *ngIf="lodingRecaptha">
      <p>Carregando recaptcha...</p>
    </div>
    <div *ngIf="!lodingRecaptha && mostrarRecaptcha" class="recaptcha-items">
      <div
        [ngClass]="{ 'recaptcha-dark': currentTheme === 'theme-dark' }"
        #divRecaptcha
        id="recaptcha"
      ></div>
      <button
        role="button"
        class="login_recaptcha_icon"
        (click)="resetRecaptcha()"
      >
        <agv-icon
          [name]="'icn_reload'"
          title="Botão para recarregar recaptcha"
          tabindex="0"
          [ngClass]="{ 'recaptcha-dark': currentTheme === 'theme-dark' }"
        ></agv-icon>
      </button>
    </div>
  </div>

  <button
    *ngIf="!mostrarRecaptcha && !loadLogin"
    role="button"
    id="login-component_logar"
    class="login_btn-entrar"
    (click)="verificaLoginRecaptcha()"
    mat-raised-button
    color="primary"
    [disabled]="disableBtnVerificaPasswordLogin()"
    [ngClass]="{
      disabled: disableBtnVerificaPasswordLogin()
    }"
    tabindex="0"
    aria-label="Clique aqui para logar após informar o login, senha e validar o recaptcha."
  >
    Entrar
  </button>

  <button
    role="button"
    id="login-component_logar"
    class="login_btn-entrar"
    *ngIf="!loadLogin && mostrarRecaptcha"
    (click)="logar()"
    mat-raised-button
    color="primary"
    [disabled]="disableBtn()"
    [ngClass]="{ disabled: disableBtn() }"
    tabindex="0"
    aria-label="Clique aqui para logar após informar o login, senha e validar o recaptcha."
  >
    Entrar
  </button>
  <button
    id="loadingLogin"
    type="button"
    class="loadingLogin"
    *ngIf="loadLogin"
  >
    <agv-icon class="loadingLogin__img" [name]="'loading'"></agv-icon>
  </button>
  <button
    role="button"
    id="login-component_cadastrar"
    class="login_btn-cadastrar"
    (click)="redirecionar()"
    mat-stroked-button
    color="primary"
    tabindex="0"
    aria-label="Botão de primeiro acesso, se você não possui cadastro clique"
  >
    Meu primeiro acesso
  </button>
</div>

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  atualizaCNPJRepresentante,
  carregaEmpresaCNPJS,
  carregarCNPJRepresentante,
  erroCarregaEmpresaCNPJ,
  sucessoCarregaEmpresaCNPJ,
  sucessoCarregarCNPJRepresentante,
} from './empresa-cnpj.actions';
import {
  catchError,
  filter,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { selectEmpresaCNPJState } from './empresa-cnpj.selector';
import { EmpresaCNPJService } from 'src/app/services/empresa-cnpj.service';
import { Injectable } from '@angular/core';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { EmpresaCNPJS } from '../../models/empresas/empresa';

@Injectable({
  providedIn: 'root',
})
export class EmpresaCNPJEffects {
  /**
   *
   */
  constructor(
    private _acoes$: Actions,
    private _store: Store,
    private empresaService: EmpresaCNPJService,
    private _tokenStorage: TokenStorageService
  ) {}

  carregaEmpresaCNPJ$ = createEffect(() =>
    this._acoes$.pipe(
      ofType(carregaEmpresaCNPJS),
      withLatestFrom(this._store.pipe(select(selectEmpresaCNPJState))),
      filter(([_, empresaCNPJState]) => {
        return empresaCNPJState?.empresaCnpj == null;
      }),
      mergeMap(() => {
        return this.empresaService.getRepresentanteCNPJFornecimentos().pipe(
          map((empresaCNPJ: EmpresaCNPJS) => {
            return sucessoCarregaEmpresaCNPJ({ empresaCNPJ: empresaCNPJ });
          }),
          catchError((error) =>
            of(erroCarregaEmpresaCNPJ({ error: error?.message }))
          )
        );
      })
    )
  );

  atualizaCNPJFornecimentoSelecionado$ = createEffect(
    () =>
      this._acoes$.pipe(
        ofType(atualizaCNPJRepresentante),
        switchMap((representante) => {
          return from(
            this._tokenStorage.setCNPJRepresentante(
              representante.representante ?? null
            )
          );
        })
      ),
    { dispatch: false }
  );

  verificaEmpresaCNPJ$ = createEffect(() =>
    this._acoes$.pipe(
      ofType(carregarCNPJRepresentante),
      withLatestFrom(this._store.pipe(select(selectEmpresaCNPJState))),
      filter(([_, empresaCNPJState]) => empresaCNPJState.representante == null),
      switchMap(([_, empresaCNPJState]) => {
        const cnpjSelecionadoSalvo: any | null =
          this._tokenStorage.getCNPJRepresentante() ?? null;

        const cnpjSelecionado =
          empresaCNPJState.representante ?? cnpjSelecionadoSalvo; // Verifique se você está acessando a propriedade corretamente
        if (cnpjSelecionado && cnpjSelecionado?.representante) {
          if (
            !cnpjSelecionadoSalvo ||
            cnpjSelecionado.cnpj !== cnpjSelecionadoSalvo.cnpj
          ) {
            this._tokenStorage.setCNPJRepresentante(cnpjSelecionado);
          }

          return of(
            sucessoCarregarCNPJRepresentante({
              representante: cnpjSelecionado,
            })
          );
        }
        return of(sucessoCarregarCNPJRepresentante({ representante: null }));
      })
    )
  );
}

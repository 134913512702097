import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { selectNoticia } from 'src/app/core/store/noticias/noticias.selectors';
import { Validator } from '../../utils/validator';
import { NoticiaService } from 'src/app/modules/site-admin/noticia/noticia.service';
import { ModalOpcoesComponent } from '../modal_opcoes/modal_opcoes.component';
import { InfosModalOpcoes } from 'src/app/core/models/infosModalOpcoes';

@Component({
  selector: 'app-noticia-lista',
  templateUrl: './noticia-lista.component.html',
  styleUrls: ['./noticia-lista.component.scss'],
})
export class NoticiaListaComponent implements OnInit {
  @Input() noticiasCards: any;
  @ViewChild('popupRemoverNoticia') popupRemoverNoticiaElement: ElementRef;
  @ViewChild(ModalOpcoesComponent, {static: false})
  modalOpcoes: ModalOpcoesComponent;
  infosOpcoes: InfosModalOpcoes;
  noticias: any;
  noticiaFocus: any;
  quantidadeNoticiasExibidas = 4;
  url: URL = new URL(window.location.href);
  buttonCopy: string = '';
  readonly noticiasPorPage: number = 4;
  noticiaIndexToRemove: number;
  buttonText = 'Saiba Mais';
  isAdmin = false;
  noticiasSubscription$: Observable<any> = this.store.select(selectNoticia);
  @Output() abrirNoticiaEvent: EventEmitter<any> = new EventEmitter<any>(); // Novo EventEmitter
  @Output() editarNoticiaEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(private router: Router, private store: Store, private noticiaService: NoticiaService) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentRoute = this.router.url.split('/')[1];
        if (currentRoute === 'admin') {
          this.buttonText = 'EDITAR';
          this.isAdmin = true;
        }
      });
  }

  ngOnInit() {
    setTimeout(() => {
      this.noticias = this.noticiasCards?.slice(0,this.quantidadeNoticiasExibidas);
    }, 500);
  }

  abrirNoticia(noticia: any) {
    this.abrirNoticiaEvent.emit(noticia); // Emitindo o evento com a notícia selecionada
  }

  compartilharNoticia(index: string) {
    this.buttonCopy = index;
    setTimeout(() => {
      this.buttonCopy = '';
    }, 5000);
  }

  editarNoticia(noticia: any) {
    if(this.isAdmin)
      this.editarNoticiaEvent.emit(noticia);
    else
      this.abrirNoticia(noticia)
  }

  verMaisNoticias(): void {
    let index = this.quantidadeNoticiasExibidas;
    this.quantidadeNoticiasExibidas += this.noticiasPorPage;
    this.noticias = this.noticiasCards?.slice(0,this.quantidadeNoticiasExibidas);
    setTimeout(() => {
      this.noticiaFocus = document.querySelector('.index_'+index);
      if(this.noticiaFocus != undefined) {
        this.noticiaFocus?.focus();
      }
    }, 100);
  }

  get mostrarBotaoVerMais(): boolean {
    return this.quantidadeNoticiasExibidas < this.noticiasCards.length;
  }

  removeNoticia() {
    this.noticiaService.removerNoticia(this.noticiaIndexToRemove).subscribe({
      next: () => {
        const index: number = this.noticias.findIndex((element: any) => element["codigo"] == this.noticiaIndexToRemove);
        if (index !== -1) {
            this.noticias.splice(index, 1);
        }
      },
      error: (error) => {
      }
    });
  }

  dataFinalFormatada(dataFinal: any): Date {
    return new Date(dataFinal.toString().split('T')[0] + ' 03:00:00 GMT');
    //return format(date, 'dd/MM/yyyy');
  }

  openModal(index: any, event: any) {
    if(Validator.validaEventKeyPressClick(event)) {
      this.noticiaIndexToRemove = index;
      this.openDialogOpcoes();
    }
  }

  openDialogOpcoes() {
    this.infosOpcoes = {
      title: 'Remover Notícia',
      content: 'Você tem certeza que deseja remover esta notícia?',
      icon: 'icon_erro',
      txtBtn1: 'Remover',
      txtBtn2: 'Cancelar',
    };
    this.modalOpcoes.openModal(this.infosOpcoes);
  }
}

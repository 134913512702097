<section class="containerModalExcluir"> 
    <div class="cabecalhoModal">
        <agv-icon id="icnExclamacao" class="imgExclamacao" role="img" name="icn_erro94"></agv-icon>
        <p class="titulo" tabindex="0">Excluir Cartão</p>
        <p class="desc" tabindex="0">Você tem certeza que deseja excluir o cartão?</p>
    </div>
    <div tabindex="0" class="campoCartao">
        <agv-icon id="icnBandeiraFluxoCartaoSalvo" class="bandeiraCartao " role="img"
            [name]="cartao.content.brand"></agv-icon>
        <div tabindex="0" class="infoCartao">
            <p>{{cartao.content.brand.charAt(0).toUpperCase() + cartao.content.brand.slice(1)}}  **** {{cartao.content.last_digits}}</p>
            <p>Vencimento: {{cartao.content.exp_month}}/{{cartao.content.exp_year}}</p>
        </div>
    </div>
    <div class="containerBotao">
        <button tabindex="0" class="remover" [mat-dialog-close]="fechaModal" (click)="removeCartao(cartao.content.idCartao)">Remover</button>
        <button tabindex="0" [mat-dialog-close]="true" class="cancelar">Cancelar</button>
    </div>
</section>
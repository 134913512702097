<section *ngIf="!isMobile" class="tabela-tarifa">
    <table>
        <thead>
            <tr class="tabela-tarifa_header-linha">
                <th scope="col">Categoria</th>
                <th scope="col">Faixa de consumo m³/mês</th>
                <th scope="col">Tarifas de água - (em R$)</th>
                <th scope="col" class="last-item">Tarifas de esgoto - (em R$)</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let tarifa of dadosTarifa" class="tabela-tarifa_body-tabela">
                <th scope="row"><p class="categoria">{{tarifa.categoria}}</p></th>
                <td>
                    <p *ngFor="let consumo of tarifa.faixas" class="linha-tamanho-tabela">{{consumo}}</p> 
                </td>
                <td>
                    <p *ngFor="let tarifaAgua of tarifa.tarifaAgua" class="linha-tamanho-tabela">{{tarifaAgua}}</p>
                </td>
                <td class="last-item">
                    <p *ngFor="let tarifaEsgoto of tarifa.tarifaEsgoto" class="linha-tamanho-tabela">{{tarifaEsgoto}}</p>
                </td>
            </tr>
        </tbody>
    </table>
</section>
<section *ngIf="isMobile" class="item-expansivo-mobile">
    <mat-accordion *ngFor="let tarifa of dadosTarifa">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p class="titulo-categoria">{{tarifa.categoria}}</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="tabela-tarifa">
                <table>
                    <thead>
                        <tr class="tabela-tarifa_header-linha">
                            <th scope="col">Faixa de consumo m³/mês</th>
                            <th scope="col">Tarifas de água - (em R$)</th>
                            <th scope="col" class="last-item">Tarifas de esgoto - (em R$)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tarifa of dadosTarifa" class="tabela-tarifa_body-tabela">
                            <td>
                                <p *ngFor="let consumo of tarifa.faixas" class="linha-tamanho-tabela">{{consumo}}</p> 
                            </td>
                            <td>
                                <p *ngFor="let tarifaAgua of tarifa.tarifaAgua" class="linha-tamanho-tabela">{{tarifaAgua}}</p>
                            </td>
                            <td class="last-item">
                                <p *ngFor="let tarifaEsgoto of tarifa.tarifaEsgoto" class="linha-tamanho-tabela">{{tarifaEsgoto}}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </mat-expansion-panel>
    </mat-accordion>
</section>
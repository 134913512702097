export class GetStyleEnderecoComponent {
  style: any = {
    selectField: {
      'padding': '12px 21px 12px 21px',
      'border': '1px solid var(--color-gray-300)',
      'background-color': 'var(--color-blue-field-with-white)'
    },
    selectFieldErro: {
      'padding': '12px 21px 12px 21px',
      'border': '1px solid var(--color-failed)'
    },
    selectFieldDisable: {
      'padding': '12px 21px 12px 21px',
      'background': '#F2F2F2',
      'pointer-events': 'none'
    },
    content: {
      'text-align': 'start'
    },
    contentErro: {
      'text-align': 'start',
      'color': 'var(--color-failed)'
    },
    label: {
      'font-weight': '400',
      'font-size': '0.75rem',
      'line-height': '20px',
      'margin-bottom': '6.25px',
      'margin-top': '0px',
      'width': 'max-content'
    },
    placeholder: {
      'font-weight': '500',
      'font-size': '0.875rem',
      'line-height': '20px',
      'color': 'var(--color-white-with-gray-letter)'
    },
    placeholderErro: {
      'font-weight': '500',
      'font-size': '0.875rem',
      'line-height': '20px',
      'color': 'var(--color-failed)'
    },
    img: {
      'width': '12px',
      'height': '6px'
    },
    option: {
      'font-weight': '500',
      'font-size': '0.875rem',
      'line-height': '20px',
      'color': 'var(--color-black-white-letter)',
      'text-align': 'start',
      'background-color': 'var(--color-blue-field-with-white)'
    }
  };


  getStyle(): any {
    return this.style;
  }
}

export class ListaTipoCivico {
  tipoCivicos = [{
    id: "G02",
    codigo: "G",
    descricao: "GLEBA"
  },
  {
    id: "KM02",
    codigo: "KM",
    descricao: "QUILÔMETRO"
  },
  {
    id: "L02",
    codigo: "L",
    descricao: "LOTE"
  },
  {
    id: "ZF02N",
    codigo: "NUMERO",
    descricao: "NÚMERO"
  },
  {
    id: "Q02",
    codigo: "Q",
    descricao: "QUADRA"
  },
  {
    id: "S/N02",
    codigo: "S/N",
    descricao: "SEM NÚMERO"
  }];

  getDescricao():any[]{
    const descricao = this.tipoCivicos.map( x => {
      return x.descricao
    })
    return descricao
  }

  getLista():any[]{
    return this.tipoCivicos;
  }
}
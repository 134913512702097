import {
  Component,
  Input,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'agv-cardInfoFornec',
  templateUrl: './cardInfoFornec.component.html',
  styleUrls: ['./cardInfoFornec.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardInfoFornec {
  @ViewChild('infosFornec') infosFornecElement: ElementRef;
  @Input() isRepLegal:boolean;
  @Input() codFornecimento: string = '';
  @Input() nomeCliente: string = '';
  @Input() endereco: string = '';

  carregaFocus() {    
    setTimeout(() => {
      //Carrega o focus titulo
      this.infosFornecElement.nativeElement.focus();
    }, 150);
  }
}

import { createReducer, on } from "@ngrx/store";
import { sucessoAlteraFaturaEmail } from "./fatura-email.actions";
import { FaturaEmail } from "../../models/service/faturaEmail";

export interface IFaturaEmailState {
    faturaEmail?: FaturaEmail;
}

export const faturaEmailKey = 'FaturaEmail';

export const faturaEmailInitialState: IFaturaEmailState = {
    faturaEmail: undefined
};

export const FaturaEmailReducer = createReducer(
    faturaEmailInitialState,
    on(sucessoAlteraFaturaEmail, (state, action) => {
        const newState = Object.assign({}, state, { alteraEnvio: action.protocolo });
        return newState;
    })
);
<section class="lista-tarifas">
    <mat-accordion *ngFor="let tipoTarifa of listaTipoTarifas" hideToggle>
        <mat-expansion-panel (expandedChange)="this.tipoTarifa.aberto = !this.tipoTarifa.aberto">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <agv-icon [name]="tipoTarifa.img"></agv-icon>
                    {{tipoTarifa.tipo}}
                </mat-panel-title>
                <mat-panel-description>
                    <agv-icon [name]="this.tipoTarifa.aberto? 'icn_remove-light-grey' : 'icn_add'"></agv-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <section class="secao-tarifa">
                <div class="descricao-tarifa">
                    <p class="titulo">{{dadosTarifa.tipo}}</p>
                    <p>
                        {{tipoTarifa.lista.titulo}}
                    </p>
                </div>
                <app-tabela-tarifas [dadosTarifa]="tipoTarifa.lista.categoria"></app-tabela-tarifas>
            </section>
        </mat-expansion-panel>
    </mat-accordion>
</section>
import { createAction, props } from '@ngrx/store';
import {
  FormularioPLA,
  FormularioPLAState,
  ResponsavelPLA,
} from './primeira-ligacao.reducers';
import { EnderecolStatePLA } from '../../models/@types/EnderecoPLA';

export const getFormularioPla = createAction(
  '[FormularioPLA] Get Formulario PLA '
);

export const atualizaFormularioPLA = createAction(
  '[FormularioPLA] Post Formulario PLA',
  props<{ formulario: FormularioPLA }>()
);

export const atualizaFormularioImovel = createAction(
  '[FormularioPLA] Post Imovel PLA',
  props<{ enderecoImovel: EnderecolStatePLA }>()
);
export const atualizaFormularioCorrespondencia = createAction(
  '[FormularioPLA] Post Correspondencia PLA',
  props<{ enderecoCorrespondencia: EnderecolStatePLA }>()
);

export const atualizaResponsavel = createAction(
  '[FormularioPLA] Post Responsavel PLA',
  props<{ responsavel: ResponsavelPLA }>()
);

export const erroCarregaFormulario = createAction(
  '[FormularioPLA] Get Formulario erro',
  props<{ error: string }>()
);

export const sucessoCarregarFormulario = createAction(
  '[FormularioPLA] Formulario carregado com sucesso',
  props<{ formularios: FormularioPLAState }>()
);

export const deletaFormularioPLA = createAction(
  '[FormularioPLA] Deleta Formulario PLA'
);

<div id="forn-fat" role="group" class="fornecimento-fatura">
    <app-mensagem [mensagem]="mensagemErro"></app-mensagem>
    <label id="forn-fat-titulo-mobile" class="fornecimento-fatura_fornecimento-mobile" fxHide.gt-sm fxShow>Fornecimento</label>
    <div id="campo-busca-fornecimento" role="group" class="fornecimento-fatura_fornecimento">
        <label id="label-busca-fornecimento" for="codigoFornecimento" fxHide fxShow.gt-sm>Fornecimento</label>
        <input id="codigoFornecimento" name="codigoFornecimento" type="text" pattern="[1-9]" placeholder="Digite..." maxlength="20" title="Digite o fornecimento"
            [value]="codFornecimento" (input)="codFornecimento = campoFornecimento.value" #campoFornecimento [disabled]="!limpouFornecimento"
            (keyup)="validaForn(campoFornecimento)" [ngClass]="{'codigoFornecimentoDesabilitado' : !limpouFornecimento}" />
            
        <agv-button *ngIf="limpouFornecimento && !buscaFornecimento" [id]="'pesquisarFornecimento'" [type]="'1'" class="edit-button" (sendAction)="pesquisarFornecimento()"
            [text]="buttonLabel" [habilita]="codFornecimento !== ''" [loading]="buscaFornecimento">
        </agv-button>
    
        <span *ngIf="!limpouFornecimento" role="button" tabindex="0" aria-label="Limpar input" (click)="limpaFornecimento();" (keypress)="limpaFornecimento();">
            <mat-icon class="fornecimento_icn" [title]="'Limpar fornecimento'">highlight_off</mat-icon>
        </span>
    </div>
    <div role="group" *ngIf="limpouFornecimento" class="fornecimento-fatura_fatura-fornecimento">
        <span id="linkPopupImgFatura" role="button" #linkPopupImgFatura tabindex="0" (click)="carragaImagemFaturaSabesp()" (keypress)="carragaImagemFaturaSabesp()">
          {{ findSupplyLabel }}
        </span>
    </div>
    <div role="group" class="modal" id="modal-fatura-fornecimento" [ngClass]="{'active' : mostrarFaturaSabesp}">
        <div role="group" class="modal_content" id="modal-fatura-fornecimento_content">
            <img tabindex="0" #popupFornecimento class="modal_content_fatura-sabesp" src="../../../../assets/imgs/png/fatura_fornecimento.jpg" title="Fatura Sabesp"
            alt="O Fornecimento está impresso no canto superior esquerdo da fatura. O número do hidrômetro fica na base de ferro do dispositivo."/>
            <span role="button" id="fecharPopupImgFatura" tabindex="0" aria-label="Fechar modal"
            (click)="carragaImagemFaturaSabesp()" (keypress)="carragaImagemFaturaSabesp()"><mat-icon class="close_icn"
                [title]="'Fechar modal'">close</mat-icon></span>
        </div>
    </div>
</div>

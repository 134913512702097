import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TarifasDescModel } from 'src/app/services/tarifas.service';


@Component({
  selector: 'app-lista-tarifas',
  templateUrl: './lista-tarifas.component.html',
  styleUrls: ['./lista-tarifas.component.scss']
})
export class ListaTarifasComponent implements OnInit, OnChanges {
  @Input() dadosTarifa: any;
  tarifaResidencial: TarifasDescModel;
  tarifaComercial: TarifasDescModel;
  tarifaPublica: TarifasDescModel;
  tarifaIndustrial: TarifasDescModel;
  listaTipoTarifas: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.montaTarifaResidencial();
    this.montaTarifaComercial();
    this.montaTarifaPublico();
    this.montaTarifaIndustrial();
    this.montaListaTipoTarifas();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["dadosTarifa"].previousValue !== changes["dadosTarifa"].currentValue) {
      this.montaTarifaResidencial();
      this.montaTarifaComercial();
      this.montaTarifaPublico();
      this.montaTarifaIndustrial();
      this.montaListaTipoTarifas();
    }
  }

  montaListaTipoTarifas() {
    this.listaTipoTarifas = [{
      tipo: 'Residencial',
      img: 'residencial-icon',
      aberto: false,
      lista: this.tarifaResidencial
    },
    {
      tipo: 'Comercial',
      img: 'comercial-icon',
      aberto: false,
      lista: this.tarifaComercial
    },
    {
      tipo: 'Industrial',
      img: 'industrial-icon',
      aberto: false,
      lista: this.tarifaIndustrial
    },
    {
      tipo: 'Pública',
      img: 'publica-icon',
      aberto: false,
      lista: this.tarifaPublica
    }]
  }

  montaTarifaResidencial() {
    this.tarifaResidencial = {
      titulo: this.dadosTarifa.residencial.titulo,
      categoria: this.dadosTarifa.residencial.categorias
    }
  }

  montaTarifaPublico() {
    this.tarifaPublica = {
      titulo: this.dadosTarifa.publica.titulo,
      categoria: this.dadosTarifa.publica.categorias
    }
  }

  montaTarifaComercial() {
    this.tarifaComercial = {
      titulo: this.dadosTarifa.comercial.titulo,
      categoria: this.dadosTarifa.comercial.categorias
    }
  }

  montaTarifaIndustrial() {
    this.tarifaIndustrial = {
      titulo: this.dadosTarifa.industrial.titulo,
      categoria: this.dadosTarifa.industrial.categorias
    }
  }

}

import {
  simulaParcelamento,
  sucessoSimulaParcelamento,
  erroSimulaParcelamento,
  simularReparcelamento,
} from './parcelamento.actions';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ParcelamentoService } from 'src/app/modules/site-cliente/parcelamento/services/parcelamento.service';

@Injectable({
  providedIn: 'root',
})
export class ParcelamentoEffectService {
  private readonly BASE_URL = `${environment.uri}`;

  constructor(private actions$: Actions, private http: HttpClient, private parcelamentoService: ParcelamentoService) {}

  simularParcelamento = createEffect(() =>
    this.actions$.pipe(
      ofType(simulaParcelamento),
      mergeMap((action) =>
        this.parcelamentoService.simularParcelamento(action.req).pipe(
          map((parcelamento) => sucessoSimulaParcelamento({ dados: parcelamento })),
          catchError((error) => of(erroSimulaParcelamento(error)))
        )
      )
    )
  );

  simularReparcelamento = createEffect(() =>
    this.actions$.pipe(
      ofType(simularReparcelamento),
      mergeMap((action) =>
        this.parcelamentoService.simularReparcelamento(action.req).pipe(
          map((parcelamento) => sucessoSimulaParcelamento({ dados: parcelamento })),
          catchError((error) => of(erroSimulaParcelamento(error)))
        )
      )
    )
  );
}

import { EtapaProcesso } from './../../../core/models/etapaProcesso';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-etapa-processo',
  templateUrl: './etapa-processo.component.html',
  styleUrls: ['./etapa-processo.component.scss']
})
export class EtapaProcessoComponent implements OnInit {

  @Input() etapas: EtapaProcesso[] = [];
  @Input() showStepper: boolean = true;

  constructor() { }

  ngOnInit(): void {}


}
<div
  id="perfil__conteudo"
  class="perfil__conteudo"
  *ngIf="
    dadosRepresentante?.representante !== undefined &&
    dadosRepresentante?.representante !== null
  "
>
  <img
    tabindex="0"
    class="perfil__conteudo__img"
    id="perfil__conteudo__img"
    alt="Imagem de perfil escolhida pelo usuário"
    [src]="avatar ? avatar : avatarPadrao"
    [width]="70"
    [height]="70"
  />
  <div class="descricao-perfil">
    <h4 tabindex="0">{{ dadosRepresentante?.representante }}</h4>
    <p tabindex="0">Representante legal</p>
    <h5 tabindex="0">{{ dadosRepresentante?.razaoSocial }}</h5>
    <p tabindex="0" *ngIf="dadosRepresentante?.cnpj"><span>CNPJ:</span> {{dadosRepresentante?.cnpj}}</p>
  </div>
</div>

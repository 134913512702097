<div role="group" class="select-container" clickOutside (clickOutside)="closeSelect()" 
  [ngStyle]="{'--select-height': style?.height || containerHeight, 'width': style?.width || containerWidth}">
  <div role="group" [ngStyle]="style?.field || selectFieldStyle" class="select-field" tabindex="0"
    [ngClass]="{ 'border-noround-bottom': selectOpened, 'disabled': disabled }" (click)="toggleSelect($event)" (blur)="validaCampoVazio()"
    (keypress)="toggleSelect($event)">

    <div role="group" [ngStyle]="style?.content || contentStyle" class="select-field__content">
      <div role="group" [ngStyle]="style?.label || labelStyle" class="select-label" [innerHTML]="label"></div>
      <div role="group" [ngStyle]="style?.selected || placeholderStyle " class="selected-data" *ngIf="value; else placeholder">
        {{ value }}
      </div>
      <ng-template #placeholder>
        <span class="placeholder" [ngStyle]="style?.placeholder || placeholderStyle">{{labelPlaceholder}}</span>
      </ng-template>
    </div>

    <div role="img" class="icon__row">
      <agv-icon [name]="srcImg" title="Ícone de seta para seleção de item" [ngStyle]="style?.img || imgStyle" class="icon"  [ngClass]="{ 'rotate-180': selectOpened }"></agv-icon>
    </div>
  </div>

  <div [ngStyle]="{'max-height': containerOpenedHeight? containerOpenedHeight : '200px' }" role="group" class="select-field__opened" *ngIf="selectOpened">
    <div role="group" [ngStyle]="style?.option || optionStyle" class="select-option" tabindex="0" *ngFor="let option of options"
      (click)="selectData(option, $event)" (keypress)="selectData(option, $event)">

      <div *ngIf="separator" role="group" class="separator"></div>

      <ng-container *ngIf="optionTemplateRef" [ngTemplateOutlet]="optionTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
    </div>
  </div>
</div>

import { createAction, props } from '@ngrx/store';
import { Solicitacoes } from '../../models/solicitacoes';

export const carregaSolicitacoes = createAction(
  `[Solicitações] carregar solicitações`,
);

export const sucessoCarregaSolicitacoes = createAction(
  `[Solicitações] sucesso carregar Solicitações`,
  props<{ solicita: Solicitacoes }>()
);

export const erroCarregarSolicitacoes = createAction(
  `[Solicitações]  erro ao carregar Solicitações`,
  props<{ error: any }>()
);

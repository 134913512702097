import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './tokenStorageServices.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private readonly BASE_URL = `${environment.uri}`;

  constructor(
    private http: HttpClient,
    private tokenService: TokenStorageService
  ) {}
 
  private getRequestOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      }),
    };
  }

  enviaEmail(body: any): Observable<any> {
    return this.http.post<any>(
      `${this.BASE_URL}v1/completa/fatura/fornecimento/cliente/envio/email`,
      body,
      this.getRequestOptions()
    );
  }
}

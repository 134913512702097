import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './tokenStorageServices.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  EmpresaCNPJS,
  EmpresaFornecimento,
  ParametroBaixarExcel,
  ParametroFatura,
} from '../core/models/empresas/empresa';
import { FornecimentoEndereco } from '../core/models/empresas/fornecimentoEndereco';
import { FaturaCnpj } from '../core/models/faturaCompleta';
import { SolicitacoEmpresa } from '../core/models/empresas/empresa-solicitacoes';

@Injectable({
  providedIn: 'root',
})
export class EmpresaCNPJService {
  private readonly BASE_URL = `${environment.uri}`;

  constructor(
    private http: HttpClient,
    private tokenService: TokenStorageService
  ) {}

  private getRequestOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      }),
    };
  }

  salvaFavoritoApelido(param: any): Observable<any> {
    return this.http.post<any>(
      `${this.BASE_URL}v1/empresa/dados/salvar`,
      param,
      this.getRequestOptions()
    );
  }

  getRepresentanteCNPJFornecimentos(): Observable<EmpresaCNPJS> {
    return this.http.get<EmpresaCNPJS>(
      `${this.BASE_URL}v1/empresa/dados/representante`,
      this.getRequestOptions()
    );
  }

  getListaFornecimentoPorCNPJ(cnpj: string): Observable<EmpresaFornecimento> {
    return this.http.get<EmpresaFornecimento>(
      `${this.BASE_URL}v1/empresa/lista/fornecimento/${cnpj}`,
      this.getRequestOptions()
    );
  }

  getListaFornecimento(): Observable<any>{
    return this.http.get<any>(
      `${this.BASE_URL}v1/empresa/lista/fornecimento/representante`,
      this.getRequestOptions()
    );
  }

  getEnderecoFornecimento(
    fornecimento: string
  ): Observable<FornecimentoEndereco> {
    return this.http.get<FornecimentoEndereco>(
      `${this.BASE_URL}v1/empresa/fornecimento/${fornecimento}/endereco`,
      this.getRequestOptions()
    );
  }

  getFaturas(parametrosBuscaFatura: ParametroFatura): Observable<any> {
    return this.http.post<FaturaCnpj>(
      `${this.BASE_URL}v1/empresa/lista/faturas/representante`, parametrosBuscaFatura,
      this.getRequestOptions()
    );
  }

  downloadFaturaXml(dadosFat:{docId:string, partDoc:string, partDoc2:string}){
    return this.http.post<{base64:string}>(
      `${this.BASE_URL}v1/empresa/download/fatura/xml`, dadosFat,
      this.getRequestOptions()
    );
  }

  downloadFaturaXmlZip(dadosFat:{docId:string, partDoc:string, partDoc2:string}[]){
    return this.http.post<{url64:string}>(
      `${this.BASE_URL}v1/empresa/download/fatura/xml/zip`, {faturas: dadosFat},
      this.getRequestOptions()
    );
  }

  downloadFaturaExcel(parametroBaixarExcel: ParametroBaixarExcel){
    return this.http.post<{url64:string}>(
      `${this.BASE_URL}v1/empresa/download/fatura/excel`, parametroBaixarExcel,
      this.getRequestOptions()
    );
  }

  getFornecimentoSolicitacoes(fornecimento: string): Observable<SolicitacoEmpresa[]> {
    return this.http.get<SolicitacoEmpresa[]>(
      `${this.BASE_URL}v1/empresa/lista/solicitacoes/fornecimento/${fornecimento}`,
      this.getRequestOptions()
    );
  }
}

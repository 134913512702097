import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './tokenStorageServices.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DadosPagtoInfoProtocolo } from '../core/models/dadosPagtoInformado';

@Injectable({
  providedIn: 'root',
})
export class ComunicarPagamentoService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private readonly BASE_URL = `${environment.uri}`;

  private getRequestOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      }),
    };
  }

  constructor(
    private http: HttpClient,
    private tokenService: TokenStorageService
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  comunicarPagamento(request: any): Observable<DadosPagtoInfoProtocolo> {
    return this.http
      .post<DadosPagtoInfoProtocolo>(
        `${this.BASE_URL}v1/completa/fatura/fornecimento/cliente/pagamentoinformado`,
        request,
        this.getRequestOptions()
      )
      .pipe(takeUntil(this.unsubscribe$));
  }
}

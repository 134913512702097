import { Endereco } from './enderecos/endereco';

export interface responseEfetivacaoModel {
  protocolo: string;
  file: string;
}

export interface responseCorreioId {
  id: string;
}

export interface dadosTrocaEfetivacaoModel {
  codigoFornecimento: string;
  enderecoCorrespondencia: Endereco;
  dataVencimentoFatura: string;
  observacoes: string;
}

export interface correioEnderecoBodyModel {
  estadoCodigo(estadoCodigo: any): unknown;
  codigoViario: string;
  cepId: string;
  cepCodigo: string;
  paisId: string;
  paisDescr: string;
  regiaoId: string;
  regiaoDescr: string;
  estadoId: string;
  estadoDesc: string;
  codigoMunicipio: string;
  municipioId: string;
  municipioDescr: string;
  bairroId: string;
  bairroDescr: string;
  toponimoId: string;
  toponimoDescr: string;
  logradouroId: string;
  logradouroDescr: string;
  tipoCivicoId: string;
  civico: string;
  complemento: string;
}

export interface dadosClienteTrocaModel {
  cpf: string;
  nome: string;
  dataNascimento: string;
  celular: string;
  email: string;
  sobrenome?: string;
}

export class dadosTrocaEfetivacao {
  // String
  dataVencimentoFatura: string;
  codigoFornecimento: string;
  codigoCliente: string;
  observacoes: string;
  cpf: string;

  // Object
  enderecoCorrespondencia: Endereco;

  constructor(
    cpf: string,
    codigoCliente: string,
    codigoFornecimento: string,
    enderecoCorrespondencia: Endereco,
    dataVencimentoFatura: string,
    observacoes?: string
  ) {
    this.cpf = cpf;
    this.codigoCliente = codigoCliente;
    this.codigoFornecimento = codigoFornecimento;
    this.enderecoCorrespondencia = enderecoCorrespondencia;
    this.dataVencimentoFatura = dataVencimentoFatura;
    this.observacoes = observacoes ? observacoes : '';
  }
}

export class dadosClienteTroca {
  cpf: string;
  nome: string;
  dataNascimento: string;
  celular: string;
  email: string;

  constructor(
    cpf: string,
    nome: string,
    dataNascimento: string,
    celular: string,
    email: string
  ) {
    this.cpf = cpf;
    this.nome = nome;
    this.dataNascimento = dataNascimento;
    this.celular = celular;
    this.email = email;
  }
}

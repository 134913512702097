export class EnderecosPLA {
  codigoViario: string;
  cepId: string;
  cepCodigo: string;
  paisId: string;
  paisCodigo: string;
  paisDescr: string;
  regiaoId: string;
  regiaoCodigo: string;
  regiaoDescr: string;
  estadoId: string;
  estadoCodigo: string;
  estadoDesc: string;
  municipioId: string;
  codigoMunicipio: string;
  municipioDescr: string;
  municipioBase: string;
  bairroId: string;
  bairroCodigo: string;
  bairroDescr: string;
  toponimoId: string;
  toponimoDescr: string;
  logradouroId: string;
  logradouroDescr: string;
  tipoCivicoCodigo: string;
  tipoCivicoId: string;
  complemento: string;

  constructor() {
   this. codigoViario = '';
   this.cepId = ''
   this.cepCodigo = ''
   this. paisId = ''
   this.paisCodigo = ''
   this.paisDescr = ''
   this.regiaoId = ''
   this.regiaoCodigo = ''
   this.regiaoDescr = ''
   this.estadoId = ''
   this.estadoCodigo = ''
   this.estadoDesc = ''
   this.municipioId = ''
   this.codigoMunicipio = ''
   this.municipioDescr = ''
   this.municipioBase = ''
   this.bairroId = ''
   this.bairroCodigo = ''
   this.bairroDescr = ''
   this.toponimoId = ''
   this.toponimoDescr = ''
   this.logradouroId = ''
   this.logradouroDescr = ''
   this.tipoCivicoCodigo = ''
   this.tipoCivicoId = ''
   this.complemento = ''
  }
}

import { ParcelamentoEffectService } from './core/store/parcelamento/parcelamento.effect.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { supplyKey, SupplyReducer } from './core/store/supply/supply.reducer';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import ptBr from '@angular/common/locales/pt';
import { Action, ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appKey, AppReducer } from './core/store/app.reducer';
import { SharedModule } from './shared/shared.module';
import { SupplyEffectService } from './core/store/supply/supply.effect.service';
import { environment } from '../environments/environment';
import { userKey, UserReducer } from './core/store/user/user.reducer';
import { UserEffectService } from './core/store/user/user.effect.service';
import {negociosKey, NegociosReducer} from "./core/store/negocios/negocios.reducer";
import {parcelamentoKey, ParcelamentoReducer} from "./core/store/parcelamento/parcelamento.reducer";
import { CadastroClienteEffectService } from './core/store/cadastro/cadastro-cliente.effect.service';
import { ExcecaoErroInterceptor } from './core/interceptors/excecao-erro.interceptor';
import { CarregamentoInterceptor } from './core/interceptors/carregamento.interceptor';
import { FaturaEmailEffectService } from './core/store/email/fatura-email.effect.service';
import { faturaEmailKey, FaturaEmailReducer } from './core/store/email/fatura-email.reducer';
import { PagtoInformadoEffectService } from './core/store/pagto-informado/pagto-informado.effect.service';
import { NoticiaEffects } from './core/store/noticias/noticias.effect.service';
import { noticiaKey, NoticiaReducer } from './core/store/noticias/noticias.reducer';
import { parametrosKey, ParametrosReducer } from './core/store/parametros/parametros.reducer';
import { ParametrosEffectService } from './core/store/parametros/parametros.effect.service';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { SolicitacoesEffectService } from './core/store/minhas-solicitacoes/minhas-solicitacoes.effect.service';
import { solicitacoesKey, SolicitacoesReducer } from './core/store/minhas-solicitacoes/minhas-solicitacoes.reducer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EmpresaCNPJKey, EmpresaCNPJReducer } from './core/store/empresa-cnpj/empresa-cnpj.reducer';
import { FormularioPLAReducer, PLAKey } from './core/store/primeira-ligacao/primeira-ligacao.reducers';
import { EmpresaCNPJEffects } from './core/store/empresa-cnpj/empresa-cnpj.effects.service';
import { PrimeiraLigacaoEffects } from './core/store/primeira-ligacao/primeira-ligacao.effects';

export function storageMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
  return function(state: S, action: A): S {
    const nextState = reducer(state, action);
    const storage = localStorage.getItem('__storage__') || '{}'
    const savedState = JSON.parse(storage) ;
    const merge = {...savedState, ...nextState};
    localStorage.setItem('__storage__', JSON.stringify(merge))
    return nextState;
  };
}

export const metaReducers: MetaReducer<any>[] = [storageMetaReducer];

registerLocaleData(ptBr);
const DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: ['DD/MM/YYYY'],
  },
  display: {
    dateInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
    monthLabel: undefined,
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    SharedModule,
    MatSidenavModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot([
      SupplyEffectService,
      CadastroClienteEffectService,
      UserEffectService,
      ParcelamentoEffectService,
      FaturaEmailEffectService,
      PagtoInformadoEffectService,
      NoticiaEffects,
      ParametrosEffectService,
      SolicitacoesEffectService,
      PrimeiraLigacaoEffects,
      EmpresaCNPJEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly:
      environment.production
    }),
    StoreModule.forRoot({
      [appKey]: AppReducer,
      [supplyKey]: SupplyReducer,
      [userKey]: UserReducer,
      [negociosKey]: NegociosReducer,
      [parcelamentoKey]: ParcelamentoReducer,
      [faturaEmailKey]: FaturaEmailReducer,
      [noticiaKey]: NoticiaReducer,
      [parametrosKey]: ParametrosReducer,
      [solicitacoesKey]: SolicitacoesReducer,
      [PLAKey]: FormularioPLAReducer,
      [EmpresaCNPJKey]: EmpresaCNPJReducer
    }, {metaReducers}),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [

    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExcecaoErroInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CarregamentoInterceptor,
      multi: true
    },
    CookieService,
    GoogleAnalyticsService,

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { InputSelect } from '../agv-material/agv-inputs/input-select/input-select.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ClienteCompleto } from 'src/app/core/models/service/cadastroCliente';
import { ClienteService } from '../../observables/cliente.service';
import { Responsavel } from 'src/app/core/models/responsavel';
import { Regex } from '../../utils/regex';

@Component({
  selector: 'app-responsavel-processo',
  templateUrl: './responsavel-processo.component.html',
  styleUrls: ['./responsavel-processo.component.scss'],
})
export class ResponsavelProcessoComponent implements OnInit {
  @Output() emitirResponsavel = new EventEmitter<Responsavel>();
  @Input() mostraCampoEmail: boolean = false;
  @Input() title: string;
  @Input() subtitle: string;

  inputSelect: InputSelect = new InputSelect();
  opcoes: string[] = ['Titular do fornecimento', 'Outra pessoa maior de 18 anos'];
  cliente: ClienteCompleto | undefined;
  responsavel: string;
  dadosResponsavel: Responsavel = new Responsavel();
  desabilitaCampo: boolean = false;
  opcaoSelecionada: boolean = false;
  erroInputs: { nome: boolean; celular: boolean; email: boolean } = {
    nome: false,
    celular: false,
    email: false,
  };

  constructor(private cliObs: ClienteService) { }

  ngOnInit(): void {
    this.cliObs.getCliente().subscribe((cliente) => {
      if (cliente?.codigo) {
        this.cliente = cliente;
      }
    });
    this.setInputSelect();
  }

  setInputSelect(): void {
    this.inputSelect.label = 'Quem estará no imóvel? *';
    if (this.cliente) {
      this.inputSelect.placeholder = 'Selecione o nome de quem estará no imóvel';
      this.opcoes.map((opcao) => {
        this.inputSelect.options.push({
          labels: [{ value: opcao, img: false }],
          value: opcao,
        });
      });
      return
    }
    this.inputSelect.placeholder = 'Outra pessoa maior de 18 anos';
  }

  pegaDados(e: any) {
    this.dadosResponsavel = new Responsavel();
    this.dadosResponsavel.tipo = e;
    this.responsavel = e;
    if (e === 'Titular do fornecimento') {
      this.dadosResponsavel.nome = Regex.capitalize(
        this.cliente!.nome
      );
      this.dadosResponsavel.sobrenome = Regex.capitalize(this.cliente!.sobrenome)
      this.dadosResponsavel.celular = Regex.maskTelefone(
        `${this.cliente!.celular ?? this.cliente!.celular}`
      );
      this.dadosResponsavel.solicitante = `${this.cliente?.codigo}`;
      this.desabilitaCampo = true;
    } else {
      this.dadosResponsavel.nome = '';
      this.dadosResponsavel.sobrenome =''
      this.dadosResponsavel.celular = '';
      this.dadosResponsavel.solicitante = `${this.cliente?.codigo}`;
      this.desabilitaCampo = false;
    }
    this.emitirResponsavel.emit(this.dadosResponsavel);
  }

  getNome(e: any) {
    this.dadosResponsavel.nome = e;
    this.retornaErro('nome')
    this.emiteDados();
  }

  getEmail(e: any) {
    this.dadosResponsavel.email = e;
    this.retornaErro('email');
    this.emiteDados();
  }

  getTelefone(e: any) {
    this.dadosResponsavel.celular = e;
    this.retornaErro('telefone');
    this.emiteDados();
  }

  emiteDados() {
    this.cliente ? '' : this.dadosResponsavel.tipo = 'Outra pessoa maior de 18 anos';
    if (this.dadosResponsavel.tipo && !this.erroInputs.celular) {
      this.emitirResponsavel.emit(this.dadosResponsavel);
    }
  }

  retornaErro(tipo: string): void {
    switch (tipo) {
      case 'nome':
        this.erroInputs.nome = this.dadosResponsavel?.nome.length < 5;
        break;
      case 'telefone':
        this.erroInputs.celular = this.dadosResponsavel?.celular.length < 9;
        break;
      case 'email':
        this.erroInputs.email = !this.validarEmail(
          this.dadosResponsavel?.email ?? ''
        );
        break;
      default:
        break;
    }
  }

  validarEmail(email: string): boolean {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
}

<section
  id="agv_endereco-imovel_secao-principal"
  class="agv-endereco__container"
>
  <form [formGroup]="enderecoFormulario">
    <div class="agv-endereco__container__wrapper">
      <agv-input-text
        [id]="'agv_endereco-imovel_estado'"
        placeholder="UF"
        label="UF *"
        [value]="enderecoImovel?.estadoDesc"
        [blocked]="true"
        (sendValor)="setDadoEndereco($event, 'estadoDesc')"
      ></agv-input-text>
      <agv-input-select
        formControlName="municipio"
        [id]="'agv_endereco-imovel_toponimo'"
        [structor]="opcoesMunicipio"
        [blocked]="enderecoImovel?.municipioBlock ?? false"
        [value]="enderecoImovel.municipioDescr"
        (enviaValorObjetoCompleto)="setCodigoEndereco($event)"
        (sendValor)="setDadoEndereco($event, 'municipioDescr')"
      ></agv-input-select>
    </div>
    <agv-input-text
      formControlName="bairro"
      [id]="'agv_endereco-imovel_bairro'"
      placeholder="Digite o bairro"
      label="Bairro *"
      [blocked]="enderecoImovel?.bairroBlock || (cepInexistente ?? false)"
      [value]="enderecoImovel.bairroDescr"
      (sendValor)="setDadoEndereco($event, 'bairroDescr')"
    ></agv-input-text>
    <agv-input-select
      formControlName="toponimo"
      [id]="'agv_endereco-imovel_toponimo'"
      [structor]="opcoesToponimo"
      [blocked]="enderecoImovel?.toponimoBlock ?? false"
      [value]="enderecoImovel.toponimoDescr"
      (sendValor)="setDadoEndereco($event, 'toponimoDescr')"
    ></agv-input-select>

    <div
      class="endereco-form-group"
      [ngClass]="{ disabled: !this.enderecoFormulario.get('municipio')?.value }"
    >
      <label for="endereco-select">Endereço *</label>
      <ng-select
        id="endereco-select"
        formControlName="endereco"
        [items]="lstaEndereco"
        bindLabel="logradouro"
        bindValue="logradouro"
        [loading]="loadingEndereco"
        placeholder="Digite o endereço"
        (change)="selecionaLogradouro($event)"
        (input)="onInputChange($event)"
        [clearOnBackspace]="true"
        [tabIndex]="0"
        [selectOnTab]="true"
        [notFoundText]="'Nenhum endereço encontrado'"
        [loadingText]="'Carregando endereços'"
        [disabled]="!this.enderecoFormulario.get('municipio')?.value"
        [class.hide-arrow]="true"
      >
        >
        <ng-template tabindex="0" ng-label-tmp let-item="item">
          {{ item.logradouro }} - {{ item.bairro }}
        </ng-template>
        <ng-template tabindex="0" ng-option-tmp let-item="item">
          <div>{{ item.logradouro }} - {{ item.bairro }}</div>
        </ng-template>
      </ng-select>
    </div>

    <div class="agv-endereco__container__wrapper">
      <agv-input-select
        formControlName="tipoCivico"
        [id]="'agv_endereco-imovel__tipo-civico'"
        [structor]="opcoesTipoCivico"
        [blocked]="enderecoImovel?.tipoCivicoBlock ?? false"
        [value]="enderecoImovel.tipoCivicoDescr"
        (sendValor)="setDadoEndereco($event, 'tipoCivicoDescr')"
      ></agv-input-select>
      <agv-input-text
        formControlName="numero"
        [id]="'agv_endereco-imovel_civico'"
        placeholder="Digite o nº"
        label="Número *"
        [blocked]="enderecoImovel?.numeroBlock ?? false"
        [value]="enderecoImovel.tipoCivicoId"
        (sendValor)="setDadoEndereco($event, 'tipoCivicoId')"
      ></agv-input-text>
    </div>
    <agv-input-text
      formControlName="complemento"
      [id]="'agv_endereco-imovel_complemento'"
      placeholder="Digite o complemento"
      label="Complemento"
      [blocked]="enderecoImovel?.complementoBlock ?? false"
      [value]="enderecoImovel.complemento"
      (sendValor)="setDadoEndereco($event, 'complemento')"
    ></agv-input-text>
  </form>
</section>

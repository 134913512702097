import { catchError, map, mergeMap, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IParametrosState } from './parametros.reducer';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { carregaParametro, erroCarregarParametros, sucessoCarregaParametro } from './parametros.actions';

@Injectable({
  providedIn: 'root',
})
export class ParametrosEffectService {
  private readonly BASE_URL = `${environment.uri}`;

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<{ parametros: IParametrosState }>
  ) {}

  buscaParametro = createEffect(() =>
    this.actions$.pipe(
      ofType(carregaParametro),
      mergeMap((action) =>{
        return this.http
          .get<any>(
            `${this.BASE_URL}v1/completa/fatura/parcelamento/parametros/${action.codigo}`)
          .pipe(
            map(parametro => {
              return sucessoCarregaParametro({valor: parametro})
            }),
            catchError(error => {
              return of(erroCarregarParametros({error: error}))
            })
          )
      })
    )
  );
}

export interface ItensMenu {
  imagem: string;
  alt_img: string;
  title_img: string;
  nome: string;
  link: string;
  interno: boolean;
  classCustom?: string | null;
  acao: (param?: string) => void;
  pf: boolean;
  pj: boolean;
  imagemNegativa?: string;
  disabled?: boolean;
}

export function factoryItensMenu(
  imagem: string,
  alt_img: string,
  title_img: string,
  nome: string,
  link: string,
  interno: boolean,
  acao: () => void,
  pf: boolean,
  pj: boolean,
): ItensMenu {
  return {
    imagem,
    alt_img,
    title_img,
    nome,
    link,
    interno,
    acao,
    pf,
    pj
  };
}

export class MenuItens {
  items: ItensMenu[];

  constructor(items: ItensMenu[]) {
    this.items = items;
  }
}

<mat-card
  [ngStyle]="{ width: width }"
  class="card-table"
  *ngFor="let item of dados; let i = index"
>
  <table class="tabela-agv">
    <input
      *ngIf="hasCheckbox"
      class="checkbox-header"
      type="checkbox"
      [checked]="isSelected(item)"
      (change)="toggleSelection(item)"
    />

    <ng-container *ngIf="isFavorite">
      <a
        (click)="selectFavorite(item)"
        class="favorite"
        [ngClass]="{ selecionado: item.favorito }"
      >
        <mat-icon>{{ item.favorito ? "star" : "star_outline" }}</mat-icon>
      </a>
    </ng-container>
    <div class="tabela-agv__card" *ngFor="let coluna of colunas; let i = index">
      <thead *ngIf="coluna.type != 'button'">
        <tr class="tabela-agv__titulos">
          <th
            role="columnheader"
            [ngClass]="{ esquerda: alinhamentoColuna === 'left' }"
          >
            <span [class.has-checkbox]="hasCheckbox && i === 0"
              >{{ coluna.display }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="coluna.type != 'button'">
        <tr>
          <td
            role="cell"
            [class]="columnHandlerStyle(coluna, item)"
            [ngClass]="{
              'acao-cell':
                coluna.field === 'editar' || coluna.field === 'remover',
              esquerda:
                (alinhamento === 'left' && i === 1) ||
                alinhamentoColuna === 'left',
              'id-cell': coluna.field === 'id'
            }"
          >
            <ng-container
              *ngIf="
                coluna.field !== 'editar' && coluna.field !== 'remover';
                else actionButtons
              "
            >
              <!-- Verifica se o valor é uma data e aplica a formatação -->
              <ng-container
                *ngIf="isDate(item[coluna.field]); else displayColumn"
              >
                <!-- Verifica se é uma data -->
                {{ newDate(item[coluna.field]) | date : "dd/MM/yyyy" }}
              </ng-container>
              <!-- Caso contrário, exibe o valor normal da coluna -->
              <ng-template #displayColumn>
                <!-- Verifica se é 'currency' -->
                <ng-container
                  *ngIf="coluna.type === 'currency'; else normalColumn"
                >
                  {{ item[coluna.field] | currency : "BRL" }}
                </ng-container>

                <!-- Caso contrário, exibe o valor normal da coluna -->
                <ng-template #normalColumn>
                  <ng-container *ngIf="coluna.icon; else ApelidoCollum">
                    <div class="collum-details">
                      <mat-icon [class]="getStatusClass(item?.status)">
                        fiber_manual_record_outline
                      </mat-icon>
                      <span> {{ item[coluna.field] }}</span>
                    </div>
                  </ng-container>

                  <ng-template #ApelidoCollum>
                    <ng-container
                      *ngIf="coluna.type === 'apelido'; else semIcone"
                    >
                      <div class="apelido">
                        <span
                          *ngIf="!item.editando"
                          [ngClass]="{ separator: !item[coluna.field] }"
                          [innerHTML]="
                            item[coluna.field] ? item[coluna.field] : ''
                          "
                        >
                        </span>
                        <input
                          *ngIf="item.editando"
                          type="text"
                          [(ngModel)]="item[coluna.field]"
                        />
                        <button
                          *ngIf="!item.editando"
                          (click)="handleApelido(item)"
                          mat-icon-button
                        >
                          <mat-icon>edit</mat-icon>
                          <span class="button-texto">Editar</span>
                        </button>
                        <button
                          class="salvar"
                          *ngIf="item.editando"
                          (click)="salvarApelido(item)"
                          mat-icon-button
                        >
                          <mat-icon>done</mat-icon>
                          <span class="button-texto">Salvar</span>
                        </button>
                      </div>
                    </ng-container>
                  </ng-template>

                  <ng-template #semIcone>
                    <p
                      [ngClass]="{ separator: !item[coluna.field] }"
                      [innerHTML]="item[coluna.field] ? item[coluna.field] : ''"
                    ></p>
                  </ng-template>
                </ng-template>
              </ng-template>
            </ng-container>
            <ng-template #actionButtons>
              <button
                *ngIf="coluna.field === 'editar'"
                (click)="editItem(item)"
              >
                <div class="botao-wrapper">
                  <agv-icon
                    [ngClass]="{ 'dark-mode': tema === 'theme-dark' }"
                    title="botão editar"
                    [name]="'button-edit'"
                  ></agv-icon>
                  <span> Editar </span>
                </div>
              </button>
              <button
                *ngIf="coluna.field === 'remover'"
                (click)="removeItem(item)"
              >
                <div class="botao-wrapper">
                  <agv-icon
                    [ngClass]="{ 'dark-mode': tema === 'theme-dark' }"
                    title="icone remover item"
                    [name]="'button-delete'"
                  ></agv-icon>

                  <span>Remover</span>
                </div>
              </button>
            </ng-template>
          </td>
        </tr>
      </tbody>

      <ng-container *ngIf="coluna.type == 'button'">
        <div class="button-action">
          <agv-button
            [text]="'Entrar'"
            [type]="'1'"
            [habilita]="true"
            (sendAction)="apertaBotao(item)"
          ></agv-button>
        </div>
      </ng-container>
    </div>
  </table>
</mat-card>

import { createAction, props } from '@ngrx/store';
import {
  DadosRepresentante,
  EmpresaCNPJS,
} from '../../models/empresas/empresa';

export const carregaEmpresaCNPJS = createAction(
  `[Empresa] Carregar empresa cnpjs`
);

export const deletaEmpresaCNPJ = createAction(`[Empresa] deleta empresa`);

export const sucessoCarregaEmpresaCNPJ = createAction(
  `[Empresa] Sucesso carregar empresa`,
  props<{ empresaCNPJ: EmpresaCNPJS }>()
);

export const erroCarregaEmpresaCNPJ = createAction(
  `[Empresa] Sucesso carregar empresa`,
  props<{ error: string }>()
);

export const atualizaCNPJRepresentante = createAction(
  `[Empresa Fornecimento] Atualiza cnpj fornecimento`,
  props<{ representante: DadosRepresentante | null }>()
);

export const carregarCNPJRepresentante = createAction(
  `[Empresa Fornecimento] Carregar cnpj fornecimento`
);

export const sucessoCarregarCNPJRepresentante = createAction(
  `[Empresa Fornecimento] Sucesso carregar cnpj fornecimentos`,
  props<{ representante: DadosRepresentante | null }>()
);

// crud fornecimentos

export const carregaFornecimentoCNPJ = createAction(
  `[Empresa] Carregar empresa fornecimento`,
  props<{ cnpj: any }>()
);

export const deletaFornecimentoCNPJ = createAction(
  `[Empresa] deleta empresa fornecimento`
);

export const sucessoCarregaFornecimentoCNPJ = createAction(
  `[Empresa] Sucesso carregar empresa fornecimento`,
  props<{ fornecimento: any }>()
);

export const erroCarregaFornecimentoCNPJ = createAction(
  `[Empresa] Sucesso carregar fornecimento`,
  props<{ error: string }>()
);

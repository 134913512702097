<div id="breadcrumb" [ngClass]="{ 'color-white': changeColor}" role="group" class="container-breadcrumb">
  <mat-icon id="breadcrumb_retorna-step" (click)="locationBack($event)" (keypress)="locationBack($event)"
    tabindex="0">chevron_left</mat-icon>
    <mat-tab-group #matTabGroup [disablePagination]="true" color="#fff" class="agv-breadcrumb-tabs">
      <mat-tab *ngFor="let breadcrumb of breadcrumbs; index as indexOfelement">
        <ng-template mat-tab-label>
          <ul class="breadcrumb">
            <li [id]="'breadcrumb_item' + indexOfelement" class="breadcrumb-item">
              <a id="{{geraId(breadcrumb.label)}}" (click)="redireciona(breadcrumb.url)" >{{breadcrumb.label}}</a>
            </li>
          </ul>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
</div>

export interface Fornecimento {
  favorito: boolean;
  codigo: string;
  apelido: string;
  status: string;
  enderecoFornecimentoCompleto: string;
  titular?: string;
}

export interface CNPJRepresentante extends CNPJ {
  representante?: Representante | null;
}

export interface CNPJ {
  default?: boolean;
  favorito?: boolean;
  cnpj: string;
  razaoSocial: string;
  dataFim: string;
  fornecimentos: Fornecimento[];
}

export interface Representante {
  codigo: string;
  nome: string;
  sobrenome: string;
}

export interface EmpresaCNPJS {
  representante: Representante;
  cnpjs: CNPJ[];
}

export interface DadosRepresentante {
  representante?: string;
  razaoSocial?: string;
  cnpj?: string;
  fornecimento?: string;
}

export class DadosRepresentanteFornecimentoCnpj {
  cnpj: string
  fornecimento: string
  razaoSocial: string
  representante: string

  constructor(){
    this.cnpj = '';
    this.fornecimento = '';
    this.razaoSocial = '';
    this.representante = '';
  }
}

export interface EmpresaFornecimento {
  razaoSocial: string;
  fornecimentos: Fornecimento[];
}

export interface ParametroFatura {
  cnpj: string;
  fornecimento: string;
  dataIni: string;
  dataFim: string;
  descZione: string;
  statusFatura: string;
  linhaIni: number;
  linhaFim: number;
}

export interface ParametroBaixarExcel {
  cnpj: string;
  fornecimento: string;
  dataIni: string;
  dataFim: string;
  descZione: string;
  statusFatura: string;
}

export class ParametroBaixarExcelClass {
  cnpj: string;
  fornecimento: string;
  dataIni: string;
  dataFim: string;
  descZione: string;
  statusFatura: string;

  constructor() {
    this.cnpj = '';
    this.fornecimento = '';
    this.dataIni = '';
    this.dataFim = '';
    this.descZione = '';
    this.statusFatura = '';
  }
}

export class ParamentroFaturaClass {
  cnpj: string;
  fornecimento: string;
  dataIni: string;
  dataFim: string;
  descZione: string;
  statusFatura: string;
  linhaIni: number;
  linhaFim: number;

  constructor() {
    this.cnpj = '';
    this.fornecimento = '';
    this.dataIni = '';
    this.dataFim = '';
    this.descZione = '';
    this.statusFatura = '';
    this.linhaIni = 1;
    this.linhaFim = 100;
  }
}

import { Fornecimento } from './../../models/fornecimento';
import { createAction, props } from '@ngrx/store';
import { BuscaFaturasPorFornecimentoResumido } from '../../models/service/faturasPorFornecimento-resumido';
import { Acordo } from '../../models/acordo';

export const carregaFornecimento = createAction(
  `[Fornecimento] carregar fornecimento`,
  props<{ fornecimento: string, recaptcha: string}>()
);

export const sucessoCarregaForencimento = createAction(
  `[Fornecimento] sucesso carregar fornecimento`,
  props<{ dados: BuscaFaturasPorFornecimentoResumido }>()
);

export const sucessoCarregaCNPJFornecimento = createAction(
  `[Fornecimento] sucesso carregar fornecimento CNPJ`,
  props<{ dados: any }>
);

export const carregaFornecimentos = createAction(
  `[Fornecimentos] carrega fornecimentos por cpf`
);

export const carregaCNPJFornecimento = createAction(
  '[Fornecimento] carrega fornecimentos por cnpj'
);

export const sucessoCarregaFornecimentos = createAction(
  `[Fornecimentos] Fornecimentos carregados`,
  props<{ dados: Fornecimento[] }>()
);

export const sucessoCarregaCNPJFornecimentos = createAction(
  `[Fornecimentos] Fornecimentos carregados`,
  props<{ dados: any }>()
);

export const erroCarregarFornecimento = createAction(
  `[Fornecimento] falhou ao carregar fornecimento`,
  props<{ error: any }>()
);

export const carregaFaturasFornecimento = createAction(
  `[Fornecimento] carregando faturas do fornecimento`,
  props<{ codigoFornecimento: string }>()
);

export const carregaFaturasFornecimentoCNPJ = createAction(
  `[Fornecimento] carregando faturas do fornecimento por cnpj`,
  props<{ cnpj: string }>()
);

export const carregaFaturasCNPJFornecimento = createAction(
  `[Fornecimento] carregando faturas do fornecimento`,
  props<{ codigoFornecimento: string }>()
);

export const sucessoFaturasFornecimento = createAction(
  `[Fornecimento] sucesso ao carregar faturas`,
  props<{ dados: any }>()
);

export const sucessoFaturasCNPJFornecimento = createAction(
  `[Fornecimento] sucesso ao carregar faturas`,
  props<{ dados: any }>()
);

export const erroFaturasFornecimento = createAction(
  `[Fornecimento] falha ao carregar faturas`
);
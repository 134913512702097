import { TokenStorageService } from './../../../services/tokenStorageServices.service';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { ClienteResumido } from '../../../core/models/clienteSimplificado';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SenhasValidator } from '../../validators/senha.validators';
import { ReqCadastraSenha } from 'src/app/core/models/service/cadastroCliente';
import { Regex } from '../../utils/regex';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { cadastraSenha } from 'src/app/core/store/cadastro/cadastro-cliente.actions';
import { getStatusCadastraSenha } from 'src/app/core/store/cadastro/cadastro-cliente.selectors';
import { Validator } from '../../utils/validator';

@Component({
  selector: 'app-input-nova-senha',
  templateUrl: './input-nova-senha.component.html',
  styleUrls: ['./input-nova-senha.component.scss'],
})
export class InputNovaSenhaComponent implements OnInit {
  @Input() marginTop: string;
  @Input() componentePrincipal: boolean;
  @Input() mensagemErroTop: boolean;
  @Input() descricaoValor: string = '';
  @Input() showVoltar: boolean;
  @Output() redicionaSenha: EventEmitter<any> = new EventEmitter();
  @Output() enviarRequisicao: EventEmitter<ReqCadastraSenha> =
    new EventEmitter();
  @Output() setVoltar: EventEmitter<any> = new EventEmitter();
  @ViewChild('titulo') tituloElement: ElementRef;
  cliente: ClienteResumido = {} as ClienteResumido;
  formPw: FormGroup;
  hideSenha = true;
  hideCofirmaSenha = true;
  mensagem: string = '';
  origem: any;

  public responseStatus$: Observable<any> = this.store.select(
    getStatusCadastraSenha
  );

  req: ReqCadastraSenha;
  senha: string | null = '';
  confirma: string | null = '';

  iconeSucesso: string ='icon-validation-success';
  iconeErro: string = 'icon-validation-error';
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.initFormPw();
    setTimeout(() => {
      //Carrega o focus no título
      this.tituloElement?.nativeElement.focus();
    }, 0);

    //Verifica se chegou nesse componente através do "Esqueci minha senha"
    this.origem = this.router.routerState.snapshot.url.split('/');
    if (this.origem[1] == 'recuperar-senha') {
      this.route.queryParams.subscribe((params) => {
        this.componentePrincipal = params['componentePrincipal'];
      });
    }
    this.cliente.nome = `${this.tokenStorageService.getUserInformation(
      'nome'
    )}`;
  }

  initFormPw() {
    this.formPw = this.fb.group({
      pw: [
        '',
        Validators.compose([
          Validators.required,
          SenhasValidator,
          Validators.maxLength(16),
          Validators.minLength(6),
        ]),
      ],
      pwConfirm: ['', Validators.compose([Validators.required])],
    });
    this.formPw.addValidators([motaValidatorFn(this.matchSenha())]);
  }

  atualizarSenha() {
    if (this.origem[1] == 'recuperar-senha') {
      this.req = new ReqCadastraSenha(
        this.formPw.get('pw')?.value,
        this.formPw.get('pwConfirm')?.value
      );

      this.store.dispatch(cadastraSenha({ req: this.req }));

      this.responseStatus$.subscribe(() => {
        this.router.navigate(['/recuperar-senha/sucesso'], {
          queryParams: { componentePrincipal: true },
        });
      });
    } else {
      let cpf: string | null = localStorage.getItem('cpf');
      const retornaCpfValido = cpf ? Regex.removeMaskCpf(cpf) : '';
      this.req = new ReqCadastraSenha(
        this.formPw.get('pw')?.value,
        this.formPw.get('pwConfirm')?.value,
        retornaCpfValido
      );
      this.redicionaSenha.emit(this.req);
    }
  }

  enviaReqbody(): void {
    this.req = new ReqCadastraSenha(this.formPw.get('pw')?.value, this.formPw.get('pwConfirm')?.value, '')
    this.enviarRequisicao.emit(this.req);
  }

  validaTouchedDirty(): boolean {
    if (
      this.formPw.get('pw')?.value &&
      (this.formPw.get('pw')?.touched || this.formPw.get('pw')?.dirty)
    ) {
      return true;
    }
    return false;
  }

  liberaConfirmar(): boolean {
    if (this.validaTamanhoCaracteres() === this.iconeErro) {
      return false;
    }
    if (this.validaCaraterEspecial() === this.iconeErro) {
      return false;
    }
    if (this.validaLetraMinuscula() === this.iconeErro) {
      return false;
    }
    if (this.validaMaiuscula() === this.iconeErro) {
      return false;
    }
    if (this.validaNumero() === this.iconeErro) {
      return false;
    }
    if (this.validaCaraterEspecialProibidos() === this.iconeErro) {
      return false;
    }
    if (!this.validaTouchedDirty()) {
      return false;
    }
    return this.matchSenha();

  }

  validaTamanhoCaracteres(): string {
    if (
      (this.formPw.get('pw')?.value &&
        this.formPw.get('pw')?.errors?.['minlength']) ||
      this.formPw.get('pw')?.errors?.['maxlength']
    ) {
      return this.iconeErro;
    } else return this.iconeSucesso;
  }

  validaCaraterEspecial(): string {
    if (this.formPw.get('pw')?.errors?.['specialCharacter'])
      return this.iconeErro;
    else return this.iconeSucesso;
  }
  validaCaraterEspecialProibidos(): string {
    if (this.formPw.get('pw')?.errors?.['forbiddenSpecialCharacters'])
      return this.iconeErro;
    else return this.iconeSucesso;
  }

  validaLetraMinuscula(): string {
    if (this.formPw.get('pw')?.errors?.['lowerCase']) return this.iconeErro;
    else return this.iconeSucesso;
  }

  validaMaiuscula() {
    if (this.formPw.get('pw')?.errors?.['upperCase']) return this.iconeErro;
    else return this.iconeSucesso;
  }

  validaNumero() {
    if (this.formPw.get('pw')?.errors?.['numberCharacters'])
      return this.iconeErro;
    else return this.iconeSucesso;
  }

  matchSenha(): boolean {
    if (this.formPw.get('pw')?.value !== this.formPw.get('pwConfirm')?.value) {
      return false;
    }
    return true;
  }

  voltar() {
    this.setVoltar.emit();
  }

  validaClick(event: Event, botao: string) {
    if (Validator.validaEventKeyPressClick(event)) {
      switch (botao) {
        case 'senha':
          this.hideSenha = !this.hideSenha;
          break;
        case 'senhaConfirma':
          this.hideCofirmaSenha = !this.hideCofirmaSenha
          break;
        default:
          break;
      }
    }
  }
}

function motaValidatorFn(valida: boolean): ValidatorFn {
  return () => {
    if (!valida) {
      return { match_error: 'Value does not match' };
    }
    return null;
  };
}

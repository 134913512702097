<div class="upload-container" [ngClass]="{ 'invalid': invalid }">
  <div>
    <div class="upload-container__header">
      <div class="upload-container__label">{{ label }}</div>
      <div class="upload-container__icon" *ngIf="showBtnInfo" (click)="clickInfo.emit()">?</div>
    </div>

    <button mat-stroked-button (click)="openFolder()" *ngIf="!fileName; else spanFileName"
      [ngClass]="{ 'invalid': invalid }">
      Carregar arquivo
    </button>
    <ng-template #spanFileName>
      <div class="upload-container__fileName">
        <p>{{ fileName }}</p>
      </div>
    </ng-template>

    <input #fileInput type="file" (change)="selectFile($event)" accept="image/jpg,image/jpeg,image/png,.pdf" hidden>
  </div>

  <div class="upload-container__imgs">
    <agv-icon class="remove" [name]="'icn_limpar'" *ngIf="fileName" (click)="removeFile()"></agv-icon>
    <img [src]="imgSrc || 'assets/images/no-file' + imgNameSuffix + '.svg'" alt="" class="imgFile">
  </div>
</div>
<p class="message-error" *ngIf="invalid">{{ messageError }}</p>

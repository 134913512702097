import { createReducer, on } from '@ngrx/store';
import { Solicitacoes } from '../../models/solicitacoes';
import { sucessoCarregaSolicitacoes } from './minhas-solicitacoes.actions';

export interface ISolicitacoesState {
  solicitacoes?: Solicitacoes;
}

export const solicitacoesKey = 'Solicitações';

export const solicitacoesInitialState: ISolicitacoesState = {
  solicitacoes: undefined,
};

export const SolicitacoesReducer = createReducer(
  solicitacoesInitialState,
  on(sucessoCarregaSolicitacoes, (state, action) => {
    const newState = Object.assign({}, state, { solicitacoes: action.solicita });
    return newState;
  }),
);

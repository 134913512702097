<div class="input-form-admin-container" [ngClass]="{'full-width': fullWidth}">
  <label [for]="inputId" class="input-form-admin-container__label">
    {{ label }}
  </label>
  <mat-icon *ngIf="icon" class="input-form-admin-container__icon">{{ icon }}</mat-icon>
  <input
    [id]="inputId"
    [ngModel]="model"
    [disabled]="disabled"
    (ngModelChange)="modelChange.emit($event)"
    [type]="type"
    class="input-form-admin-container__input-form-admin"
  />
</div>

import { Component, EventEmitter, Inject, OnInit, Output,} from '@angular/core';
import { Validator } from '../../utils/validator';
import { InfosModalOpcoes } from 'src/app/core/models/infosModalOpcoes';

@Component({
  selector: 'app-modal_opcoes',
  templateUrl: './modal_opcoes.component.html',
  styleUrls: ['./modal_opcoes.component.scss'],
})
export class ModalOpcoesComponent implements OnInit {

  @Output() opcaoModal1Event: EventEmitter<any> = new EventEmitter<any>(); // Novo EventEmitter
  @Output() opcaoModal2Event: EventEmitter<any> = new EventEmitter<any>();
  mostrarOpcoes: boolean = false;
  infosModal: InfosModalOpcoes;

  constructor() {}

  ngOnInit(): void {}

  actionBtn1(event: any) {
    if(Validator.validaEventKeyPressClick(event)){
      this.closeModal();
      this.opcaoModal1Event.emit();
    }
  }

  actionBtn2(event: any) {
    if(Validator.validaEventKeyPressClick(event)) {
      this.closeModal();
      this.opcaoModal2Event.emit();
    }
  }

  openModal(infoModal: InfosModalOpcoes) {
    if(infoModal) {
      this.infosModal = infoModal;
      this.mostrarOpcoes = true;
      const modalOverlay = document.getElementById('modalOverlay');
      if(modalOverlay != null){
        modalOverlay.style.display = "block";
      }
    }
  }

  closeModal() {
    if(Validator.validaEventKeyPressClick(event)) {
      this.mostrarOpcoes = false;
      const modalOverlay = document.getElementById('modalOverlay');
      if(modalOverlay != null){
        modalOverlay.style.display = "none";
      }
    }
  }
}

import { PesquisaSatisfacaoModel } from '../core/models/pesquisaSatisfacao';
import { TokenStorageService } from './tokenStorageServices.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PesquisaSatisfacaoService implements OnDestroy {
  private readonly BASE_URL = `${environment.uri}`;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private tokenService: TokenStorageService
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getRequestOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      }),
    };
  }

  postPesquisa(body: PesquisaSatisfacaoModel): Observable<any> {
    return this.http.post<any>(
      `${this.BASE_URL}v1/completa/pesquisa/satisfacao/salvar`,
      body,
      this.getRequestOptions()
    );
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartaoServices } from 'src/app/services/cartaoCredito.service';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';

@Component({
  selector: 'app-modal-excluir-cartao',
  templateUrl: './modal-excluir-cartao.component.html',
  styleUrls: ['./modal-excluir-cartao.component.scss']
})
export class ModalExcluirCartaoComponent implements OnInit {
  fechaModal:boolean;
  usuarioToken: string;
  
  constructor(@Inject(MAT_DIALOG_DATA) public cartao: any, private tokenService: TokenStorageService, private cartaoService: CartaoServices) { }

  ngOnInit(): void {
    this.fechaModal = false;
    const token = this.tokenService.getToken();
    token ? this.usuarioToken = token : '';
  }
  
  removeCartao(id: string) {
    this.cartaoService.excluiCartaoSelecionado(this.usuarioToken, id).subscribe({
      next: () => {},
      error: () => { },
      complete: () => { 
        this.fechaModal = true;
      },
    })
  }

}

import { Component, ContentChild, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { EventEmitterService } from 'src/app/event-emits.service';
import { Validator } from '../../utils/validator';

export interface ISelectStyle {
  height?: string;
  width?: string;
  content?: any;
  field?: any;
  label?: any;
  selected?: any;
  placeholder?:any;
  img?: any;
  option?: any;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {

  @Input() label: string;
  @Input() options: any[];
  @Input() value: any;
  @Input() style?: ISelectStyle;
  @Input() separator: boolean = false;
  @Input() disabled: boolean = false;
  // TODO: Refatorar a estilização desse componente para aceitar somente o "@Input() style: ISelectStyle"
  @Input() containerHeight: any;
  @Input() containerOpenedHeight: any;
  @Input() containerWidth: any;
  @Input() selectFieldStyle: any;
  @Input() contentStyle: any;
  @Input() labelStyle: any;
  @Input() placeholderStyle:any;
  @Input() labelPlaceholder: string;
  @Input() imgStyle: any;
  @Input() optionStyle: any;

  @Output() select = new EventEmitter();
  @Output() emissorValue = new EventEmitter();

  @ContentChild('option',{static: false}) optionTemplateRef: TemplateRef<any>;

  selectOpened = false;

  srcImg: string = 'arrow_black';

  constructor(private mediaObserver: MediaObserver) {
    this.mediaObserver.asObservable().subscribe((media) => {
      if (media[0].mqAlias == 'xs') {
        this.srcImg = 'arrow_black_mobile';
      } else {
        this.srcImg = 'arrow_black';
      }
  });
  }

  toggleSelect(event: any) {
    if(this.disabled)
      return
    if (Validator.validaEventKeyPressClick(event)) {
      this.selectOpened = !this.selectOpened;
    }
  }

  closeSelect() {
    this.selectOpened = false;
  }

  selectData(data: any, event: any) {
    this.value = data;
    this.toggleSelect(event);
    this.select.emit(data);
    this.validaCampoVazio()
  }

  validaCampoVazio() {
    this.emissorValue.emit(this.value);
    EventEmitterService.get('emissorValue').emit(this.value);
  }
}

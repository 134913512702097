<div id="politica-priv" role="group" class="politica-priv">
    <div id="politica-priv-container" role="group" class="politica-priv-container">
        <div id="politica-privdescricao" role="group" class="politica-priv_titulos">
            <h2 class="politica-priv__titulo" tabindex="0">Política de Privacidade</h2>
            <span class="politica-priv__subtitulo" tabindex="0">
                Leia abaixo as Informações a respeito da política de privacidade da Agência Virtual Sabesp.
            </span>
        </div>
        <mat-accordion id="politica-priv-conteudo" >
            <mat-expansion-panel id="politica-priv-painel" hideToggle *ngFor="let item of itensPoliticaPrivacidade;index as indexOfelement;"
            (opened)="elementoAbriu(indexOfelement)" (closed)="elementoFechou(indexOfelement)">
                <mat-expansion-panel-header id="politica-priv-painel-header" >
                    <div id="politica-priv-painel-header-conteudo" class="box-pergunta">
                        <mat-panel-title>
                            {{item.titulo}}
                        </mat-panel-title>
                        <mat-panel-description id="politica-priv-painel-header-toggle" >
                            <agv-icon id="{{indexOfelement}}" class="icn_expandir" [ngClass]="{'dark-mode': currentTheme === 'dark-mode'}" [name]="'icn_add'" title="Expandir"></agv-icon>
                        </mat-panel-description>
                    </div>
                </mat-expansion-panel-header>
                <span tabindex="0" [innerHTML]="item.conteudo"></span>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of,} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { carregaSolicitacoes, erroCarregarSolicitacoes, sucessoCarregaSolicitacoes } from './minhas-solicitacoes.actions';
import { Solicitacoes } from '../../models/solicitacoes';

@Injectable({
  providedIn: 'root',
})
export class SolicitacoesEffectService {
  private readonly BASE_URL = `${environment.uri}`;

  constructor(
    private actions$: Actions,
    private http: HttpClient,

  ) {}

  carregarSolicitacoes = createEffect(() =>
    this.actions$.pipe(
      
      ofType(carregaSolicitacoes),
      mergeMap((action) =>{
        
        return this.http
          .get<Solicitacoes>(
            `${this.BASE_URL}v1/completa/solicitacoes`,
          )
          .pipe(
            map(solicitacoes => {
              return sucessoCarregaSolicitacoes({solicita: solicitacoes})
            }),
            catchError(error => {
              return of(erroCarregarSolicitacoes({error: error}))
            })
          )
      })
    )
  );

}

<div role="group" class="box-consumo">
  <div role="group" #charts class="chart-container">
    <div role="group" #containerBody class="container-body">
      <canvas id="MyChart">{{ chart }}</canvas>
    </div>
    <div role="group" class="control">
      <a role="button" aria-label="Voltar mês" #chevronLeft (keypress)="changeLeitura($event, 'previous')"
        (click)="onPreviousClick()" tabindex="0" class="chevron">
        <mat-icon>chevron_left</mat-icon>
      </a>
      <a role="button" aria-label="Próximo mês" #chevronRight (keypress)="changeLeitura($event, 'next')"
        (click)="onNextClick()" tabindex="0" class="chevron">
        <mat-icon>chevron_right</mat-icon>
      </a>
    </div>
  </div>
</div>


<ng-container id="buscar-header-mobile" class="search-menu__container">
  <button id="botao-buscar-mobile" role="button" title="Pesquisar" mat-icon-button *ngIf="!opened" (click)="open()">
    <agv-icon [name]="'icn_search_mobile'" id="img-buscar" class="search-menu-container-icon" [ngClass]="{'dark-mode' : currentTheme === 'theme-dark'}"></agv-icon>
  </button>

  <div id="modal-buscar-mobile" class="search-menu__content" *ngIf="opened" @slideInTop @slideOutTop>
    <button id="botao-buscar-modal-mobile" role="button" title="Pesquisar" mat-icon-button>
      <agv-icon [name]="'icn_search_mobile'" id="img-buscar-modal" alt="Pesquisar" [ngClass]="{'dark-mode' : currentTheme === 'theme-dark'}"></agv-icon>
    </button>
    <input id="campo-busca-modal" mat-input [(ngModel)]="termoPesquisa" (input)="filtrarServicos()" class="search-menu__input" [formControl]="searchControl" [placeholder]="'O que você procura?'"
      #inputSearch>

    <button id="botao-fechar-modal" mat-icon-button role="button" title="Fechar Pesquisa" (click)="close()">
      <mat-icon id="img-fechar" class="search-menu__icon">close</mat-icon>
    </button>
    <div id="opcoes-campo-busca" *ngIf="(termoPesquisa != '' && termoPesquisa != null)" class="search-menu__option">
      <ul *ngIf="(termoPesquisa != '' && termoPesquisa != null)">
        <li>
          <p class="search-menu__option__titulo">Resultados encontrados</p>
          <p class="search-menu__option__descricao">Foram encontrados {{servicosFiltrados.length}} resultados para o termo: {{termoPesquisa}}</p>
        </li>
        <li *ngFor="let servico of servicosFiltrados">
          <a (click)="redirecionaServico(servico); servico.acao()">{{ servico.nome }}</a>
        </li>
      </ul>
    </div>
  </div>
</ng-container>

import { ListaCheckModel } from 'src/app/core/models/listaCheck';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'agv-lista-check',
  templateUrl: './lista-check.component.html',
  styleUrls: ['./lista-check.component.scss']
})
export class ListaCheckComponent implements OnInit {

  @Input() titulo: string;
  @Input() dados: ListaCheckModel[] = [];
  @Input() editar: boolean;
  @Output() editEvent = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {}

  edit() {
    this.editEvent.emit(true)
  }
}
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Regex } from 'src/app/shared/utils/regex';

@Component({
  selector: 'agv-input-cep',
  template: `
    <div role="group" [id]="id + '__input-cep'" class="input-cep" [ngClass]="{'disabled' : blocked}">
      <label class="input-cep__label" [for]="id + '__input-cep__cep'">{{
        label
      }}</label>
      <input
        #inputCep
        [id]="id + '__input-cep__cep'"
        [placeholder]="placeholder"
        class="input-cep__input"
        (keydown)="changeValor($event)"
        (keypress)="changeValor($event)"
        (keyup)="changeValor($event)"
        maxlength="9"
        [disabled]="blocked"
      />
    </div>
  `,
  styles: [
    `
      .input-cep {
        display: flex;
        height: 65px;
        width: 100%;
        position: relative;
        background-color: var(--color-white);
        border: 1px solid var(--color-gray-300);
        border-radius: 4px;
        padding: 10px 22px 10px 22px;

        &__label {
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.25rem;
          position: absolute;
          top: 6px;
          color: var(--color-always-grey);
          display: flex;
          width: calc(100% - 44px);
          height: 1.25rem;
        }

        &__input {
          font-weight: 400;
          font-size: 0.875rem !important;
          line-height: 1.25rem;
          color: var(--color-black);
          background: transparent;
          border: none;
          width: calc(100% - 44px);
          height: 20px;
          bottom: 13px;
          position: absolute;
        }

        &.disabled {
          cursor: not-allowed;
          background-color: var(--color-gray-weak);
          
          input, label {
            cursor: not-allowed;
          }
        }
      }
    `,
  ],
})
export class InputCepComponent implements OnInit, OnChanges {
  @Output() sendValor = new EventEmitter<any>();
  @ViewChild('inputCep') inputCep: ElementRef;
  @Input() id: string = '';
  @Input() value: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() blocked: boolean = false;
  cep: string = '';

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue !== changes['value']?.previousValue) {
      if (this.inputCep && this.inputCep.nativeElement)
        this.inputCep.nativeElement.value = changes['value']?.currentValue;
    }
  }

  changeValor(e: any) {
    this.cep = Regex.maskCEP(this.inputCep.nativeElement.value);
    this.inputCep.nativeElement.value = this.cep;
    this.sendValor.emit(this.cep);
  }
}

<div class="container-noticia-card">
  <div [className]="'index_'+i+' noticias__cards__row__card'" tabindex="0" *ngFor="let card of noticias; let i = index;"
    [ngStyle]="{'height': isAdmin ? '116px' : ''}">
    <img [src]="card?.capa" alt="" class="noticias__cards__row__card__img-noticias" />
    <div class="noticias__cards__row__card_container-conteudo">
      <span class="noticias__cards__row__card__title">
        {{ card?.titulo }}
      </span>
      <div class="noticias__cards__row__card__content-description-button-edit">
        <div>
          <p class="noticias__cards__row__card__content-description-button-edit__description">
            {{ card?.subtitulo | ellipsis : 150 }}<span *ngIf="isAdmin">... <u><a class="link" tabindex="0"
                  (click)="abrirNoticia(card)">Veja a Notícia</a></u></span>
          </p>
        </div>
        <div>
          <button (click)="editarNoticia(card)" class="noticias__cards__row__card__content-description-button-edit__button">
            {{ buttonText }}
          </button>
        </div>
      </div>
      <div>
        <p *ngIf="isAdmin" class="noticias__cards__row__card__expirara">
          <b>EXPIRARA EM: </b>{{ dataFinalFormatada(card?.dataFinal ) | date : "dd/MM/yyyy" }}</p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="quantidadeNoticiasExibidas < noticiasCards?.length">
  <button (click)="verMaisNoticias()">
    Veja mais notícias
  </button>

</div>
















<!-- <div class="noticias__cards__row__card__dados__btns"> -->
          <!-- <button class="noticias__cards__row__card__dados__btns__compart" fxHide fxShow.xs
          [cdkCopyToClipboard]="url.origin + '/sabesp-informa/' + card.codigo"
          (cdkCopyToClipboardCopied)="compartilharNoticia(card.codigo)">
          <span *ngIf="card.codigo === buttonCopy" class="noticias__cards__row__card__dados__btns__compart__text">
            Link copiado
          </span>
          <agv-icon *ngIf="card.codigo !== buttonCopy" class="icon-compartilhar" [name]="'icn_compartilhar'"
            title="Ícone de compartilhar"></agv-icon>
          </button> -->
      <!-- <div class="noticias__cards__row__card__dados__status"> -->
        <!-- <agv-icon *ngIf="card?.rascunho == 'N'" class="icon-status" [name]="'icon_sucesso_white'"
        title="Ícone de sucesso"></agv-icon> -->
        <!-- <agv-icon *ngIf="card?.rascunho == 'S'" class="icon-status" [name]="'icon_atencao_white'"
        title="Ícone de atenção"></agv-icon> -->
        <!-- <span *ngIf="isAdmin" class="noticias__cards__row__card__dados__data publicacao">
          {{card?.rascunho != 'N' ? 'RASCUNHO' : 'PUBLICADO'}}</span> -->
    <!-- <agv-icon *ngIf="isAdmin" class="remove_noticia_icn" tabindex="0" [title]="'Remover notícia'" [name]="'icn_limpar'" (click)="openModal(card.codigo, $event)" (keypress)="openModal(card.codigo, $event)"></agv-icon> -->
<!-- </div> -->
<!-- <app-modal_opcoes (opcaoModal1Event)="removeNoticia()"></app-modal_opcoes> -->

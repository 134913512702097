import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Tipocivico, Toponimo } from "../core/models/enderecos/endereco";
import { TokenStorageService } from "./tokenStorageServices.service";
import { environment } from "src/environments/environment";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EnderecoService implements OnDestroy {
  private readonly BASE_URL = `${environment.uri}`;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private tokenService: TokenStorageService
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getRequestOptions(params?: HttpParams): {
    headers: HttpHeaders;
    params?: HttpParams;
  } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      }),
      params: params ? params : undefined,
    };
  }

  listaMunicipios(): Observable<any> {
    return this.http
      .get<HttpResponse<any>>(
        `${this.BASE_URL}v1/generic/lista/municipio`,
        this.getRequestOptions()
      )
      .pipe(takeUntil(this.unsubscribe$));
  }

  listaToponimos(): Observable<Toponimo[]> {
    return this.http.get<Toponimo[]>(
      `${this.BASE_URL}v1/generic/lista/toponimo`,
      this.getRequestOptions()
    );
  }

  listaToponimoCorreio(): Observable<Toponimo[]> {
    return this.http.get<Toponimo[]>(
      `${this.BASE_URL}v1/generic/lista/correios/toponimo`,
      this.getRequestOptions()
    );
  }


  listaTipoCivicos(): Observable<Tipocivico[]> {
    return this.http
      .get<Tipocivico[]>(
        `${this.BASE_URL}v1/generic/lista/tipocivico`,
        this.getRequestOptions()
      ).pipe(takeUntil(this.unsubscribe$));
  }

  listaTipoCivicosCorreio(): Observable<Tipocivico[]> {
    return this.http.get<Tipocivico[]>(
      `${this.BASE_URL}v1/generic/lista/correios/tipocivico`,
      this.getRequestOptions()
    );
  }

  listaEndereco(endereco: any): Observable<any> {
    const paramsData = {
      codMunicipio: endereco?.municipio,
      toponimoId: endereco?.toponimo,
      via: endereco?.endereco ?? ' ',
    };
    const params = new HttpParams({ fromObject: paramsData });
    return this.http.get<any>(
      `${this.BASE_URL}v1/generic/busca/endereco`,
      this.getRequestOptions(params)
    ).pipe(takeUntil(this.unsubscribe$));;
  }

  buscaFornecimento(param: any): Observable<any> {
    const paramsData = {
      logradouroId: param?.logradouroId,
      tipoCivicoId: param?.tipoCivicoId,
      civico: param?.civico,
    };
    const params = new HttpParams({ fromObject: paramsData });
    return this.http.get<any>(
      `${this.BASE_URL}v1/generic/busca/fornecimento`,
      this.getRequestOptions(params)
    ).pipe(takeUntil(this.unsubscribe$));
  }

  validarFornecimento(
    service: string,
    codigoFornecimento: string | null = null,
    idEndereco: string | null = null
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('service', service);
    if (codigoFornecimento) {
      params = params.append('fornecimento', codigoFornecimento);
    } else if (idEndereco) {
      params = params.append('idEndereco', idEndereco);
    }
    return this.http
      .get(`${this.BASE_URL}v1/generic/valida/fornecimento`, { params })
      .pipe(
        catchError((error) => {
          console.error('Erro na requisição:', error);
          return throwError(() => error);
        }),
        takeUntil(this.unsubscribe$)
      );
  }

  validarFornecimentoLogado(
    service: string,
    codigoFornecimento: string | null = null,
    idEndereco: string | null = null
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('service', service);
    if (codigoFornecimento) {
      params = params.append('fornecimento', codigoFornecimento);
    } else if (idEndereco) {
      params = params.append('idEndereco', idEndereco);
    }
    return this.http
      .get(`${this.BASE_URL}v1/generic/sec/valida/fornecimento`, this.getRequestOptions(params))
      .pipe(
        catchError((error) => {
          console.error('Erro na requisição:', error);
          return throwError(() => error);
        }),
        takeUntil(this.unsubscribe$)
      );
  }

  buscarEndereco(endereco: any): Observable<any> {
    const paramsData = {
      codMunicipio: endereco?.municipio,
      toponimoId: endereco?.toponimo,
      via: endereco?.endereco ?? ' ',
    };
    const params = new HttpParams({ fromObject: paramsData });
    return this.http
      .get<any>(
        `${this.BASE_URL}v1/generic/busca/endereco`,
        this.getRequestOptions(params)
      )
      .pipe(takeUntil(this.unsubscribe$));
  }

  listaTipoCivicoImovel(): Observable<Tipocivico[]> {
    return this.http
      .get<Tipocivico[]>(
        `${this.BASE_URL}v1/generic/lista/tipocivico`,
        this.getRequestOptions()
      )
      .pipe(takeUntil(this.unsubscribe$));
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormularioPLAState, PLAKey } from './primeira-ligacao.reducers';

export const selectFormularioState =
  createFeatureSelector<FormularioPLAState>(PLAKey);

// Selector para obter o formulário PLA
export const selectFormularioPLA = createSelector(
  selectFormularioState,
  (state: FormularioPLAState) => state
);

import { Auth } from './../../models/auth';
import { successAuth, sucessoCadastraNovaSenha} from './user.actions';
import { createReducer, on } from '@ngrx/store';
import { Cliente } from './../../models/cliente';

export interface IUserState {
  clientInformation?: Cliente;
  auth?: Auth;
}

export const userKey = 'User';

export const userInitialState: IUserState = {
  clientInformation: undefined,
  auth: undefined,
};

export const UserReducer = createReducer(
  userInitialState,
  on(successAuth, (state, action) => {
    const newState = Object.assign({}, state, { auth: action.payload });
    return newState;
  }),
  on(sucessoCadastraNovaSenha, (state, action) => {
    const newState = Object.assign({}, state, { auth: action.status });
    return newState;
  })
);

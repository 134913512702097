import { Acordo } from './../../../../core/models/acordo';
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/shared/observables/theme.service';
import { AcordoService } from 'src/app/shared/observables/acordo.service';


@Component({
  selector: 'app-acesso-meus-parcelamentos',
  templateUrl: './acesso-meus-parcelamentos.component.html',
  styleUrls: ['./acesso-meus-parcelamentos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AcessoMeusParcelamentosComponent implements OnInit {
  
  // Controlador do tema
  currentTheme: string;

  @Input() acordos: Acordo[] = [];
  
  constructor(
    private router: Router, 
    private theme: ThemeService,
    private acordo: AcordoService
    ) {}

  ngOnInit(): void {
    this.theme.currentTheme.subscribe(theme => this.currentTheme = theme);
  }

  onclick(): void {
    this.router.navigate(['/meus-parcelamentos']);
  }
}

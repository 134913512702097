export class  CondicaoPagamento {
  diaVencimento: number[];
  qdeParcelas: string[];
}

export class DadosPagamento{
  valor:number;
  metodoPagamento:string;
  parcelas:string;

  constructor(){
    this.valor = 0;
    this.metodoPagamento = '';
    this.parcelas = '';
  }
}

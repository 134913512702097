<div
  id="selecao-endereco"
  class="address-select"
  tabindex="0"
  attr.aria-label="{{ createAriaLabel(2) }}"
>
  <div
    id="selecao-endereco__loading"
    role="group"
    class="address-select__loading"
    *ngIf="loadingForn"
  >
    <agv-icon
      class="address-select__loading__img"
      [name]="'loading'"
    ></agv-icon>
  </div>
  <div id="selecao-endereco-header" role="group" class="address-select__title">
    <span aria-hidden="true"> Selecione o endereço </span>
    <div
      id="selecao-endereco-popup-info"
      role="group"
      class="address-interrogation"
      (click)="showInfo()"
      (keypress)="enderecoPopup($event)"
      tabindex="0"
      aria-label="Se estiver com problemas para encontrar o fornecimento, clique aqui!"
    >
      ?
    </div>
  </div>
  <div
    id="selecao-endereco-conteudo"
    role="group"
    class="address-select__content"
  >
    <!-- COMBO CNPJ -->
    <div
      id="campo-selecao-cnpj"
      *ngIf="isRepresentanteLegal"
      class="campo-selecao-cnpj"
    >
      <div
        id="container-selecao"
        role="group"
        class="selectContainer selectCnpj"
        clickOutside
        (clickOutside)="closeSelectCnpj()"
      >
        <div
          id="campo-selecao"
          role="group"
          tabindex="0"
          class="select-field"
          (click)="toggleSelectCnpj()"
          (keypress)="abrirCnpj($event)"
          attr.aria-label="{{ createAriaLabel(3) }}"
        >
          <div
            id="campo-selecao-conteudo"
            role="group"
            class="select-field__content"
          >
            <div role="group" class="address-label">CNPJ</div>
            <div
              id="campo-selecao-enderecos"
              role="group"
              class="address-value"
            >
              <span
                *ngIf="fornecimentos && fornecimentos.length > 0"
                class="address-value__label1"
              >
                {{ cnpjSelected?.razaoSocial }}
              </span>
            </div>
          </div>
          <div class="icon-arrow">
            <agv-icon
              class="icon"
              [ngClass]="{ 'rotate-180': cnpjOpened }"
              [name]="'arrow_black'"
            ></agv-icon>
          </div>
        </div>
        <div
          id="container-selecao-aberta"
          *ngIf="cnpjOpened"
          class="select-field__opened"
        >
          <div class="container-address" role="list">
            <section>
              <div
                id="container-seleciona-cnpj-{{ i }}"
                tabindex="0"
                role="listitem"
                *ngFor="let cpnj of cnpjs; let i = index"
                class="address"
                (click)="selectCnpj(cpnj)"
                (keypress)="selecionarCnpj($event, cpnj)"
                aria-label="Endereço"
              >
                <div
                  id="seleciona-cnpj-info-razao-social"
                  role="group"
                  class="address__description"
                >
                  {{ cpnj?.razaoSocial }}
                </div>
                <div
                  id="seleciona-cnpj-info-cnpj"
                  role="group"
                  class="address__supply"
                >
                  <span class="mr-2">CNPJ</span> {{ cpnj.cnpj }}
                </div>
                <div class="separator"></div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div
        id="container-info-cnpj-selecionado"
        role="group"
        class="address-select__info"
        #vencimentoFatura
      >
        <div
          id="container-info-cnpj-selecionado-cnpj"
          class="info-box"
          tabindex="0"
          attr.aria-label="{{ createAriaLabel(4) }}"
        >
          <span aria-hidden="true">CNPJ</span>
          <span role="group" class="info-value" aria-hidden="true">{{
            cnpjSelected?.cnpj
          }}</span>
        </div>
        <div class="separator"></div>
        <div
          id="container-info-cnpj-selecionado-venc"
          class="info-box border-none"
          tabindex="0"
          attr.aria-label="{{ createAriaLabel(1) }}"
        >
          <span aria-hidden="true">Vigência até</span>
          <span
            *ngIf="cnpjSelected?.dataFim"
            class="info-value"
            aria-hidden="true"
            >{{ cnpjSelected?.dataFim }}</span
          >
          <span
            *ngIf="!cnpjSelected?.dataFim"
            class="info-value"
            aria-hidden="true"
            >--/--/--</span
          >
        </div>
      </div>
    </div>

    <!-- COMBO FORNECIMENTO -->
    <div id="campo-selecao-fornecimento" class="campo-selecao-fornecimento">
      <div
        id="container-selecao"
        role="group"
        class="selectContainer"
        clickOutside
        (clickOutside)="closeSelect()"
      >
        <div
          id="campo-selecao"
          role="group"
          tabindex="0"
          class="select-field"
          (click)="toggleSelect()"
          (keypress)="abrirEndereco($event)"
          attr.aria-label="{{ createAriaLabel(3) }}"
        >
          <div
            id="campo-selecao-conteudo"
            role="group"
            class="select-field__content"
          >
            <div role="group" class="address-label">Endereço</div>
            <div
              id="campo-selecao-enderecos"
              role="group"
              class="address-value"
            >
              <span
                *ngIf="
                  fornecimentos !== undefined &&
                  fornecimentos !== null &&
                  fornecimentos.length > 0
                "
                class="address-value__label1"
              >
                {{ labelAddress1 }}
              </span>
              <span
                *ngIf="fornecimentos === undefined || fornecimentos === null"
                >Nenhum endereço encontrado</span
              >
            </div>
          </div>
          <div class="icon-arrow">
            <agv-icon
              class="icon"
              [ngClass]="{ 'rotate-180': selectOpened }"
              [name]="'arrow_black'"
            ></agv-icon>
          </div>
        </div>

        <div
          id="container-selecao-aberta"
          *ngIf="selectOpened"
          class="select-field__opened"
        >
          <div class="container-address" role="list">
            <div
              id="select-sem-fornec"
              class="address"
              *ngIf="
                fornecimentos === undefined ||
                fornecimentos === null ||
                !(fornecimentos.length > 0)
              "
            >
              <div class="address__description">Nenhum endereço encontrado</div>
              <div class="address__supply" aria-hidden="true">
                <span class="mr-2">Fornecimento</span> -
              </div>
            </div>
            <section
              *ngIf="
                fornecimentos !== undefined &&
                fornecimentos !== null &&
                fornecimentos.length > 0
              "
            >
              <div
                id="container-seleciona-endereco-{{ i }}"
                tabindex="0"
                role="listitem"
                *ngFor="
                  let fornecimento of fornecimentosFiltrados;
                  let i = index
                "
                class="address"
                (click)="selectAddress(fornecimento)"
                (keypress)="selecionarEndereco($event, fornecimento)"
                aria-label="Endereço"
              >
                <div
                  id="seleciona-endereco-info-endereco"
                  role="group"
                  class="address__description"
                  [ngClass]="{
                    'font-hard-gray':
                      fornecimento.status.toUpperCase() !== 'ATIVO'
                  }"
                >
                  {{
                    fornecimento.enderecoFornecimento !== undefined
                      ? fornecimento.enderecoFornecimento.replace(
                          " N ",
                          "
                  Nº "
                        )
                      : labelAddress1
                  }}
                </div>
                <div
                  id="seleciona-endereco-info-fornec"
                  role="group"
                  class="address__supply"
                  [ngClass]="{
                    'font-hard-gray':
                      fornecimento.status.toUpperCase() !== 'ATIVO'
                  }"
                >
                  <span class="mr-2">Fornecimento</span>
                  {{ fornecimento.codigo }}
                </div>
                <div
                  id="seleciona-endereco-info-inativo"
                  role="group"
                  *ngIf="
                    fornecimento.status.toUpperCase() === 'ENCERRADO A FATURAR'
                  "
                  class="address__inactive"
                >
                  Inativo
                </div>
                <div
                  id="seleciona-endereco-info-encerrado"
                  role="group"
                  *ngIf="
                    fornecimento.status.toUpperCase() === 'ENCERRADO E FATURADO'
                  "
                  class="address__inactive"
                >
                  Encerrado e Faturado
                </div>
                <div
                  id="seleciona-endereco-info-cortado"
                  role="group"
                  *ngIf="fornecimento.status.toUpperCase() === 'CORTADO'"
                  class="address__cut"
                >
                  Cortado
                </div>
                <div
                  id="seleciona-endereco-info-cortado"
                  role="group"
                  *ngIf="
                    fornecimento.status.toUpperCase().includes('SUPRIMIDO')
                  "
                  class="address__cut"
                >
                  Suprimido
                </div>
                <div class="separator"></div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div
        *ngIf="!isMobile; else mobile"
        id="container-info-endereco-selecionado"
        role="group"
        class="address-select__info"
        #vencimentoFatura
      >
        <div
          id="container-info-endereco-selecionado-fornec"
          class="info-box"
          tabindex="0"
          attr.aria-label="{{ createAriaLabel(4) }}"
        >
          <span aria-hidden="true">Fornecimento</span>
          <span
            role="group"
            class="info-value"
            aria-hidden="true"
            *ngIf="
              fornecimentos !== undefined &&
              fornecimentos !== null &&
              fornecimentos.length > 0
            "
            >{{ fornecimentoSelecionado.codigo }}</span
          >
          <span
            role="group"
            class="info-value"
            aria-hidden="true"
            *ngIf="fornecimentos === undefined || fornecimentos === null"
            >------</span
          >
        </div>
        <div class="separator"></div>
        <div
          id="container-info-endereco-selecionado-venc"
          class="info-box border-none"
          tabindex="0"
          attr.aria-label="{{ createAriaLabel(1) }}"
        >
          <span aria-hidden="true">Próximo vencimento</span>
          <span
            *ngIf="
              fornecimentoSelecionado.proximoVencimento !== undefined &&
              fornecimentos !== undefined &&
              fornecimentos !== null &&
              fornecimentos.length > 0
            "
            class="info-value"
            aria-hidden="true"
            >{{
              fornecimentoSelecionado.proximoVencimento | date : "dd/MM/yyyy"
            }}</span
          >
          <span
            *ngIf="
              fornecimentoSelecionado.proximoVencimento === undefined ||
              fornecimentos === undefined ||
              fornecimentos === null ||
              !(fornecimentos.length > 0)
            "
            class="info-value"
            aria-hidden="true"
            >--/--/--</span
          >
        </div>
      </div>

      <ng-template #mobile>
        <div class="address-select__info__mobile">
          <div class="list">
            <span class="title">Fornecimento:</span>
            <span class="value">{{ fornecimentoSelecionado?.codigo }}</span>
          </div>
          <div class="list">
            <span class="title">Proximo Vencimento:</span>
            <span class="value">{{
              faturaAtual?.dataVencimento | date : "dd/MM/yyyy"
            }}</span>
          </div>
          <div class="list">
            <span class="title">Próxima leitura:</span>
            <span class="value">{{
              faturaAtual?.dadosFatura?.dataProximaLeitura
            }}</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

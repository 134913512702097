<div class="input-container">
  <input
    class="input-search"
    type="text"
    placeholder="{{placeholder}}"
    [(ngModel)]="filtroServico"
    (input)="aplicarFiltroTabela(filtroServico)"
  />
  <span class="icon-container">
    <mat-icon>search</mat-icon>
  </span>
</div>
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Fatura } from 'src/app/core/models/faturaCompleta';
import { Validator } from 'src/app/shared/utils/validator';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Fornecimento } from 'src/app/core/models/fornecimento';
import { EventEmitterService } from 'src/app/event-emits.service';
import { DadosPagtoInfo } from 'src/app/core/models/dadosPagtoInformado';
import { Store } from '@ngrx/store';
import { IPagtoInformadoState } from 'src/app/core/store/pagto-informado/pagto-informado.reducer';
import { Actions, ofType } from '@ngrx/effects';
import {
  distinctUntilChanged,
  finalize,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { Router } from '@angular/router';
import { Parcela } from 'src/app/core/models/acordo';
import { ThemeService } from 'src/app/shared/observables/theme.service';
import { FaturaService } from 'src/app/shared/observables/fatura.service';
import {
  carregaFaturasFornecimento,
  sucessoFaturasFornecimento,
} from 'src/app/core/store/supply/supply.actions';
import { FornecimentoService } from 'src/app/shared/observables/fornecimento.service';
import { AcordoService } from 'src/app/shared/observables/acordo.service';
import { ClienteService } from 'src/app/shared/observables/cliente.service';
import { ClienteCompleto } from 'src/app/core/models/service/cadastroCliente';
import { CNPJService } from 'src/app/shared/observables/cnpj.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPesquisaSastifacaoComponent } from '../../dialog-pesquisa-sastifacao/dialog-pesquisa-sastifacao.component';
import { ComunicarPagamentoService } from 'src/app/services/pagamentoInformado.service';
import { Regex } from 'src/app/shared/utils/regex';

@Component({
  selector: 'app-comunica-pagto',
  templateUrl: './comunica-pagto.component.html',
  styleUrls: ['./comunica-pagto.component.scss'],
})
export class ComunicaPagtoComponent implements OnInit {
  // Controlador do tema
  currentTheme: string;

  @ViewChild('listaBancos') listaBancosElement: ElementRef;
  @Input() fatura: Fatura | null = null;
  @Input() fornecimento: Fornecimento | null = null;
  @Input() comunicaPgtoType: string = '';

  faturasSelecionadas: Fatura[] = [];
  parcelasSelecionadas: Parcela[] = [];
  parcelas: any[] = [];
  columnstable: string[] = [];
  bancos: any = [];
  lstFaturas: Fatura[];

  codigoFornecimento: string = '';
  mensagemProcesso: string = '';
  tituloProcesso: string = '';
  msgErroDate: string = '';
  msgErroItem: string = '';
  protocolo: string = '';
  valida: string = '';
  local: string = '';
  data: string = '';

  acordosRompidos: number = 0;

  erroSelectedBanco: boolean = false;
  errorSelecionada: boolean = false;
  comunicaSucesso: boolean = false;
  selectOpened: boolean = false;
  selecionada: boolean = true;
  isMobile: boolean = false;
  comunica: boolean = true;
  carregando: boolean = false;

  dados: DadosPagtoInfo = new DadosPagtoInfo();
  cliente: ClienteCompleto | undefined = new ClienteCompleto();
  destroyed$ = new Subject<boolean>();
  cnpj: any;

  constructor(
    private store: Store<{ pagtoInfo: IPagtoInformadoState }>,
    private fornObs: FornecimentoService,
    private acordoObs: AcordoService,
    private cliObs: ClienteService,
    private fatObs: FaturaService,
    private cnpjObs: CNPJService,
    private theme: ThemeService,
    private updates$: Actions,
    public dialog: MatDialog,
    private router: Router,
    private comunicarPagamentoService: ComunicarPagamentoService
  ) {}

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
    this.fatObs.currentFaturas.subscribe((faturas) => {
      if (!this.comunicaSucesso) {
        this.lstFaturas = [];
        if (faturas) {
          this.lstFaturas = faturas.filter(
            (x) =>
              x.situacaoDaFatura.toUpperCase() === 'EM ABERTO' ||
              x.situacaoDaFatura.toUpperCase() === 'RESIDUAL A PAGAR' ||
              x.situacaoDaFatura.toUpperCase() === 'EM ATRASO'
          );
          if (this.lstFaturas.length <= 0) {
            this.tituloProcesso = 'Nada por aqui';
            this.mensagemProcesso =
              'Este fornecimento não possui faturas em atraso.';
          }
        } else {
          this.tituloProcesso = 'Nada por aqui';
          this.mensagemProcesso =
            'Este fornecimento não possui nenhuma fatura fechada.<br/> Após o fechamento da fatura e seu vencimento, ela aparecerá aqui.';
        }
      }
      this.cliObs
        .getCliente()
        .pipe(take(1), distinctUntilChanged(), takeUntil(this.destroyed$))
        .subscribe((cliente) => (this.cliente = cliente));
      this.cnpjObs
        .getRepresentante()
        .pipe(take(1), distinctUntilChanged(), takeUntil(this.destroyed$))
        .subscribe((cnpj) => {
          this.cnpj = cnpj;
        });
    });

    this.acordoObs.currentAcordos.subscribe((acordos) => {
      if (acordos) {
        acordos.forEach((acordo) => {
          if (!acordo.statusAcordo.toUpperCase().includes('ROMPIDO')) {
            acordo.parcelas.forEach((parcela) => {
              if (
                parcela.status.toUpperCase() === 'EM ABERTO' ||
                parcela.status.toUpperCase() === 'RESIDUAL A PAGAR' ||
                parcela.status.toUpperCase() === 'EM ATRASO'
              )
                this.parcelas = this.parcelas.concat({
                  acordo: acordo.codigoAcordo,
                  numParcela: parcela.numParc,
                  dataEmissao: acordo.dataParcelamento,
                  valor: parcela.valor,
                  dataVencimento: parcela.dataVencimento,
                  situacao: parcela.status,
                  codeline: parcela.codeline,
                });
            });
          } else {
            this.acordosRompidos++;
          }
        });
      }
    });

    this.fornObs.currentFornecimento.subscribe(
      (fornecimento) => (this.codigoFornecimento = fornecimento?.codigo)
    );

    if (this.comunicaPgtoType === 'parcelamento') {
      this.columnstable = [
        'select',
        'numDoc',
        'numParcela',
        'dataEmissao',
        'valor',
        'dataVencimento',
        'situacaoDaParcela',
      ];
      this.fornecimento?.acordos.forEach((x) =>
        this.parcelas.concat(x.parcelas)
      );
    } else {
      this.columnstable = [
        'select',
        'numDoc',
        'dataEmissao',
        'valor',
        'dataVencimento',
        'situacaoDaFatura',
      ];
    }

    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
    window.onresize = () => (this.isMobile = window.innerWidth <= 768);

    this.bancos = [
      {
        nome: 'Bradesco',
        img: 'icn_bradesco_ativo',
        alt: 'Logotipo Bradesco',
      },
      {
        nome: 'Itaú',
        img: 'icn_itau_ativo',
        alt: 'Logotipo Itaú',
      },
      {
        nome: 'Santander',
        img: 'icn_santander_ativo',
        alt: 'Logotipo Santander',
      },
      {
        nome: 'Outro',
        img: 'icn_banco',
        alt: 'Ícone genérico de banco',
      },
    ];
  }

  comunicar() {
    if (!this.selecionada) {
      this.errorSelecionada = true;
      return;
    } else {
      this.errorSelecionada = false;
    }
    if (!this.local || this.local === '') {
      this.erroSelectedBanco = true;
      return;
    } else {
      this.erroSelectedBanco = false;
    }
    if (!this.data || this.data === '') {
      this.msgErroDate = 'Selecione uma data';
      return;
    }
    if (new Date(this.data) > new Date()) {
      this.msgErroDate = 'A data selecionada não pode estar no futuro';
      return;
    } else {
      this.msgErroDate = '';
    }
    if (
      (this.comunicaPgtoType === 'parcelamento' &&
        this.parcelasSelecionadas.length === 0) ||
      (this.comunicaPgtoType !== 'parcelamento' &&
        this.faturasSelecionadas.length === 0)
    ) {
      this.msgErroItem =
        'Selecione ao menos uma ' +
        (this.comunicaPgtoType === 'parcelamento' ? 'parcela' : 'fatura');
      return;
    }
    this.msgErroItem = '';
    if (this.fatura) {
      this.msgErroDate = '';
      this.errorSelecionada = false;
    }

    this.dados.nomeBanco = this.local;
    this.dados.dataPagamento = new Date(this.data).toISOString().split('T')[0];

    this.dados.tipoPessoa = `${this.cliente?.tipoPessoa}`;
    this.dados.codigoFornecimento = `${this.fornecimento?.codigo}`;

    if (this.comunicaPgtoType === 'parcelamento') {
      this.parcelasSelecionadas.forEach((x) =>
        this.dados.codelineFaturas.push(x.codeline)
      );
    } else {
      this.faturasSelecionadas.forEach((x) =>
        this.dados.codelineFaturas.push(x.codigoPagamento)
      );
    }
    if (
      this.fornecimento?.status === 'SUPRIMIDO - POR DÉBITO' ||
      this.fornecimento?.status === 'CORTADO'
    ) {
      if (
        this.faturasSelecionadas.filter(
          (x) => x.situacaoDaFatura.toUpperCase() === 'EM ATRASO'
        ).length !==
          this.fornecimento?.faturas.filter(
            (x) => x.situacaoDaFatura.toUpperCase() === 'EM ATRASO'
          ).length ||
        this.acordosRompidos > 0 ||
        this.parcelas.length > 0
      ) {
        this.dados.subtipoProcesso = '1';
        this.capturaComunica(this.dados);
        return;
      } else {
        this.dados.subtipoProcesso = '3';
      }
    } else {
      this.dados.subtipoProcesso = '1';
      this.capturaComunica(this.dados);
      return;
    }

    document.getElementById('comunica-pagto')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
    this.comunica = false;
  }

  toggleSelect(evento: any) {
    if (Validator.validaEventKeyPressClick(evento)) {
      this.selectOpened = !this.selectOpened;
    }
  }

  loseFocusSelect() {
    setTimeout(() => {
      if (!document.activeElement?.classList.contains('banco'))
        this.selectOpened = false;
    });
  }

  checkMensagemExiste(): boolean {
    // Lógica para a rota 'meus-parcelamentos' e 'fatura-completa'
    const urlAtual = this.router.url;
    if (
      urlAtual === '/meus-parcelamentos' &&
      this.parcelas.length === 0 &&
      !this.comunicaSucesso
    ) {
      this.tituloProcesso = 'Nada por aqui';
      this.mensagemProcesso = 'Este fornecimento não possui faturas em atraso.';
      return true;
    } else if (
      urlAtual === '/fatura-completa' &&
      this.lstFaturas.length === 0 &&
      !this.comunicaSucesso
    ) {
      this.tituloProcesso = 'Nada por aqui';
      this.mensagemProcesso = 'Este fornecimento não possui faturas em atraso.';
      return true;
    } else {
      return false;
    }
  }

  bancoSelecionado(banco: string) {
    this.local = banco;
  }

  changeDate(event: MatDatepickerInputEvent<any>) {
    if (new Date(event.value) > new Date()) {
      this.msgErroDate = 'A data selecionada não pode estar no futuro';
      this.congela(false);
    } else {
      this.msgErroDate = '';
      this.congela(false);
    }
  }

  congela(deve: boolean) {
    setTimeout(() => {
      let doc: any = document.querySelector('.mat-datepicker-close-button');
      if (doc) {
        let element: any = doc?.childNodes[0];
        element.innerHTML = 'Fechar calendário';
      }
    });
    if (!deve) {
      document.documentElement.style.overflow = 'initial';
    }
  }

  //usado para descongelar a tela quando o datepicker fica por cima do campo de data (geralmente no modo mobile)
  descongelaMobile() {
    if (this.isMobile) {
      this.congela(false);
    }
  }

  habilitaBotao(): boolean {
    if (
      this.local !== null &&
      this.local !== '' &&
      this.data !== null &&
      new Date(this.data) < new Date()
    ) {
      return true;
    } else {
      return false;
    }
  }

  capturaItem(e: any) {
    if (this.comunicaPgtoType === 'parcelamento') {
      EventEmitterService.get('parcelasSelecionadas').subscribe((parcela) => {
        this.parcelasSelecionadas = parcela;
      });
    } else {
      EventEmitterService.get('faturasSelecionadas').subscribe((faturas) => {
        this.faturasSelecionadas = faturas;
      });
    }
  }

  capturaComunica(dados: DadosPagtoInfo) {
    dados.tipoPessoa =
      this.router.url.includes('negocios/empresa') ||
      localStorage.getItem('FluxoPJ')
        ? 'PJ'
        : 'PF';
    dados.cnpj = Regex.removeMaskCnpj(this.cnpj?.cnpj ?? '');
    this.carregando = true;
    this.comunicarPagamentoService
      .comunicarPagamento(dados)
      .pipe(
        tap((response) => {
          this.protocolo = response.protocolo;
          if (this.protocolo) {
            const mensagens: any = {
              '1': {
                titulo: 'PAGAMENTO INFORMADO',
                mensagem: `Sua comunicação de pagamento foi enviada com sucesso, mas poderá levar alguns minutos para efetivação.
                Foi gerado o protocolo de atendimento nº${this.protocolo}.`,
              },
              '3-SUPRIMIDO - POR DÉBITO': {
                titulo:
                  'PAGAMENTO INFORMADO COM EFEITO DE RELIGAÇÃO POR DÉBITO',
                mensagem: `Foi gerado o protocolo de atendimento nº${this.protocolo}.`,
              },
              '3-CORTADO': {
                titulo: 'PAGAMENTO INFORMADO COM RESTABELECIMENTO',
                mensagem: `Foi gerado o protocolo de atendimento nº${this.protocolo}.`,
              },
            };

            const chave =
              this.dados.subtipoProcesso === '3'
                ? `${this.dados.subtipoProcesso}-${this.fornecimento?.status}`
                : this.dados.subtipoProcesso;
            const mensagemConfig = mensagens[chave];

            if (mensagemConfig) {
              this.tituloProcesso = mensagemConfig.titulo;
              this.mensagemProcesso = mensagemConfig.mensagem;
            }

            this.dialog.open(DialogPesquisaSastifacaoComponent, {
              width: '600px',
              maxWidth: '90vw',
              panelClass: 'dialog-pesquisa-satifacao-container',
              data: this.protocolo,
              disableClose: true,
            });
          }
        }),
        switchMap(() => {
          this.store.dispatch(
            carregaFaturasFornecimento({
              codigoFornecimento: this.codigoFornecimento,
            })
          );
          return this.updates$.pipe(ofType(sucessoFaturasFornecimento));
        }),
        finalize(() => (this.carregando = false)),
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
        take(1)
      )
      .subscribe({
        next: (response) => {
          this.comunicaSucesso = true;
        },
        error: (error) => {
          console.log(error);
          this.comunicaSucesso = false;
        },
      });
  }

  capturaComunicaOutro(valor: boolean) {
    this.protocolo = '';
    this.local = '';
    this.data = '';
    this.faturasSelecionadas = [];
    this.parcelasSelecionadas = [];
    this.dados = new DadosPagtoInfo();
    this.comunica = valor;
    this.comunicaSucesso = false;
    this.codigoFornecimento = `${this.fornecimento?.codigo}`;
    this.carregando = false;
  }
}

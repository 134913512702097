import { MensagemComponent } from './../mensagem-erro/mensagem-erro.component';
import { EventEmitterService } from './../../../event-emits.service';
import { Validator } from './../../utils/validator';
import { Regex } from './../../utils/regex';
import {Component, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges, SimpleChanges, ElementRef, AfterViewInit, NgZone} from '@angular/core';
import { environment } from 'src/environments/environment';
import { ThemeService } from '../../observables/theme.service';
import { SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-valida-cpf',
  templateUrl: './valida-cpf.component.html',
  styleUrls: ['./valida-cpf.component.scss']
})
export class ValidaCpfComponent implements OnInit, OnChanges, AfterViewInit {

  // Controlador do tema
  currentTheme: string = 'default-theme';

  @Output() redicionaCpf: EventEmitter<any> = new EventEmitter<string>();
  @Output() resetInputEvent: EventEmitter<any> = new EventEmitter<boolean>();
  @ViewChild('divRecaptcha') divRecaptcha!: ElementRef<HTMLDivElement>;
  @ViewChild(MensagemComponent, {static: false}) mensagemComponent: MensagemComponent;

  @Input() showBtn: boolean = true;
  @Input() findInformationClient = false;
  @Input() showErrorTransf = false;
  @Input() origemFluxo: string = "";
  @Input() carregaCPF: string | null = "";
  @Input() mensagem: string | SafeHtml = '';
  recaptchaValue: string = ''

  cpfValido: boolean = true;
  disabledCpf: boolean = false;
  buscaCpf: boolean = false;
  recaptchaSelecionado: boolean = false;

  cpf: string = '';

  constructor(
    private theme: ThemeService,
    private ngZone: NgZone,
  ) {
    if (environment.disabledRecaptcha) {
      this.recaptchaSelecionado = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.showErrorTransf) {
      this.cpfValido = this.showErrorTransf ? false : true
    }
    this.cpf = this.carregaCPF ?? '';
  }

  ngOnInit(): void {
    this.theme.currentTheme.subscribe(theme => this.currentTheme = theme);
  }

  get grecaptcha(): any {
    const w = window as any;
    return w['grecaptcha'];
  }

  renderizarReCaptcha() {
    this.ngZone.runOutsideAngular(() => {
      if (!this.grecaptcha || !this.divRecaptcha) {
        setTimeout(() => {
          this.renderizarReCaptcha();
        }, 500);

        return;
      }

      const idElemento = this.divRecaptcha.nativeElement.getAttribute('id');
      if (this.grecaptcha && this.grecaptcha.enterprise) {
        this.grecaptcha?.enterprise?.render(idElemento, {
          sitekey: environment.siteKey,
          callback: (response: string) => {
            this.ngZone.run(() => {
              this.recaptchaValue = response;
              this.recaptchaSelecionado = true;
            });
          },
        });
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderizarReCaptcha();
    }, 800);
  }

  mascaraCPF() {
    this.cpf = Regex.maskCpf(this.cpf);
    if (this.cpf.length < 14) {
      this.cpfValido = false;
      this.mensagem = 'CPF inválido, o CPF deve conter 11 dígitos.'
    } else {
      this.cpfValido = true;
      this.mensagem = ''
    }
  }

  validaCPF() {
    this.buscaCpf = true;
    this.cpfValido = Validator.validarCPF(this.cpf);
    this.removerBtnCarregar();

    if(!this.cpfValido) {
      this.mensagem = 'CPF inválido!';
      this.mensagemComponent.focusMensagem();
      return;
    }

    this.redicionaCpf.emit(this.cpf);
    EventEmitterService.get('validarCPF').emit(this.cpf);
    localStorage.setItem('cpf', this.cpf);
    this.disabledCpf = true;
  }

  resetInput() {
    this.cpf = "";
    this.mensagem = "";
    this.cpfValido = true;
    this.disabledCpf = false;
    this.resetInputEvent.emit(true)
  }

  removerBtnCarregar() {
    this.buscaCpf = false;
  }
}

<!-- carousel -->
<div class="carousel">
  <ng-container *ngFor="let slide of slides; let i = index">
    <div  class="slide" *ngIf="i === currentSlide">
      <ng-container
      [ngTemplateOutlet]="temp"
      [ngTemplateOutletContext]="{ data: slide }"
      >
      </ng-container>
    </div>
  </ng-container>

  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>


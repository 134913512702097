import { Regex } from './../../utils/regex';
import { catchError, map, Observable, Subject } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpStatusCode, HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { Store } from '@ngrx/store';
import { IUserState } from 'src/app/core/store/user/user.reducer';
import { loadingClientInformation } from 'src/app/core/store/user/user.actions';

interface ResponseLogin {
  token: string;
  expires: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly BASE_URL = `${environment.uri}v1/canais/auth`;
  private _apikey: string = `${environment.apikey}`;

  constructor(
    private readonly http: HttpClient,
    private _tokenService: TokenStorageService,
    private store: Store<{ user: IUserState }>
  ) {}

  autenticarLogin(
    cpf: string,
    password: string,
    recaptcha: string,
    manterConectado: boolean = false
  ): Observable<HttpStatusCode> {
    const headers = new HttpHeaders({
      'X-ReCaptcha-Token': recaptcha ?? '',
      'apikey': this._apikey,
    });

    return this.http
      .post(
        `${this.BASE_URL}`,
        {
          cpf: Regex.removeMaskCpf(cpf),
          password,
        },
        { headers }
      )
      .pipe(
        map((res: any) => {
          this._tokenService.saveToken(res, manterConectado);
          this._tokenService.saveUser(manterConectado);
          if (manterConectado)
            this._tokenService.manterConectado(manterConectado.toString());
          else this._tokenService.removerManterConectado();
          localStorage.removeItem('fezParc');
          localStorage.removeItem('fezReparc');
          return HttpStatusCode.Accepted;
        }),
        catchError((err, caught) => {
          console.error('ERROR REQUEST LOGIN', err, caught);
          throw err.status;
        })
      );
  }
}
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmpresaCNPJKey, EmpresaCNPJState } from './empresa-cnpj.reducer';

export const selectEmpresaCNPJState =
  createFeatureSelector<EmpresaCNPJState>(EmpresaCNPJKey);

export const selectEmpresaCNPJ = createSelector(
  selectEmpresaCNPJState,
  (state: EmpresaCNPJState) => {
    return state.empresaCnpj;
  }
);

export const selectCNPJRepresentante = createSelector(
  selectEmpresaCNPJState,
  (state: EmpresaCNPJState) => {
    return state.representante;
  }
);

export const selectEmpresaFornecimento = createSelector(
  selectEmpresaCNPJState,
  (state: EmpresaCNPJState) => {
    return state.fornecimento;
  }
);

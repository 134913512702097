import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DetalhesFatura } from 'src/app/core/models/pagamentoFaturaItens';
import { PagamentoFaturaService } from 'src/app/shared/components/pagamento-fatura/pagamento-fatura.service';

@Component({
  selector: 'app-codigo-barras',
  templateUrl: './codigo-barras.component.html',
  styleUrls: ['./codigo-barras.component.scss'],
})
export class CodigoBarrasComponent implements OnInit, OnChanges {
  @Input() identificaParcela: any;
  @Output() sucessoExibirPdf: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() dataVencimento: string | null;
  @Input() status: string = '';
  @Input() tipo: string | null;
  @Input() codBarras: string;
  @Input() linhaDigitavel: string = '';
  @Input() total: number;
  @Input() cobrancaJuridica: boolean = false;

  detalhes: DetalhesFatura = {} as DetalhesFatura;

  public buttonCopy: boolean = false;
  hiddeInfosPagto: boolean = false;
  downloadEmProcesso: boolean;
  exibirEmProcesso: boolean;
  isMobile: boolean = false;
  copiado: boolean = false;

  mensagemFatura: string = '';

  //Transforma a string com o codigo de barras recebido em um array para ser usado para o gerador da imagem
  get values(): string[] {
    return this.codBarras?.split('\n');
  }

  get statusTemplate(): string {
    if (!this.status?.charAt(0)) return '';
    return (
      (this.status?.charAt(0) || '')?.toUpperCase() +
        (this.status || '')?.slice(1)?.toLowerCase() || ''
    );
  }

  constructor(
    private readonly pagamentoFaturaService: PagamentoFaturaService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.status.toLocaleUpperCase() == 'SUSPENSA PARA ANÁLISE') {
      this.mensagemFatura = `Esta fatura está em análise e não pode ser exibida`;
    } else if (
      this.status.toLocaleUpperCase() == 'AGUARDANDO CONFIRMAÇÃO DO BANCO'
    ) {
      this.mensagemFatura = `Esta fatura está aguardando confirmação do banco e não pode ser exibida.`;
    } else if (this.status.toLocaleUpperCase() == 'COBRANÇA JURÍDICA') {
      this.mensagemFatura = `Esta fatura está em cobrança jurídica e não pode ser exibida. <br/>Para mais informações, vá a uma agência de atendimento.`;
    } else if (this.status.toLocaleUpperCase() == 'EM ATRASO' && this.cobrancaJuridica) {
      this.mensagemFatura = `Essa fatura está em cobrança jurídica e não pode ser exibida.`;
    } else if (this.status.toLocaleUpperCase() == 'EM ACORDO DE PARCELAMENTO') {
      this.mensagemFatura = `A fatura encontra-se em acordo de parcelamento, por favor acesse os parcelamentos.`;
    } else {
      this.mensagemFatura = '';
    }
    this.codBarras = this.formataCodBarras();
  }

  formataCodBarras(): string {
    const segments = this.codBarras?.match(/.{1,12}/g);
    return segments?.join(' ') ?? '';
  }

  ngOnInit(): void {
    this.exibirEmProcesso = false;
    this.downloadEmProcesso = false;
    this.detalhes =
      this.pagamentoFaturaService.getInfoPagamentoFaturaItens().detalhes;
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
    window.onresize = () => (this.isMobile = window.innerWidth <= 768);
  }

  copyToClipboard() {
    this.copiado = true;
    setTimeout(() => {
      this.copiado = false;
    }, 5000);
    
  }

  exibePDF(exibir: boolean) {
    this.sucessoExibirPdf.emit(exibir);
  }

  loadingControl(serv: string) {
    switch (serv) {
      case 'download':
        this.downloadEmProcesso = true;
        break;
      case 'exibir':
        this.exibirEmProcesso = true;
        break;
    }
  }
}

<div *ngIf="mostrar" class="cookies-banner">
  <div class="cookies-banner__content">
    <div class="cookies-banner__text">
      <p>
        A Agência Virtual Sabesp utiliza cookies para melhorar sua navegação em
        nossas páginas. Alguns desses cookies são essenciais para o
        funcionamento do site, mas outros apenas otimizam sua experiência e nos
        ajudam a melhorar nossos serviços.
      </p>
      <p>
        Para mais informações, acesse nossa
        <a href="/politica-privacidade">politica de privacidade</a> e <a> termo de uso</a>
      </p>
    </div>

    <div class="cookies-banner__actions">
      <button (click)="aceitarTodos()" class="botao-aceitar" mat-button>
        Aceitar todos
      </button>
      <button (click)="rejeitarTodos()" class="botao-rejeitar" mat-button>Rejeitar todos</button>
      <button (click)="abrirGerenciadorCookies()" class="botao-gerenciar" mat-button>Gerenciar cookies</button>
    </div>
  </div>
</div>

import { ItensMenu } from '../../../core/models/menuServicoItens';
import { MenuItens } from '../../../core/models/menuServicoItens';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  private indexSource = new BehaviorSubject<number>(0);
  currentIndex = this.indexSource.asObservable();

  public changeTab(index: number) {
    this.indexSource.next(index);
  }

  public getInfoMenuItens(): MenuItens {
    const stringItemMenu: ItensMenu[] = [
      {
        imagem: 'icn_troca_titular',
        alt_img: 'Icone de usuário trocando titularidade com outro usuário',
        title_img:
          'Icone com usuário no canto inferior esquerdo, uma seta indicando a troca do usuário na esquerda para o usuário no canto superior direito',
        nome: 'Transferência de Titularidade',
        link: '/troca-titular',
        interno: false,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_emergencia_vazamento',
        alt_img: '',
        title_img: '',
        nome: 'Emergência para vazamentos',
        link: '/vazamento',
        interno: false,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_qualidade_agua',
        alt_img: '',
        title_img: '',
        nome: 'Conheça a qualidade da água',
        link: '/qualidade-da-agua',
        interno: false,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_servicos_prestados',
        alt_img: '',
        title_img: '',
        nome: 'Veja os serviços que prestamos para você',
        link: '/mapa-do-site',
        interno: false,
        acao: () => {},
        pf: true,
        pj: true,
      },
      {
        imagem: 'icn_agencia_mais_perto',
        alt_img: '',
        title_img: '',
        nome: 'Procure uma agência mais próxima de você',
        link: '/agencia-mais-proxima',
        interno: false,
        acao: () => {},
        pf: true,
        pj: true,
      },
    ];
    const menuServico = new MenuItens(stringItemMenu);

    return menuServico;
  }
}

<div class="modal-home">
  <p class="modal-home__tituloPagina" tabindex="0">
    <strong>
      {{tituloPagina}}
    </strong>
  </p>
  <div class="modal-home__container">
    <agv-icon id="icone-erro" class="modal-home__container__img" role="img" [name]="'icn-exclamacao'"
      title="Ícone exclamação de erro"></agv-icon>
    <p class="modal-home__container__titulo"  tabindex="0">
      <strong >
        {{tituloModal}}
      </strong>
    </p>
    <div class="modal-home__container__conteudo"  tabindex="0">
      <span class="modal-home__container__conteudo__textoSpan">
        {{descricaoModal}}
      </span>
    </div>
  </div>
  <agv-button [text]="'Ir para a home'" *ngIf="mostraBotao" [type]="'2'" (sendAction)="redireciona()" [habilita]="true">
  </agv-button>
</div>
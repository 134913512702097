import { Cliente } from './../../models/cliente';
import { createAction, props } from '@ngrx/store';
import { Auth } from '../../models/auth';
import { ClienteResumido } from '../../models/clienteSimplificado';
import { ReqAlterarSenha } from '../../models/service/cadastroCliente';

export const loadingClientInformation = createAction(
  '[User] loading client information',
  props<{ payload: string }>()
);

export const failedLoadingClientInformation = createAction(
  `[User] failed loading  client information`
);

interface IPayloadAuth {
  password: string;
  cpf: string;
}

export const loadingAuth = createAction(
  '[User] loading auth',
  props<{ payload: IPayloadAuth, recaptcha: string }>()
);

export const successAuth = createAction(
  '[User] success auth',
  props<{ payload: Auth }>()
)

export const failedAuth = createAction(
  '[User] failed auth'
)

// Serviço utilizado para validação do CPF do cliente no fluxo do troca de titularidade
export const validaCPF = createAction(
  '[User] Validar cpf',
  props<{ cpf:string}>()
)

// Serviço utilizado para validação do CPF do cliente no fluxo do troca de titularidade
export const sucessoValidacao = createAction(
  '[User] Sucesso validação',
  props<{ cliente:ClienteResumido}>()
)

// Serviço utilizado para validação do CPF do cliente no fluxo do troca de titularidade
export const falhaValidacao = createAction(
  '[User] Falha na validação cpf'
)

// Serviço utilizado para alteração de senha
export const cadastraNovaSenha = createAction(
  '[User] Cadastra senha',
  props<{ ReqAlteraSenha:ReqAlterarSenha}>()
)

// Serviço utilizado para alteração de senha
export const sucessoCadastraNovaSenha = createAction(
  '[User] Sucesso validação',
  props<{ status:number}>()
)

// Serviço utilizado para alteração de senha
export const erroCadastraNovaSenha = createAction(
  '[User] Falha na validação cpf',
  props<{ error:any}>()
)

export class PodeAcordo {
  qntComFaturasAbertas: number;
  valorTotalComFaturasAbertas: number;
  qntSemFaturasAbertas: number;
  valorTotalSemFaturasAbertas: number;

  constructor() {
    this.qntComFaturasAbertas = 0;
    this.valorTotalComFaturasAbertas = 0;
    this.qntSemFaturasAbertas = 0;
    this.valorTotalSemFaturasAbertas = 0;
  }
}

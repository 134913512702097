import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
export interface styles {
  [key: string]: string;
}
@Component({
  selector: 'app-lista-questionarios',
  templateUrl: './lista-questionarios.component.html',
  styleUrls: ['./lista-questionarios.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListaQuestionariosComponent implements OnInit {
  @Input() descricaoPergunta: string = '';
  @Input() subTitulo?: string | null = null;
  @Input() perguntas: any = [];
  @Input() selectoption: number | null = null;

  @Output() public opcaoEmitter = new EventEmitter();
  @Output() public opcaoPerguntaEmitter = new EventEmitter();

  constructor() {}

  ngOnInit() {}
  selecionaOpcao(index: number, pergunta: string | null = null) {
    this.selectoption = index;
    this.opcaoEmitter.emit(this.selectoption);

    if(pergunta){
      let questionario = {
        opcao: this.selectoption,
        pergunta: pergunta
      }
      this.opcaoPerguntaEmitter.emit(questionario)
    }
  }
}

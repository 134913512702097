import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DadosTarifasModel } from 'src/app/services/tarifas.service';

@Component({
  selector: 'app-tabela-tarifas',
  templateUrl: './tabela-tarifas.component.html',
  styleUrls: ['./tabela-tarifas.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabelaTarifasComponent implements OnInit {
  @Input() dadosTarifa: DadosTarifasModel[];
  isMobile: boolean;

  constructor() {
  }

  ngOnInit(): void {
    if (window.innerWidth <= 768) this.isMobile = true;
    else this.isMobile = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    const w = event.target as Window;
    const width = w.innerWidth;
    if (width <= 768) this.isMobile = true;
    else this.isMobile = false;
  }
}



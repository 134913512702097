import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/site-cliente/site-cliente.module').then(
        (m) => m.SiteClienteModule
      ),
    data: { title: 'Home' },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/site-admin/admin.module').then((m) => m.AdminModule),
    data: { title: 'Home' },
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { preloadingStrategy: NoPreloading }), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'agv-icon',
  template: `<span class="agv-icon" [innerHTML]="svgIcon"></span>`,
  styleUrls: ['./agv-icon.component.scss'],
})
export class AgvIconComponent implements OnChanges {
  @Input() public name?: string;
  public svgIcon: any;
  error: any;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(): void {
    if (!this.name || this.name === '') {
      this.svgIcon = '';
      throw Error('Necessário informar o nome so ícone que deseja encontrar');
    }
    this.httpClient
      .get(`assets/imgs/svg/${this.name}.svg`, { responseType: 'text' })
      .subscribe(
        (value) => {
          this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
        },
        (error) => {
          throw Error(
            `Não foi possível encontrar a imagem ${this.name} no diretório padrão: ${error}`
          );
        }
      );
  }
}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { DadosRepresentante } from 'src/app/core/models/empresas/empresa';
import { selectEmpresaCNPJ } from 'src/app/core/store/empresa-cnpj/empresa-cnpj.selector';
import { IRespnseAvatar } from 'src/app/modules/site-cliente/site-cliente.component';
import { EmpresaCNPJService } from 'src/app/services/empresa-cnpj.service';

@Component({
  selector: 'agv-perfil-cnpj',
  templateUrl: './perfil-cnpj.component.html',
  styleUrls: ['./perfil-cnpj.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PerfilCnpjComponent implements OnInit {
  @Input() dadosRepresentante?: DadosRepresentante | null = null;

  public avatarPadrao: string =
    '../../../../assets/imgs/svg/account_circle.svg';
  public avatar?: string;
  constructor() {}

  ngOnInit() {
 
  }
}

import { PesquisaSatisfacaoService } from 'src/app/services/pesquisa-satisfacao.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { DialogErrorComponent } from '../dialog-error/dialog-error.component';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dialog-pesquisa-sastifacao',
  templateUrl: './dialog-pesquisa-sastifacao.component.html',
  styleUrls: ['./dialog-pesquisa-sastifacao.component.scss'],
})
export class DialogPesquisaSastifacaoComponent {
  maxLength = 512;
  showAvalicao: boolean = false;
  exibirTelaDeSucesso: boolean = false;
  loadingRequest: boolean = false;

  numeros = [
    { valor: 1, selecionado: false },
    { valor: 2, selecionado: false },
    { valor: 3, selecionado: false },
    { valor: 4, selecionado: false },
    { valor: 5, selecionado: false },
  ];
  numSelecionado: number = 0;
  observacoes: string;

  constructor(
    private pesquisaService: PesquisaSatisfacaoService,
    private tokenService: TokenStorageService,
    private readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogPesquisaSastifacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public protocolo: any
  ) {}

  get charactersRemaining(): number {
    const textControl = this.observacoes;
    if (textControl)
      return this.maxLength - (textControl ? textControl.length : 0);
    else {
      return 0;
    }
  }

  mostraInputAvaliacao(index: number) {
    this.showAvalicao = false;
    if (index + 1 <= 3) {
      this.showAvalicao = true;
    }
  }

  enviarForm() {
    this.loadingRequest = true;
    this.pesquisaService
      .postPesquisa({
        protocolo:
          this.protocolo?.toString() || this.tokenService.getProtocolo(),
        nota: this.numSelecionado,
        obs: this.observacoes ?? '',
      })
      .subscribe({
        next: (data) => {
          this.exibirTelaDeSucesso = true;
          this.loadingRequest = false;
          this.tokenService.removeProtocolo();

          setTimeout(() => {
            this.dialogRef.close();
          }, 5000);
        },
        error: (error) => {
          this.loadingRequest = false;
          this.openDialogError(error?.error?.description);
          this.dialogRef.close();
        },
      });
  }

  openDialogError(errorMessage: string) {
    this.dialog.open(DialogErrorComponent, {
      data: {
        img: 'icon_atencao',
        title: 'Ops! Algo deu errado',
        content: errorMessage,
      },
      panelClass: 'custom-dialog-error',
    });
  }

  selecionarNumero(index: number): void {
    this.mostraInputAvaliacao(index);
    this.numeros.forEach((numero, i) => {
      numero.selecionado = i <= index;
    });
    this.numSelecionado = index + 1;
  }
}

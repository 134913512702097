import {TabService} from './tab.service';
import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

export interface ITab {
  label: string,
  component: any
}

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {

  @Input() tabs: ITab[] = [];
  @Input() index: number = 0;

  constructor(
    private tabService: TabService
  ) {}

  ngOnInit(): void {}

  indexChange(index: number) {
    this.tabService.changeTab(index)
  }
}

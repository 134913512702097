import { createReducer, on } from '@ngrx/store';
import { showTabChange } from './negocios.actions';

export interface INegociosState {
  showTabs: boolean;
}

export const negociosKey = 'Negocios';

export const negociosInitialState: INegociosState = {
  showTabs: true,
};

export const NegociosReducer = createReducer(
  negociosInitialState,
  on(showTabChange, (state, action) => {
    return Object.assign({}, state, { showTabs: !state.showTabs });
  })
);

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Validator } from '../../utils/validator';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-nova-agv-aviso',
  templateUrl: './dialog-nova-agv-aviso-cnpj.component.html',
  styleUrls: ['./dialog-nova-agv-aviso-cnpj.component.scss'],
})
export class DialogNovaAgvAvisoCNPJComponent implements OnInit {
  public UrlAgv = environment.urlAGV;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogNovaAgvAvisoCNPJComponent>,
    private serviceToken: TokenStorageService
  ) { }

  ngOnInit(): void { }

  novaPagina() {
    this.router.navigate(['representante-legal/identificacao']);
    this.dialogRef.close();
  }

  popUpNovo(e: any) {
    if (Validator.validaEventKeyPressClick(e)) {
      this.novaPagina();
    }
  }

  navegarFecharPoUp() {
    this.router.navigate(['fatura-completa']);
    this.dialogRef.close();
  }
}

<div id="forn-fat" role="group" class="container">
  <app-mensagem *ngIf="msgError" [mensagem]="msgError"></app-mensagem>
  <label id="forn-fat-titulo-mobile" class="container_input-mobile" fxHide.gt-sm fxShow
    *ngIf="!submitFeito && (infoDescricao === null || infoDescricao === undefined || infoDescricao?.titulo === 'undefined')">{{label}}</label>
  <div id="campo-busca-input" role="group" class="container_input" [ngClass]="{'ContainerComErro': msgError}">
    <label id="label-busca-input" for="codigoinput" fxHide fxShow.gt-sm>{{label}}</label>
    <input id="codigoinput" name="codigoinput" type="text" placeholder="Digite..." maxlength="20" title="Digite ..."
      #campoinput [disabled]="false" [(ngModel)]="valueInput" [ngClass]="{'codigoinputDesabilitado' : true}"
      (keyup)="mascaraCPF()" />
    <agv-button *ngIf="!submitFeito" [id]="'pesquisarinput'" [type]="'1'" class="edit-button" (sendAction)="click()"
      [text]="labelBtn" [habilita]="qtdCaracterInput === valueInput.length" [loading]="false">
    </agv-button>

    <span *ngIf="submitFeito" role="button" tabindex="0" aria-label="Limpar input" (click)="limpar()">
      <mat-icon class="input_icn" [title]="'Limpar input'">highlight_off</mat-icon>
    </span>

  </div>
  <div id="dadosinput" role="group" #titularEnderecoFormatado class="container_info-input" tabindex="0"
    *ngIf="submitFeito && infoDescricao !== null && infoDescricao !== undefined && infoDescricao?.titulo !== null && infoDescricao?.titulo !== undefined && infoDescricao?.titulo !== 'undefined'">
    <p id="titular-input" class="info-input_titular">{{infoDescricao.titulo}}</p>
    <p id="endereco-input">{{infoDescricao.descricao}}</p>
  </div>
</div>
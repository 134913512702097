import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CamposCartaoValidacao, CartaoEncriptar } from 'src/app/core/models/pagamentoCartao';
import { SecurityCodeWarningComponent } from 'src/app/shared/components/cartao-dados-pagamento/security-code-warning/security-code-warning.component';
import { Regex } from 'src/app/shared/utils/regex';
import { encriptaCartao } from '../../../../../../assets/pagSeguro/pagSeguro'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-input-dadosCartao',
  templateUrl: './input-dadosCartao.component.html',
  styleUrls: ['./input-dadosCartao.component.scss']
})

export class InputDadosCartaoComponent implements OnInit {
  @Output() enviaDadosCartao: EventEmitter<any> = new EventEmitter();
  @Output() enviaTokenCartao: EventEmitter<string> = new EventEmitter();
  metodoPagEscolhido: string;
  dadosCartao: FormGroup;
  tokenCartao: string;
  
  msgErro: CamposCartaoValidacao = new CamposCartaoValidacao();
  cartaoEmitido: CartaoEncriptar = new CartaoEncriptar();

  constructor(private dialog: MatDialog) {
    this.dadosCartao = new FormBuilder().group({
      nomeTitular: ['', Validators.required],
      numCartao: ['', Validators.required],
      validade: ['', Validators.required],
      codSeguranca: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  encripPagSeguro() {
    const card = encriptaCartao(environment.publicKeyCard, this.cartaoEmitido);
    this.tokenCartao = card.encryptedCard;
  }

  getSelectValue(e: string): void {
    this.dadosCartao.controls['tipoCartao'].setValue(e);
  }

  verificaSelecVazio(e: any) {
    const isCampoSelectVazio = !e;
    this.msgErro['tipoCartao'] = isCampoSelectVazio ? 'Este dado precisa ser preenchido para prosseguir.' : '';
  }

  validaCampos(input: string) {
    if (this.dadosCartao.controls[input].errors) {
      this.msgErro[input] = 'Este dado precisa ser preenchido para prosseguir.'
    } else {
      switch (input) {
        case 'nomeTitular':
          this.msgErro[input] = this.dadosCartao.controls[input].value.length < 5 ? 'Quantidade de caracteres digitados é inválida.' : '';
          break;
        case 'numCartao':
          if (this.dadosCartao.controls[input].value.length < 16) {
            this.msgErro[input] = 'Quantidade de caracteres digitados é inválida.';
            return
          } else if (!this.cartaoEmitido.brand) {
            this.msgErro[input] = 'Número de cartão inválido e/ou bandeira não aceita.';
            return
          } else {
            this.msgErro[input] = '';
          }
          break;
        case 'codSeguranca':
          this.msgErro[input] = this.dadosCartao.controls[input].value.length < 3 ? 'Quantidade de caracteres digitados é inválida.' : '';
          break;
        case 'docTitular':
          this.msgErro[input] = Regex.removeMaskCpf(this.dadosCartao.controls[input].value).length < 11 ? 'Digite um numero de CPF válido.' : '';
          break;
        case 'validade':
          this.msgErro[input] = this.validaVencimento(this.dadosCartao.controls[input].value) ? '' : 'Data de validade inválida.';
          break;
        default:
          break;
      }
    }
    this.emitInfoCartao();
  }

  validaVencimento(data: string): boolean {
    const anoVencimento = parseInt(data.substring(3), 10);
    const mesVencimento = parseInt(data.substring(0, 2), 10);
    const anoAtual = new Date().getFullYear();
    const mesAtual = new Date().getMonth();
    if (anoAtual - anoVencimento >= 0) {
      if (mesVencimento >= 1 && mesVencimento <= 12) {
        const isDataValida = mesAtual - mesVencimento <= 0;
        return isDataValida;
      } else return false;
    }
    return true;
  }

  maskVencimento() {
    const vencimentoFormatado = this.dadosCartao.controls['validade'].value.replace(/(\d{2})(\d{3})/, "$1/$2");
    this.dadosCartao.controls['validade'].setValue(vencimentoFormatado);
  }

  maskCpf() {
    this.dadosCartao.controls['docTitular'].setValue(Regex.maskCpf(this.dadosCartao.controls['docTitular'].value));
  }

  maskNumeroCartao() {
    const removerLetras = this.dadosCartao.controls['numCartao'].value.replace(/\D/g, '');
    const separandoBlocos = removerLetras.match(/.{1,4}/g);
    const numeroCartaoFormat = separandoBlocos ? separandoBlocos.join(' ') : '';
    this.dadosCartao.controls['numCartao'].setValue(numeroCartaoFormat);
    this.identificarBandeira();
  }

  emitInfoCartao() {
    this.separaDadosCartao();
    this.encripPagSeguro();
    if (this.dadosCartao.valid && this.tokenCartao && !(this.msgErro.codSeguranca && this.msgErro.docTitular && this.msgErro.nomeTitular && this.msgErro.numCartao && this.msgErro.validade)) {
      this.enviaTokenCartao.emit(this.tokenCartao);
      this.enviaDadosCartao.emit(this.cartaoEmitido);
    }
  }

  separaDadosCartao() {
    const indexValidade = this.dadosCartao.controls['validade'].value.indexOf('/');
    this.cartaoEmitido.exp_month = this.dadosCartao.controls['validade'].value.substring(0, indexValidade);
    this.cartaoEmitido.exp_year = this.dadosCartao.controls['validade'].value.substring(indexValidade + 1, this.dadosCartao.controls['validade'].value.length);
    this.cartaoEmitido.first_digits = this.dadosCartao.controls['numCartao'].value.substring(0, 4);
    if(this.dadosCartao.controls['numCartao'].value.length > 17){
      this.cartaoEmitido.last_digits = this.dadosCartao.controls['numCartao'].value.substring(15, this.dadosCartao.controls['numCartao'].value.length);
    } else{
      this.cartaoEmitido.last_digits = this.dadosCartao.controls['numCartao'].value.substring(12, this.dadosCartao.controls['numCartao'].value.length).replace(' ', '');
    }
    this.setValoresCorpoCartao();
  }

  setValoresCorpoCartao() {
    this.cartaoEmitido.holder = this.dadosCartao.controls['nomeTitular'].value;
    this.cartaoEmitido.number = this.dadosCartao.controls['numCartao'].value;
    this.cartaoEmitido.securityCode = this.dadosCartao.controls['codSeguranca'].value;
  }

  retiraNumeroCampoNome() {
    const campoNomeTitular = this.dadosCartao.controls['nomeTitular'];
    let regexp = /[^a-zA-Z ]/g;
    campoNomeTitular.value.match(regexp) ? campoNomeTitular.setValue(campoNomeTitular.value.replace(regexp, '')) : ' ';
  }

  identificarBandeira(): void {
    this.cartaoEmitido.brand = '';
    const numeroCartao = this.dadosCartao.controls['numCartao'].value.replace(/\D/g, '');
    const bandeiraCartao = Regex.bandeiraCartao(numeroCartao);
    if (bandeiraCartao) {
      this.cartaoEmitido.brand = bandeiraCartao;
    }
  }

  openDialogCVV() {
    this.dialog.open(SecurityCodeWarningComponent, {
      width: '90%',
      maxWidth: '475px',
      panelClass: 'modal-aviso',
    });
  }
}

import { HttpClient } from "@angular/common/http";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { IFaturaEmailState } from "./fatura-email.reducer";
import { alteraFaturaEmail, erroAlteraFaturaEmail, sucessoAlteraFaturaEmail } from "./fatura-email.actions";
import { catchError, map, mergeMap, of } from "rxjs";
import { ResponseProcotolo } from "../../models/service/faturaEmail";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})

export class FaturaEmailEffectService {
    private readonly BASE_URL = `${environment.uri}`;

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store<{ alteraEnvio: IFaturaEmailState}>
    ) { }

    alterarEnvio = createEffect(() =>
        this.actions$.pipe(
            ofType(alteraFaturaEmail),
            mergeMap((action) =>{                
                return this.http
                    .post<ResponseProcotolo>(
                        `${this.BASE_URL}v1/completa/fatura/fornecimento/cliente/envio/email`,
                        action.req
                    )
                    .pipe(
                        map((response) =>
                            sucessoAlteraFaturaEmail({ protocolo: response})
                        ),
                        catchError(error => {
                            return of(erroAlteraFaturaEmail({ error: error }))
                        })
                    )
                })
        )
    );
}
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, map, withLatestFrom, filter } from 'rxjs/operators';
import { NoticiaService } from 'src/app/services/noticia.service';
import { carregaNoticias, setNoticias } from './noticias.actions';

@Injectable()
export class NoticiaEffects {
  carregarNoticias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(carregaNoticias),
    // Verifica se a lista de notícias está vazia
      mergeMap(() =>
        this.noticiasService.getNoticias().pipe(
          map((noticias) => setNoticias({ noticias }))
        )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private noticiasService: NoticiaService,
    private store: Store
  ) {}
}

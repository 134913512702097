import { ClienteResumido } from "../clienteSimplificado";

export class BuscaEmail {
  email: string;
  nome: string;
  token: string;

  constructor() {
    this.email = '';
    this.nome = '';
    this.token = '';
  }
}

export class ReqCheckToken {
  cpf: string | null;
  token: string;

  constructor() {
    this.cpf = '';
    this.token = '';
  }
}

export class DadosReenvioToken {
  email: string;
  cpf: string;

  constructor() {
    this.email = '';
    this.cpf = '';
  }
}

export class CheckToken {
  token: string;
  expires: string;
  message: string;
  cpf: string;

  constructor() {
    this.token = '';
    this.expires = '';
    this.message = '';
    this.cpf = '';
  }
}

export class ReqCadastraSenha {
  senha: string;
  confirma: string;
  cpf: string | null;

  constructor(senha?: string, confirma?: string, cpf?: string) {
    this.senha = senha ?? '';
    this.confirma = confirma ?? '';
    this.cpf = cpf ?? '';
  }
}

export class ReqAlterarSenha {
  senhaAtual: string;
  senha: string;
  confirma: string

  constructor(senhaAtual?: string, senha?: string, confirma?: string) {
    this.senhaAtual = senhaAtual ?? '';
    this.senha = senha ?? '';
    this.confirma = confirma ?? '';
  }
}

export class Cliente extends ClienteResumido {
  email: string;
  cpf: string;
  cnpj: string;
  celular: string;
  dataNascimento: string;

  constructor() {
    super();
    this.email = '';
    this.cpf = '';
    this.cnpj = '';
    this.celular = '';
    this.dataNascimento = '';
  }
}

export class ClienteCompleto {
  codigo: string;
  nome: string;
  sobrenome: string;
  nomeCorrecao: string;
  email: string;
  cpf: string;
  dataNascimento: string;
  dataNascimentoCorrecao: string;
  celular: string;
  senha: string;
  senhaConfirma: string;
  flagAceiteTermo: string;
  tipoPessoa: string;

  constructor() {
    this.codigo = '';
    this.nome = '';
    this.sobrenome = '';
    this.nomeCorrecao = '';
    this.email = '';
    this.cpf = '';
    this.dataNascimento = '';
    this.dataNascimentoCorrecao = '';
    this.celular = '';
    this.senha = '';
    this.senhaConfirma = '';
    this.flagAceiteTermo = '';
  }
}

export class ResponseProcotolo {
  protocolo: string;

  constructor() {
    this.protocolo = '';
  }
}

export class FornecimentoCadastro {
  codigo: string;
  status: string;
  produto: string;
  codigoTipoFornecimento: string;
  tipoFornecimento: string;
  tipoMercado: string;
  categoriaUso: string;
  economias: string;
  enderecoEmailEnvioFatura: string;
  codigoPde: string;
  tipoLigacao: string;
  tipoPde: string;
  tipoEntidade: string;
  proximoVencimento: string;
  descTipoEntidade: string;
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  endereco: string;
  numero: string;
  uf: string;

  constructor() {
    this.codigo = '';
    this.status = '';
    this.produto = '';
    this.codigoTipoFornecimento = '';
    this.tipoFornecimento = '';
    this.tipoMercado = '';
    this.categoriaUso = '';
    this.economias = '';
    this.enderecoEmailEnvioFatura = '';
    this.codigoPde = '';
    this.tipoLigacao = '';
    this.tipoPde = '';
    this.tipoEntidade = '';
    this.proximoVencimento = '';
    this.descTipoEntidade = '';
    this.bairro = '';
    this.cep = '';
    this.cidade = '';
    this.complemento = '';
    this.endereco = '';
    this.numero = '';
    this.uf = '';
  }
}

export interface InfoArquivo {
  dataEmissao: string,
  dataNascimento: string;
  frente: boolean;
  nome: string;
  numeroDocumento: string;
  cpf: string;
  status: string;
  tipo: string;
  verso: boolean
}

export class DadosCadastroCliente {
  cliente: Cliente;
  fornecimentos: FornecimentoCadastro[];

  constructor() {
    this.cliente = new Cliente();
    this.fornecimentos = [new FornecimentoCadastro()]
  }
}

export interface DadosComponenteOCR {
  nomeCompleto: string;
  documento: string;
  dataNascimento: string;
  celular: string;
  email: string;
}
export interface DadosDocumentoOCR {
  cpf: string;
  dataEmissao: string;
  dataNascimento: string;
  frente: boolean;
  verso: boolean;
  nome: string;
  numeroDocumento: string;
  status: string;
  tipo: string;
}

export interface erroChamadaOcr {
  erro: boolean;
  codigo: string;
  lado: string;
}

export interface ComprovanteRepLegal {
  fileBase64: string,
  nomeArquivo: string,
  tipo: string
}

export interface DadosCnpj {
  razaoSocial: string,
  endereco: string
}

export class Representante {
  codigo: string = '';
  nome: string = '';

  constructor() {}

  setDadosRepresentante(codigo: string, nome: string){
    this.codigo = codigo;
    this.nome = nome;
  }
}

export class Cnpj {
  default: boolean = false;
  favorito: boolean = false;
  cnpj: string = '';
  razaoSocial: string = '';
  nomeFantasia: string = '';
  dataIni: string = '';
  dataFim: string = '';
  fornecimentos: FornecimentoCnpj[] = [];

  constructor() {}

  setDadosCnpj(defaultValue: boolean, favorito: boolean, cnpj: string, razaoSocial: string, nomeFantasia: string, dataIni: string,
    dataFim: string, fornecimentos: FornecimentoCnpj[]) {
    this.default = defaultValue;
    this.favorito = favorito;
    this.cnpj = cnpj;
    this.razaoSocial = razaoSocial;
    this.nomeFantasia = nomeFantasia;
    this.dataIni = dataIni;
    this.dataFim = dataFim;
    fornecimentos.map(x => this.fornecimentos.push(x))
  }
}

export class FornecimentoCnpj {
  favorito: boolean = false;
  codigo: string = '';
  apelido: string = '';
  status: string = '';
  produto: string = '';
  codigoTipoFornecimento: string = '';
  tipoFornecimento: string = '';
  tipoMercado: string = '';
  categoriaUso: string = '';
  economias: string = '';
  enderecoFornecimentoCompleto: string = '';
  codigoPde: string = '';
  tipoLigacao: string = '';
  tipoPde: string = '';
  tipoEntidade: string = '';
  descTipoEntidade: string = '';

  constructor() { }

  setDadosFornecimento(favorito: boolean, codigo: string, apelido: string, status: string, produto: string, codigoTipoFornecimento: string,
    tipoFornecimento: string, tipoMercado: string, categoriaUso: string, economias: string, enderecoFornecimentoCompleto: string,
    codigoPde: string, tipoLigacao: string, tipoPde: string, tipoEntidade: string, descTipoEntidade: string) {
    this.favorito = favorito;
    this.codigo = codigo;
    this.apelido = apelido;
    this.status = status;
    this.produto = produto;
    this.codigoTipoFornecimento = codigoTipoFornecimento;
    this.tipoFornecimento = tipoFornecimento;
    this.tipoMercado = tipoMercado;
    this.categoriaUso = categoriaUso;
    this.economias = economias;
    this.enderecoFornecimentoCompleto = enderecoFornecimentoCompleto;
    this.codigoPde = codigoPde;
    this.tipoLigacao = tipoLigacao;
    this.tipoPde = tipoPde;
    this.tipoEntidade = tipoEntidade;
    this.descTipoEntidade = descTipoEntidade;
  }
}

export class DadosRepresentante {
  representante: Representante = new Representante();
  fornecimento: Cnpj = new Cnpj();

  constructor(representante: Representante, fornecimento: Cnpj) {
    this.representante = representante;
    this.fornecimento = fornecimento;
  }
}
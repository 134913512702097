<app-mensagem [mensagem]="mensagem"></app-mensagem>
<div role="group" class="identificacao-cliente_cpf">
    <div role="group" class="input-padrao" appearance="fill"
      [class.identificacao-cliente_cpf-invalido]="!cpfValido || mensagem" [ngClass]="{'disabled': disabledCpf && (mensagem === undefined || mensagem === '')}">
      <div class="input-padrao_box">
          <label for="campo-cpf">CPF *</label>
          <input  id="campo-cpf" maxlength="14" type="text" [(ngModel)]="cpf" (keyup)="mascaraCPF()" placeholder="Digite seu CPF">
      </div>
      <agv-icon [name]="'icn-error-x'" title="Ícone de limpeza de campo cpf" class="cursor-pointer" *ngIf="cpf.length > 10 && !buscaCpf" (click)="resetInput()" (keypress)="resetInput()" tabindex="0"></agv-icon>
      <button id="loadingPesquisa" type="button" class="loadingPesquisa" *ngIf="buscaCpf">
        <agv-icon class="loadingPesquisa__img" [name]="'spinner'" title="Ícone de carregamento"></agv-icon>
      </button>
    </div>
 <div [ngClass]="{'recaptcha-dark': currentTheme === 'theme-dark'}" #divRecaptcha id="recaptcha"></div>
     <div *ngIf="showErrorTransf" class="content-error-transf">
      <p>
        Esta solicitação não pode ser realizada através da Agência Virtual pois existem faturas vencidas em outro fornecimento sobre sua responsabilidade. Para solicitar este serviço pela internet, providencie o pagamento dessas faturas através do item Minhas Faturas. Você também pode solicitar a alteração em uma de nossas Agências de Atendimento.
      </p>
    </div>
    <button *ngIf="showBtn" mat-raised-button [disabled]="cpf.length < 14 || !recaptchaSelecionado" (click)="validaCPF() ">Continuar</button>
</div>

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'agv-aviso-texto',
  templateUrl: './aviso-texto.component.html',
  styleUrls: ['./aviso-texto.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AvisoTextoComponent implements OnInit {
  @Input() titulo: string = '';
  @Input() texto: string = '';
  @Input() icon: string = '';

  constructor() {}


  ngOnInit() {}
}

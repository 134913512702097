import { createReducer, on } from '@ngrx/store';
import {
  atualizaCNPJRepresentante,
  carregaEmpresaCNPJS,
  carregaFornecimentoCNPJ,
  deletaEmpresaCNPJ,
  deletaFornecimentoCNPJ,
  erroCarregaEmpresaCNPJ,
  erroCarregaFornecimentoCNPJ,
  sucessoCarregaEmpresaCNPJ,
  sucessoCarregaFornecimentoCNPJ,
  sucessoCarregarCNPJRepresentante,
} from './empresa-cnpj.actions';
import {
  CNPJRepresentante,
  DadosRepresentante,
  EmpresaCNPJS,
} from '../../models/empresas/empresa';
import { Fornecimento } from '../../models/fornecimento';

export interface EmpresaCNPJState {
  empresaCnpj: EmpresaCNPJS | null;
  cnpjFornecimentos: CNPJRepresentante | null;
  representante: DadosRepresentante | null;
  fornecimento: Fornecimento | null;
  error: string | null; // Adicionando propriedade 'error' para controlar erros
}

export const EmpresaCNPJKey = 'EmpresaCNPJ';

export const initialState: EmpresaCNPJState = {
  empresaCnpj: null,
  representante: null,
  cnpjFornecimentos: null,
  fornecimento: null,
  error: null,
};

export const EmpresaCNPJReducer = createReducer(
  initialState,

  //CNPJS
  on(carregaEmpresaCNPJS, (state) => state), // Ação de carregamento não faz alterações no estado
  on(sucessoCarregaEmpresaCNPJ, (state, { empresaCNPJ }) => ({
    ...state,
    empresaCnpj: empresaCNPJ,
    error: null,
  })),
  on(erroCarregaEmpresaCNPJ, (state) => ({
    ...state,
    error: state.error ?? null,
  })),
  on(deletaEmpresaCNPJ, (state) => ({
    ...state,
    empresaCnpj: null,
    error: null,
  })),
  //fornecimentos

  on(atualizaCNPJRepresentante, (state, { representante }) => ({
    ...state,
    representante: representante ?? null,
  })),

  on(sucessoCarregarCNPJRepresentante, (state, { representante }) => ({
    ...state,
    representante: representante ?? null,
  })),

  on(carregaFornecimentoCNPJ, (state) => state),

  on(sucessoCarregaFornecimentoCNPJ, (state, { fornecimento }) => ({
    ...state,
    fornecimento: fornecimento,
    error: null,
  })),

  on(erroCarregaFornecimentoCNPJ, (state) => ({
    ...state,
    error: state.error ?? null,
  })),
  
  on(deletaFornecimentoCNPJ, (state) => ({
    ...state,
    fornecimento: null,
    error: null,
  }))
);

import { HttpClient } from '@angular/common/http';
import {
  PagamentoFaturaItens,
  DetalhesFatura,
  OutrosDetalhes,
  DetalhesPagto,
} from '../../../core/models/pagamentoFaturaItens';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PagamentoFaturaService {
  readonly apiURL: string;

  constructor(private http: HttpClient) {
    this.apiURL = 'http://localhost:3000';
  }

  public getInfoPagamentoFaturaItens(): PagamentoFaturaItens {
    const estruturaDetalhes: DetalhesFatura = {
      venc: '05/10/2021',
      total: '60,53',
      barcode: '82630000000 5 62040097150 8 02007302771 4 72910521082 1',
      titleImgBarcode: 'barcode',
      altImgBarcode: 'Imagem do código de barras',
      titleIcnCopiar: 'icn_copiar',
      altIcnCopiar: 'Íconde de copiar',
      titleIcnBaixar: 'Íconde de copiar código de barras',
      altIcnBaixar: 'Ícone de baixar',
      linkBaixar: '',
    };

    const estruturaOutrosDetalhes: OutrosDetalhes = {
      titleIcnPagarApp: 'icn_barcode_app',
      altIcnPagarApp: 'Ícone de scanear barcode',
      linkTermo: '',
    };

    const estruturaDetalhesPagto: DetalhesPagto = {
      codigoFatura: '020211500103497711',
      dataEmissao: '05/09/2021',
      valor: '60,53',
      dataVenc: '05/10/2021',
      status: 'Em atraso',
    };

    const pagamentoFatura = new PagamentoFaturaItens(
      estruturaDetalhes,
      estruturaOutrosDetalhes,
      estruturaDetalhesPagto
    );

    return pagamentoFatura;
  }

  cadastrarEmail(email: string, confirmaEmail: string, aceite: boolean){
    try {
      if (email != '' && email == confirmaEmail) {
        var json = {
          idDoUsuario: '1',
          emailDoUsuario: email,
          aceitaTermo: aceite,
        };

        this.http.post(`${this.apiURL}/email`, json).subscribe(
          (resultado) => {},
          (erro) => {
            if (erro.status == 400) {
            }
            throw new Error('Erro de conexão!');
          }
        );
      } else {
        throw new Error('Confira o e-mail!');
      }
    } catch (e) {
      console.error(e);
    }
  }

  comunicarPagto(codigoFatura: string, local: string, data: string) {
    try {
      if (local != '' && data != '') {
        var json = { idDaFatura: codigoFatura, local: local, data: data };

        this.http.post(`${this.apiURL}/comunicar`, json).subscribe(
          (resultado) => {},
          (erro) => {
            if (erro.status == 400) {
            }
            throw new Error('Erro de conexão!');
          }
        );
      } else {
        throw new Error('Preencha local e data!');
      }
    } catch (e) {
      console.error(e);
    }
  }
}

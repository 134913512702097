export class LeituraFatura {
    descricao: string;
    data: Date;
    valor: number;

    constructor() {
        this.descricao = '';
        this.data = new Date();
        this.valor = 0;
    }
}
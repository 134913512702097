<mat-nav-list id="menu-lateral-mobile" class="nav-list__wrapper">
  <div
    class="nav-list__menu-user"
    [ngStyle]="{ padding: collapse ? '12px 12px 24px 12px' : '12px' }"
  >
    <a
      *ngIf="!collapse"
      class="menu-user__wrapper"
      (click)="abrirUsuario()"
      (keypress)="abrirUsuario()"
      tabindex="0"
    >
      <div class="menu-user__icons">
        <agv-icon *ngIf="!hideAgvIcon" [name]="userIcon" title="user"></agv-icon>
        <img alt="user" src="{{userIcon}}" *ngIf="hideAgvIcon" />
        <span class="nome-usuario">
          {{
            isLogged ? capitalize(cliente?.nome + " " + cliente?.sobrenome) : "Entrar"
          }}</span
        >
      </div>
      <div class="menu-user__expand">
        <agv-icon [name]="'arrow_drop_down'"></agv-icon>
      </div>
    </a>
    <ng-template [ngIf]="collapse">
      <div
        class="menu-user__wrapper"
        tabindex="0"
        (keypress)="abrirUsuario()"
        (click)="abrirUsuario()"
      >
        <div class="menu-user__icons">
          <a
            (click)="$event.stopPropagation(); fileInput.click()"
            (keypress)="$event.stopPropagation(); fileInput.click()"
            tabindex="0"
          >
          <agv-icon *ngIf="!hideAgvIcon" [name]="userIcon" title="user"></agv-icon>
          <img alt="user" src="{{userIcon}}" *ngIf="hideAgvIcon" />
          <agv-icon class="edita-foto" [name]="editUserIcon" title="edit"></agv-icon>
            <input
              #fileInput
              accept="image/*"
              type="file"
              (change)="enviaFoto($event)"
              style="display: none"
            />
          </a>
          <span class="nome-usuario"
            >{{ cliente?.nome }} {{ cliente?.sobrenome }}</span
          >
        </div>
        <div class="menu-user__expand">
          <agv-icon [ngClass]="{ 'rotate-180': true }" [name]="'arrow_drop_down'"></agv-icon>
        </div>
      </div>
      <div class="menu-user__lista-items" *ngIf="isLogged">
        <ul>
          <li>
            <a
              tabindex="0"
              (click)="redirecMinhaConta($event)"
              (keypress)="redirecMinhaConta($event)"
            >
            - Minha conta</a
            >
          </li>
          <li>
            <a
              tabindex="0"
              (click)="redirectAlteraSenha($event)"
              (keypress)="redirectAlteraSenha($event)"
            >
              - Alterar senha</a
            >
          </li>
          <li>
            <span (click)="redirectMinhasSolicitacoes()">
              - Minhas solicitações
            </span>
          </li>
        </ul>
      </div>
    </ng-template>
  </div>
  <div class="nav-list__opcoes">
    <a
      (click)="irFaturaCompleta($event)"
      (keypress)="irFaturaCompleta($event)"
      class="items"
      tabindex="0"
    >
      <span>Para Você</span>
    </a>
    <mat-accordion>
      <mat-expansion-panel
        class="mat-elevation-z0"
        hideToggle
        (expandedChange)="expanded = !expanded"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Para Negócios </mat-panel-title>
          <mat-panel-description>
            <agv-icon [ngClass]="{ 'rotate-180': expanded }" [name]="'arrow_drop_down'"></agv-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="lista-items">
          <ul>
            <!-- <li>
              <a
                (click)="goToNegocios($event)"
                (keypress)="goToNegocios($event)"
                tabindex="0"
              >
                - Representante Legal</a
              >
            </li> -->
            <li>
              <a
                (click)="goToNegocios($event)"
                (keypress)="goToNegocios($event)"
                tabindex="0"
              >
                - Empresa</a
              >
            </li>
            <!-- <li>
              <a
                (click)="goToNegocios($event)"
                (keypress)="goToNegocios($event)"
                tabindex="0"
              >
                - Imobiliária</a
              >
            </li> -->
            <!-- <li>
              <a
                (click)="goToNegocios($event)"
                (keypress)="goToNegocios($event)"
                tabindex="0"
              >
                - Gestora de condomínio</a
              >
            </li> -->
          </ul>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <a
      (click)="redirecionaAviso($event)"
      class="items"
      (keypress)="redirecionaAviso($event)"
      tabindex="0"
    >
      <span>Notícias</span>
    </a>
    <a
      *ngIf="isLogged"
      (click)="signOut($event)"
      (keypress)="signOut($event)"
      class="items"
      tabindex="0"
    >
      <span>Sair</span>
    </a>
  </div>
</mat-nav-list>

<div class="gerenciador-cookies__container">
  <div class="gerenciador-cookies__header">
    <span>GERENCIADOR DE COOKIES</span>
  </div>
  <div class="gerenciador-cookies__content">
    <div class="text">
      <p>
        A Agência virtual Sabesp utiliza cookies para ajuda-lo a navegar com
        eficiência e executar algumas funções. Você encontrará informações sobre
        todos os cookies e categoria de consentimento abaixo.
      </p>
      <p>
        Os cookies que são classificados como “Necessário” são armazenados em
        seu navegador, pois são essenciais para o uso
        <span *ngIf="!mostrarTextoCompleto"
          >...
          <a *ngIf="!mostrarTextoCompleto" (click)="mostrarTextoCompleto = true"
            >mostrar mais</a
          ></span
        >
        <span *ngIf="mostrarTextoCompleto">
          de funcionalidades básicas do site.
        </span>
      </p>
      <p *ngIf="mostrarTextoCompleto">
        Também usamos cookies de terceiros que nos ajudam a analisar como você
        usa esse site, armazenar suas preferências e fornecer conteúdo e
        anúncios que sejam relevantes para você. Esses cookies somente serão
        armazenado s em seu navegador mediante seu prévio consentimento. Você
        pode optar por ativar ou desativar alguns ou todos esses cookies, mas
        desativá-los pode afetar sua experiência de navegação.
      </p>
    </div>
    <div class="itens">
      <div *ngFor="let item of itens" class="item">
        <div (click)="toggleItem(item)" class="item-header">
          <span> <mat-icon>chevron_right </mat-icon>{{ item.titulo }}</span>
          <mat-slide-toggle
            *ngIf="item.titulo !== 'Cookies Necessários'"
            (click)="$event.stopPropagation()"
            [(ngModel)]="item.autorizado"
          >
          </mat-slide-toggle>
          <span *ngIf="item.titulo === 'Cookies Necessários'" class="ativo"
            >Sempre ativo</span
          >
        </div>
        <div class="item-content" *ngIf="item.expandido">
          <p>{{ item.texto }}</p>
        </div>
      </div>
    </div>
    <div class="actions">
      <button (click)="rejeitarTodos()" mat-button>Rejeitar todos</button>
      <button (click)="salvarPreferencias()"  mat-button>Salvar preferencias</button>
      <button (click)="aceitarTodos()" mat-button>Aceitar tudo</button>
    </div>
    <div class="footer">
      <button mat-icon-button class="custom-button">
        <mat-icon mat-dialog-close class="custom-icon">close</mat-icon>
      </button>
    </div>
  </div>
</div>

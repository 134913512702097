import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "./tokenStorageServices.service";

@Injectable({
    providedIn: 'root'
})
export class CnpjService {

    private readonly BASE_URL = `${environment.uri}`;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private http: HttpClient,
        private tokenService: TokenStorageService
    ) { }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    listaFaturasPorFornecimento(codFornecimento: string): Observable<any> {
        return this.http.get(`${this.BASE_URL}v2/completa/fatura/fornecimento/${codFornecimento}/cliente/listafaturas`);
    }
}
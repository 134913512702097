import { Component, EventEmitter, OnInit, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { Validator } from '../../utils/validator';

@Component({
  selector: 'app-filtro-faturas',
  templateUrl: './filtro-faturas.component.html',
  styleUrls: ['./filtro-faturas.component.scss'],
})
export class FiltroFaturasComponent implements OnInit {
  collapsed = false;
  @Output() filtroSelecionadoEvent = new EventEmitter<string>();
  filtroSelecionado: string = 'todas';
  @Input() filtrosFatura: string[];
  tabIndexValue: number = -1;
  @ViewChild('filtros') filtrosElement: ElementRef;
  @ViewChild('situacao') situacaoElement: ElementRef;
  @ViewChild('todas') todasElement: ElementRef;
  
  selectOpened = false;
  
  ngOnInit(): void {
    this.filtroSelecionadoEvent.emit(this.filtroSelecionado);
  }

  userNamengmodelchange(value: any) {
    this.filtroSelecionado = value;
    this.filtroSelecionadoEvent.emit(value); //undefined
  }
  setTabIndex(index: number) {
    this.tabIndexValue = index;
  }
  marcarOpcao(opcao: string) {
    this.filtroSelecionado = opcao;
    this.filtroSelecionadoEvent.emit(opcao);
    this.closeSelect();
    setTimeout(() => {
      this.situacaoElement?.nativeElement?.focus();
    }, 500);
  }

  congela(deve: boolean) {
    if (deve) {
      document.documentElement.style.overflow = 'hidden';
    } else if (!deve) {
      document.documentElement.style.overflow = 'initial';
    }
  }
  selecionaFiltros(){
    this.filtrosElement?.nativeElement?.focus();
  }

  abrirSituacao(e: any) {
    if (Validator.validaEventKeyPressClick(e)) {
      this.toggleSelect();
    }
  }

  toggleSelect() {
    this.selectOpened = !this.selectOpened;
    setTimeout(() => {
      this.todasElement?.nativeElement?.focus();
    }, 500);
  }

  closeSelect() {
    this.selectOpened = false;
  }

  selecionarFiltro(e: any, opcao: string) {
    if (Validator.validaEventKeyPressClick(e)) {
      this.marcarOpcao(opcao);
    }
  }
}


import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICadastroState, cadastroKey } from './cadastro-cliente.reducer';

export const cadastroSelectors = createFeatureSelector<ICadastroState>(cadastroKey);

export const getEmail = createSelector(cadastroSelectors, (state) => {
  return state?.email;
});

export const getToken = createSelector(cadastroSelectors, (state) => {
  return state?.token;
});

export const getDadosCliente = createSelector(cadastroSelectors, (state) => {
  return state?.dados;
});

export const getDadosReenvioToken = createSelector(cadastroSelectors, (state) => {
  return state?.dadosReenvioToken;
});

export const getConfirmaDadosCliente = createSelector(cadastroSelectors, (state) => {
  return state?.protocolo;
});

export const getStatusCadastraSenha = createSelector(cadastroSelectors, () => {
  return 'Sucesso'
})

import { createReducer, on } from '@ngrx/store';
import { BuscaFaturasPorFornecimentoResumido } from '../../models/service/faturasPorFornecimento-resumido';
import {
  sucessoCarregaCNPJFornecimentos,
  sucessoCarregaForencimento,
  sucessoFaturasFornecimento,
} from './supply.actions';
import { Acordo } from '../../models/acordo';

export interface ISupplyState {
  supply?: BuscaFaturasPorFornecimentoResumido;
}

export const supplyKey = 'Supply';

export const supplyInitialState: ISupplyState = {
  supply: undefined,
};

export const SupplyReducer = createReducer(
  supplyInitialState,
  on(sucessoCarregaForencimento, (state, action) => {
    const newState = Object.assign({}, state, { supply: action.dados });
    return newState;
  }),
  on(sucessoFaturasFornecimento, (state, action) => {
    return Object.assign({}, state, { faturas: action.dados });
  }),
  on(sucessoCarregaCNPJFornecimentos, (state, action) => {
    return Object.assign({}, state, { representante: action.dados });
  })
);

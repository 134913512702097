import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SolicitacaoConcluidaModel } from 'src/app/core/models/solicitacaoConcluida';

@Component({
  selector: 'app-solicitacao-concluida',
  templateUrl: './solicitacao-concluida.component.html',
  styleUrls: ['./solicitacao-concluida.component.scss']
})

export class SolicitacaoConcluidaComponent implements OnInit {

  @Input() solicitacao: SolicitacaoConcluidaModel;
  @Input() tituloMensagem: string = 'Solicitação concluída';
  @ViewChild('solicitacaoConcluida') tituloElement: ElementRef;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      //Carrega o focus no título
      this.tituloElement?.nativeElement.focus();      
    }, 0);
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private readonly SUFIX_URLS_TOKEN = [
    'completa/fatura/fornecimento/cpf/',
    'completa/fatura/fornecimento/cliente/',
    'completa/fatura/fornecimento/cliente/',
  ];

  private readonly SUFIX_URLS_CNPJ = [
    'contratos/representante/legal/documento/ocr2',
    'contratos/representante/legal/documento/ocr',
    'contratos/representante/legal/upload/arquivos'
  ];

  constructor(private tokenService: TokenStorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken() ?? this.tokenService.getTokenPrimeiroAcesso();
    const isLoggedIn = token ? true : false;
    const isApiUrl = request.url.startsWith(environment.uri);
    const isCnpjToken = this.SUFIX_URLS_CNPJ.some((suffix) =>
      request.url.includes(suffix)
    );

    if (isLoggedIn && isApiUrl && !isCnpjToken ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    else if(isLoggedIn && isApiUrl && isCnpjToken ){
      let cnpj = localStorage.getItem('cnpj');
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'X-cnpj-representado': `${cnpj}`
        },
    })
  }

    return next.handle(request);
  }
}
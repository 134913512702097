<div class="content-aviso">
  <div class="dialog-aviso" aria-hidden="true">
    <div class="dialog-aviso__img">
      <img [src]="'assets/imgs/svg/icon_atencao.svg'" alt="Ícone de exclamação" />
    </div>
  
    <div class="dialog-aviso__title" tabindex="0">
      <span>Nova Agência Virtual Sabesp</span>
    </div>
  </div>
  
  <div tabindex="0" class="dialog-aviso__content">
    <p> A Agência Virtual Sabesp está de cara nova.
      <a tabindex="0" role="link" (click)="novaPagina()"(keypress)="popUpNovo($event)">Clique aqui</a>
      e veja o que há de novo!</p>
  </div>
  
  <div tabindex="0" class="dialog-aviso__content">
  <p>Realize a sua
    <a tabindex="0" aria-label="clique aqui para realizar sua atualização cadastral" role="link" tabindex="0"(click)="navegarFecharPoUp()">Atualização Cadastral</a> para ter acessos aos Serviços da Agência Virtual Sabesp.</p>
  </div>
  
  <div class="dialog-aviso__button">
    <button
      mat-icon-button
      mat-dialog-close
      [mat-dialog-close]="true"
      class="button_close"
      aria-label="Fechar a caixa de dialogo"
      id="fechaPop"
      (click)="setStorage()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
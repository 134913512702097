import {
  AUTO_STYLE,
  animate,
  animation,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const expand = trigger('expand', [
  state('void', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
  transition('void => true', animate(400 + 'ms ease-in')),
]);

const collapse = trigger('collapse', [
  state('void', style({ height: 0, visibility: 'hidden' })),
  transition('void => *', animate(400 + 'ms ease-in')),
]);

// -----------------------------------------------------------------------------------------------------
// @ Slide in top
// -----------------------------------------------------------------------------------------------------
const slideInTop = trigger('slideInTop', [
  state(
    'void',
    style({
      transform: 'translate3d(0, -30%, 0)',
    })
  ),

  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    })
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),

  // Transition
  transition('void => *', animate('{{timings}}'), {
    params: {
      timings: `225ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    },
  }),
]);

// -----------------------------------------------------------------------------------------------------
// @ Slide in bottom
// -----------------------------------------------------------------------------------------------------
const slideInBottom = trigger('slideInBottom', [
  state(
    'void',
    style({
      transform: 'translate3d(0, 30%, 0)',
    })
  ),

  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    })
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),

  // Transition
  transition('void => *', animate('{{timings}}'), {
    params: {
      timings: `225ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    },
  }),
]);

// -----------------------------------------------------------------------------------------------------
// @ Slide in left
// -----------------------------------------------------------------------------------------------------
const slideInLeft = trigger('slideInLeft', [
  state(
    'void',
    style({
      transform: 'translate3d(-100%, 0, 0)',
    })
  ),

  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    })
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),

  // Transition
  transition('void => *', animate('{{timings}}'), {
    params: {
      timings: `225ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    },
  }),
]);

// -----------------------------------------------------------------------------------------------------
// @ Slide in right
// -----------------------------------------------------------------------------------------------------
const slideInRight = trigger('slideInRight', [
  state(
    'void',
    style({
      transform: 'translate3d(100%, 0, 0)',
    })
  ),

  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    })
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),

  // Transition
  transition('void => *', animate('{{timings}}'), {
    params: {
      timings: `225ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    },
  }),
]);

// -----------------------------------------------------------------------------------------------------
// @ Slide out top
// -----------------------------------------------------------------------------------------------------
const slideOutTop = trigger('slideOutTop', [
  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    })
  ),

  state(
    'void',
    style({
      transform: 'translate3d(0, -100%, 0)',
    })
  ),

  // Prevent the transition if the state is false
  transition('false => void', []),

  // Transition
  transition('* => void', animate('{{timings}}'), {
    params: {
      timings: `225ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    },
  }),
]);

// -----------------------------------------------------------------------------------------------------
// @ Slide out bottom
// -----------------------------------------------------------------------------------------------------
const slideOutBottom = trigger('slideOutBottom', [
  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    })
  ),

  state(
    'void',
    style({
      transform: 'translate3d(0, 100%, 0)',
    })
  ),

  // Prevent the transition if the state is false
  transition('false => void', []),

  // Transition
  transition('* => void', animate('{{timings}}'), {
    params: {
      timings: `225ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    },
  }),
]);

// -----------------------------------------------------------------------------------------------------
// @ Slide out left
// -----------------------------------------------------------------------------------------------------
const slideOutLeft = trigger('slideOutLeft', [
  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    })
  ),

  state(
    'void',
    style({
      transform: 'translate3d(-100%, 0, 0)',
    })
  ),

  // Prevent the transition if the state is false
  transition('false => void', []),

  // Transition
  transition('* => void', animate('{{timings}}'), {
    params: {
      timings: `225ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    },
  }),
]);

// -----------------------------------------------------------------------------------------------------
// @ Slide out right
// -----------------------------------------------------------------------------------------------------
const slideOutRight = trigger('slideOutRight', [
  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    })
  ),

  state(
    'void',
    style({
      transform: 'translate3d(100%, 0, 0)',
    })
  ),

  // Prevent the transition if the state is false
  transition('false => void', []),

  // Transition
  transition('* => void', animate('{{timings}}'), {
    params: {
      timings: `225ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    },
  }),
]);

const scaleIn = animation([
  style({ opacity: 0, transform: 'scale(0.5)' }), // start state
  animate(
    '{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)',
    style({ opacity: 1, transform: 'scale(1)' })
  ),
]);

const scaleOut = animation([
  animate(
    '{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)',
    style({ opacity: 0, transform: 'scale(0.5)' })
  ),
]);

export {
  scaleIn,
  scaleOut,
  expand,
  collapse,
  slideInTop,
  slideInBottom,
  slideInLeft,
  slideInRight,
  slideOutTop,
  slideOutBottom,
  slideOutLeft,
  slideOutRight,
};

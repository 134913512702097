<div class="dialog-aviso" aria-hidden="true">
  <div class="dialog-aviso__img">
    <img [src]="'assets/imgs/svg/icon_atencao.svg'" alt="Ícone de exclamação" />
  </div>

  <div class="dialog-aviso__title" tabindex="0">
    <span>OPS...</span>
  </div>

  <div class="dialog-aviso__content" tabindex="0">
    <p>
      Não Identificamos no nosso sistema que o seu cadastro é de representante
      legal. Para acessar a área "Para Negócios" você precisa se
      <a
        role="link"
        (click)="novaPagina()"
        (keypress)="popUpNovo($event)"
        tabindex="0"
        aria-label="clique aqui para ser direcionado para a página de novidades"
        ><span> cadastrar como representante legal.</span>
      </a>
    </p>
  </div>

  <div class="dialog-aviso__button">
    <button
      mat-icon-button
      mat-dialog-close
      [mat-dialog-close]="true"
      class="button_close"
      aria-label="Fechar a caixa de dialogo"
      id="fechaPop"
      (click)="navegarFecharPoUp()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'agv-tabela-admin',
  templateUrl: './tabela-admin.component.html',
  styleUrls: ['./tabela-admin.component.scss'],
})
export class TabelaAdminComponent implements OnChanges {
  // Controlador do tema
  currentTheme: string;

  @Input() colunas: any;
  @Input() dados: any[] = [];
  @Input() alinhamento: string;
  @Input() placeholder: string;

  @Output() edit = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  itensPorPagina: number = 5;
  paginaAtual: number = 1;
  filtroServico: string = '';
  dadosOriginais: any[]; // Variável para armazenar os dados originais
  destroyed$ = new Subject<boolean>();
  colunaFiltro: string = 'nome'; // Defina uma coluna padrão para a filtragem, por exemplo, 'nome'

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.dados?.length > 0) this.dadosOriginais = [...this.dados];
  }


  editItem(item: any) {
    this.edit.emit(item);
  }

  removeItem(item: any) {
    this.remove.emit(item);
  }

  isDate(value: any): boolean {
    return value instanceof Date;
  }

  atualizarItensPorPagina(novoLimite: number) {
    this.itensPorPagina = novoLimite;
  }

  selecionarItensPorPagina(limite: number) {
    this.itensPorPagina = limite;
  }

  aplicarFiltro(filtro: string) {
    setTimeout(() => {
      this.filtroServico = filtro;
      const filtroNormalizado = this.removerAcentosEToLower(filtro);
      this.dados = this.dadosOriginais.filter((dado) => {
        const colunaIndex = 1;
        const coluna = this.colunas[colunaIndex].field;
        const dadoNormalizado = this.removerAcentosEToLower(dado[coluna]);
        return dadoNormalizado.includes(filtroNormalizado);
      });

      if (!filtro) {
        this.dados = [...this.dadosOriginais];
      }
    }, 300);
  }

  atualizarPaginaAtual(pagina: number) {
    this.paginaAtual = pagina;
  }

  getListaItensPaginados(): any[] {
    const indiceInicial = (this.paginaAtual - 1) * this.itensPorPagina;
    const indiceFinal = indiceInicial + this.itensPorPagina;
    return this.dados?.slice(indiceInicial, indiceFinal).length > 0
      ? this.dados?.slice(indiceInicial, indiceFinal)
      : this.dados;
  }

  removerAcentosEToLower(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }
}

<div id="select-fornec-container" class="select-fornec">
    <div id="select-fornec-conteudo">
        <div id="select-fornec-title" class="select-fornec__title">
            <span>Selecione os fornecimentos na lista abaixo</span>
            <p>Confira a lista de fornecimentos vinculados ao CNPJ em que você será o representante legal</p>
        </div>
        <div id="select-fornec-input-container" class="select-fornec__input-container">
            <div id="select-fornec-input" class="select-fornec__input-container__input">
                <label>Busque por número de fornecimento</label>
                <input (keyup)="filtrarFornecimentos()" placeholder="Digite o fornecimento" [(ngModel)]="campoBusca">
            </div>
            <agv-icon [name]="'icn_search_mobile'"></agv-icon>
        </div>
        <div id="select-fornec-lista-fornec">
            <div class="select-fornec__checkbox-selectAll">
                <mat-checkbox [checked]="selectTodos" (change)="selectAll($event)" color="primary"></mat-checkbox>
                <span>Selecionar todos</span>
            </div>
            <div [ngClass]="{'lista-fornec-checked': fornecimento.selecionado}" [id]="fornecimento.codigoFornecimento"
                *ngFor="let fornecimento of fornecimentos" class="select-fornec__lista-fornec">
                <mat-checkbox [checked]="fornecimento.selecionado"
                    (change)="checkbox($event, fornecimento.codigoFornecimento)" color="primary"></mat-checkbox>
                <div class="select-fornec__lista-fornec__desc">
                    <span>Fornecimento: {{fornecimento.codigoFornecimento}}</span>
                    <p>{{fornecimento.idToponimo}} {{fornecimento.endereco}} {{fornecimento.cidade}} -
                        {{fornecimento.uf}}</p>
                </div>
            </div>
        </div>
        <!-- TODO: Paginação de fornecimentos -->
    </div>
</div>

<div class="backgrounModal" id="popUpInformativo" >
  <div class="layoutModal">
    <div role="group" class="popUpContainer">
      <h3 tabindex="0" #popUpDoc>Validação do seu documento</h3>
      <div>
        <div tabindex="0" aria-label="Envie uma imagem do seu RG, CPF, CNH, o documento deverá conter Nome Completo, CPF e
          Data de Nascimento para efetuar o seu cadastro e ter acesso aos serviços da Agência Virtual Sabesp.">
          <p aria-hidden="true" class="textPopUp">
            Envie uma imagem do seu RG, CPF, CNH, o documento deverá conter <span class="negrito">Nome Completo, CPF e
              Data de Nascimento</span>
            para efetuar
            o seu cadastro e ter acesso aos serviços da Agência Virtual Sabesp.
          </p>
        </div>
        <p class="textPopUp" tabindex="0">
          Para que a análise de documentação possa ser
          mais assertiva e melhorar a qualidade da imagem carregada, leia as nossas orientações abaixo:
        </p>
        <p class="textPopUp" tabindex="0"><span class="bullet">&#8226;</span> Envie uma imagem, conforme o exemplo:</p>
        <div class="agrupaExemploDoc" tabindex="0">
          <div class="exemploDoc">
            <p class="textExemplo"><b>Exemplo:</b></p>
            <div class="docImage">
              <p class="textPopUp">Documento:</p>
              <img src="../../../../../assets/imgs/png/modeloRG.jpg"
                alt="A imagem possui um documento com foto frente e verso onde todas as informações estão legíveis">
            </div>
          </div>
        </div>
        <div>
          <p class="textPopUp" tabindex="0"><span class="bullet">&#8226;</span>Certifique-se de que o documento seja
            colocado em uma superfície que não mostre cores ou
            iluminação diferente.</p>
          <p class="textPopUp" tabindex="0"><span class="bullet">&#8226;</span>Enquadre o documento na câmera garantindo
            que ele ocupe a maior área da imagem, verifique
            se há uma boa legibilidade das informações.</p>
        </div>
      </div>
      <div role="button" class="footer-modal" tabindex="0" aria-label="fechar pop-up" (click)="closedModal($event)" (keypress)="closedModal($event)">
        <mat-icon id="botao-modal-fechar" mat-button>highlight_off</mat-icon>
      </div>
    </div>
  </div>
</div>
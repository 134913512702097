<section id="modal_redicionamento-maps" class="redicionamento-maps">
    <agv-icon name="icn_localizacao"></agv-icon>
    <p id="modal_redicionamento-maps_mensagem" class="redicionamento-maps__mensagem">
        Você está sendo redirecionado para google maps.
    </p>
    <button class="redicionamento-maps__botao-redireciona" (click)="redirecionarMaps()">Abrir</button>
    <button mat-icon-button mat-dialog-close [mat-dialog-close]="true"
        class="redicionamento-maps__close" aria-label="Fechar a caixa de dialogo" id="fechaPop">
        <mat-icon>close</mat-icon>
    </button>
</section>
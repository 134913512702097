import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CarregamentoService {
  constructor() {}

  private _carregamentoSubject = new BehaviorSubject<boolean>(false);

  iniciarCarregamento(): void {
    this._carregamentoSubject.next(true);
  }

  pararCarregamento(): void {
    this._carregamentoSubject.next(false);
  }

  estaCarregando(): Observable<boolean> {
    return this._carregamentoSubject.asObservable().pipe(delay(0));
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly BASE_URL = `${environment.uri}`;
  constructor(private http: HttpClient) {}

  authJWT(jwt: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${jwt}`)
    return this.http.get(`${this.BASE_URL}v1/auth/check`, {headers}
    );
  }
}

<div class="formulario-cartao-dados" *ngIf="!fluxoConcluido">
  <div class="header-formulario">
    <div class="block-icon-title-subtitle">
      <div class="background-icon">
        <img src="'../../../../assets/imgs/svg/cartao-credito.svg" alt="" class="img-icon-card" />
      </div>
      <div class="content-title-subtitle">
        <span class="title">Dados do seu cartão</span>
        <span class="subtitle">Informe os dados do seu cartão de crédito</span>
      </div>
    </div>
    <div class="content-img-bandeiras">
      <img src="'../../../../assets/imgs/svg/visa.svg" alt="" class="bandeiras" />
      <img src="'../../../../assets/imgs/svg/mastercard.svg" alt="" class="bandeiras" />
      <img src="'../../../../assets/imgs/svg/hipercard.svg" alt="" class="bandeiras" />
      <img src="'../../../../assets/imgs/svg/elo.svg" alt="" class="bandeiras" />
      <img src="'../../../../assets/imgs/svg/diners.svg" alt="" class="bandeiras" />
    </div>
  </div>
  <div class="row-grey"></div>
  <div class="campos-formulario-2colunas">
    <div class="campos-formulario" [ngSwitch]="step.passoAtual">
      <section id="campo_escolhecartaoSalvo" *ngSwitchCase="'selecionaCartao'">
        <app-box-cartoes-salvos [listaCartao]="listaCartoes"
          (emiteCartao)="capturaCartaoSalvoSelecionado($event)"></app-box-cartoes-salvos>
      </section>
      <section *ngSwitchCase="'insereDadosNovoCartao'">
        <agv-input-select [structor]="structorSelect" [blocked]="true" class="campo-forma-pag"></agv-input-select>
        <div class="box-novo-cartao">
          <app-input-dadosCartao (enviaDadosCartao)="capturaDadosCartaoInserido($event)"
            (enviaTokenCartao)="capturaTokenCartaoInserido($event)"></app-input-dadosCartao>
          <app-input-mtdo-pag (enviaMtdoPagamento)="capturaDadosMtdPagamento($event)"
            [listaCondicoesParcelamento]="listaParcelamento" [valoresMulta]="valoresMulta"></app-input-mtdo-pag>
          <div class="content-checkbox">
            <mat-checkbox id="autorizaSalvarDados" class="checkbox" [(ngModel)]="salvarCartao" role="checkbox"
              color="primary" tabIndex="0">Armazenar os dados do cartão para pagamentos futuro.</mat-checkbox>
          </div>
        </div>
      </section>
      <section *ngSwitchCase="'insereMtdoPagCartaoSalvo'">
        <div tabindex="0" class="campoCartao">
          <agv-icon id="icnBandeiraFluxoCartaoSalvo" class="bandeiraCartao" role="img"
            [name]="novoCartaoInformacoes.brand"></agv-icon>
          <div tabindex="0" class="infoCartao">
            <p>
              {{
              novoCartaoInformacoes.brand.charAt(0).toUpperCase() +
              novoCartaoInformacoes.brand.slice(1)
              }}
              **** {{ novoCartaoInformacoes.last_digits }}
            </p>
            <p>
              Vencimento: {{ novoCartaoInformacoes.exp_month }}/{{
              novoCartaoInformacoes.exp_year
              }}
            </p>
          </div>
        </div>
        <app-input-mtdo-pag style="display: block; margin-top: 16px" [listaCondicoesParcelamento]="listaParcelamento"
          [valoresMulta]="valoresMulta" (enviaMtdoPagamento)="capturaDadosMtdPagamento($event)"></app-input-mtdo-pag>
      </section>
      <section id="campo_checkoutPagamento" *ngSwitchCase="'checkoutPagamento'">
        <app-checkout-cartao [valorJuros]="(valoresMulta.valorAtualizado - valoresMulta.valorOriginal)" [dadosCartao]="novoCartaoInformacoes" [dadosPagamento]="dadosPagamento"
          (retornaFluxo)="capturaRetornPasso($event)"></app-checkout-cartao>
      </section>
    </div>
    <div class="content-button-total-pagar">
      <div class="content-campo-total-pagar">
        <div class="div-total-pagar">
          <div class="label-sem-juros">
            <label class="label-total-pagar"><strong>Total a pagar</strong></label>
          </div>
          <div class="valor">
            <h1>{{ faturaValor | currency }}</h1>
          </div>
        </div>
      </div>
      <div class="button">
        <agv-button [loading]="loading" [habilita]="!habilitaBotao()" (sendAction)="changeStep()" [type]="'1'" [text]="
            step.passoAtual === 'checkoutPagamento'
              ? 'Finalizar pagamento'
              : 'Continuar'
          " [loading]="loading"></agv-button>
      </div>
    </div>
  </div>
</div>
<div class="tela-sucesso" *ngIf="fluxoConcluido">
  <app-notificacao-processo tabIndex="0" [tipoMensagem]="mensagemFluxoFinalizado.imagem"
    [tituloMensagem]="mensagemFluxoFinalizado.titulo" [mensagem]="mensagemFluxoFinalizado.mensagem">
  </app-notificacao-processo>
</div>
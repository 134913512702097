import { Component, OnInit, AfterViewInit, Input, ViewEncapsulation, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Validator } from '../../utils/validator';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit, AfterViewInit {
  @ViewChild('matTabGroup') matTabGroup: ElementRef<HTMLDivElement> | any;

  @Input() showBtnVoltar = true;
  changeColor: boolean = false;

  public breadcrumbs: Breadcrumb[] = [];
  private currentRouteUrl: string = ''; // Variável para armazenar o caminho da rota atual

  constructor(
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private tokenService: TokenStorageService
  ) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd),
      startWith(this.router))
      .subscribe(() => {
        this.montaCaminhoPao();
        this.scrollToLastTab();
      });
  }

  ngAfterViewInit() {
    this.scrollToLastTab();
  }

  ngOnInit() { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.scrollToLastTab();
  } 

  scrollToLastTab() {
    setTimeout(() => {
      if (this.matTabGroup && this.matTabGroup._elementRef.nativeElement) {
        const tabGroup = this.matTabGroup._elementRef.nativeElement;
        const tabs = tabGroup.querySelectorAll('.mat-tab-label');
        const ultimoTab = tabs[tabs.length - 1];
        if (ultimoTab) {
          ultimoTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
        }
      }
    }, 100)
  }

  montaCaminhoPao() {
    const breadcrumbs: Breadcrumb[] = [];

    this.breadcrumbs = [];
    this.currentRouteUrl = ''; // Limpa o caminho da rota atual

    let currentRoute = this.router.routerState.root.firstChild;
    const changeColor =
      this.route.snapshot?.firstChild?.data['changeColor'] || false;

    // Mantenha o controle de quais rótulos já foram adicionados aos breadcrumbs
    const addedLabels: string[] = [];
    while (currentRoute) {
      const routeData = currentRoute.snapshot.data;
      if (routeData && routeData['title']) {
        // Verifica se a rota é "Home" e o item do localStorage fluxoPJ é true
        if (
          routeData['title'] === 'Home' &&
          localStorage.getItem('FluxoPJ') &&
          !addedLabels.includes(routeData['title'])
        ) {
          breadcrumbs.push({
            label: routeData['title'],
            url: '/negocios/empresa', // Define a URL para "/negocios/empresa"
          });
          addedLabels.push(routeData['title']); // Adiciona o rótulo à lista de rótulos adicionados
        } else {
          // Verifica se o rótulo já foi adicionado anteriormente
          if (!addedLabels.includes(routeData['title'])) {
            breadcrumbs.push({
              label: routeData['title'],
              url: this.getUrl(currentRoute),
            });
            addedLabels.push(routeData['title']); // Adiciona o rótulo à lista de rótulos adicionados
          }
        }

        this.currentRouteUrl = this.getUrl(currentRoute); // Atualiza o caminho da rota atual
      }
      currentRoute = currentRoute.firstChild;
      this.changeColor = changeColor;
    }

    this.breadcrumbs = breadcrumbs;
  }

  locationBack(event: any) {
    if (Validator.validaEventKeyPressClick(event)) {
      if (this.breadcrumbs.length >= 2) {
        const currentBreadcrumb = this.breadcrumbs.find(
          (breadcrumb) => breadcrumb.url === this.currentRouteUrl
        );
        if (currentBreadcrumb) {
          const currentIndex = this.breadcrumbs.indexOf(currentBreadcrumb);
          const previousUrl = this.breadcrumbs[currentIndex - 1].url;
          // Se o usuário não for um cliente e tentar acessar esta página diretamente,
          // redireciona-o de volta para a tela inicial em vez de procurar um serviço,
          // pois um redirecionamento já ocorreu quando a fatura foi completada.
          if (
            previousUrl === '' &&
            this.tokenService.getToken() &&
            this.tokenService.getCliente()
          ) {
            this.router.navigateByUrl('/fatura-completa');
          } else {
            this.router.navigateByUrl(previousUrl);
          }
        } else {
          this._location.back();
        }
      } else {
        this._location.back();
      }
    }
  }

  geraId(nome: string): string {
    return nome.replace(/\s/g, '').toLowerCase();
  }

  redireciona(link: string) {
    this.router.navigate([link]);
  }

  private getUrl(route: ActivatedRoute): string {
    const segments: string[] = [];
    let currentRoute: ActivatedRoute | null = route;

    while (currentRoute) {
      const urlSegment = currentRoute.snapshot.url
        .map((segment) => segment.path)
        .join('/');

      if (urlSegment && !segments.includes(urlSegment)) {
        // Verifica se o segmento já foi adicionado
        segments.unshift(urlSegment); // Adiciona no início para manter a ordem correta
      }

      currentRoute = currentRoute.parent;
    }

    return segments.length > 0 ? '/' + segments.join('/') : '';
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
}

import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validator } from '../../utils/validator';

interface IDialogError {
  img?: string;
  title: string;
  content: string;
  align?: string;
  imgAnimation?: boolean;
}

@Component({
  selector: 'app-dialog-error',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.scss'],
})
export class DialogErrorComponent implements OnInit {
  img?: string;
  title: string;
  content: string;
  align?: string = 'center';
  imgAnimation?: boolean = false;
  @ViewChild('fecha') fecha: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IDialogError) {}

  ngOnInit() {
    this.img = this.data.img;
    this.title = this.data.title;
    this.content = this.data.content;
    this.align = this.data.align;
    this.imgAnimation = this.data.imgAnimation;
  }

  fechaPopUp(e:any){
    if (Validator.validaEventKeyPressClick(e)) {
      document.getElementById('fechaPop')?.click();
    }
  }

}

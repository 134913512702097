<div id="responsavel" class="responsavel">
  <h2 tabindex="0">{{title}}</h2>
  <p tabindex="0">
    {{subtitle}}
  </p>
  <div id="responsavel__select" class="responsavel__select">
    <agv-input-select [structor]="inputSelect" [blocked]="!this.cliente" (sendValor)="pegaDados($event)"></agv-input-select>
  </div>
  <div id="responsavel__nome" class="responsavel__nome">
    <agv-input-text (sendValor)="getNome($event)" [label]="'Nome Completo *'" [id]="'responsavel__nome'"
      [value]="dadosResponsavel.nome" [placeholder]="'Digite o nome completo'" [blocked]="desabilitaCampo"></agv-input-text>
    <div>
      <p *ngIf="erroInputs.nome" class="error-message">Informe quem estará acompanhando a equipe da Sabesp
        no imóvel.</p>
    </div>
  </div>
  <div id="responsavel__telefone" class="responsavel__telefone">
    <agv-input-telefone (sendValor)="getTelefone($event)" [label]="'Celular *'" [id]="'responsavel__telefone'"
      [value]="dadosResponsavel.celular" [placeholder]="'Digite celular para contato'" [blocked]="desabilitaCampo"></agv-input-telefone>
    <div>
      <p *ngIf="erroInputs.celular"
        class="error-message">Informe um número de celular válido.</p>
    </div>
  </div>
  <div id="responsavel__email" class="responsavel__nome" *ngIf="mostraCampoEmail">
    <agv-input-text label="E-mail *" id="responsavel__email" placeholder="Digite seu e-mail" (sendValor)="getEmail($event)"></agv-input-text>
    <div>
      <p *ngIf="erroInputs.email"
        class="error-message">Informe um e-mail válido.</p>
    </div>
  </div>
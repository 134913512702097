<div id="endereco-corresp-container" class="endereco-corresp">
  <div class="endereco-corresp__title" *ngIf="checked">
    <span>Endereço de correspondência</span>
    <p>É necessário informar um endereço com CEP válido para a entrega de correspondências</p>
  </div>
  <div>
    <div>
      <app-select
        [label]="isMobile? 'Informe o endereço para envio de fatura/corresp. *' : 'Informe o endereço para envio de fatura/correspondência *'"
        [options]="['Igual ao da ligação', 'Novo endereço para envio da fatura']"
        (select)="handleNovoEnderecoCorreio($event)" (emissorValue)="verificaSelecVazio($event)" containerHeight="65px"
        [containerWidth]="isMobile? '314px' : '474px'"
        [selectFieldStyle]="isCampoSelectVazio? style.selectFieldErro : style.selectField"
        [contentStyle]="isCampoSelectVazio? style.contentErro : style.content" [labelStyle]="style.label"
        [placeholderStyle]="isCampoSelectVazio? style.placeholderErro : style.placeholder" [imgStyle]="style.img"
        [optionStyle]="style.option">
        <ng-template let-option #option> {{ option }} </ng-template>
      </app-select>
      <p *ngIf="isCampoSelectVazio" class="mensagem-erro-select">Este campo é obrigatório, por favor selecione uma opção
        para prosseguir. </p>
    </div>
  </div>
  <div *ngIf="showCampoEndereco">
    <form [formGroup]="validacaoEnderecoCorreio">
      <div class="endereco-corresp__input-box input-unico" [ngClass]="{'container-input-cep-erro': mensagemErro}">
        <label>CEP*</label>
        <input placeholder="Digite o CEP" (keyup)="validaCep()" (blur)="this.emissorEnderecoEnvio()" [(ngModel)]="fornecimento.cep" formControlName="cep">
      </div>
      <div class="mensagem-erro" *ngIf="mensagemErro">
        <p>{{mensagemErro}}</p>
      </div>
      <a class="link-cep">Não sei meu CEP</a>
      <div class="agrupa-inputs">
        <div class="endereco-corresp__input-box input-box-disable ">
          <label>UF*</label>
          <input disabled placeholder="UF" [(ngModel)]="fornecimento.uf" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="endereco-corresp__input-box input-box-disable ">
          <label>Município</label>
          <input disabled placeholder="Município" [(ngModel)]="fornecimento.cidade" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div class="endereco-corresp__input-box input-unico">
        <label>Bairro*</label>
        <input placeholder="Digite o bairro" [(ngModel)]="fornecimento.bairro" (blur)="this.emissorEnderecoEnvio()"
          formControlName="bairro">
      </div>
      <div style="margin-bottom: 16px;">
        <app-select label="Tipo de endereço*" (select)="handleEndereco($event)" [options]="['Avenida', 'Rua']"
          [value]="fornecimento.idToponimo? fornecimento.idToponimo: 'Selecione'"
          [containerWidth]="isMobile? '314px' : '474px'" [selectFieldStyle]="style.selectField"
          [contentStyle]="style.content" [labelStyle]="style.label" [placeholderStyle]="style.placeholder"
          [imgStyle]="style.img" [optionStyle]="style.option">
          <ng-template let-option #option> {{ option }} </ng-template>
        </app-select>
      </div>
      <div class="endereco-corresp__input-box input-unico">
        <label>Endereço*</label>
        <input placeholder="Digite o endereço" [(ngModel)]="fornecimento.endereco"
          (blur)="this.emissorEnderecoEnvio()" formControlName="endereco">
      </div>
      <div class="agrupa-inputs">
        <div class="endereco-corresp__input-box">
          <label>Número*</label>
          <input placeholder="Digite o Nº" [(ngModel)]="fornecimento.numero" (blur)="this.emissorEnderecoEnvio()"
            formControlName="numero">
        </div>
        <div class="endereco-corresp__input-box">
          <label>Complemento</label>
          <input placeholder="Digite o complemento" [(ngModel)]="fornecimento.complemento"
            (blur)="this.emissorEnderecoEnvio()" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </form>
  </div>
</div>

import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export class Validator {
  static validarCPF(cpfValidar: string): boolean {
    cpfValidar = cpfValidar.replace('.', '');
    cpfValidar = cpfValidar.replace('.', '');
    cpfValidar = cpfValidar.replace('-', '');

    // considera-se erro cpf's formados por uma sequencia de numeros iguais
    if (
      cpfValidar.includes('00000000000') ||
      cpfValidar.includes('11111111111') ||
      cpfValidar.includes('22222222222') ||
      cpfValidar.includes('33333333333') ||
      cpfValidar.includes('44444444444') ||
      cpfValidar.includes('55555555555') ||
      cpfValidar.includes('66666666666') ||
      cpfValidar.includes('77777777777') ||
      cpfValidar.includes('88888888888') ||
      cpfValidar.includes('99999999999') ||
      cpfValidar.length < 11
    ) {
      return false;
    }

    let d1, d2;
    let digito1, digito2, resto;
    let digitoCPF;
    let nDigResult;

    d1 = d2 = 0;
    digito1 = digito2 = resto = 0;

    for (let nCount = 1; nCount < cpfValidar.length - 1; nCount++) {
      digitoCPF = parseInt(cpfValidar.substring(nCount - 1, nCount));

      // multiplique a ultima casa por 2 a seguinte por 3 a seguinte por 4
      // e assim por diante.
      d1 = d1 + (11 - nCount) * digitoCPF;

      // para o segundo digito repita o procedimento incluindo o primeiro
      // digito calculado no passo anterior.
      d2 = d2 + (12 - nCount) * digitoCPF;
    }

    // Primeiro resto da divisão por 11.
    resto = d1 % 11;

    // Se o resultado for 0 ou 1 o digito é 0 caso contrário o digito é 11
    // menos o resultado anterior.
    if (resto < 2) digito1 = 0;
    else digito1 = 11 - resto;

    d2 += 2 * digito1;

    // Segundo resto da divisão por 11.
    resto = d2 % 11;

    // Se o resultado for 0 ou 1 o digito é 0 caso contrário o digito é 11
    // menos o resultado anterior.
    if (resto < 2) digito2 = 0;
    else digito2 = 11 - resto;

    // Digito verificador do CPF que está sendo validado.
    let nDigVerific = cpfValidar.substring(
      cpfValidar.length - 2,
      cpfValidar.length
    );

    // Concatenando o primeiro resto com o segundo.
    nDigResult = '' + digito1 + digito2;

    // comparar o digito verificador do cpf com o primeiro resto + o segundo
    // resto.
    return nDigVerific.includes(nDigResult);
  }

  static validarEmail(email: string): boolean {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email.trim());
  }
  

  //Retorna se o usuário cliar, pressionar o enter ou espaço
  static validaEventKeyPressClick(event: any): boolean {
    let key = event.which || event.keyCode;
    return (
      (event.type === 'keypress' && (key == 13 || key == 32)) ||
      event.type === 'click'
    );
  }

}

import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { PostNoticia } from './models/noticia.models';

@Injectable({
  providedIn: 'root',
})
export class NoticiaService {
  public url = environment.uri;
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private readonly http: HttpClient, private storageService: TokenStorageService
  ) {}

  public getImgGaleria(pagLimit:number, pag:number): Observable<any> {
    const paramsData = {pag: pag, limit: pagLimit};
    const params = new HttpParams({fromObject: paramsData});
    return this.http.get<HttpResponse<any>>(
      `${this.url}v1/home/noticia/galeria`,
      { params}
    );
  }

  public postNoticia(body:PostNoticia): Observable<any> {
    return this.http.post<HttpResponse<any>>(
      `${this.url}v1/home/noticia`, body
    );
  }

  public alterarNoticia(id: any, body:PostNoticia): Observable<any> {
    return this.http.put<HttpResponse<any>>(
      `${this.url}v1/home/noticia/${id}`, body
    );
  }

  public agendaNoticia(body:PostNoticia): Observable<any> {
    return this.http.post<HttpResponse<any>>(
      `${this.url}v1/home/noticia/rascunho`, body
    );
  }

  public alterarAgendarNoticia(id: any, body:PostNoticia): Observable<any> {
    return this.http.put<HttpResponse<any>>(
      `${this.url}v1/home/noticia/rascunho/${id}`, body
    );
  }

  public getTodasNoticias(pag:number, pagLimit:number): Observable<any> {
    const paramsData = {pag: pag, limit: pagLimit};
    const params = new HttpParams({fromObject: paramsData});
    return this.http.get<String>(
      `${this.url}v1/home/noticia/todas`,
      { params}
    );
  }

  public getNoticia(id: any): Observable<any> {
    return this.http.get<String>(
      `${this.url}v1/home/noticia/${id}`
    );
  }

  public removerNoticia(id: any): Observable<any> {
    return this.http.delete<any>(`${this.url}v1/home/noticia/${id}`);
  }
}

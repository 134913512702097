<tbody class="cardFaturaDesktop" *ngIf="faturaRevisadaData">
  <td style="padding-left: 0">
    <input type="checkbox" id="cb2" color="primary" class="checkbox-body" aria-label="Selecionar a Fatura"
      (change)="emitCodPag()" [(ngModel)]="isSelected" />
  </td>

  <td class="conteudo-body" tabindex="0" style="width: 161px;">
    {{ faturaRevisadaData.dataEmissao | date : "dd/MM/yyyy" }}
  </td>

  <td class="conteudo-body" style="width: 149px;" tabindex="0">
    {{ faturaRevisadaData.valor| currency : "BRL" }}
  </td>

  <td class="conteudo-body" style="width: 190px;" tabindex="0">
    {{ faturaRevisadaData.dataVencimento| date : "dd/MM/yyyy" }}
  </td>

  <td tabindex="0" class="conteudo-body" style="color: #DC803E;width: 187px; text-align: center;">
    {{ faturaRevisadaData.estadoSaldoPagamento.charAt(0).toUpperCase() +
    faturaRevisadaData.estadoSaldoPagamento.slice(1).toLowerCase() }}
  </td>
  <td class="action-column" style="width: 235px;">
    <div class="action-column__btns">
      <button id="copyButton" class="btn-custom" disabled>
        <div>
          <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" title="Ícone de copiar" [name]="'icn_copy'"></agv-icon>
          <span>Copiar</span>
        </div>
      </button>
  
      <button class="btn-custom" (click)="downloadFaturaCompleta(faturaRevisadaData, true)">
        <div>
          <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" title="Ícone de visualizar" [name]="'icn_lupa'"></agv-icon>
          <span> Detalhes</span>
        </div>
      </button>
  
      <button class="btn-custom" [fxHide]='liberaLoadingDownload'
        (click)="downloadFaturaCompleta(faturaRevisadaData, false)">
        <div>
          <agv-icon [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" title="Ícone de baixar" [name]="'icn-download'"></agv-icon>
          <span>Baixar</span>
        </div>
      </button>
  
      <button [fxHide]='!liberaLoadingDownload' class="loadingDownload btn-custom">
        <div>
          <div class="loadingDownloadLayout">
            <agv-icon [fxHide]="downloadExec" class="loadingDownload__img" [name]="'button-loading16'"></agv-icon>
            <agv-icon [fxHide]="!downloadExec" [name]="'button-check16'"></agv-icon>
            <p>Aguarde</p>
          </div>
        </div>
      </button>
    </div>
  </td>
</tbody>
<div *ngIf="!faturaRevisadaData && !cobrancaJuridica" class="warning">
  <agv-icon role="img" class="warning__icon" [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" [name]="'icon_atencao'" title="Ícone de alerta"></agv-icon>
  <p>Esta fatura não possui conta revisada.</p>
</div>
<div *ngIf="cobrancaJuridica" class="warning">
  <agv-icon role="img" class="warning__icon" [ngClass]="{ 'dark-theme': currentTheme === 'theme-dark' }" [name]="'icon_atencao'" title="Ícone de alerta"></agv-icon>
  <p>Esta fatura não possui conta revisada e está em cobrança jurídica, portanto não poderá ser exibida.</p>
</div>
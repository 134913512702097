<div id="questionario" class="questionario">
  <div class="questionario__title">
    <h2 tabindex="0" id="questionario__title">
      {{ descricaoPergunta }}
    </h2>

    <h3 *ngIf="subTitulo" id="questionario__subTitle">
      {{ subTitulo }}
    </h3>
  </div>

  <mat-radio-group class="questionario__radio-group" [(ngModel)]="selectoption">
    <ul
      id="questionario__radio-group__opcoes"
      class="questionario__radio-group__opcoes"
    >
      <li
        *ngFor="let pergunta of perguntas; let i = index"
        id="questionario__radio-group__opcoes__action"
        class="questionario__radio-group__opcoes__action"
        (click)="selecionaOpcao(i, pergunta)"
        (keypress)="selecionaOpcao(i, pergunta)"
      >
        <mat-radio-button [value]="i"></mat-radio-button>
        <p tabindex="0">{{ pergunta?.pergunta }}</p>
      </li>
    </ul>
  </mat-radio-group>
</div>

import { createReducer, on } from '@ngrx/store';
import {
  sucessoCadastraSenha,
  sucessoCadastroClienteNeta,
  sucessoCarregaEmail,
  sucessoCarregarDadosCliente,
  sucessoCheckToken,
  sucessoConfirmaDadosCliente,
  sucessoReenviarToken,
} from './cadastro-cliente.actions';
import {
  CheckToken,
  DadosCadastroCliente,
  DadosReenvioToken,
  ResponseProcotolo,
} from '../../models/service/cadastroCliente';

export interface ICadastroState {
  email?: string;
  token?: CheckToken;
  dados?: DadosCadastroCliente;
  dadosReenvioToken?: DadosReenvioToken;
  protocolo?: ResponseProcotolo;
  status?: string;
}

export const cadastroKey = 'PrimeiroAcesso';

export const cadastroInitialState: ICadastroState = {
  email: undefined,
  token: undefined,
  dados: undefined,
  dadosReenvioToken: undefined,
  protocolo: undefined,
  status: '',
};

export const CadastroReducer = createReducer(
  cadastroInitialState,
  on(sucessoCarregaEmail, (state, action) => {
    const newState = Object.assign({}, state, { cadastro: action.dados });
    return newState;
  }),
  on(sucessoCheckToken, (state, action) => {
    const newState = Object.assign({}, state, { cadastro: action.dados });
    return newState;
  }),
  on(sucessoCarregarDadosCliente, (state, action) => {
    const newState = Object.assign({}, state, { cadastro: action.dados });
    return newState;
  }),
  on(sucessoReenviarToken, (state, action) => {
    const newState = Object.assign({}, state, { cadastro: action.dados });
    return newState;
  }),
  on(sucessoConfirmaDadosCliente, (state, action) => {
    const newState = Object.assign({}, state, { cadastro: action.protocolo });
    return newState;
  }),
  on(sucessoCadastroClienteNeta, (state, action) => {
    const newState = Object.assign({}, state, { cadastro: action.protocolo });
    return newState;
  }),
  on(sucessoCadastraSenha, (state) => {
    return {
      ...state,
      status: 'sucesso',
    };
  })
);

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ListaSimularParcelas,
  ValoresMultaCartao,
} from 'src/app/core/models/pagamentoCartao';
import { CartaoServices } from 'src/app/services/cartaoCredito.service';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { InputSelect } from '../agv-material/agv-inputs/input-select/input-select.model';

@Component({
  selector: 'app-input-mtdo-pag',
  templateUrl: './input-mtdo-pag.component.html',
  styleUrls: ['./input-mtdo-pag.component.scss'],
})
export class InputMtdoPagComponent implements OnInit, OnChanges {
  @Input() valoresMulta: ValoresMultaCartao;
  @Input() listaCondicoesParcelamento: ListaSimularParcelas[];
  @Output() enviaMtdoPagamento: EventEmitter<any> = new EventEmitter();

  inputQuantidadeParcelas: InputSelect = new InputSelect();
  mensagemErroMtdoPag: string;
  parcelamento: ListaSimularParcelas = new ListaSimularParcelas();
  valorAtualizado: number;
  mensagemErroqtdParcela: string;
  tokenCliente: string;
  listaParcelamento: string[] = [];
  abreJuros: boolean = false;
  icone: string = 'icn_plus';

  constructor(
    private cartaoService: CartaoServices,
    private tokenService: TokenStorageService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.carregaOpçoesPagamento();
    this.montaStructorParcelas();
  }

  ngOnInit(): void {
    this.carregaOpçoesPagamento();
    this.montaStructorParcelas();
  }

  montaStructorParcelas() {
    this.inputQuantidadeParcelas.placeholder = 'Selecione a quantidade de parcelas';
    this.inputQuantidadeParcelas.label = 'Quantidade de parcelas?';
    this.listaCondicoesParcelamento.map(opcaoParcelamento => {
      if (opcaoParcelamento.parcelas) {
        this.inputQuantidadeParcelas.options.push({ value: opcaoParcelamento.parcelas, labels: [{ value: opcaoParcelamento.label, img: false }] })
      }
    })
  }

  abreCampoJuros() {
    this.abreJuros = !this.abreJuros;
    this.icone = this.abreJuros ? 'icn_menos' : 'icn_plus';
  }

  capturaToken() {
    const token = this.tokenService.getToken();
    this.tokenCliente = token ? token : '';
  }

  verificaSelecVazio(e: any) {
    const isCampoSelectVazio = !e;
    this.mensagemErroMtdoPag = isCampoSelectVazio
      ? 'Este dado precisa ser preenchido para prosseguir.'
      : '';
  }

  capturaValorSelectParcelas(e: number): void {
    this.parcelamento.parcelas = e;
    this.emitirDadosPagamento();
  }

  verificaSelecParcelaVazio(e: any) {
    const isCampoSelectVazio = !e;
    this.mensagemErroqtdParcela = isCampoSelectVazio
      ? 'Este dado precisa ser preenchido para prosseguir.'
      : '';
  }

  emitirDadosPagamento() {
    this.enviaMtdoPagamento.emit({
      parcelas: this.parcelamento,
    });
  }

  carregaOpçoesPagamento() {
    this.listaParcelamento = this.listaCondicoesParcelamento.map((parcela) => {
      return parcela.label;
    });
  }

  totalJuros(): number {
    if (this.valoresMulta)
      return (
        this.valoresMulta.valorAtualizado - this.valoresMulta.valorOriginal
      ); else return 0
  }
}

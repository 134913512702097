<div *ngIf="!exibirTelaDeSucesso" class="dialog-pesquisa-satisfacao">
  <div class="dialog-pesquisa-satisfacao__header">
    <span class="titulo">PESQUISA DE SATISFAÇÃO</span>
    <span>De 1 a 5, qual a sua satisfação com este atendimento?</span>
  </div>
  <div class="dialog-pesquisa-satisfacao__content">
    <div class="avaliacao-wrapper">
      <div
        tabindex="0"
        class="numeros"
        *ngFor="let numero of numeros; let i = index"
        (click)="selecionarNumero(i)"
        (keypress)="selecionarNumero(i)"
        [ngClass]="{ selecionado: numero.selecionado }"
      >
        {{ numero.valor }}
      </div>
    </div>

    <form *ngIf="showAvalicao" class="avaliacao-texto" >
      <textarea
        placeholder=" (Opcional) O que você considerou para dar essa nota?"
        maxlength="512"
        [(ngModel)]="observacoes"
      ></textarea>
      <span class="contador-caracter"
        >{{ charactersRemaining }} caracteres restantes</span
      >
    </form>
  </div>
  <div class="dialog-pesquisa-satisfacao__actions">
    <button *ngIf="!loadingRequest" [disabled]="numSelecionado === 0 ||  loadingRequest" (click)="enviarForm()">Enviar</button>
    <div *ngIf="loadingRequest" class="loadingButton">
      <agv-icon [name]="'spinner'"></agv-icon>
      <p>Aguarde</p>
    </div>
  </div>
  <div class="dialog-pesquisa-satisfacao__footer">
    <button mat-dialog-close  mat-icon-button>
      <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="exibirTelaDeSucesso" class="dialog-pesquisa-satisfacao-sucesso">
  <agv-icon id="icone-sucesso" class="sucesso-icon" role="img" [name]="'icon_sucesso'"
  title="Ícone sucesso"></agv-icon>
  <span>A SABESP AGRADECE SUA PARTICIPAÇÃO!</span>
  <div class="wrapper-acao-buttons">
    <button mat-dialog-close >Fechar</button>
  </div>
</div>

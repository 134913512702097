<div id="checkoutPagamento_container" class="checkoutPagamento">
    <div id="dadosPag_header"  class="layoutRow headerSecao">
        <p class="tituloSecao">Dados do cartão</p>
        <button class="botaoEditar" (click)="retornFluxo('cartaoCredito')">Editar</button>
    </div>
    <div tabindex="0" class="campoCartao">
        <div class="infoCampoCartao">
            <agv-icon id="icnBandeiraFluxoCartaoSalvo" class="bandeiraCartao " role="img"
                [name]="dadosCartao.brand? dadosCartao.brand : 'cartao-credito'"></agv-icon>
            <div tabindex="0" class="infoCartao">
                <p>{{dadosCartao.brand.charAt(0).toUpperCase() + dadosCartao.brand.slice(1)}} **** {{dadosCartao.last_digits}}</p>
                <p>Vencimento: {{dadosCartao.exp_month}}/{{dadosCartao.exp_year}}</p>
            </div>
        </div>
    </div>
    <p-divider layout="horizontal"></p-divider>
    <section id="formaPag_conteudo">
        <div id="formaPag_header" class="layoutRow headerSecao">
            <p class="tituloSecao">Forma de pagamento</p>
            <button class="botaoEditar" (click)="retornFluxo('mtdoPagamento')">Editar</button>
        </div>
        <section id="dadosCartão_dadosSensiveis">
            <div id="dadosCartão_nome" class="campoInfo">
                <p class="subtitulo">Valor total</p>
                <p class="conteudo">{{dadosPagamento.valor | currency : "BRL"}}</p>
            </div>
            <div id="dadosCartão_nome" class="campoInfo">
                <p class="subtitulo">Total de Juros, multa e encargos</p>
                <p class="conteudo">{{valorJuros | currency : "BRL"}}</p>
            </div>
            <div *ngIf="dadosPagamento.parcelas" id="dadosCartão_numeroCartao" class="campoInfo">
                <p class="subtitulo">Quantidade de parcelas</p>
                <p class="conteudo">{{dadosPagamento.parcelas}}</p>
            </div>
        </section>
    </section>
</div>
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'agv-input-form-admin',
  templateUrl: './input-form-admin.component.html',
  styleUrls: ['./input-form-admin.component.scss']
})
export class InputFormAdminComponent {
  @Input() label!: string; // Label do input
  @Input() inputId!: string; // ID do input
  @Input() model: any; // Valor do input
  @Input() type: string = 'text'; // Tipo do input
  @Input() fullWidth: boolean = false; // Define se o input terá 100% de largura
  @Input() disabled: boolean = false; // Controla se o input será desabilitado
  @Input() icon?: string; // Novo input para o ícone
  @Output() modelChange = new EventEmitter<any>(); // Emite mudanças no valor do input
}

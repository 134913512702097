<div *ngIf="mostrarOpcoes" id="confirmation-modal" class="modal-opcoes" [ngClass]="{'modal-opcoes': !mostrarOpcoes}">
  <div class="modal-opcoes__content">
    <div>
      <agv-icon id="icone-erro" class="modal-opcoes-img" role="img" [name]="infosModal.icon" title="Ícone informação notícia"></agv-icon>
      <div class="modal-opcoes-titulo">
        <p><strong>{{infosModal.title}}</strong></p>
      </div>
      <p [innerHTML]="infosModal.content"></p>
      <button (click)="actionBtn1($event)" (keypress)="actionBtn1($event)" class="btn-opcao1">{{infosModal.txtBtn1}}</button>
      <button (click)="actionBtn2($event)" (keypress)="actionBtn2($event)" class="btn-opcao2">{{infosModal.txtBtn2}}</button>
    </div>
  </div>
</div>
<div class="modal-opcoes-overlay" id="modalOverlay"></div>
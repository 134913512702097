<div>
    <div role="group" class="validar-usuario">
        <div role="group" class="validar-usuario-content">
                <app-valida-cpf *ngIf="showInputCpf"
                    [showBtn]="!cpfValidado"
                    (redicionaCpf)="validaCpf($event)"
                    (resetInputEvent)="limparCampos()"
                    [origemFluxo]="origemFluxo"
                    [carregaCPF]="documento"
                    [mensagem]="mensagem"
                ></app-valida-cpf>
            <div *ngIf="cpfValidado || !showInputCpf" class="verficacod">
                <div #titulo role="group" tabindex="-1">
                    <h3 *ngIf="fluxoRecSenha" class="titulo">Informe o código de verificação</h3>
                    <h3 *ngIf="!fluxoRecSenha" class="titulo">{{nome | titlecase}}, informe o código de verificação</h3>
                    <div role="group" *ngIf="codVerificaoValidado" class="mensagem-erro"  >
                        <app-mensagem [mensagem]="'Código incorreto. Digite novamente ou solicite novo código.'"></app-mensagem>
                    </div>
                    <span *ngIf="!codVerificaoValidado && !recuperar" class="subtitulo">
                        Enviamos o código de verificação com 6 dígitos para o e-mail 
                        <br>
                        <b>{{emailCliente}}</b>
                        , caso não tenha acesso a esse e-mail faça a <a class="linkInterno" id="linkAtualizacao" (click)="acessarAtualziacao()" (keypress)="keyAcessarAtualziacao($event)">atualização cadastral.</a>
                    </span>
                    <span *ngIf="!codVerificaoValidado  && recuperar" class="subtitulo">
                        Enviamos o código de verificação com 6 dígitos para o e-mail 
                        <br>
                        <b>{{emailCliente}}</b>
                        , caso não tenha acesso a esse e-mail entre em contato com um dos nossos <span class="linkExterno" (click)="redirectCanais()">Canais de Atendimento</span>.
                    </span>
                </div>
                <div role="group" tabindex="0" [attr.aria-label]="nome.split(' ')[0] + ' ,informe o código de verificação. Você recebeu um número de 6 dígitos por e-mail em ' + emailCliente " class="conteudo-inputCod" [ngStyle]="{'margin-top': codVerificaoValidado ? 'initial' : '20px'}">
                  <ng-otp-input title="Campo de código verificador"
                  (onInputChange)="handleChange($event)"
                  [config]="{length:6, allowNumbersOnly: true, inputStyles: otpInput}"></ng-otp-input>
                    <span tabindex="0" role="group" *ngIf="!showSendAgain" class="timer-alert">Código pode ser reenviado em {{counter}}</span>
                    <span tabindex="0" role="group" *ngIf="showSendAgain" role="button" class="subtitulo-cod" (click)="reenviarCodigo()">Reenviar código</span>
                </div>
            </div>
            <button tabindex="0" class="botao-continuar" role="group"
            aria-label="Clique para prosseguir com o cadastro"
            *ngIf="cpfValidado || !showInputCpf"
            [disabled]="codigoConfirmacao.length < 6"
            [ngClass]="{'botao-habilitado': codigoConfirmacao.length === 6}"
            (click)="validaCodVerificacao()"
            mat-raised-button type="submit">Continuar</button>

            <button class="botao-voltar" *ngIf="showVoltar" (click)="voltar()">Voltar</button>
        </div>
    </div>
</div>

import { createReducer, on } from '@ngrx/store';
import {
  atualizaFormularioCorrespondencia,
  atualizaFormularioImovel,
  atualizaFormularioPLA,
  atualizaResponsavel,
  deletaFormularioPLA,
  erroCarregaFormulario,
  sucessoCarregarFormulario,
} from './primeira-ligacao.actions';
import { EnderecoCorreio } from '../../models/enderecos/enderecoCorreio';

export interface FormularioPLA {
  pavimentada: boolean;
  passeio: boolean;
  servicoComercial: string;
}

export interface ResponsavelPLA {
  titular: string;
  responsavel: string;
  telefone: string;
}

export interface FormularioPLAState {
  formulario: FormularioPLA | null;
  enderecoImovel: EnderecoCorreio;
  enderecoCorrespondencia: EnderecoCorreio;
  responsavel: ResponsavelPLA | null;
}

export const PLAKey = 'PLA';

export const initialState: FormularioPLAState = {
  formulario: null,
  enderecoCorrespondencia: new EnderecoCorreio(),
  enderecoImovel: new EnderecoCorreio(),
  responsavel: null,
};

export const FormularioPLAReducer = createReducer(
  initialState,
  on(atualizaFormularioPLA, (state, { formulario }) => {
    return {
      ...state,
      formulario: formulario
        ? { ...state.formulario, ...formulario }
        : state.formulario,
    };
  }),
  on(atualizaFormularioImovel, (state, { enderecoImovel }) => ({
    ...state,
    enderecoImovel: {
      ...state.enderecoImovel,
      ...enderecoImovel,
    },
  })),
  on(
    atualizaFormularioCorrespondencia,
    (state, { enderecoCorrespondencia }) => {
      return {
        ...state,
        enderecoCorrespondencia: enderecoCorrespondencia
          ? { ...state.enderecoCorrespondencia, ...enderecoCorrespondencia }
          : state.enderecoCorrespondencia,
      };
    }
  ),
  on(atualizaResponsavel, (state, { responsavel }) => ({
    ...state,
    responsavel: responsavel
      ? { ...state.responsavel, ...responsavel }
      : state.responsavel,
  })),

  on(sucessoCarregarFormulario, (state, { formularios }) => ({
    ...state,
    enderecoCorrespondencia: {
      ...state.enderecoCorrespondencia,
      ...formularios.enderecoCorrespondencia,
    },
    enderecoImovel: {
      ...state.enderecoImovel,
      ...formularios.enderecoImovel,
    },
    responsavel: formularios.responsavel
      ? { ...state.responsavel, ...formularios.responsavel }
      : state.responsavel,

    formulario: formularios.formulario
      ? { ...state.formulario, ...formularios.formulario }
      : state.formulario,
  })),

  on(erroCarregaFormulario, (state) => ({
    ...state,
    formulario: null,
  })),

  on(deletaFormularioPLA, (state) => ({
    ...state,
    formulario: null,
    enderecoCorrespondencia: new EnderecoCorreio(),
    enderecoImovel: new EnderecoCorreio(),
    responsavel: null,
  }))
);

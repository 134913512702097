import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FornecimentoCorreio } from 'src/app/core/models/fornecimento-correio';
import { EventEmitterService } from 'src/app/event-emits.service';

@Component({
  selector: 'app-select-fornecimento',
  templateUrl: './select-fornecimento.component.html',
  styleUrls: ['./select-fornecimento.component.scss']
})
export class SelectFornecimentoComponent implements OnInit {
  @Output() fornecimentoEscolhido = new EventEmitter();
  fornecimentos: FornecimentoCorreio[];
  fornecimentosSelecionados: FornecimentoCorreio[] = [];
  campoBusca: any;
  selectTodos: boolean;

  constructor(
    
  ) { }

  ngOnInit(): void { }

  filtrarFornecimentos(): void {
    if (this.campoBusca) {
      this.fornecimentos = this.fornecimentos.filter(x => {
        return x.codigoFornecimento.includes(this.campoBusca);
      })
    }
  }

  selectAll(e: any): void {
    this.fornecimentos.forEach(x => {
      x.selecionado = e.checked;
    })
    this.selectTodos = e.checked;
    this.emitFornec()
  }

  checkbox(e: any, id: any): void {
    this.fornecimentos.filter(x => {
      if (x.codigoFornecimento.includes(id)) {
        x.selecionado = e.checked;
      }
    })
    this.fornecimentos.forEach(x => {
      if (!e.selecionado) {
        this.selectTodos = e.selecionador
        return
      }
    })
    this.emitFornec()
  }

  emitFornec(){
    this.fornecimentosSelecionados = this.fornecimentos.filter(x => {
        if(x.selecionado){
          return x
        } else {
          return ''
        }
    })
    this.fornecimentoEscolhido.emit(this.fornecimentosSelecionados);
    EventEmitterService.get('fornecimentoEscolhido').emit(this.fornecimentosSelecionados);
  }
}

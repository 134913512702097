import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var gtag: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  init() {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics}`;
    script.async = true;

    script.onload = () => {
      document.getElementsByTagName('head')[0].appendChild(script);
      const gtagEl = document.createElement('script');
      const gtagBody = document.createTextNode(`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
      `);
      gtagEl.appendChild(gtagBody);
      document.body.appendChild(gtagEl);
      gtag('js', new Date());
      gtag('config', environment.googleAnalytics);
    };
    document.head.appendChild(script);
  }
}

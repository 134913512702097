import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartaoEncriptar } from 'src/app/core/models/pagamentoCartao';
import { Validator } from '../../utils/validator';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { CartaoServices } from 'src/app/services/cartaoCredito.service';

@Component({
  selector: 'app-box-cartoes-salvos',
  templateUrl: './box-cartoes-salvos.component.html',
  styleUrls: ['./box-cartoes-salvos.component.scss']
})
export class BoxCartoesSalvosComponent implements OnInit {
  @Input() gerenciarCartao: boolean;
  @Input() listaCartao: CartaoEncriptar[] = [];
  @Output() emiteCartao: EventEmitter<CartaoEncriptar | null> = new EventEmitter();
  usuarioToken: string;
  cartaoSelecionado: CartaoEncriptar | null;
  isMobile: boolean;

  constructor() { }

  ngOnInit(): void { }


  emitirIdCartao(cartao: CartaoEncriptar | null, e: Event) {
    Validator.validaEventKeyPressClick(e) ? this.emiteCartao.emit(cartao) : '';
    this.cartaoSelecionado = cartao;
  }

}



import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'agv-page-size',
  templateUrl: './page-size.component.html',
  styleUrls: ['./page-size.component.scss']
})
export class PageSizeComponent {

  @Input() itensPorPagina: number = 5;
  @Input() qtdItens: number = 0;
  @Input() isMobile: boolean = false;
  @Input() estiloPersonalizado: boolean;
  @Input() isBloqueioService: boolean = false;

  @Output() itensPorPaginaChange = new EventEmitter<number>(); // Use um evento de saída para notificar o pai sobre a mudança

  itensDesktop: number[] = [5, 10, 20, 30, 40, 50];
  itensDesktopAdministrativo: number[] = [10, 20, 30, 40, 50, 100]
  itensMobile: number[] = [3, 5, 10];

  itensPorPaginaSelecionado(limite: number) {
    this.itensPorPagina = limite;
    this.itensPorPaginaChange.emit(this.itensPorPagina);
  }

  desabilitaBotao(item: number): boolean {
    let restoItensPagina = item - 10;
    return restoItensPagina > this.qtdItens;
  }

}

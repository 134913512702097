<section id="grupoInputDadosCartao" class="containerDadosCartao">
    <form [formGroup]="dadosCartao">
        <div>
            <div id="dadosCartao_nome" class="input" [ngClass]="{'inputErro': msgErro.nomeTitular}">
                <label>Nome impresso no cartão *</label>
                <input type="text" placeholder="Digite o nome impresso no cartão" formControlName="nomeTitular"
                    (ngModelChange)="retiraNumeroCampoNome()" (blur)="validaCampos('nomeTitular')">
            </div>
            <mat-error *ngIf="msgErro.nomeTitular">
                {{msgErro.nomeTitular}}
            </mat-error>
        </div>
        <div clas>
            <div id="dadosCartao_numCartao" class="input" [ngClass]="{'inputErro': msgErro.numCartao}">
                <label>Número do cartão *</label>
                <input type="text" maxlength="19" placeholder="Digite o número do cartão" formControlName="numCartao"
                    (blur)="validaCampos('numCartao')" (ngModelChange)="maskNumeroCartao()"
                    (keypress)="identificarBandeira()">
                <agv-icon id="icone-cartao" role="img" class="img" title="Ícone cartão de crédito"
                    [name]="cartaoEmitido.brand? cartaoEmitido.brand : msgErro.numCartao? 'cartao-credit-erro' : 'cartao-credito'"></agv-icon>
            </div>
            <mat-error *ngIf="msgErro.numCartao">
                {{msgErro.numCartao}}
            </mat-error>
        </div>
        <section id="grupoInputDuasCol" class="grupoInputDuasCol">
            <div>
                <div id="dadosCartao_valCartao" class="input" [ngClass]="{'inputErro': msgErro.validade}">
                    <label>Data de Validade *</label>
                    <input type="text" maxlength="7" placeholder="00/0000" formControlName="validade"
                        (blur)="validaCampos('validade')" (ngModelChange)="maskVencimento()">
                    <agv-icon id="icone-calendario" role="img" class="img" title="Ícone calendario"
                        name="calendar-img"></agv-icon>
                </div>
                <mat-error *ngIf="msgErro.validade">
                    {{msgErro.validade}}
                </mat-error>
            </div>
            <div>
                <div id="dadosCartao_cvv" class="input" [ngClass]="{'inputErro': msgErro.codSeguranca}">
                    <label>Código de Segurança *</label>
                    <input type="text" placeholder="CVV" maxlength="3" formControlName="codSeguranca"
                        (blur)="validaCampos('codSeguranca')">
                    <agv-icon id="icone-ajuda" role="img" class="img" title="Ícone ajuda" name="icn-ajuda"
                        (keypress)="openDialogCVV()" (click)="openDialogCVV()"></agv-icon>
                </div>
                <mat-error *ngIf="msgErro.codSeguranca">
                    {{msgErro.codSeguranca}}
                </mat-error>
            </div>
        </section>
    </form>
</section>
import { HostListener, Component, OnInit, Input } from '@angular/core';
import { EtapaProcesso } from '../../../core/models/etapaProcesso';

@Component({
  selector: 'agv-stepper',
  templateUrl: './agv-stepper.component.html',
  styleUrls: ['./agv-stepper.component.scss'],
})
export class AGVStepperComponent implements OnInit {
  @Input() etapas: EtapaProcesso[] = [];
  @Input() showStepper: boolean = true;
  public innerWidth = window.innerWidth;
  public isMobile: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.verificaTamanhoTela();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    const w = event.target as Window;
    const width = w.innerWidth;
    if (width <= 960) this.isMobile = true;
    else this.isMobile = false;
  }

  verificaTamanhoTela() {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 960) this.isMobile = true;
    else this.isMobile = false;
  }
}

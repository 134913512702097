import { EnderecosPLA } from './enderecoPLA';

export class EnderecoCorreio extends EnderecosPLA {
  [key: string]: string | boolean;
  block: boolean;
  tipoCivicoDesc: string;
  tipoCivico: string;
  civico: string;

  constructor() {
    super();
    this.block = false;
  }
}

<div id="codigo-barras" role="group" class="codigo-barras-container">
  <div id="codigo-barras-conteudo" role="group" class="content"
    [ngStyle]="{'height': hiddeInfosPagto ? (isMobile ? 'initial' : '100%') : '100%'}">
    <div id="codigo-barras-info-fat" role="group" tabindex="0" class="row row__title-mobile">
      <p id="codigo-barras-info-venc" role="text" class="text__venc"><b>{{identificaParcela}} - {{tipo == 'parcelamento'
          ? 'Parcela' : 'Fatura'}} selecionada</b></p>
      <p id="codigo-barras-info-venc" role="text" class="text__venc">{{tipo == 'parcelamento' ?
        'Parcela' : 'Fatura'}} - <b>{{statusTemplate === 'Em pagamento cartão' ? 'Em pagamento com cartão de crédito' : statusTemplate}}</b>
        <span *ngIf="status?.toUpperCase() !== 'PAGA' && status?.toUpperCase() !== 'EM PAGAMENTO CARTÃO'">
          - <b>Total a pagar {{total | currency : 'BRL' }}</b>
        </span>
      </p>
    </div>
    <hr class="divider"
      [ngStyle]="{'margin': hiddeInfosPagto ? (isMobile ? '16px 0' : 'initial') : (isMobile ? '35px 0' : 'initial')}">
    <div id="codigo-barras-opcoes-fat" role="group" class="row row__codigo-mobile">
      <div *ngIf="mensagemFatura === ''">
        <p id="barcode" role="text" class="text__codigo" *ngIf="status?.toUpperCase() !== 'PAGA' && status?.toUpperCase() !== 'EM PAGAMENTO CARTÃO'">{{linhaDigitavel}}
        </p>
        <div id="fat-paga" class="fat-paga" *ngIf="status?.toUpperCase() === 'PAGA'">
          <agv-icon role="img" [name]="'icon_sucesso'"
            title="Ícone de sucesso no processo de baixar segunda via"></agv-icon>
          <p id="msg-fat-paga">Pagamento da fatura já foi realizado! </p>
        </div>
        <div id="fat-paga" class="fat-paga" *ngIf="status?.toUpperCase() === 'EM PAGAMENTO CARTÃO'">
          <agv-icon role="img" [name]="'icon_atencao'"
            title="Ícone de atençaõ"></agv-icon>
          <p id="msg-fat-paga">
            O seu pagamento está sendo processado pala instituição bancária.
            <br/>
            Esse processo pode levar até 24 horas!
          </p>
        </div>
        <div id="codigo-barras-funcionalidades" role="group" class="agrupa-buttons">
          <div id="codigo-barras-opcao-copiar" role="group" class="btn btn__copiar"
            *ngIf="status?.toUpperCase() !== 'PAGA' && status?.toUpperCase() !== 'EM PAGAMENTO CARTÃO' && !copiado"> <mat-icon id="copiar-codigo-barras-img"
              class="icn_copiar">content_copy</mat-icon> <span id="copiar-codigo-barras" role="button" class="text__btn"
              (click)="copyToClipboard()" [cdkCopyToClipboard]="linhaDigitavel" tabindex="0" aria-label="Copiar códido de barras">Copiar código</span> </div>
          <div id="codigo-barras-opcao-copiado" role="group" class="btn btn__copiado" *ngIf="copiado">
            <agv-icon role="img" id="codigo-barras-copiado-img" class="icn_copiado" [name]="'icon_sucesso'"
              title="Ícone de sucesso ao copiar o código de barras"></agv-icon>
            <span id="codigo-barras-copiado" role="button" class="text__btn" tabindex="0"
              aria-label="código de barras copiado">Código copiado</span>
          </div>
          <div tabindex="0" *ngIf="!exibirEmProcesso && status?.toUpperCase() !== 'EM PAGAMENTO CARTÃO'" id="codigo-barras-opcao-pdf" role="button" class="btn btn__copiar"
            (click)="exibePDF(true); loadingControl('exibir')"> <mat-icon id="codigo-barras-pdf-img"
              class="icn_copiar">search</mat-icon> <span id="codigo-barras-exibir-pdf" class="text__btn baixar">Exibir
              em PDF</span>
          </div>
          <div *ngIf="exibirEmProcesso" class="btn btn__copiar">
            <div class="loadingDownloadCodBarras">
              <agv-icon role="img" class="loadingDownloadCodBarras__img" [name]="'button-loading16'"></agv-icon>
              <p>Aguarde</p>
            </div>
          </div>
          <div *ngIf="!downloadEmProcesso && status?.toUpperCase() !== 'EM PAGAMENTO CARTÃO'" id="codigo-barras-opcao-baixar" role="button" class="btn"
            (click)="exibePDF(false); loadingControl('download')">
            <mat-icon id="baixar-codigo-barras-img" class="icn_baixar">file_download_outline</mat-icon>
            <span id="baixar-codigo-barras" class="text__btn baixar" tabindex="0">Baixar em PDF</span>
          </div>
          <div *ngIf="downloadEmProcesso" class="btn">
            <div class="loadingDownloadCodBarras">
              <agv-icon role="img" class="loadingDownloadCodBarras__img" [name]="'button-loading16'"></agv-icon>
              <p>Aguarde</p>
            </div>
          </div>
        </div>
        <div id="secao-img-codigo-barras" role="group" class="row__img-barcode"
          *ngIf="status?.toUpperCase() !== 'PAGA' && status?.toUpperCase() !== 'EM PAGAMENTO CARTÃO'"> <ngx-barcode id="codigo-barras-img" role="img"
            *ngFor="let bcValue of values" [bc-value]="bcValue" [bc-width]="1.16" [bc-height]="76"> </ngx-barcode>
        </div>
      </div>
      <div *ngIf="mensagemFatura !== ''">
        <div id="fat-suspensa" class="fat-suspensa" tabindex="0">
          <agv-icon role="img" id="icn-fat-alert" [name]="'icon_atencao'" title="Ícone de alerta" class="mb-3"></agv-icon>
          <p id="msg-fat-suspensa">{{mensagemFatura}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
export interface ItemsColumn {
  description?: string | null;
  image: string;
  imageDark: string;
  link: string;
  interno: boolean;
  focus?: string | null;
  classCustom?: string | null;
  alt?: string | null;
}

export function factoryItemsColumn(
  description: string,
  image: string,
  imageDark: string,
  link: string,
  interno: boolean
): ItemsColumn {
  return {
    description,
    image,
    imageDark,
    link,
    interno
  };
}

export class FooterColumns {
  name: string;
  classCustom: string;
  items: ItemsColumn[];
  footerChild?: FooterColumns;

  constructor(
    name: string,
    classCustom: string,
    items: ItemsColumn[],
    footerChild?: FooterColumns
  ) {
    this.name = name;
    this.classCustom = classCustom;
    this.items = items;
    this.footerChild = footerChild;
  }
}

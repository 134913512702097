export class ClienteResumido {
  codigo: string;
  nome: string;
  sobrenome: string;
  tipoPessoa: string;

  constructor() {
    this.codigo = '';
    this.nome = '';
    this.sobrenome = '';
    this.tipoPessoa = '';
  }
}

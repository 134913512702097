import { createReducer, on } from '@ngrx/store';
import { BuscaFaturasPorFornecimentoResumido } from '../../models/service/faturasPorFornecimento-resumido';
import { sucessoCarregaParametro } from './parametros.actions';

export interface IParametrosState {
  parametros?: BuscaFaturasPorFornecimentoResumido;
}

export const parametrosKey = 'Parametros';

export const parametrosInitialState: IParametrosState = {
  parametros: undefined,
};

export const ParametrosReducer = createReducer(
  parametrosInitialState,
  on(sucessoCarregaParametro, (state, action) => {
    const newState = Object.assign({}, state, { parametro: action.valor });
    return newState;
  })
);

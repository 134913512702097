import { createAction, props } from '@ngrx/store';

export const setNoticias = createAction(
  '[Noticia] Set Noticias',
  props<{ noticias: any[] }>()
);

export const carregaNoticias = createAction(
  '[Noticia] Buscar Noticia',
);

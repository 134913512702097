import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent {

  @Input() titulo: string;
  @Input() texto: string;
  @Output() eventClose: EventEmitter<any> = new EventEmitter() ; 

  close() {
    this.eventClose.emit();
  }
  
  closePopUp(e: any) {
    let key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.close();
    }
  }
}
<div role="group" id="header-acessibilidade" role="group" class="header-acessibilidade">
  <div role="group" class="header-acessibilidade_acesso-rapido">
    <header>
      <ul id="header-acessibilidade-conteudo-desktop" class="atalhos" *ngIf="!isMobile">
        <li id="acessibilidade-conteudo-1-desktop" (click)="focusAncora('conteudoPrincipal')"><a
            href="#conteudoPrincipal" class="ancora" accesskey="1"><span>1</span> Ir para o conteúdo</a></li>
        <li id="acessibilidade-conteudo-2-desktop" (click)="focusAncora('menuServicos')" *ngIf="home"><a
            href="#menuServicos" class="ancora" accesskey="2"><span>2</span> Ir para o menu</a></li>
        <li id="acessibilidade-conteudo-3-desktop" (click)="focusAncora('rodape')"><a href="#rodape" class="ancora"
            accesskey="3"><span>{{home ? 3 : 2}}</span> Ir para o rodapé</a></li>
      </ul>
      <ul id="header-acessibilidade-conteudo-mobile" class="atalhos" *ngIf="isMobile">
        <li id="acessibilidade-conteudo-1-mobile" (click)="focusAncora('conteudoPrincipal')"><a
            accesskey="1"><span>1</span> Conteúdo</a></li>
        <li id="acessibilidade-conteudo-2-mobile" (click)="focusAncora('menuServicos')" *ngIf="home"><a role="link"
            accesskey="2"><span>2</span> Menu</a></li>
        <li id="acessibilidade-conteudo-3-mobile" (click)="focusAncora('rodape')"><a role="link"
            accesskey="3"><span>{{home ? 3 : 2}}</span> Rodapé</a></li>
      </ul>
    </header>
  </div>
  <div *ngIf="isHomolog" class="aviso-ambiente">
    <span>Ambiente de Homologação</span>
  </div>
  <div *ngIf="isQuality" class="aviso-ambiente-qua">
    <span>Ambiente de Qualidade</span>
  </div>

  <div role="group" id="header-acessibilidade-funcionalidade" role="group" fxFlex="20"
    class="header-acessibilidade_acoes">
    <ul id="content-header__float__acessibilidade__view" class="header-acessibilidade_acoes_Btn">
      <li role="button" tabindex="0" title="aumentar fonte" (click)="aumentaFont()" mat-icon-button>
        <mat-icon>text_increase
        </mat-icon>
      </li>
      <li role="button" tabindex="0" (click)="diminuiFont()" title="diminuir fonte" mat-icon-button>
        <mat-icon>text_decrease
        </mat-icon>
      </li>
    </ul>
    <button id="header-botao-darkmode" role="button" title="Alterar Contraste" (click)="switchMode()" mat-icon-button>
      <mat-icon>contrast
      </mat-icon>
    </button>
  </div>

</div>
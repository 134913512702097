<div [ngStyle]="componentePrincipal ? {'margin-top': marginTop, 'margin-bottom': '70px'} : {'margin-top': marginTop}"
  role="group" class="input-nova-senha-container">
  <div role="group" class="content">
    <span [ngStyle]="{'display': componentePrincipal ? '' : 'none'}" #titulo tabindex="0" class="text__title">
      {{cliente.nome.split(' ')[0] | titlecase}}, validamos sua conta</span>
    <span tabindex="0" *ngIf="!componentePrincipal && descricaoValor != ''" class="text__subtitle">{{descricaoValor}}</span>
    <span tabindex="0" *ngIf="!this.formPw.get('pw')?.value !== this.formPw.get('pwConfirm')?.value && componentePrincipal" class="text__subtitle">Agora
      crie uma senha de acesso</span>
    <div [ngStyle]="{'display': componentePrincipal || mensagemErroTop? '' : 'none'}" role="group" class="mensagem-erro"
      *ngIf="this.formPw.get('pw')?.value !== this.formPw.get('pwConfirm')?.value">
      <app-mensagem [mensagem]="'As senhas devem ser iguais para que você possa prosseguir.'"></app-mensagem>
    </div>
    <form id="form-input-nova-senha" [formGroup]="formPw" (ngSubmit)="atualizarSenha()" class="agrupa-inputs-senha">
      <div id="secao-input-nova-senha" role="group" class="input-senha">
        <div id="box-input-nova-senha" role="group" class="box-input">
          <label class="text__label" for="pw">Senha</label>
          <input (keyup)="liberaConfirmar();enviaReqbody()" id="pw" placeholder="Digite sua senha" [type]="hideSenha ? 'password' : 'text'" class="text__input"
            formControlName="pw" >
        </div>
        <mat-icon id="botao-show-nova-senha" (keypress)="validaClick($event, 'senha')" (click)="validaClick($event, 'senha')">{{hideSenha ? 'visibility_off' :
          'visibility'}}</mat-icon>
      </div>
      <div class="agrupa-input-erro">
        <div role="group" id="secao-input-nova-senha-confirmacao"
          [ngClass]="{'input-senha': true, 'input-senha-error': this.formPw.get('pw')?.value !== this.formPw.get('pwConfirm')?.value}">
          <div role="group" id="box-input-nova-senha-confirmacao" class="box-input">
            <label class="text__label" for="pwConfirm">Confirme sua senha</label>
            <input (keyup)="liberaConfirmar();enviaReqbody()" [ngClass]="{'input-erro': this.formPw.get('pw')?.value !== this.formPw.get('pwConfirm')?.value}" id="pwConfirm"
              placeholder="Digite sua senha" [type]="hideCofirmaSenha ? 'password' : 'text'" class="text__input"
              formControlName="pwConfirm">
          </div>
          <mat-icon id="botao-show-nova-senha-confirmacao" (keypress)="validaClick($event, 'senhaConfirma')"
            (click)="validaClick($event, 'senhaConfirma')">{{hideCofirmaSenha ? 'visibility_off' :
            'visibility'}}</mat-icon>
        </div>
        <div class="input-validacao" tabindex="0">
          <agv-icon *ngIf="validaTouchedDirty()" [name]="validaTamanhoCaracteres()" title="icon-aviso"></agv-icon>
          <span>A senha deve conter de 6 à 16 caracteres</span>
        </div>
        <div class="input-validacao" tabindex="0">
          <agv-icon *ngIf="validaTouchedDirty()" [name]="validaCaraterEspecial()" title="icon-aviso"></agv-icon>
          <span>A senha deve conter pelo menos 1 caracter especial(!@#$%^&*()_+-=)</span>
        </div>
        <div class="input-validacao" tabindex="0">
          <agv-icon *ngIf="validaTouchedDirty()" [name]="validaCaraterEspecialProibidos()" title="icon-aviso"></agv-icon>
          <span>A senha contem caracteres não válidos</span>
        </div>
        <div class="input-validacao" tabindex="0">
          <agv-icon *ngIf="validaTouchedDirty()" [name]="validaLetraMinuscula()" title="icon-aviso"></agv-icon>
          <span> A senha deve conter pelo menos 1 letra minúscula</span>
        </div>
        <div class="input-validacao" tabindex="0">
          <agv-icon *ngIf="validaTouchedDirty()" [name]="validaMaiuscula()" title="icon-aviso"></agv-icon>
          <span> A senha deve conter pelo menos 1 letra maiúscula</span>
        </div>

        <div class="input-validacao" tabindex="0">
          <agv-icon *ngIf="validaTouchedDirty()" [name]="validaNumero()" title="icon-aviso"></agv-icon>
          <span>A senha deve conter pelo menos 1 número</span>
        </div>

        <mat-error *ngIf="this.formPw.get('pw')?.value !== this.formPw.get('pwConfirm')?.value && !componentePrincipal && !mensagemErroTop" tabindex="0">
          As senhas devem ser iguais para que você possa prosseguir.
        </mat-error>
      </div>
      <button [ngStyle]="{'display': componentePrincipal ? '' : 'none'}" id="input-nova-senha-prosseguir "
        class="btn-continuar"
        [disabled]="!liberaConfirmar()"
        type="button" (click)="atualizarSenha()">Continuar</button>

      <button class="btn-voltar" *ngIf="showVoltar" (click)="voltar()">Voltar</button>
    </form>
  </div>
</div>
<div class="localizacao">
  <h1 class="title" tabindex="0">Localização</h1>
  <div *ngIf="!mostrarRecaptcha">
    <div
      *ngIf="errorMessage && errorMessage !== '' && errorMessage.descricao"
      class="error-message"
    >
      <p [innerHTML]="errorMessage.descricao | safe : 'html'"></p>
    </div>
    <agv-input-select
      class="localizacao_selecionaLocalizacao"
      id="input-select-localizar"
      [structor]="selectOptionLocalizar"
      (sendValor)="capturaInfoSelectLocalizar($event)"
    ></agv-input-select>
    <div *ngIf="optionEscolhida === 'Fornecimento'" class="option-details">
      <agv-input-select-endereco
        [mostraNovaOpcao]="true"
        [listaFornec]="lstFornecimento"
        (enviarFornecimentoSelected)="capturaInfoSelectFornecimento($event)"
        [habilita]="true"
      ></agv-input-select-endereco>

      <div *ngIf="fornecimentoSelecionado === ''">
        <agv-input-text
          class="localizacao_margin-bottom"
          label="Outro Fornecimento *"
          placeholder="Digite o número de fornecimento"
          type="number"
          maxlength="20"
          [(ngModel)]="fornecimento"
        ></agv-input-text>
      </div>
    </div>
    <div *ngIf="optionEscolhida === 'Endereço'" class="option-details">
      <agv-endereco-imovel
        [lstaEndereco]="lstaEndereco"
        [abreListaEndereco]="abreListaEndereco"
        [loadingEndereco]="loadingEndereco"
        (enviaFormulario)="atualizarFormulario($event)"
        *ngIf="dadosEnderecos; else msgDados"
        [dadosEnderecos]="dadosEnderecos"
        (enviaEnderecoPreenchido)="recebeEnderecoFormulario($event)"
      ></agv-endereco-imovel>
      <ng-template #msgDados>
        <p>Aguarde um momento por favor ...</p>
      </ng-template>
    </div>
  </div>

  <div class="recaptcha-wrapper" *ngIf="mostrarRecaptcha">
    <div class="texto-recaptcha" *ngIf="lodingRecaptha">
      <p>Carregando recaptcha...</p>
    </div>
    <div *ngIf="!lodingRecaptha" class="recaptcha-items">

      <div
        [ngClass]="{ 'recaptcha-dark': currentTheme === 'theme-dark' }"
        #divRecaptcha
        id="recaptcha"
      ></div>
      <button
        role="button"
        class="login_recaptcha_icon"
        (click)="resetRecaptcha()"
      >
        <agv-icon
          [name]="'icn_reload'"
          title="Botão para recarregar recaptcha"
          tabindex="0"
          [ngClass]="{ 'recaptcha-dark': currentTheme === 'theme-dark' }"
        ></agv-icon>
      </button>
    </div>
  </div>

  <div class="localizacao_container-botao">
    <agv-button
      [id]="''"
      [class]="''"
      [text]="'Continuar'"
      [type]="'1'"
      [loading]="loading"
      [habilita]="verificarHabilitacaoBotaoContinuar()"
      (sendAction)="redirecionaContinuar()"
    ></agv-button>

    <agv-button
      [id]="''"
      [class]="''"
      [habilita]="true"
      [text]="'Cancelar'"
      [type]="'2'"
      [loading]="false"
      (sendAction)="cancel($event)"
    ></agv-button>
  </div>
</div>

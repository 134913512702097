export class IObjectKeys {
    [key: string]: string;
}

export class RetornoParcelas {
    parcelas: number;
    label: string;

    constructor() {
        this.parcelas = 0;
        this.label = '';
    }
}

export class CartaoEncriptar {
    idCartao?: string;
    holder?: string;
    number?: string;
    brand: string;
    securityCode?: string;
    first_digits: string;
    last_digits: string;
    exp_month: string;
    exp_year: string;

    constructor() {
        this.idCartao = '';
        this.holder = '';
        this.number = '';
        this.brand = '';
        this.securityCode = '';
        this.first_digits = '';
        this.last_digits = '';
        this.exp_month = '';
        this.exp_year = '';
    }
}
export class CamposCartaoValidacao extends IObjectKeys {
    nomeTitular: string;
    numCartao: string;
    validade: string;
    codSeguranca: string;
    docTitular: string;

    constructor() {
        super();
        this.nomeTitular = '';
        this.numCartao = '';
        this.validade = '';
        this.codSeguranca = '';
        this.docTitular = '';
    }
}
export class CorpoGerarTokenCliente {
    //propriedades estão em upperCase devido à api do SERASA 
    CustomerId: string; //Passar fornecimento do cliente
    Brand: string;
    Type: string;

    constructor(customerId: string, brand: string) {
        this.CustomerId = customerId ?? '';
        this.Brand = brand ?? '';
        this.Type = 'CreditCard';
    }
}

export interface TokenClienteCredito {
    AccessToken: string
    ReferenceId: string
}

export class CorpoValidacaoDados {
    Customer: ClienteInfoCredito;
    Card: CartaoCreditoInfo;

    constructor(customer?: ClienteInfoCredito, card?: CartaoCreditoInfo) {
        this.Customer = customer ?? new ClienteInfoCredito();
        this.Card = card ?? new CartaoCreditoInfo();
    }
}

export class ClienteInfoCredito {
    Name: string;
    Identity: string;
    IdentityType: string;
    Email: string;
    Birthdate: string
    Phone: string;
    Address: EnderecoCredito;

    constructor(name?: string, identity?: string, email?: string, birthdate?: string, phone?: string, address?: EnderecoCredito) {
        this.Name = name ?? '';
        this.Identity = identity ?? '';
        this.IdentityType = 'CPF';
        this.Email = email ?? '';
        this.Birthdate = birthdate ?? '';
        this.Phone = phone ?? '';
        this.Address = address ?? new EnderecoCredito();
    }
}

export class EnderecoCredito {
    Street: string;
    Number: string;
    Complement: string;
    ZipCode: string;
    City: string;
    State: string;
    Country: string;
    District: string;

    constructor(street?: string, number?: string, complement?: string, zipCode?: string, city?: string, district?: string) {
        this.Street = street ?? '';
        this.Number = number ?? '';
        this.Complement = complement ?? '';
        this.ZipCode = zipCode ?? '';
        this.City = city ?? '';
        this.State = 'SP';
        this.Country = 'BRA';
        this.District = district ?? '';
    }
}

export class CartaoCreditoInfo {
    Type: string;
    Number: string;
    Holder: string;
    ExpirationDate: string;
    SecurityCode: string;
    Brand: string;

    constructor(number?: string, holder?: string, expirationDate?: string, securityCode?: string, brand?: string) {
        this.Type = 'Credit';
        this.Number = number ?? '';
        this.Holder = holder ?? '';
        this.ExpirationDate = expirationDate ?? '';
        this.SecurityCode = securityCode ?? '';
        this.Brand = brand ?? '';
    }
}

export interface CartaoEncriptado {
    holder: string,
    number: string,
    expMonth: string,
    expYear: string,
    securityCode: string
}

export interface CorpoSimularParcCartao {
    codigoFornecimento: string,
    codigoSujeito: string,
    listaCodeline: ListaCodeline[]
}

export class CorpoRetornoSimularParc {
    valorAtualizado: number;
    valorATM: number;
    valorJuros: number;
    valorMulta: number;
    valorOriginal: number;
    valorTRCF: number;
    parcelas: ListaSimularParcelas[]

    constructor() {
        this.valorAtualizado = 0;
        this.parcelas = [];
    }
}

export class ValoresMultaCartao {
    valorAtualizado: number = 0;
    valorATM: number = 0;
    valorJuros: number = 0;
    valorMulta: number = 0;
    valorOriginal: number = 0;
    valorTRCF: number = 0;

    constructor(valorAtualizado: number, valorAtm: number, valorJuros: number, valorMulta: number, valorOriginal: number, valorTrcf: number) {
        this.valorAtualizado = valorAtualizado;
        this.valorATM = valorAtm;
        this.valorJuros = valorJuros;
        this.valorMulta = valorMulta;
        this.valorOriginal = valorOriginal;
        this.valorTRCF = valorTrcf;
    }
}

export class ListaSimularParcelas {
    parcelas: number;
    label: string;

    constructor() {
        this.parcelas = 0;
        this.label = '';
    }
}
export class CorpoEfetivaPag {
    tokenCartao: string; //acessToken
    idCartao: string; //referenceId
    salvarCartao: boolean;
    valorAtualizado: number;
    parcelas: number;
    faturas: string[];
    codigoFornecimento: string;
    cpf_cnpj: string;
    brand: string;
    first_digits: string;
    last_digits: string;
    exp_month: string;
    exp_year: string;
    nome: string;
    email: string;
    nascimento: string;
    area: string;
    telefone: string;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    municipio: string;
    estado: string;
    cep: string;
    pais: string;

    constructor(tokenCartao?: string, idCartao?: string, salvarCartao?: boolean, valorAtualizado?: number, parcelas?: number, faturas?: string[], codFornecimento?: string,
        documento?: string, brand?: string, primeiroDig?: string, ultimosDig?: string, mesExpiracao?: string, anoExpiracao?: string, nome?: string, email?: string, nascimento?: string,
        area?: string, telefone?: string, logradouro?: string, numero?: string, complemento?: string, bairro?: string, municipio?: string, cep?: string) {
            this.tokenCartao = tokenCartao?? '';
            this.idCartao = idCartao?? '';
            this.salvarCartao = salvarCartao?? false;
            this.valorAtualizado = valorAtualizado?? 0;
            this.parcelas = parcelas?? 0;
            this.faturas = faturas?? [];
            this.cpf_cnpj = documento?? '';
            this.brand = brand?? '';
            this.first_digits = primeiroDig?? '';
            this.last_digits = ultimosDig?? '';
            this.exp_month = mesExpiracao?? '';
            this.exp_year = anoExpiracao?? '';
            this.nome = nome?? '';
            this.area = area?? '';
            this.telefone = telefone?? '';
            this.logradouro = logradouro?? '';
            this.numero = numero?? '';
            this.complemento = complemento?? '';
            this.bairro = bairro?? '';
            this.municipio = municipio?? '';
            this.estado = 'SP';
            this.cep = cep?? '';
            this.pais = 'BRA';
    }

}


// export class CorpoEfetivaPag {
//     tokenCartao: string;
//     idCartao: string; // Passar se não tiver token
//     codigoFornecimento: string;
//     salvarCartao: boolean;
//     valorAtualizado: number;
//     parcelas: number;
//     faturas: string[];

//     constructor() {
//         this.idCartao = '';
//         this.tokenCartao = '';
//         this.codigoFornecimento = '';
//         this.salvarCartao = false;
//         this.valorAtualizado = 0;
//         this.parcelas = 0;
//         this.faturas = [];
//     }
// }

export class ListaCodeline {
    codeline: string;
    debitoOriginal: number

    constructor() {
        this.codeline = '',
            this.debitoOriginal = 0
    }
}


import { ListaTipoCivico } from './../shared/components/endereco/getDadosEndereco.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Endereco, Municipio, Tipocivico, Toponimo } from './../core/models/enderecos/endereco';
import { Injectable } from "@angular/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { environment } from "src/environments/environment";
import { ParamBuscaEndereco } from '../core/models/enderecos/paramBuscaEndereco';

@Injectable({providedIn: 'root'})

export class GenericService {

  constructor(
    private http: HttpClient,
) { }

  private readonly BASE_URL = `${environment.uri}`;
  private Unsubscribe$ = new Subject<void>();


  private getRequestOptions(params?: HttpParams): {
    headers: HttpHeaders;
    params?: HttpParams;
} {
    return {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }),
        params: params ? params : undefined,
    };
}


// Métodos para Sabesp
buscaCepImovelSabesp(cep: string): Observable<Endereco[]> {
  return this.http.get<Endereco[]>(
    `${this.BASE_URL}v1/primeira/ligacao/endereco/imovel/cep/${cep}`,
    this.getRequestOptions()
  ).pipe(takeUntil(this.Unsubscribe$));
}
  listaMunicipioSabesp(): Observable<Municipio[]> {
    return this.http.get<Municipio[]>(`${this.BASE_URL}v1/generic/lista/municipio`);
  }

  listaToponimoSabesp(): Observable<Toponimo[]> {
    return this.http.get<Toponimo[]>(`${this.BASE_URL}v1/generic/lista/toponimo`);
  }

  ListaTipoCivicoSabesp(): Observable<Tipocivico[]> {
    return this.http.get<Tipocivico[]>(`${this.BASE_URL}v1/generic/lista/tipocivico`);
  }

  BuscaEnderecoSabesp(param: ParamBuscaEndereco): Observable<any>{
    const p =  new HttpParams({fromObject: {
      codMunicipio: param.codMunicipio, toponimoId: param.toponimoId, via: param.via
    }})
    return this.http.get<any>(`${this.BASE_URL}v1/generic/busca/endereco`, this.getRequestOptions(p))
}



  // Métodos para Correios
  buscaCepImovelCorreios(cep: string): Observable<Endereco[]> {
    return this.http.get<Endereco[]>(
      `${this.BASE_URL}v1/primeira/ligacao/endereco/imovel/cep/${cep}`,
      this.getRequestOptions()
    ).pipe(takeUntil(this.Unsubscribe$));
  }

  listaMunicipioCorreios(): Observable<Municipio[]> {
    return this.http.get<Municipio[]>(`${this.BASE_URL}v1/generic/lista/municipio`);
  }

  listaToponimoCorreios(): Observable<Toponimo[]> {
    return this.http.get<Toponimo[]>(`${this.BASE_URL}v1/generic/lista/correios/toponimo`);
  }

  ListaTipoCivicoCorreios(): Observable<Tipocivico[]> {
    return this.http.get<Tipocivico[]>(`${this.BASE_URL}v1/generic/lista/correios/tipocivico`);
  }

}

<div class="pagination-buttons">
  <button tabindex="0" aria-label="Seta retorna para início" (click)="atualizarPagina(1)" class="itens-page"  
  [disabled]="paginaAtual === 1"
  [ngClass]="{disabled: paginaAtual === 1}">
    <agv-icon [name]="'arrow-left'" [ngClass]="{ 'dark-mode': currentTheme === 'theme-dark' }" title="Seta dupla para esquerda"></agv-icon>  
  </button>
  <button tabindex="0" aria-label="Seta retorna pagina anterior" (click)="paginaAnterior()" class="itens-page"  
  [disabled]="paginaAtual === 1"
  [ngClass]="{disabled: paginaAtual === 1}">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <div *ngFor="let page of getPaginas(); let i = index">
    <button [attr.aria-label]="'Prosseguir para página' + ' ' + page" tabindex="0"
    *ngIf="isMobile 
      ? page === paginaAtual 
      : page >= paginaAtual - 2 && page <= paginaAtual + 2
    "
    class="itens-page"
    (click)="atualizarPagina(page)"
    [ngClass]="{ active: paginaAtual === page }"
    >
      {{ page }}
    </button>
  </div>
  <button tabindex="0" aria-label="Seta prossegue próxima página"  (click)="proximaPagina()" class="itens-page"  
    [disabled]="paginaAtual === ultimaPagina"
    [ngClass]="{disabled: paginaAtual === ultimaPagina}">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <button tabindex="0" aria-label="Seta prossegue para o final" (click)="atualizarPagina(ultimaPagina)" class="itens-page"  
    [disabled]="paginaAtual === ultimaPagina"
    [ngClass]="{disabled: paginaAtual === ultimaPagina}">
    <agv-icon [name]="'arrow-double'" [ngClass]="{ 'dark-mode': currentTheme === 'theme-dark' }" title="Seta dupla para direita"></agv-icon>  
  </button>
</div>

import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { correioEnderecoBodyModel, dadosClienteTrocaModel, dadosTrocaEfetivacaoModel, responseCorreioId, responseEfetivacaoModel } from "../core/models/trocaTitularidade";
import { TokenStorageService } from "./tokenStorageServices.service";
import { Endereco } from "../core/models/enderecos/endereco";
import { InfoArquivo } from "../core/models/service/cadastroCliente";

interface protocolo {
    protocolo:string
}

export interface toponimo {
    id:string,
    cod:string,
    desc:string
}

@Injectable({
    providedIn: 'root'
})

export class TrocaTitularService {
    private readonly BASE_URL = `${environment.uri}`;

    constructor(
        private http: HttpClient,
        private storageService: TokenStorageService
    ) { }
    
    checkFornecimento(fornecimento: string): Observable<{nomeTitular: string, enderecoForencimento: string}> {
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.storageService.getToken()}` });
        return this.http.get<{nomeTitular: string, enderecoForencimento: string}>
            (`${this.BASE_URL}v1/troca/titularidade/check/fornecimento/${fornecimento}`);
    }    
    
    efetivaTroca(body: dadosTrocaEfetivacaoModel): Observable<any> {
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.storageService.getToken()}` });
        return this.http.post<HttpResponse<responseEfetivacaoModel>>
            (`${this.BASE_URL}v1/completa/fatura/troca/titularidade/efetivar`, body);
    }

    buscaEndereco(cep:string):Observable<any>{
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.storageService.getToken()}` });
        return this.http.get<HttpResponse<correioEnderecoBodyModel>>
            (`${this.BASE_URL}v1/troca/titularidade/endereco/correspondencia/cep/${cep}`);
    }

    getInfosDocumentoFrente(fileBase64: any, authToken: string) {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${authToken}`);
        headers.append('Accept', 'application/json');
        return this.http.post<InfoArquivo>(
          `${this.BASE_URL}v1/troca/titularidade/ocr`, fileBase64, { headers }
        );
      }
    
      getInfosDocumentoVerso(fileBase64: any, authToken: string) {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${authToken}`);
        headers.append('Accept', 'application/json');
        return this.http.post<InfoArquivo>(
          `${this.BASE_URL}v1/troca/titularidade/ocr2`, fileBase64, { headers }
        );
      }
}
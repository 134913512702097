import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  Endereco,
  EnderecoBuscaPla,
  Municipio,
  Tipocivico,
  Toponimo,
} from 'src/app/core/models/enderecos/endereco';
import { InputSelect } from '../agv-material/agv-inputs/input-select/input-select.model';
import { IRequestDadosEnderecos } from 'src/app/modules/site-cliente/falta-agua/localizacao/localizacao-falta-agua.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { GenericService } from 'src/app/services/generic.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'agv-endereco-imovel',
  templateUrl: './agv-endereco-imovel.component.html',
  styleUrls: ['./agv-endereco-imovel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgvEnderecoImovelComponent implements OnInit {
  // Actions
  @Output() enviaEnderecoPreenchido: EventEmitter<Endereco> =
    new EventEmitter();

  @Output() enviaFormulario: EventEmitter<any> = new EventEmitter();

  // Inputs
  @Input() enderecoImovel: Endereco = new Endereco();
  @Input() cepInexistente: boolean = false;

  @Input() dadosEnderecos: IRequestDadosEnderecos | null = null;
  @Input() abreListaEndereco: boolean = false;
  @Input() loadingEndereco: boolean = false;
  @Input() lstaEndereco: EnderecoBuscaPla[] = [];

  // Object
  opcoesMunicipio: InputSelect = new InputSelect();
  opcoesTipoCivico: InputSelect = new InputSelect();
  opcoesToponimo: InputSelect = new InputSelect();
  lstTipocivico: Tipocivico[];
  lstToponimo: Toponimo[];
  lstMunicipio: Municipio[];

  enderecoFormulario: FormGroup;
  private unsubscribe$ = new Subject<void>();

  constructor(public fb: FormBuilder, private genericService: GenericService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setEstado();
    this.setMunicipio();
    this.setToponimo();
    this.setTipoCivico();
  }

  ngOnInit(): void {
    this.enderecoFormulario = this.fb.group({
      bairro: [{ value: '', disabled: true }],
      endereco: [null, Validators.required],
      toponimo: ['', Validators.required],
      municipio: ['', Validators.required],
      idLogradouro: ['', Validators.required],
      codMunicipio: ['', Validators.required],
      tipoCivico: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [],
    });

    this.enderecoFormulario.get('endereco')?.disable();
    this.enderecoFormulario.get('toponimo')?.disable();

    // Emitir dados sempre que o formulário for alterado
    this.enderecoFormulario.valueChanges.subscribe((value) => {
      if (this.enderecoFormulario.value) {
        this.enviaFormulario.emit(value);
        this.emitDadosPreenchidos();
      }
    });

    this.setEstado();
    this.setMunicipio();
    this.setToponimo();
    this.setTipoCivico();
  }

  setEstado() {
    this.enderecoImovel.estadoDesc = 'SP';
  }

  setMunicipio() {
    this.opcoesMunicipio.label = 'Município *';
    this.opcoesMunicipio.placeholder = 'Selecione';
    this.listaMunicipio();
  }

  listaMunicipio() {
    this.genericService.listaMunicipioSabesp().subscribe({
      next: (listaMunicipios)=>{
        listaMunicipios.forEach((municipio) => {
          this.opcoesMunicipio.options.push({
            value: municipio.id,
            labels: [{ value: municipio.descricao, img: false }],
            id: municipio.codigo,
          });
        });
      },

      error: (erro)=>{
        this.opcoesMunicipio = new InputSelect()
        console.error(erro)
      },

      complete: ()=>{
        takeUntil(this.unsubscribe$)
      }
    })
  }

  setToponimo() {
    this.opcoesToponimo.label = 'Tipo de Endereço *';
    this.opcoesToponimo.placeholder = 'Selecione';
    this.listaToponimo();
  }

  listaToponimo() {
    this.genericService.listaToponimoSabesp().subscribe({
      next: (listaToponimos)=>{
        listaToponimos.forEach((toponimo) => {
          this.opcoesToponimo.options.push({
            value: toponimo.toponimoId,
            labels: [{ value: toponimo.toponimoDescr, img: false }],
          });
        });
      },

      error: (erro) => {
        this.opcoesToponimo = new InputSelect();
        console.error(erro);
      },

      complete: () => {
        takeUntil(this.unsubscribe$);
      }
    });
  }


  setTipoCivico() {
    this.opcoesTipoCivico.label = 'Tipo de número: *';
    this.opcoesTipoCivico.placeholder = 'Selecione';
    this.listaTipoCivico();
  }

  listaTipoCivico() {
    this.genericService.ListaTipoCivicoSabesp().subscribe({
      next: (listaTipoCivicos) => {
        listaTipoCivicos.forEach((tipoCivico) => {
          this.opcoesTipoCivico.options.push({
            value: tipoCivico.tipoCivicoCodigo,
            labels: [{ value: tipoCivico.tipoCivicoDescr, img: false }],
          });
        });
      },

      error: (erro) => {
        this.opcoesTipoCivico = new InputSelect();
        console.error(erro);
      },

      complete: () => {
        takeUntil(this.unsubscribe$);
      }
    });
  }

  emitDadosPreenchidos() {
    this.enviaEnderecoPreenchido.emit(this.enderecoImovel);
  }

  selecionaLogradouro(endereco: EnderecoBuscaPla) {
    this.enderecoImovel.logradouroDescr = endereco.logradouro;
    this.enderecoImovel.logradouroId = endereco.idLogradouro;
    this.enderecoImovel.bairroId = endereco.idBairro;
    this.enderecoImovel.bairroDescr = endereco.bairro;
    this.enderecoFormulario.patchValue({ bairro: endereco.bairro });
    this.enderecoFormulario.patchValue({ idLogradouro: endereco.idLogradouro });
  }

  setCodigoEndereco(value: any) {
    this.enderecoFormulario.patchValue({ codMunicipio: value?.id });
  }

  setDadoEndereco(e: string, campoEndereco: string) {
    this.enderecoImovel[campoEndereco] = e;
    if (campoEndereco === 'municipioDescr') {
      this.enderecoFormulario.get('endereco')?.enable();
      this.enderecoFormulario.get('toponimo')?.enable();
      this.enderecoFormulario.get('endereco')?.reset();
    }
    this.emitDadosPreenchidos();
  }

  onInputChange(searchValue: any): void {
    if (searchValue.target.value) {
      let texto = searchValue.target.value;
      this.enderecoFormulario.patchValue({ endereco: texto });
    }
  }
  buscarEnderecos(searchValue: string) {}
}

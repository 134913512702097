<div class="page-size">
  <span [ngClass]="{'page-title-personalizado': estiloPersonalizado}" class="page-title">Itens por páginas: </span>
  <div>
    <button *ngFor="let item of (isMobile ? itensMobile : (isBloqueioService ? itensDesktopAdministrativo : itensDesktop))" tabindex="0"
      [attr.aria-label]="'filtra' + ' ' + item + ' ' + 'itens por página'" class="itens-page"
      (click)="itensPorPaginaSelecionado(item)"
      [ngClass]="{ active: itensPorPagina === item, disabled: desabilitaBotao(item) }"
      [disabled]="desabilitaBotao(item)">
      {{ item }}
    </button>
  </div>
</div>

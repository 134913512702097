<div class="cadastrar-cliente">
    <div class="cadastrar-cliente_dados">
        <div class="cadastrar-cliente_dados_divide-tela">
            <div [ngClass]="{'dividir-tela-imagem-erro': erroDocFrente}" class="cadastrar-cliente_dividir-tela-imagem">
                <div role="group" *ngIf="loadingFrente" class="loadingService">
                    <agv-icon class="loadingService__img" [name]="'button-loading16'"></agv-icon>
                </div>
                <div *ngIf="!loadingFrente" class="containerUploadImage">
                    <div class="docs">
                        <label for="docFrente" aria-hidden="true" aria-label="Carregar arquivo frente">
                            Documento *
                        </label>
                        <label for="docFrente" tabindex="0" title="Carregar arquivo frente"
                            (keypress)="selecionarLabelArquivo('docFrente', $event)" class="nome-arquivo"
                            (keyup)="emitirDadosCliente()">{{nomeArquivoFrente}}</label>
                        <input id="docFrente" fxHide type="file" (keyup)="emitirDadosCliente()"
                            accept="image/jpg, image/jpeg, image/png" (change)="selecionarArquivo($event, 'frente')"
                            required />
                    </div>
                    <div class="docs-arquivos">
                        <img for="docFrente" *ngIf="docFrente" alt="foto do Documento da frente" [src]="docFrente"
                            class="preview">
                        <agv-icon for="docFrente" *ngIf="!docFrente && !erroDocFrente"
                            title="foto da frente do Documento" class="preview" [name]="'documento_frente'"></agv-icon>

                        <agv-icon for="docVerso" *ngIf="!docFrente && erroDocFrente" alt="foto da frente do Documento"
                            class="preview" [name]="'doc_frente_vermelho'"></agv-icon>
                        <agv-icon *ngIf="docFrente" class="arquivo_icn" [name]="'icn_limpar'" tabindex="0" title="Remover documento frente" (click)="limparAquivo('frente', $event);"></agv-icon>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'dividir-tela-imagem-erro': erroDocVerso}" class="cadastrar-cliente_dividir-tela-imagem">
                <div role="group" *ngIf="loadingVerso" class="loadingService">
                    <agv-icon class="loadingService__img" [name]="'button-loading16'"></agv-icon>
                </div>
                <div *ngIf="!loadingVerso" class="containerUploadImage">
                    <div class="docs">
                        <label for="docVerso" [ngClass]="{'verso-desabilitado': versoDesabilitado}">
                            Documento *
                        </label>
                        <label for="docVerso" tabindex="0" title="Carregar arquivo verso"
                            (keypress)="selecionarLabelArquivo('docVerso', $event)" class="nome-arquivo"
                            [ngClass]="{'verso-desabilitado': versoDesabilitado}"
                            (keyup)="emitirDadosCliente()">{{nomeArquivoVerso}}
                        </label>
                        <input id="docVerso" fxHide type="file" accept="image/jpg, image/jpeg, image/png"
                            [disabled]="versoDesabilitado" (change)="selecionarArquivo($event, 'verso')" />
                    </div>
                    <div class="docs-arquivos">
                        <img for="docVerso" *ngIf="docVerso" alt="foto do verso do documento" [src]="docVerso"
                        class="preview">
                        <agv-icon for="docVerso" *ngIf="(!docVerso && !versoDesabilitado) && !erroDocVerso" 
                        title="foto do verso do documento" class="preview" [name]="'documento_verso'"></agv-icon>
                        <agv-icon for="docVersoInativo" *ngIf="!docFrente || versoDesabilitado"
                            title="foto do verso do documento inativo" [name]="'icn-doc-verso-inativo'" class="preview"></agv-icon>
                        <agv-icon for="docVerso" *ngIf="(!docVerso) && erroDocVerso" 
                        title="foto do verso do documento" class="preview" [name]="'doc_verso_vermelho'"></agv-icon>
                        <agv-icon class="arquivo_icn" *ngIf="docVerso" [name]="'icn_limpar'" tabindex="0" title="Remover documento verso" (click)="limparAquivo('verso', $event);"></agv-icon>
                    </div>
                </div>
            </div>
        </div>
        <mat-error
            *ngIf="arquivoTamExcedido|| tipoArquivoInvalido || documentoInvalido || documentoInfoFaltante || documentoDivergente"
            class="cadastrar-cliente_dados_erro-tamanho-arquivo">
            {{setMessageErrorDoc()}}
        </mat-error>
        <mat-form-field [ngClass]="{'disabled': campoDesabilitado}" tabindex="0"
            class="cadastrar-cliente_dados__input mat-form-field-details" appearance="fill" [formGroup]="validacoes">
            <label for="nomeCliente" class="field-cadastrar-label_disable">Nome completo *</label>
            <input id="nomeCliente" matInput [(ngModel)]="clienteNovoNeta.nome" formControlName="nome" title="Nome"
                class="field-cadastrar-input_disable" placeholder="Digite seu nome" />
            <mat-error *ngIf="validacoes.controls['nome'].hasError('required')">
                Este campo é obrigatório, por favor preencha a informação para prosseguir.
            </mat-error>
        </mat-form-field>
        <mat-form-field [ngClass]="{'disabled': campoDesabilitado}" tabindex="0"
            class="cadastrar-cliente_dados__input mat-form-field-details" appearance="fill">
            <label for="cpfCliente" class="field-cadastrar-label_disable">CPF *</label>
            <input id="cpfCliente" matInput [(ngModel)]="clienteNovoNeta.cpf" disabled class="backgroundUnable"
                aria-label="Digite seu cpf" placeholder="000.000.000-00">
        </mat-form-field>
        <div class="cadastrar-cliente_dados_divide-tela">
            <mat-form-field [ngClass]="{'disabled': campoDesabilitado}"
                class="cadastrar-cliente_dados__input cadastrar-cliente_dividir-tela dataNasc mat-form-field-details"
                appearance="fill" [tabindex]="0" [formGroup]="validacoes">
                <label for="dataNascimentoCliente" class="field-cadastrar-label_disable">Data de Nascimento *</label>
                <input id="dataNascimentoCliente" matInput [(ngModel)]="clienteNovoNeta.dataNascimento"
                    formControlName="dataNascimento" title="DataNascimento" class="field-cadastrar-input_disable"
                    placeholder="00/00/0000" type="text" onfocus="(this.type='date')" max="dataHoje" />
                <mat-error class="telefoneError" *ngIf="form['dataNascimento'].hasError('validIdade')">
                    Menores de idade não podem se cadastrar.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="cadastrar-cliente_dados__input cadastrar-cliente_dividir-tela mat-form-telefone"
                appearance="fill" [formGroup]="validacoes">
                <label for="telefoneCliente" class="field-cadastrar-label">Celular </label>
                <input id="telefoneCliente" matInput #inputDocumento (keyup)="emitirDadosCliente()"
                    [(ngModel)]="clienteNovoNeta.celular" (keyup)="mascaraTelefone()" maxlength="15" type="tel"
                    formControlName="celular" placeholder="(00) 00000-00000" title="Digite o celular"
                    class="field-cadastrar-input" />
                <mat-error class="telefoneError" *ngIf="form['celular'].invalid">
                    Celular inválido. Digite um celular válido para prosseguir.
                </mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="cadastrar-cliente_dados__input mat-form-email" appearance="fill"
            [formGroup]="validacoes">
            <label for="emailCliente" class="field-cadastrar-label">E-mail *</label>
            <input placeholder="Digite seu email" id="emailCliente" matInput #inputDocumento
                (keyup)="emitirDadosCliente()" [(ngModel)]="clienteNovoNeta.email" type="email" formControlName="email"
                required title="Digite seu e-mail" class="field-cadastrar-input" />
            <mat-error *ngIf="form['email'].invalid">
                E-mail inválido. Digite um e-mail válido para prosseguir
            </mat-error>
        </mat-form-field>
        <div role="group" class="checkboxContainer container-aviso" tabindex="0">
            <input type="checkbox" id="cb" color="primary" class="checkbox" [(ngModel)]="dadosIncorretos" />
            <span class="aviso-span">Os dados acima <b>NÃO</b> estão corretos ou <b>NÃO</b> foram encontrados.</span>
        </div>
        <app-form-infos-incorretas *ngIf="dadosIncorretos"
            (emitDadoCorreto)="capturaNovosdados($event)"></app-form-infos-incorretas>
        <div class="container-textoInformativo">
            <p class="textoInformativo">Campos marcados com * são obrigatórios</p>
        </div>
    </div>
</div>
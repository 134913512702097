<div class="sucesso-nova-senha-container">
    <div  class="content">
        <agv-icon class="icn-sucess" [name]="'icon_sucesso'"></agv-icon>
        <h1 tabindex="0" class="text__title">Parabéns!</h1>
        <div tabindex="0" class="box-desc">
            <h1 class="text__desc">Nova senha cadastrada com sucesso.</h1>
            <h1 class="text__desc">Agora você pode acessar a Agência Virtual Sabesp</h1>
        </div>
        <button role="button" tabindex="0" class="btn-voltar text__btn" (click)="voltar()">Retornar para a Home</button>
    </div>
</div>


import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { slideInTop, slideOutTop } from '../../layout/animations/slide';
import { ThemeService } from 'src/app/shared/observables/theme.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-acessibilidade',
  templateUrl: './acessibilidade.component.html',
  styleUrls: ['./acessibilidade.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [slideInTop, slideOutTop],
})
export class AcessibilidadeComponent implements OnInit {
  // Controlador do tema
  currentTheme: string;

  fontSize: number = 0;
  lightTheme: boolean = true;
  isMobile: boolean = false;
  home: boolean = true;
  url: string = window.location.href;

  @Input() isHomolog = environment.env == 'hml';
  @Input() isQuality = environment.env == 'qua';
  fontTeste: any;

  @HostListener('document:keydown.alt.1', ['$event'])
  goToConteudoPrincipal(event: KeyboardEvent) {
    event.preventDefault();
    const elemento = document.querySelector(
      '#acessibilidade-conteudo-1-desktop a'
    ) as HTMLAnchorElement;
    if (elemento) {
      elemento.click();
    }
  }

  @HostListener('document:keydown.alt.2', ['$event'])
  goToMenuServicos(event: KeyboardEvent) {
    event.preventDefault();
    const elemento = document.querySelector(
      '#acessibilidade-conteudo-2-desktop a'
    ) as HTMLAnchorElement;
    if (elemento) {
      elemento.click();
    }
  }

  @HostListener('document:keydown.alt.3', ['$event'])
  goToRodape(event: KeyboardEvent) {
    event.preventDefault();
    const elemento = document.querySelector(
      '#acessibilidade-conteudo-3-desktop a'
    ) as HTMLAnchorElement;
    if (elemento) {
      elemento.click();
    }
  }

  constructor(
    private theme: ThemeService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private breakpointObserver: BreakpointObserver,
  ) {
    console.log(environment.env);
  }

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
    this.renderer.setAttribute(this.document.body, 'class', this.currentTheme);
    this.breakpointObserver.observe('(max-width: 600px)').subscribe((data) => {
      this.isMobile = data.matches;
    });

    if (
      window.location.pathname.length > 1 &&
      !window.location.pathname.includes('representante-legal') &&
      !window.location.pathname.includes('fatura-completa')
    ) {
      this.home = false;
    }
  }

  setFontSize(size: string) {
    let htmlRoot: HTMLElement = <HTMLElement>(
      document.getElementsByTagName('html')[0]
    );
    switch (size) {
      case 'asc':
        if (htmlRoot != null) {
          htmlRoot.style.fontSize = `${1.15}rem`;
          document.body.style.fontSize = `${1.25}rem`;
        }
        break;
      case 'dsc':
        htmlRoot.style.fontSize = `${1}rem`;
        document.body.style.fontSize = `${1}rem`;
        break;
    }
  }

  switchMode() {
    const change =
      this.currentTheme === 'default-theme' ? 'theme-dark' : 'default-theme';
    this.renderer.setAttribute(this.document.body, 'class', change);
    this.theme.changeTheme(change);
  }

  decreaseFontSize() {
    this.fontSize -= 1;
    if (this.fontSize >= 1) {
      document.body.style.fontSize = this.fontSize + 'rem';
    }
  }

  get isDarkMode(): boolean {
    return this.currentTheme === 'theme-dark';
  }

  focusAncora(ancora: string) {
    window.location.href = `${this.url}#${ancora}`;
  }
  //Função para aumentar font da página
  aumentaFont() {
    if (this.fontSize <= 5) {
      this.fontSize++;
      document.documentElement.style.fontSize =`1${this.fontSize*10}%`;
    }
  }
  // Função para diminuir o font da página
  diminuiFont() {
    // Garante que o valor da fonte não fique abaixo de 1 e que só funcione quando o valor for igual ou menor que 6
    if (this.fontSize > 1) {
      // Diminui o valor de fontSize
      this.fontSize--;
      // Aplica o valor de fontSize diretamente como rem
      document.documentElement.style.fontSize =`1${this.fontSize*10}%`;
    }else{
      document.documentElement.style.fontSize = `100%`;
    }
  }
}

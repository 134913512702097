export class TotalCalculoConta {
    valor: number;
    multiplo: number;
    valorTotal: number;

    constructor() {
        this.valor = 0;
        this.multiplo = 0;
        this.valorTotal = 0;
    }
}
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ItensMenu } from 'src/app/core/models/menuServicoItens';
import { ThemeService } from '../../observables/theme.service';
import { MenuServicoService } from './menu-servico.service';
import { environment } from 'src/environments/environment';
import { TabService } from '../tab/tab.service';
import { PrimeIcons } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-servico',
  templateUrl: './menu-servico.component.html',
  styleUrls: ['./menu-servico.component.scss']
})
export class MenuServicoComponent implements OnInit {

  // String
  textoServicos: string = 'Ver mais serviços';
  token: string | null = '';
  currentTheme: string;
  
  // Boolean
  isLogado: boolean = false;
  
  // Outros
  setaExpand = PrimeIcons.ANGLE_DOWN;
  public url = environment.urlAGV;

  // Views
  @ViewChild('meuSvgElement', { static: false }) meuSvgElement!: ElementRef<HTMLImageElement>;

  // Lista
  servicos: ItensMenu[] = [];

  // Boolean
  expandido: boolean = false;

  constructor(
    private readonly menuServicoService: MenuServicoService, 
    private tokenService: TokenStorageService,
    private theme: ThemeService,
    private tabObs: TabService,
    private router: Router,
  ) { }
  
  ngOnInit(): void {
    this.isLogado = this.tokenService.getToken() ? true : false;
    this.theme.currentTheme.subscribe(theme => this.currentTheme = theme);
    this.carregaServicos();
    this.tabObs.currentIndex.subscribe((tab)=>{
      this.carregaServicos();
    })
  }

  carregaServicos() {
    this.servicos = this.menuServicoService.getInfoMenuItens().items;
      if (localStorage.getItem("FluxoPJ")) {
        this.servicos = this.servicos.filter((s) => s.pj);
      }
  }

  onRedirect(item: ItensMenu) {
    if (item.link && item.link !== '') window.location.href = item.link
  }

  expandir() {
    let menu = document.querySelector(".menu-servico");
    let menuServicos = document.querySelector(".menu-servico_servicos");
    if (menu !== undefined && !this.expandido) {
      menu?.classList.add("expandir");
      if (window.innerWidth > 600) {
        let height = ((this.servicos.length / 3) * 150) + 540;
      }
      document.getElementById("seta_expandir")?.classList.add("_rotation_180");
      if (menuServicos !== undefined) {
        setTimeout(()=>{
          menuServicos?.classList.remove("_hidden");
        },500)
      }
      this.textoServicos = 'Ver menos serviços'
      this.expandido = true;
    } else {
      document.getElementById("seta_expandir")?.classList.remove("_rotation_180");
      if (menuServicos !== undefined) {
          menuServicos?.classList.add("_hidden");
      }
      this.expandido = false;
      this.textoServicos = 'Ver mais serviços'
    }
  }

  trataRota(menuItem: ItensMenu) {
    if (menuItem.interno) {
      this.router.navigate([`/${menuItem.link}`])
      menuItem.acao(menuItem.link);
    } else {
      this.redirecionaLinkExterno(menuItem.link)
    }
  }

  redirecionaLinkExterno(link: string): void {
    if (this.checkToken())
      window.open(this.url + `?token=${this.token}&servico=${link}`, '_blank');
    else window.open(`${this.url}${link}`);
  }

  checkToken(): boolean {
    if (this.tokenService.getToken()) {
      this.token = this.tokenService.getToken();
      return true;
    } else {
      return false;
    }
  }
}
